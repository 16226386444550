import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton } from "@mui/material";
import PopupModal from "components/popupModal";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import CustomizedTooltip from "components/customizedTooltip";
import { useDeleteHashtag } from "../../../hooks/useDeleteHashtag";

function DeleteItem({ item, setSnakBarDelete, hashtags, page, setPage }) {
  const intl = useIntl();
  const hashtagName = item?.hashtag;
  const disabledIcon = item?.status !== "inactive";
  const [openPopupDelete, setOpenPopupDelete] = useState(null);
  let modalBody = (
    <Box className="shared-dialog-body">
      <Box
        component={"p"}
      >{`${CheckValueLocale("content_delete_hashtag", "", {}, intl)}`}</Box>
      <Box
        component={"p"}
      >{`${CheckValueLocale("question_content_delete_hashtag", "", { hashtagName }, intl)}`}</Box>
      <Box
        component={"strong"}
      >{`${CheckValueLocale("delete_hashtag_can't_be_reversed", "", {}, intl)}`}</Box>
    </Box>
  );

  const cancelPopupDelet = () => {
    setOpenPopupDelete(null);
  };

  const { mutate: handleDeleteHashtags, isPending: buttonLoading } =
    useDeleteHashtag();

  const confirmDelet = () => {
    handleDeleteHashtags(
      { hashtagId: item?.id },
      {
        onSuccess: () => {
          setSnakBarDelete({
            open: true,
            title: "",
            message: CheckValueLocale(
              "hashtag_delete_msg_success",
              "",
              { hashtagName },
              intl,
            ),
            severity: "success",
          });
          if (hashtags?.length === 1 && page > 1) {
            setPage(page - 1);
          }
        },
        onError: () => {
          setSnakBarDelete({
            open: true,
            title: CheckValueLocale("hashtag_delete_title_error", "", {}, intl),
            message: CheckValueLocale("hashtag_delete_msg_error", "", {}, intl),
            severity: "error",
          });
        },
      },
    );

    setOpenPopupDelete(null);
  };

  const checkToOpenPopupDelete = () => {
    if (!item?.monitors?.length) {
      setOpenPopupDelete(true);
    }
  };
  return (
    <>
      <CustomizedTooltip
        title={
          !disabledIcon ? (
            <Box className="item-title-tooltip">
              {CheckValueLocale("delete", "", {}, intl)}
            </Box>
          ) : (
            ""
          )
        }
        arrow
        placement="bottom"
      >
        <IconButton
          disabled={disabledIcon}
          onClick={checkToOpenPopupDelete}
          id="instagram-hash-delete-btn"
        >
          <FontAwesomeIcon
            className={`delete-icon ${disabledIcon && "delete-icon-disabled"}`}
            icon={faTrashCan}
          />
        </IconButton>
      </CustomizedTooltip>

      {openPopupDelete && (
        <PopupModal
          title={CheckValueLocale("delete_hashtag", "", {}, intl)}
          body={modalBody}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
          open={openPopupDelete || false}
          close={cancelPopupDelet}
          accept={confirmDelet}
          minWidth="600px"
          classeName="popupModal-delet actions-buttons-shared"
          warning
          isRightBtnLoading={buttonLoading}
          disabled={buttonLoading}
        />
      )}
    </>
  );
}

export default DeleteItem;
