// ******************* Any update in this file will affect **************************** //
// ******************* Custom Dashboard Comparison Widgets **************************** //
import _ from "lodash";

// ******************* Transform PieChart to StackedBarChart (Multiple Monitors X Axis) ****************************
// Params:
// data is an array of objects each object for ex: {data: [...], data: [...], monitor_name: "", monitor_id: "", data_source: ""}
const handlePieChartCompare = (data) => {
  const result = { data: [], pie_data: [] };
  const aggregatedPieData = {};
  const allPieNames = []; // Holds all keys names

  data?.forEach((item) => {
    item?.pie_data?.forEach((pieItem) => {
      if (!allPieNames?.includes(pieItem?.name))
        allPieNames?.push(pieItem?.name);
    });
  });

  data?.forEach((item) => {
    const name = item?.monitorName;

    // To reserve place in values array in case of found in one and others not
    const values = allPieNames?.map((pieName) => {
      const pieItem = item?.pie_data?.find((p) => p?.name === pieName);
      return pieItem ? pieItem?.value : 0;
    });

    result?.data?.push({
      name,
      value: values,
      dataSource: item?.dataSource,
    });

    item?.pie_data?.forEach((pieItem) => {
      if (!aggregatedPieData?.[pieItem?.name])
        aggregatedPieData[pieItem?.name] = 0;

      aggregatedPieData[pieItem?.name] += pieItem?.value;
    });
  });

  result.pie_data = Object.keys(aggregatedPieData)?.map((name) => ({
    name,
    value: aggregatedPieData?.[name],
  }));

  return result;
};

// ******************* Transform BarChart to BarChart (Multiple Monitors X Axis) ****************************
// Params: Array of objects each object contains (monitorName, dataSource, data)
// Result example::: {data: newData}
// ** newData schema [{"name": "monitorName (dataSource)", "value": 26863}, ....]
const handleBarComparison = (dataArr) => {
  const newData = [];

  dataArr?.forEach((monitorData) => {
    const monitorValue = monitorData?.data?.reduce(
      (sum, obj) => sum + obj?.value,
      0, // 0 >>> accumulator starts from 0
    );

    return newData?.push({
      name: monitorData?.monitorName,
      value: monitorValue,
      tooltipData: monitorData?.stats_data,
      dataSource: monitorData?.dataSource,
    });
  });

  return { data: newData, dataExcel: dataArr };
};

// ******************* Transform (StackedBarChart or StackedLineChart) to StackedBarChart (Multiple Monitors X Axis) ****************************
// Params: Array of objects each object contains (monitorName, dataSource, pie_data)
// Result example::: {pie_data, data}
// ** pie_data schema [{"name": "comments", "value": 27512}, ....]
// ** data schema [{"name": "monitorName (dataSource)", "value": [26863, 1188912, 341967]}, ....]
const handleStackedBarComparison = (dataArray) => {
  if (dataArray?.length) {
    let keyArray = [];
    const keysIndexesObj = {};

    // To add each key name to the main object and reserve index in values array
    dataArray?.forEach((monitorData) => {
      monitorData?.pie_data?.forEach((item) => {
        if (!keysIndexesObj?.hasOwnProperty(item?.name)) {
          keysIndexesObj[item?.name] = keyArray?.length; // to give index for each key name
          keyArray?.push(item?.name); // key names array
        }
      });
    });

    // return new data array to has values across x-axis
    const newData = dataArray?.map((monitorData) => {
      const responseValues = Array(keyArray?.length)?.fill(0); // zeros array with length of all values keys

      monitorData?.pie_data?.forEach(
        (item) => (responseValues[keysIndexesObj?.[item?.name]] += item?.value),
      );

      // each column x-axis as moniorName (dataSource) ex: `monitor 1 (Facebook)`
      return {
        name: monitorData?.monitorName,
        value: responseValues,
        dataSource: monitorData?.dataSource,
      };
    });

    // Finally newPieData calculated from the newData after comparison
    const newPieData = keyArray?.map((key) => ({ name: key, value: 0 }));
    newData?.forEach((data) =>
      data?.value?.forEach((val, index) => (newPieData[index].value += val)),
    );

    return { pie_data: newPieData, data: newData, dataExcel: dataArray };
  }
};

// ******************* Transform StackedBarChart (Reach Funnel) to StackedBarChart (Reach Funnel) (Multiple Monitors X Axis) ****************************
// Params: Array of objects each object contains (monitorName, dataSource, data)
// Result example::: {pie_data, data}
// ** data schema [{authors: {name: `monitorName (dataSource)`, value: [2123, 871]}}, ....(potential_reach, potential_impression)]
const handleReachFunnelComparison = (dataArray) => {
  const results = {};

  dataArray?.forEach((item) => {
    item?.data?.forEach((metric) => {
      results[metric?.name] = [
        ...(results?.[metric?.name] || []),
        {
          name: item?.monitorName,
          value: metric?.value,
          dataSource: item?.dataSource,
        },
      ];
    });
  });

  return results;
};

// ******************* Transform LineChart to StackedLineChart (Multiple Monitors) ****************************
// Params: Array of objects each object contains (monitorName, dataSource, data)
// Result example::: {data: newData, pie_data: newPieData}
// ** newData schema {"monitorName": [{ name: 1654041600, value: 22 }, ....], .........}
// ** newPieData schema [{name: "monitorName", value: 32 }, ........]
const handleLineChartComparison = (dataArray) => {
  let newData = {};
  let newPieData = [];

  dataArray?.forEach((item) => {
    // To avoid key duplication for the same monitor different data source
    const handledMonitorName = `${item?.monitorName}__${item?.dataSource}__${item?.monitorID}`;

    newData[handledMonitorName] = item?.data;
    newPieData?.push({
      name: item?.monitorName,
      value: item?.total_count,
      dataSource: item?.dataSource,
    });
  });

  return { data: newData, pie_data: newPieData };
};

// ******************* Transform ThemesAnalysisWidget to StackedBarWidget (Multiple Monitors x-axix) ****************************
// Params: Array of objects each object contains (monitorName, dataSource, data)
// Result example::: {data: newData, pie_data: newPieData}
// ** newData schema {"monitorName": [{ name: 1654041600, value: 22 }, ....], .........}
// ** newPieData schema [{name: "monitorName", value: 32 }, ........]
const handleThemesComparison = (dataArray) => {
  let newData = {
    themes: { data: [], pie_data: [] },
    sub_themes: { data: [], pie_data: [] },
  };

  let themesKeys = [];
  let themesIdxObj = {};

  let subThemesKeys = [];
  let subThemesIdxObj = {};

  // avoid un-arranged legends by setting (theme and sub-theme) keys with indexes
  dataArray?.forEach((monitorData) => {
    Object?.keys(monitorData?.data?.theme_analysis?.main_themes_view)?.map(
      (key) => {
        // Main Themes --------------
        if (!themesIdxObj?.hasOwnProperty(key)) {
          themesIdxObj[key] = themesKeys?.length;
          themesKeys?.push(key);
        }
        // Sub Themes --------------
        Object?.keys(
          monitorData?.data?.theme_analysis?.main_themes_view?.[key]
            ?.sub_themes,
        )?.map((subKey) => {
          if (!subThemesIdxObj?.hasOwnProperty(subKey)) {
            subThemesIdxObj[subKey] = subThemesKeys?.length;
            subThemesKeys?.push(subKey);
          }
        });
      },
    );
  });

  // Calculating the sum of each key and setting in the correct index in responses array
  dataArray?.forEach((item) => {
    let themesResultArr = Array(themesKeys?.length)?.fill(0);
    let subThemesResultArr = Array(subThemesKeys?.length)?.fill(0);

    Object?.keys(item?.data?.theme_analysis?.main_themes_view)?.map(
      (themeKey) => {
        themesResultArr[themesIdxObj?.[themeKey]] +=
          item?.data?.theme_analysis?.main_themes_view?.[themeKey]?.count;

        Object?.keys(
          item?.data?.theme_analysis?.main_themes_view?.[themeKey]?.sub_themes,
        )?.map((subKey) => {
          subThemesResultArr[subThemesIdxObj?.[subKey]] +=
            item?.data?.theme_analysis?.main_themes_view?.[
              themeKey
            ]?.sub_themes?.[subKey];
        });
      },
    );

    newData?.themes?.data?.push({
      name: item?.monitorName,
      dataSource: item?.dataSource,
      value: themesResultArr,
    });

    newData?.sub_themes?.data?.push({
      name: item?.monitorName,
      dataSource: item?.dataSource,
      value: subThemesResultArr,
    });
  });

  // calculate pie_data to be used in (themes, sub_themes)
  const calculatePieData = (keys, data) => {
    const pieData = Array(keys?.length)?.fill(0);
    data?.forEach((monitor) =>
      monitor?.value?.forEach((count, index) => (pieData[index] += count)),
    );
    return pieData?.map((count, i) => ({ name: keys[i], value: count }));
  };

  // Calculate pie_data for both themes and sub_themes using the function
  newData.themes.pie_data = calculatePieData(themesKeys, newData?.themes?.data);
  newData.sub_themes.pie_data = calculatePieData(
    subThemesKeys,
    newData?.sub_themes?.data,
  );

  return newData;
};

export {
  handlePieChartCompare,
  handleBarComparison,
  handleStackedBarComparison,
  handleReachFunnelComparison,
  handleLineChartComparison,
  handleThemesComparison,
};
