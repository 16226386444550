import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const StateCell = ({ user }) => {
  const intl = useIntl();
  //Handle Status of User
  const getStatusLocalization = (status) => {
    const account_type = {
      active: CheckValueLocale("active", "", {}, intl),
      deactivated: CheckValueLocale("deactivated", "", {}, intl),
      invited: CheckValueLocale("invited", "", {}, intl),
    };
    return account_type[status];
  };
  return (
    <>
      {user?.attributes?.is_locked ? (
        <Box className="status locked">
          {CheckValueLocale("locked", "", {}, intl)}
        </Box>
      ) : getStatusLocalization(user?.attributes?.status) ? (
        <Box className={"status " + user?.attributes?.status}>
          {getStatusLocalization(user?.attributes?.status)}
        </Box>
      ) : null}
    </>
  );
};

export default StateCell;
