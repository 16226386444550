import {
  Button,
  Chip,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";

const SharedField = (props) => {
  const intl = useIntl();
  const [focused, setFocused] = useState(false);
  const isDisable = !handlelUserRoles("CDP", "EDIT_PROFILE");
  const handleInputMouseDown = (event) => {
    event.preventDefault();
  };

  return (
    <Grid item lg={6} xl={6} xs={6}>
      <InputLabel className="audience-label">
        {CheckValueLocale(props.title, "", {}, intl)}
      </InputLabel>
      <TextField
        className="audience-field"
        variant="outlined"
        type="text"
        name={props.name}
        disabled={isDisable}
        fullWidth
        placeholder={CheckValueLocale(props.placHolder, "", {}, intl)}
        value={props.userValues}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        InputProps={{
          endAdornment: focused && (
            <InputAdornment onMouseDown={handleInputMouseDown}>
              <Button
                className="audience-btn-field"
                onClick={(e) => props.updateWordValues(e, props.name, "btn")}
                value={props.userValues}
                id="audience-user-profile-add-btn"
              >
                {CheckValueLocale("add_plus", "", {}, intl)}
              </Button>
            </InputAdornment>
          ),
        }}
        onChange={props.onChange}
        onKeyDown={(e) => {
          props.updateWordValues(e, props.name);
        }}
        error={props?.updatedError.length > 0 ? true : false}
      />

      {props?.updatedError?.length > 0 && (
        <FormHelperText error>{props?.updatedError}</FormHelperText>
      )}

      {props.selectedWord.map((item, index) => {
        return item?.value ? (
          <Chip
            className={"chip-style"}
            key={index}
            label={
              item?.value
                ? props?.name == "tiktok" && item?.value[0] !== "@"
                  ? "@" + item?.value
                  : item?.value
                : item
            }
            onDelete={
              item?.editable === false || isDisable
                ? ""
                : () => props.deleteWords(index, props.name, item?.id)
            }
          />
        ) : null;
      })}
    </Grid>
  );
};

export default SharedField;
