import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import Snackbar from "components/snackBar";
import ListHeader from "./list/Header";
import ReportList from "./list/List";
import ReportController from "services/controllers/reportController";
import DeleteReportModal from "./DeleteReportModal";
import ListFilters from "./list/Filters";
import CircularLoading from "components/circularProgress";

const Reports = () => {
  const intl = useIntl();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [reports, setReports] = useState([]);
  const [totalReports, setTotalReports] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState({
    id: "",
    name: "",
    open: false,
  });
  const [showActionMessage, setShowActionMessage] = useState({
    type: "",
    message: "",
  });
  const [openFilters, setOpenFilters] = useState(false);
  const [reportFiltersValues, setReportFiltersValues] = useState({});
  const [isRequestError, setIsRequestError] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const getReports = () => {
    ReportController.getReports(searchText, page).then((res) => {
      setIsRequestError(!!res?.errorMsg);
      setReportResponseData(res);
    });
  };

  const setReportResponseData = (res) => {
    res?.data && setReports([...res?.data]);
    setTotalReports(res?.totalPages || 0);
    setLoading(false);
  };

  const getReportsByFilters = (filterValues, pageValue) => {
    ReportController.getFilteredReports(filterValues, pageValue).then((res) => {
      setIsRequestError(!!res?.errorMsg);
      setReportResponseData(res);
    });
  };

  useEffect(() => {
    !loading && setLoading(true);
    if (isFiltersSelected(reportFiltersValues)) {
      getReportsByFilters(reportFiltersValues, page);
    } else {
      getReports();
    }
  }, [page]);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };

  const handelTextSearch = () => {
    setSearchInput(searchText);
    setPage(1);
    setLoading(true);
    getReports();
  };

  useEffect(() => {
    if (location?.state) {
      if (location?.state?.editReportData) {
        setShowActionMessage({ ...location.state.editReportData });
        unsetActionNotification();
        window?.history?.replaceState({}, "editReportData");
      }
      if (location?.state?.createReportData) {
        setShowActionMessage({ ...location.state.createReportData });
        unsetActionNotification();
        window?.history?.replaceState({}, "createReportData");
      }
    }
  }, [location?.state]);

  const unsetActionNotification = () => {
    setTimeout(() => {
      setShowActionMessage({
        type: "",
        message: "",
      });
    }, 10000);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal({
      id: "",
      name: "",
      open: false,
    });
  };

  const handleOpenDeleteModal = (reportData) => {
    setOpenDeleteModal({ ...reportData, open: true });
  };

  const handleDeleteReport = () => {
    setButtonLoading(true);
    if (openDeleteModal?.id) {
      ReportController.deleteReports(openDeleteModal?.id).then((res) => {
        setShowActionMessage({
          type: res?.status === 200 ? "success" : "error",
          message: CheckValueLocale(
            res?.status === 200
              ? "report_delete_success"
              : "report_delete_fail",
            "",
            {},
            intl,
          ),
        });
        setOpenDeleteModal({
          id: "",
          name: "",
          open: false,
        });
        unsetActionNotification();
        if (res?.status === 200) {
          setPage(1);
          setLoading(true);
          getReports();
        }
        setButtonLoading(false);
      });
    }
  };
  const handelApplyFilter = (filterData) => {
    setReportFiltersValues({ ...filterData });
    setOpenFilters(false);
    page > 1 && setPage(1);
    setLoading(true);
    getReportsByFilters(filterData, 1);
  };

  const isFiltersSelected = (filterData) => {
    let isSelected = false;
    if (filterData && Object.keys(filterData)?.length !== 0) {
      Object.keys(filterData)?.map((val) => {
        if (filterData[val] && !isSelected) {
          isSelected = true;
        }
        return val;
      });
    }
    return isSelected;
  };

  return (
    <Container maxWidth="xl">
      <ListFilters
        open={openFilters}
        handelFilterAction={setOpenFilters}
        handelApplyFilter={handelApplyFilter}
        setReportResponseData={setReportResponseData}
      />
      {loading ? (
        <CircularLoading />
      ) : (
        <Box className="report-main-box">
          <DeleteReportModal
            modalData={openDeleteModal}
            handleClose={handleCloseDeleteModal}
            handleDeleteReport={handleDeleteReport}
            buttonLoading={buttonLoading}
          />
          <ListHeader
            handelTextSearch={handelTextSearch}
            handelFilterAction={setOpenFilters}
            searchText={searchText}
            setSearchText={setSearchText}
            reports={reports}
            searchInput={searchInput}
          />
          <ReportList
            list={reports}
            handleOpenDeleteModal={handleOpenDeleteModal}
            searchText={searchText}
            getReportData={getReports}
          />
          {totalReports > 1 && (
            <Pagination
              onChange={handleChangePage}
              page={page}
              className="pagination-monitor-list pagination-report-list"
              count={totalReports}
              variant="outlined"
            />
          )}
        </Box>
      )}
      {isRequestError && (
        <Snackbar
          lang={window?.localStorage?.lang}
          open={true}
          handleClose={() => {
            setIsRequestError(!!false);
          }}
          severity="error"
          message={CheckValueLocale(
            "something_went_wrong_try_agin",
            "",
            {},
            intl,
          )}
        />
      )}
      {showActionMessage?.type && (
        <Snackbar
          lang={window?.localStorage?.lang}
          open={true}
          handleClose={() => {
            setShowActionMessage({
              type: "",
              message: "",
            });
          }}
          severity={showActionMessage?.type}
          message={showActionMessage?.message}
        />
      )}
    </Container>
  );
};

export default Reports;
