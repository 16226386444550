import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Alert, Box } from "@mui/material";
import _ from "lodash";
import LiveDashboardHeader from "./components/liveDashboardHeader";
import XPlateformDataSource from "./components/xPlateformDataSource/xPlateformDataSource";
import FacebookDataSource from "./components/facebookDataSource/facebookDataSource";
import InstagramDataSource from "./components/instagramDataSource/instagramDataSource";
import NewsBlogsDataSource from "./components/newsBlogsDataSource/newsBlogsDataSource";
import CircularLoading from "components/circularProgress";
import DashboardFunction from "./components/dashboardFunction";
import "./main.scss";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";
import SaveOrderContent from "./components/saveOrderContent";
import PowerLogoContent from "./components/powerLogoContent";
import StatisticWidgets from "./components/statisticWidget";
import PopupModal from "components/popupModal";
import PopupBodyShareLink from "./popupBodyShareLink/popupBodyShareLink";
import LiveDashboardDeleted from "./components/liveDashboardDeleted";
import LiveDashboardNotAccess from "./components/liveDashboardNotAccess";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import Services from "services/api/Services";
import LucButton from "shared/lucButton/lucButton";

const defaultStatisticWidgets = {
  totalPosts: 0,
  totalAuthors: 0,
  totalInteractions: 0,
};
const LiveDashboard = () => {
  const intl = useIntl();

  const [totalStatisticWidgets, setTotalStatisticWidgets] = useState({
    xPlatformDataSource: _.cloneDeep(defaultStatisticWidgets),
    facebookDataSource: _.cloneDeep(defaultStatisticWidgets),
    instagramDataSource: _.cloneDeep(defaultStatisticWidgets),
    newsBlogsDataSource: _.cloneDeep(defaultStatisticWidgets),
  });
  const [loadingStatisticWidgets, setLoadingStatisticWidgets] = useState(true);
  const [openShareLinkPopup, setOpenShareLinkPopup] = useState(false);

  const {
    startDate,
    endDate,
    unixDate,
    response,
    selectedWidgets,
    monitorLiveDashboardDetails,
    monitorLiveDashboardDetailsError,
    loadingMonitorLiveDashboardDetails,
    lastUpdated,
    getSocialIcon,
    resetAllFields,
    preLoaderTrackerDataSources,
    singleTweetAnalysis,
    applyFilter,
    handleLiveDashboardDetails,
    xPlateformWidgetTypes,
    setXPlateformWidgetTypes,
    facebookWidgetTypes,
    setFacebookWidgetTypes,
    instagramWidgetTypes,
    setInstagramWidgetTypes,
    newsBlogsWidgetTypes,
    setNewsBlogsWidgetTypes,
    orderFlag,
    setOrderFlag,
    sortWidgets,
    editLiveDashboardHandler,
    closeSnackBar,
    snackBar,
    isActionLoading,
    getWidgetsSoretedArr,
    getSourceName,
    liveDashboardName,
    changeLang,
    redirectToMonitor,
    getNameKeyWidget,
    showEditBtn,
    isDashboardDeleted,
    isDashboardNotAccess,
    unixRangeToDateRange,
    countViewersPublic,
    dataSources,
    errorClosedSocket,
  } = DashboardFunction();

  useEffect(() => {
    if (selectedWidgets) {
      selectedWidgets?.map((dataSource) => {
        if (getSourceName(dataSource?.data_source_id) === "TWITTER")
          setXPlateformWidgetTypes(getWidgetsSoretedArr(dataSource?.widgets));
        if (getSourceName(dataSource?.data_source_id) === "FACEBOOK")
          setFacebookWidgetTypes(getWidgetsSoretedArr(dataSource?.widgets));
        if (getSourceName(dataSource?.data_source_id) === "INSTAGRAM")
          setInstagramWidgetTypes(getWidgetsSoretedArr(dataSource?.widgets));
        if (getSourceName(dataSource?.data_source_id) === "TALKWALKER")
          setNewsBlogsWidgetTypes(getWidgetsSoretedArr(dataSource?.widgets));
      });
    }
  }, [selectedWidgets]);

  useEffect(() => {
    if (monitorLiveDashboardDetails) {
      handleLiveDashboardDetails();
    }
  }, [monitorLiveDashboardDetails, dataSources]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      setTotalStatisticWidgets({
        xPlatformDataSource: _.cloneDeep(defaultStatisticWidgets),
        facebookDataSource: _.cloneDeep(defaultStatisticWidgets),
        instagramDataSource: _.cloneDeep(defaultStatisticWidgets),
        newsBlogsDataSource: _.cloneDeep(defaultStatisticWidgets),
      });
      setLoadingStatisticWidgets(true);
    }
  }, [resetAllFields]);

  const totalStatistic = (keyName) => {
    return Object.values(totalStatisticWidgets)?.reduce(
      (total, dataSource) => total + dataSource?.[keyName],
      0,
    );
  };
  const { data: themesOptions } = useThemesOptions({ locale: intl?.locale });

  return (
    <>
      <Box className="live-dashboard-wrapper prevent-select">
        {loadingMonitorLiveDashboardDetails ? (
          <CircularLoading />
        ) : isDashboardDeleted ? (
          <LiveDashboardDeleted />
        ) : (
          <>
            <LiveDashboardHeader
              applyFilter={applyFilter}
              startDate={startDate}
              endDat={endDate}
              lastUpdated={lastUpdated}
              liveDashboardName={liveDashboardName}
              changeLang={changeLang}
              selectedWidgets={selectedWidgets}
              redirectToMonitor={redirectToMonitor}
              showEditBtn={showEditBtn}
              setOpenShareLinkPopup={setOpenShareLinkPopup}
              customLiveDashboardPublic={
                monitorLiveDashboardDetails?.[0]?.custom_date
              }
              isDashboardNotAccess={isDashboardNotAccess}
              unixRangeToDateRange={unixRangeToDateRange}
              countViewersPublic={countViewersPublic}
            />
            {isDashboardNotAccess ? (
              <LiveDashboardNotAccess
                dashboardUserName={
                  monitorLiveDashboardDetailsError?.data?.dashboard_user_name
                }
              />
            ) : (
              <>
                {errorClosedSocket ? (
                  <Alert
                    className="alert-error-closed-socket"
                    severity="warning"
                  >
                    <Box>
                      {CheckValueLocale(
                        "alert_error_closed_socket_live_dashboard",
                        "",
                        {},
                        intl,
                      )}
                    </Box>
                    <LucButton
                      type="secondary"
                      variant="flat"
                      size="small"
                      onClick={() => window.location.reload()}
                    >
                      {CheckValueLocale("refresh_live_dashboard", "", {}, intl)}
                    </LucButton>
                  </Alert>
                ) : null}

                <StatisticWidgets
                  totalStatisticWidgets={{
                    totalPosts: totalStatistic("totalPosts"),
                    totalAuthors: totalStatistic("totalAuthors"),
                    totalInteractions: totalStatistic("totalAuthors"),
                  }}
                  loadingStatisticWidgets={loadingStatisticWidgets}
                />
                {selectedWidgets?.map((dataSource, index) => {
                  if (
                    getSourceName(dataSource?.data_source_id) === "TWITTER" &&
                    xPlateformWidgetTypes
                  ) {
                    return (
                      <XPlateformDataSource
                        getSocialIcon={getSocialIcon}
                        resetAllFields={resetAllFields}
                        response={response}
                        preLoaderTrackerDataSources={
                          preLoaderTrackerDataSources
                        }
                        unixDate={unixDate}
                        singleTweetAnalysis={singleTweetAnalysis}
                        xPlateformWidgetTypes={xPlateformWidgetTypes}
                        setXPlateformWidgetTypes={setXPlateformWidgetTypes}
                        sortWidgets={sortWidgets}
                        getNameKeyWidget={getNameKeyWidget}
                        showEditBtn={showEditBtn}
                        setTotalStatisticWidgets={setTotalStatisticWidgets}
                        setLoadingStatisticWidgets={setLoadingStatisticWidgets}
                        key={index}
                        themesOptions={themesOptions}
                      />
                    );
                  }
                  if (
                    getSourceName(dataSource?.data_source_id) === "FACEBOOK" &&
                    facebookWidgetTypes
                  ) {
                    return (
                      <FacebookDataSource
                        getSocialIcon={getSocialIcon}
                        resetAllFields={resetAllFields}
                        response={response}
                        preLoaderTrackerDataSources={
                          preLoaderTrackerDataSources
                        }
                        unixDate={unixDate}
                        singleTweetAnalysis={singleTweetAnalysis}
                        facebookWidgetTypes={facebookWidgetTypes}
                        setFacebookWidgetTypes={setFacebookWidgetTypes}
                        sortWidgets={sortWidgets}
                        getNameKeyWidget={getNameKeyWidget}
                        showEditBtn={showEditBtn}
                        setTotalStatisticWidgets={setTotalStatisticWidgets}
                        setLoadingStatisticWidgets={setLoadingStatisticWidgets}
                        key={index}
                        themesOptions={themesOptions}
                      />
                    );
                  }
                  if (
                    getSourceName(dataSource?.data_source_id) === "INSTAGRAM" &&
                    instagramWidgetTypes
                  ) {
                    return (
                      <InstagramDataSource
                        getSocialIcon={getSocialIcon}
                        resetAllFields={resetAllFields}
                        response={response}
                        preLoaderTrackerDataSources={
                          preLoaderTrackerDataSources
                        }
                        unixDate={unixDate}
                        singleTweetAnalysis={singleTweetAnalysis}
                        instagramWidgetTypes={instagramWidgetTypes}
                        setInstagramWidgetTypes={setInstagramWidgetTypes}
                        sortWidgets={sortWidgets}
                        getNameKeyWidget={getNameKeyWidget}
                        showEditBtn={showEditBtn}
                        setTotalStatisticWidgets={setTotalStatisticWidgets}
                        setLoadingStatisticWidgets={setLoadingStatisticWidgets}
                        key={index}
                        themesOptions={themesOptions}
                      />
                    );
                  }
                  if (
                    getSourceName(dataSource?.data_source_id) ===
                      "TALKWALKER" &&
                    newsBlogsWidgetTypes
                  ) {
                    return (
                      <NewsBlogsDataSource
                        getSocialIcon={getSocialIcon}
                        resetAllFields={resetAllFields}
                        response={response}
                        preLoaderTrackerDataSources={
                          preLoaderTrackerDataSources
                        }
                        unixDate={unixDate}
                        singleTweetAnalysis={singleTweetAnalysis}
                        newsBlogsWidgetTypes={newsBlogsWidgetTypes}
                        setNewsBlogsWidgetTypes={setNewsBlogsWidgetTypes}
                        sortWidgets={sortWidgets}
                        getNameKeyWidget={getNameKeyWidget}
                        showEditBtn={showEditBtn}
                        setTotalStatisticWidgets={setTotalStatisticWidgets}
                        setLoadingStatisticWidgets={setLoadingStatisticWidgets}
                        key={index}
                      />
                    );
                  }
                })}
              </>
            )}

            {orderFlag ? (
              <SaveOrderContent
                editLiveDashboardHandler={editLiveDashboardHandler}
                isActionLoading={isActionLoading}
                setOrderFlag={setOrderFlag}
                showEditBtn={showEditBtn}
              />
            ) : null}
            {Services.showProjectLogoInLiveDashboard ? (
              <PowerLogoContent />
            ) : null}

            <SnackBar
              open={snackBar?.open}
              handleClose={closeSnackBar}
              severity={snackBar?.severity}
              message={CheckValueLocale(snackBar?.message, "", {}, intl)}
              title={CheckValueLocale(snackBar?.title, "", {}, intl)}
            />
          </>
        )}
        {openShareLinkPopup ? (
          <PopupModal
            title={CheckValueLocale("shar_link_live_dashboard", "", {}, intl)}
            body={
              <PopupBodyShareLink
                monitorLiveDashboardDetails={monitorLiveDashboardDetails}
                showEditBtn={showEditBtn}
              />
            }
            open={openShareLinkPopup}
            close={() => setOpenShareLinkPopup(false)}
            accept={() => setOpenShareLinkPopup(false)}
            classeName="popup-share-link-live-dashboard"
            rightBtn={CheckValueLocale("done", "", {}, intl)}
          />
        ) : null}
      </Box>
    </>
  );
};

export default LiveDashboard;
