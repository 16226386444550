import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";

export const useAddAiAgentResource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => AiAgentsServices.addResource(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["aiAgentResources"] });
      queryClient.invalidateQueries({ queryKey: ["aiAgentDetails"] });
    },
  });
};
