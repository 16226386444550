import { Box } from "@mui/material";
import PageHeader from "components/pageHeader";
import moment from "moment";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const BrandingHeader = ({ companyBranding }) => {
  const intl = useIntl();
  return (
    <Box className="company-branding-header">
      <PageHeader
        title="settings_menu_branding"
        titleId="branding-page-header"
      />

      <Box className="description">
        {CheckValueLocale("company_branding_desc", "", {}, intl)}
        {companyBranding?.updated_at ? (
          <Box component={"span"}>
            {`${CheckValueLocale("branding_last_updated", "", {}, intl)}: ${moment?.unix(companyBranding?.updated_at)?.format("DD/MM/YYYY")}`}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default BrandingHeader;
