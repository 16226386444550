import { useMutation, useQueryClient } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useActiveLucidyaApi = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => SettingsController.activateLucidyaApisRequest(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["companyLucidyaApisList"],
      });
    },
  });
};
