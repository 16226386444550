import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";

export const useDublicateAiAgent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => AiAgentsServices.dublicateAiAgent(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["aiAgentList"],
      });
    },
  });
};
