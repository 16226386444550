import {
  faArrowsTurnToDots,
  faBuildingColumns,
  faClockRotateLeft,
  faHashtag,
  faKeySkeleton,
  faLink,
  faPalette,
  faPuzzlePiece,
  faUser,
  faUserGroup,
} from "@fortawesome/pro-regular-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import { handlelUserRoles } from "utils/helpers";
import CompanyPage from "../../pages/companyPage";
import Teams from "../../pages/teamsPage";
import Subscriptions from "pages/settings/pages/subscriptionsPage";
import HistoricalRequestPage from "pages/settings/pages/historicalRequestPage";
import ThirdPartyIntegrations from "pages/settings/pages/thirdPartyIntegrations";
import InboundedRouting from "../../pages/inboundRoutingPage";
import InstagramHashtags from "../../pages/InstagramHashtags/index";
import LucidyaAPIsIndex from "../../pages/lucidyaAPIs";
import UserManagementPage from "pages/settings/pages/userManagementPage";
import AuthenticationPage from "pages/settings/pages/authenticationPage";
import BrandingPage from "pages/settings/pages/brandingPage";

// ********************** Settings Nav Items Guide ********************** //
//   name: "settings_menu_company", >>> Translation key displayed in sidebar
//   icon: faBuildingColumns, >>> Icon displayed in sidebar
//   path: "/settings/company", >>> Route
//   component: CompanyPage, >>> Page component to be rendered
//   show: handlelUserRoles("GENERAL", "VIEW_SETTING"), >>> Condition to show the item or not in sidebar and render not found page
// ********************************************************************* //

const activeProducts = JSON.parse(window.localStorage.activeProducts || null);

const lucidyaApisActive = activeProducts?.find(
  (product) => product?.name == "PUBLIC_API",
);
const lucidyaApisActiveCheck = lucidyaApisActive ? true : false;

export const settingsNavItems = [
  {
    name: "settings_menu_general",
    items: [
      {
        name: "settings_menu_company",
        icon: faBuildingColumns,
        path: "/settings/company",
        component: CompanyPage,
        show: handlelUserRoles("GENERAL", "VIEW_SETTING"),
      },
      {
        name: "users",
        icon: faUser,
        path: "/settings/user_managements",
        component: UserManagementPage,
        show: handlelUserRoles("GENERAL", "VIEW_USER"),
      },
      {
        name: "settings_menu_teams",
        icon: faUserGroup,
        path: "/settings/teams",
        component: Teams,
        show: handlelUserRoles("GENERAL", "VIEW_USER"),
      },
      {
        name: "settings_menu_subscriptions",
        icon: faCreditCard,
        path: "/settings/subscription",
        component: Subscriptions,
        show: handlelUserRoles("GENERAL", "VIEW_SUBSCRIPTION"),
      },
      {
        name: "settings_menu_authentication",
        icon: faLink,
        path: "/settings/accounts_management",
        component: AuthenticationPage,
        show:
          handlelUserRoles("GENERAL", "VIEW_COMPANY_AUTH") ||
          handlelUserRoles("GENERAL", "VIEW_OTHERS_AUTH"),
      },
      {
        name: "settings_menu_branding",
        icon: faPalette,
        path: "/settings/branding",
        component: BrandingPage,
        show: handlelUserRoles("GENERAL", "VIEW_REPORT_BRANDING"),
      },
    ],
  },
  {
    name: "settings_menu_product_settings",
    items: [
      {
        name: "settings_menu_hisorical_data_request",
        icon: faClockRotateLeft,
        path: "/settings/historical_data",
        component: HistoricalRequestPage,
        show:
          handlelUserRoles("SM", "CREATE_HISTORICAL_REQUEST") ||
          handlelUserRoles("CXM", "CREATE_HISTORICAL_REQUEST"),
      },
      {
        name: "settings_menu_instagram_hashtags",
        icon: faHashtag,
        path: "/settings/instagram_hashtags",
        component: InstagramHashtags,
        show: activeProducts?.find(
          (product) => product?.name == "SM" && product?.active,
        ),
      },
      {
        name: "settings_menu_inbound_routing",
        icon: faArrowsTurnToDots,
        path: "/settings/inbound_routing",
        component: InboundedRouting,
        show:
          activeProducts?.find(
            (product) => product.name == "ENGAGEMENTS" && product?.active,
          ) &&
          handlelUserRoles("ENGAGEMENTS", "ACCESS_ENGAGEMENT_SYSTEM") &&
          (handlelUserRoles("ENGAGEMENT_ACCESS", "FULL_ACCESS") ||
            handlelUserRoles("ENGAGEMENT_ACCESS", "INBOX")),
      },
    ],
  },
  {
    name: "settings_menu_integrations",
    items: [
      {
        name: "settings_menu_3rd_party_integrations",
        icon: faPuzzlePiece,
        path: "/settings/3rd_party_integrations",
        component: ThirdPartyIntegrations,
        show: handlelUserRoles("GENERAL", "VIEW_INTEGRATION"),
      },
      {
        name: "settings_menu_lucidya_api",
        icon: faKeySkeleton,
        path: "/settings/lucidya_api",
        component: LucidyaAPIsIndex,
        show:
          !lucidyaApisActiveCheck ||
          (lucidyaApisActiveCheck &&
            handlelUserRoles("PUBLIC_API", "VIEW_PUBLIC_API")),
      },
    ],
  },
];
