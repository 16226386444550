// ***************************************************************************** //
// ***************************************************************************** //
// ********* Please keep in mind that this file is used in many places ********* //
// ** [Settings > Historical Request &&&& Monitor List Options Dropdown Menu] ** //
// ***************************************************************************** //
// ***************************************************************************** //

import * as Yup from "yup";
import moment from "moment";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useHistoricalProducts } from "../../hooks/useHistoricalProducts";
import { useHistoricalRequestDatasources } from "../../hooks/useHistoricalRequestDatasources";
import { useHistoricalRequestMonitors } from "../../hooks/useHistoricalRequestMonitors";
import { useXPlatformAvailableData } from "../../hooks/useXPlatformAvailableData";
import { useCreateHistoricalRequest } from "../../hooks/useCreateHistoricalRequest";
import SnackBar from "components/snackBar";
import PopupModal from "components/popupModal.js";
import HistoricalRequestStepOne from "./steps/historicalRequestStepOne";
import HistoricalRequestStepTwo from "./steps/historicalRequestStepTwo";

const CreateHistoricalRequest = (props) => {
  const intl = useIntl();
  const {
    openCreateModal,
    setOpenCreateModal,
    setPageSnackbar,
    remainNewsBlogs,
    remainPosts,
    remainInteractions,
    monitorInfo, // Only for monitor option
  } = props;

  const isMonitorOption = !!monitorInfo?.id;

  const [step, setStep] = useState(1);

  const [startDate, setStartDate] = useState(
    moment().subtract(6, "d").startOf("day").unix(),
  );
  const [endDate, setEndDate] = useState(moment().unix());
  const [startNewDate, setStartNewDate] = useState(moment().unix());
  const [endNewDate, setEndNewDate] = useState(moment().unix());
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(23.5);
  const [oldestDate, setOldestDate] = useState(
    moment.unix(645794640).format("YYYY/MM/DD"),
  );
  const [defaultDateRange, setDefaultDateRange] = useState("last_7_days");
  const [remainQuota, setRemainQuota] = useState(null);
  const [snackbar, setSnackbar] = useState({});
  const [datePickerRestriction, setDatePickerRestriction] = useState(false);
  const [resetCalendar, setResetCalendar] = useState(1);
  const [hasKeyword, setHasKeyword] = useState(false);

  const formik = useFormik({
    initialValues: {
      product_id: "",
      data_source_id: "",
      monitor_model_id: "",
      count: "",
    },
    validationSchema:
      step === 1
        ? Yup.object().shape({
            product_id: Yup.string().required("required"),
            data_source_id: Yup.string().required("required"),
            monitor_model_id: Yup.string().required("required"),
          })
        : Yup.object().shape({
            count: Yup.number()
              .min(1, "")
              .max(remainQuota, "quota_out")
              .required("this_field_required"),
          }),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: () =>
      step === 1 ? handleNextStep() : handleCreateHistoricalRequest(),
  });

  // ----------- Get Historical Request Available Products ----------- //
  const { data: companyProductsData, isPending: companyProductsLoading } =
    useHistoricalProducts({ enableFetch: openCreateModal });

  const companyProducts = companyProductsData?.data || [];

  // --- Get historical request datasources depending on product --- //
  const { data: dataSourcesData, isPending: dataSourcesLoading } =
    useHistoricalRequestDatasources({
      productId: formik?.values?.["product_id"],
    });

  // Get historical request monitors depending on datasources and product //
  const { data: monitorsData, isPending: monitorsLoading } =
    useHistoricalRequestMonitors({
      productId: formik?.values?.["product_id"],
      dataSourceId: formik?.values?.["data_source_id"],
    });

  // ------------ Get xPlatform available data for Twitter ------------ //
  const {
    data: xPlatformAvailableData,
    isPending: xPlatformAvailableDataLoading,
  } = useXPlatformAvailableData({
    enableFetch:
      !!formik?.values?.["product_id"] &&
      !!formik?.values?.["data_source_id"] &&
      dataSourcesData?.ids?.["TWITTER"] ===
        formik?.values?.["data_source_id"] && // This condition is used to make sure that we are only fetching data for the selected xPlatformId
      !!formik?.values?.["monitor_model_id"],
    productId: formik?.values?.["product_id"],
    dataSourceId: formik?.values?.["data_source_id"],
    monitorId: formik?.values?.["monitor_model_id"],
    startDate,
    endDate,
  });

  let quotaLimitMsg = xPlatformAvailableData?.msg || null;
  let xPlatformActualCount = xPlatformAvailableData?.data?.actual_count || 0;
  let xPlatformDuplicatesCount =
    xPlatformAvailableData?.data?.current_count || 0;

  // ------------ Set pre-selected data from monitorInfo ------------ //
  useEffect(() => {
    if (isMonitorOption && monitorInfo) {
      // Set pre-selected product id
      if (companyProductsData?.ids?.["SM"]) {
        formik?.setValues({
          ...formik?.values,
          product_id: companyProductsData?.ids?.["SM"],
          ...(monitorInfo?.data_sources?.length === 1
            ? {
                data_source_id:
                  dataSourcesData?.ids?.[monitorInfo?.data_sources?.[0]],
              }
            : {}),
          monitor_model_id: monitorInfo?.id,
        });

        if (
          monitorInfo?.type === "KEYWORD" &&
          monitorInfo?.data_sources?.length === 1 &&
          monitorInfo?.data_sources?.[0] === "INSTAGRAM"
        )
          setHasKeyword(true);
        else setHasKeyword(false);
        if (
          monitorInfo?.facebook_pages?.length <= 0 &&
          monitorInfo?.type === "KEYWORD" &&
          monitorInfo?.data_sources?.[0] === "FACEBOOK"
        )
          setDatePickerRestriction(true);
        else setDatePickerRestriction(false);
      }
      formik?.validateForm();
    }
  }, [
    isMonitorOption,
    monitorInfo,
    companyProductsData?.ids,
    dataSourcesData?.ids,
    monitorsData,
  ]);

  const historicalDate = (type) => {
    const date = {
      legacy: moment().subtract(30, "d").startOf("day").unix(),
      enhanced: moment().subtract(100, "d").startOf("day").unix(),
    };
    return date[type];
  };

  useEffect(() => {
    if (
      !!formik?.values?.["product_id"] &&
      !!formik?.values?.["data_source_id"]
    ) {
      if (
        formik?.values?.["product_id"] === companyProductsData?.ids?.["SM"] &&
        formik?.values?.["data_source_id"] ===
          dataSourcesData?.ids?.["TALKWALKER"]
      ) {
        setOldestDate(
          moment
            .unix(historicalDate(dataSourcesData?.nb_provider_name || ""))
            ?.format("YYYY/MM/DD"),
        );
      } else setOldestDate(moment.unix(645794640)?.format("YYYY/MM/DD"));
    }
  }, [formik?.values?.["data_source_id"]]);

  // ------------------ Create New Historical Request ------------------ //
  const { mutate: createHistorical, isPending: createLoading } =
    useCreateHistoricalRequest();

  const handleCreateHistoricalRequest = () => {
    const queryData = {
      monitor_model_id: formik?.values?.["monitor_model_id"],
      product_id: formik?.values?.["product_id"],
      data_source_id: formik?.values?.["data_source_id"],
      start_date: startDate,
      end_date: endDate,
      count: formik?.values?.["count"],
      ...(formik?.values?.["data_source_id"] ===
      dataSourcesData?.ids?.["TWITTER"]
        ? { duplicates: xPlatformDuplicatesCount }
        : {}), // "Duplicates" key sent only in case of X-Platform
      hasPage:
        formik?.values?.["data_source_id"] ===
          dataSourcesData?.ids?.["FACEBOOK"] &&
        formik?.values?.["product_id"] === companyProductsData?.ids?.["SM"]
          ? datePickerRestriction
          : undefined,
    };

    createHistorical(
      { queryData }, // Create Historical Request Payload
      {
        onSuccess: (data) => {
          if (data?.data?.data?.data?.hr_id) {
            setPageSnackbar({
              open: true,
              severity: "success",
              title: "create_historical_success_title",
              message: isMonitorOption
                ? "create_historical_success_body_monitor_option"
                : "create_historical_success_body",
            });
          }
        },
        onError: (error) => {
          if (error?.error?.response) {
            setPageSnackbar({
              open: true,
              severity: "error",
              title: "create_historical_fail_title",
              message: isMonitorOption
                ? "create_historical_fail_body_monitor_option"
                : "create_historical_fail_body",
            });
          }
        },
        onSettled: () => {
          handleCloseCreateModal();
        },
      },
    );
  };

  const calcRemaining = () => {
    if (formik?.values?.["product_id"] === companyProductsData?.ids?.["CXM"])
      setRemainQuota(remainInteractions);
    else if (
      formik?.values?.["product_id"] === companyProductsData?.ids?.["SM"] &&
      formik?.values?.["data_source_id"] ===
        dataSourcesData?.ids?.["TALKWALKER"]
    )
      setRemainQuota(remainNewsBlogs);
    else setRemainQuota(remainPosts);
  };

  const applyFilter = (startDate, endDate) => {
    setStartNewDate(startDate);
    setEndNewDate(endDate);
    setStartTime(0);
    setEndTime(23.5);
  };

  const handleResetTime = () => {
    setResetCalendar((prev) => prev + 1);
    setStartTime(0);
    setEndTime(23.5);
  };

  const handleNextStep = () => {
    if (startDate < endDate) {
      formik?.setFieldTouched("count", false); // Reset count as untouched
      setStep(2);
    } else {
      setSnackbar({
        open: true,
        severity: "error",
        title: "",
        message: "error_time_msg",
      });
    }
    calcRemaining();
  };

  useEffect(() => {
    const newEndDate = endNewDate - (24 - endTime) * 60 * 60 + 1;
    const newStartDate = startNewDate + startTime * 60 * 60;
    setEndDate(newEndDate);
    setStartDate(newStartDate);
  }, [startNewDate, endNewDate, endTime, startTime]);

  // --------------- Check if the start date is greater than the end date --------------- //
  useEffect(() => {
    if (
      openCreateModal &&
      formik?.values?.["product_id"] &&
      formik?.values?.["data_source_id"] &&
      formik?.values?.["monitor_model_id"] &&
      startDate > endDate
    ) {
      setSnackbar({
        open: true,
        severity: "error",
        title: "",
        message: "error_time_msg",
      });
    } else setSnackbar({});
  }, [
    openCreateModal,
    formik?.values?.["product_id"],
    formik?.values?.["data_source_id"],
    formik?.values?.["monitor_model_id"],
    startDate,
    endDate,
  ]);

  const handleCloseCreateModal = async () => {
    setOpenCreateModal(false);
    await formik?.resetForm();
    setStep(1);
    setStartTime(0);
    setEndTime(23.5);
    setDefaultDateRange("last_7_days");
    xPlatformActualCount = 0;
    xPlatformDuplicatesCount = 0;
    quotaLimitMsg = null;
  };

  const handleBackBtn = async () => {
    await setStep(1);
    formik?.setFieldValue("count", ""); // Reset field to empty string
    formik?.setFieldTouched("count", false); // Mark the field as not touched
    formik?.setFieldError("count", ""); // Clear the error message for count
  };

  const checkIfFBPage = (event) => {
    if (
      formik?.values?.["product_id"] == companyProductsData?.ids?.["SM"] &&
      formik?.values?.["data_source_id"] == dataSourcesData?.ids?.["FACEBOOK"]
    ) {
      let monitorDetailsModel = monitorsData?.find(
        (monitor) => monitor?.attributes?.id === event?.target?.value,
      );
      let doesHavePage =
        monitorDetailsModel?.attributes?.facebook_keyword_pages?.length <= 0 &&
        monitorDetailsModel?.attributes?.monitor_type_name === "KEYWORD";

      setDatePickerRestriction(doesHavePage);
    } else {
      setDatePickerRestriction(false);
    }
  };

  const updateDatePicker = (event) => {
    let monitorID = event?.target?.value;
    if (
      formik?.values?.["data_source_id"] == dataSourcesData?.ids?.["INSTAGRAM"]
    ) {
      let monitorDetailsModel = monitorsData?.find(
        (monitor) => monitor?.attributes?.id === monitorID,
      );
      setHasKeyword(
        monitorDetailsModel?.attributes?.monitor_type_name == "KEYWORD",
      );
    }
  };

  const datePickerRestrictionConfirmation = hasKeyword || datePickerRestriction;
  return (
    <Box id="create-historical-request-container">
      <PopupModal
        id="create-historical-request-popup"
        classeName="create-historical-request-popup"
        open={openCreateModal}
        title={
          <Box id="create-historical-modal-title">
            {CheckValueLocale("new_historical_request", "", {}, intl)}
            <Box component="span" className="steps-counter">
              {CheckValueLocale(
                "create_historical_request_steps_count",
                "",
                { num: step },
                intl,
              )}
            </Box>
          </Box>
        }
        body={
          <Box id="create-historical-modal-body">
            {step === 1 ? (
              <HistoricalRequestStepOne
                companyProductsData={companyProductsData}
                companyProducts={companyProducts}
                companyProductsLoading={companyProductsLoading}
                dataSourcesData={dataSourcesData}
                dataSourcesLoading={dataSourcesLoading}
                monitorsData={monitorsData}
                monitorsLoading={monitorsLoading}
                applyFilter={applyFilter}
                quotaLimitMsg={quotaLimitMsg}
                xPlatformId={dataSourcesData?.ids?.["TWITTER"]}
                startNewDate={startNewDate}
                endNewDate={endNewDate}
                defaultDateRange={defaultDateRange}
                startTime={startTime}
                setStartTime={setStartTime}
                setDefaultDateRange={setDefaultDateRange}
                oldestDate={oldestDate}
                xPlatformAvailableDataLoading={xPlatformAvailableDataLoading}
                endTime={endTime}
                setEndTime={setEndTime}
                xPlatformDuplicatesCount={xPlatformDuplicatesCount}
                xPlatformActualCount={xPlatformActualCount}
                formik={formik}
                isMonitorOption={isMonitorOption}
                monitorInfo={monitorInfo}
                handleResetTime={handleResetTime}
                setHasKeyword={setHasKeyword}
                checkIfFBPage={checkIfFBPage}
                updateDatePicker={updateDatePicker}
                datePickerRestrictionConfirmation={
                  datePickerRestrictionConfirmation
                }
                resetCalendar={resetCalendar}
              />
            ) : (
              <HistoricalRequestStepTwo
                formik={formik}
                remainQuota={remainQuota}
                xPlatformId={dataSourcesData?.ids?.["TWITTER"]}
                xPlatformActualCount={xPlatformActualCount}
              />
            )}
          </Box>
        }
        disabled={
          !formik?.dirty ||
          !formik?.isValid ||
          (step === 2 && !formik?.touched?.count)
        }
        accept={formik?.handleSubmit}
        close={handleCloseCreateModal}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale(
          step === 1 ? "next_step" : "request",
          "",
          {},
          intl,
        )}
        isRightBtnLoading={createLoading}
        modalStyle={step === 1 ? "form-style" : null}
        minWidth="600px"
        showBackBtn={step === 2 ? true : false}
        backBtn={CheckValueLocale("back_historical", "", {}, intl)}
        backBtnId="create-historical-request-back-btn"
        backBtnAction={handleBackBtn}
      />

      <SnackBar
        open={snackbar?.open}
        handleClose={() => setSnackbar({})}
        severity={snackbar?.severity}
        title={CheckValueLocale(snackbar?.title, "", {}, intl)}
        message={CheckValueLocale(snackbar?.message, "", {}, intl)}
        alertStyle={{
          maxWidth: snackbar?.message === "error_time_msg" ? "auto" : "320px",
        }}
      />
    </Box>
  );
};
export default CreateHistoricalRequest;
