import { useQueryClient } from "@tanstack/react-query";
import { useState, useEffect, useMemo } from "react";
import { io } from "socket.io-client";

export const useAISocketConnection = ({
  aiAgentId,
  aiAgentDetails,
  onError,
  onMessage,
  runTestAction,
  Services,
  intl,
  CheckValueLocale,
  runTestErrorMap,
}) => {
  const queryClient = useQueryClient();
  const [connectionState, setConnectionState] = useState({
    isConnected: false,
    isLoading: false,
    isThinking: false,
    isModalOpen: false,
  });

  const socket = useMemo(() => {
    if (connectionState.isConnected) {
      return io(Services.socketUrl + "engagements", {
        path: "/ws/",
        transports: ["websocket"],
        auth: {
          "luc-authorization": localStorage.getItem("user_token"),
        },
      });
    }
    return null;
  }, [connectionState.isConnected, Services.socketUrl]);

  const handleSocketConnection = () => {
    socket?.emit("join_ai_room", {
      company_id: localStorage.getItem("company_id"),
      ai_agent_id: aiAgentId,
      user_id: localStorage.getItem("user_id"),
    });
  };

  const handleAIResponse = (data) => {
    if (data?.status === false) {
      socket?.disconnect();
      queryClient.invalidateQueries({
        queryKey: ["aiAgentResources", { ai_agent_id: aiAgentDetails?.id }],
      });
      queryClient.invalidateQueries({
        queryKey: ["aiAgentDetails", aiAgentDetails?.id],
      });
      setConnectionState({
        isConnected: false,
        isLoading: false,
        isThinking: false,
        isModalOpen: false,
      });

      onError({
        success: false,
        title: CheckValueLocale("unable_to_run_test", "", {}, intl),
        message: CheckValueLocale(
          `ai_agent_run_test_${runTestErrorMap[data?.response?.error_code || 0]}` ||
            "try_again_error_message",
          "",
          {
            files: data?.response?.resource_names?.join(", "),
          },
          intl,
        ),
      });
    } else {
      if (data?.response && Object.keys(data.response).length > 0) {
        onMessage(data.response);
      }
      setConnectionState((prev) => ({
        ...prev,
        isThinking: false,
        isLoading: false,
        isModalOpen: true,
      }));
    }
  };

  const handleRoomJoined = () => {
    runTestAction(aiAgentDetails?.id, {
      onSuccess: () => {
        setConnectionState((prev) => ({ ...prev, isConnected: true }));
      },
      onError: () => {
        socket?.disconnect();
        setConnectionState({
          isConnected: false,
          isLoading: false,
          isThinking: false,
          isModalOpen: false,
        });
        onError({
          success: false,
          title: CheckValueLocale("failed_error_message", "", {}, intl),
          message: CheckValueLocale("try_again_error_message", "", {}, intl),
        });
      },
    });
  };

  useEffect(() => {
    if (socket && connectionState.isConnected) {
      socket.on("connect", handleSocketConnection);
      socket.on("join_ai_room", handleRoomJoined);
      socket.on("ai_agent_response", handleAIResponse);
      socket.on("disconnect", closeConnection);

      return () => {
        socket.off("connect");
        socket.off("join_ai_room");
        socket.off("ai_agent_response");
        socket.disconnect();
      };
    }
  }, [socket, connectionState.isConnected]);

  const closeConnection = () => {
    if (socket) {
      socket.disconnect();
    }
    setConnectionState({
      isConnected: false,
      isLoading: false,
      isThinking: false,
      isModalOpen: false,
    });
  };

  const startConnection = () => {
    setConnectionState((prev) => ({
      ...prev,
      isLoading: true,
      isConnected: true,
    }));
  };

  const setThinking = (isThinking) => {
    setConnectionState((prev) => ({
      ...prev,
      isThinking,
    }));
  };

  return {
    ...connectionState,
    socket,
    startConnection,
    closeConnection,
    setThinking,
  };
};
