import { useEffect, useState } from "react";
import "./homePage.scss";
import { Box, Container } from "@mui/material";
import { handleActiveFeature, handlelUserRoles } from "utils/helpers";
import Header from "./components/header/header";
import UtilitiesSection from "./components/header/utilitiesSection";
import SideBar from "./components/sideBar/sideBar";
import "./components/needsAttention/needsAttention.scss";
import HomePageController from "services/controllers/homePageController";
import { useIntl } from "react-intl";
import SlideSection from "./components/slideSection";
import { useNotificationsList } from "pages/notifications/hooks/useNotificationsList";

const HomePage = () => {
  const intl = useIntl();

  const [loading, setLoading] = useState(true);
  const [luciSuite, setLuciSuite] = useState([]);
  const [needsAttentionData, setNeedsAttentionData] = useState({});
  const [reportsData, setReportsData] = useState({});
  const [loadingDelay, setLoadingDelay] = useState(true);
  const [monitorsLoading, setMonitorsLoading] = useState(true);
  const [needsAttLoading, setNeedsAttLoading] = useState(true);
  const [reportsLoading, setReportsLoading] = useState(true);
  const [needHelp, setNeedHelp] = useState(true);
  const [dashboard, setDashboard] = useState({
    dashboards: [],
    limitReach: null,
    smCount: null,
    cxmCount: null,
  });
  const [dashboardsLoading, setDashboardsLoading] = useState(true);

  const [csmInfo, setCsmInfo] = useState({});

  // Get notifications list with any change in filters
  const { data: notificationsData, isPending: notificationLoading } =
    useNotificationsList({ page: undefined }); // To avoid sending page number in Hompage API call

  useEffect(() => {
    if (
      notificationLoading === false &&
      monitorsLoading === false &&
      needsAttLoading === false &&
      reportsLoading === false &&
      dashboardsLoading === false
    ) {
      setLoading(false);
    } else setLoading(true);
  }, [
    notificationLoading,
    monitorsLoading,
    needsAttLoading,
    reportsLoading,
    dashboardsLoading,
  ]);

  const enabledDashboards =
    (handlelUserRoles("SM", "VIEW_DASHBOARD_LIST") &&
      handleActiveFeature("SM", "is_dashboards_enabled")) ||
    handleActiveFeature("SM", "is_sl_custom_dashboards_enabled") ||
    handleActiveFeature("CXM", "is_cxm_custom_dashboards_enabled");

  const getHomePageMonitors = () => {
    HomePageController.getHomePageMonitors().then((result) => {
      if (result?.data?.status === 200) {
        setLuciSuite(result?.data?.data?.data);
      } else {
        setLuciSuite([]);
      }
      setMonitorsLoading(false);
    });
    HomePageController.getHomePageNeedsAttention().then((result) => {
      if (result?.data?.status === 200) {
        setNeedsAttentionData(result?.data?.data?.data);
      } else setNeedsAttentionData({});
      setNeedsAttLoading(false);
    });
    HomePageController.getHomePageReport().then((result) => {
      if (result?.data?.status === 200) setReportsData(result?.data?.data);
      else setReportsData({});
      setReportsLoading(false);
    });
    HomePageController.getCsmInfo().then((result) => {
      if (result?.data?.status === 200) setCsmInfo(result?.data?.data?.data);
      setNeedHelp(false);
    });
    if (enabledDashboards) {
      HomePageController.getDashboards().then((result) => {
        setDashboardsLoading(false);
        if (result?.data?.status === 200)
          setDashboard({
            dashboards: result?.data?.data?.data,
            limitReach: result?.data?.data?.dashboard_limit_reach,
            smCount: result?.data?.data?.sm_monitors_count,
            cxmCount: result?.data?.data?.cxm_monitors_count,
          });
        else
          setDashboard({
            dashboards: [],
            limitReach: null,
            smCount: null,
            cxmCount: null,
          });
      });
    } else {
      setDashboardsLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoadingDelay(false);
    }, 2000);
  }, []);

  useEffect(() => {
    getHomePageMonitors();
  }, []);

  return (
    <Box className="home-page-contaner">
      <Container maxWidth="xl">
        <Header />
        <UtilitiesSection />
        <Box className="all-sections">
          <SlideSection
            loading={loading}
            loadingDelay={loadingDelay}
            luciSuite={luciSuite}
            needsAttentionData={needsAttentionData}
            notifications={notificationsData?.data}
            dashboard={dashboard}
          />
          <SideBar
            reportsData={reportsData}
            loading={loading}
            loadingDelay={loadingDelay}
            csmInfo={csmInfo}
            needHelp={needHelp}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default HomePage;
