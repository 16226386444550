import { useMutation, useQueryClient } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useStopHistoricalRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ requestId }) =>
      await SettingsController.stopHistoricalRequest(requestId),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["historicalRequests"] });
      queryClient.invalidateQueries({ queryKey: ["historicalRequestStatus"] });
    },
    retry: false,
  });
};
