// ************** Settings Page **************
// This page is used to show the main settings page layout
// and render the sidebar at the left side of the page
// and the content at the right side mapping on the settingsNavItems
// array and returning the corresponding component for each item with its route
// *******************************************

import { Box } from "@mui/material";
import { Redirect, Route, Switch } from "react-router-dom";
import { settingsNavItems } from "./components/sidebar/settingsNavItems";
import SettingsSidebar from "./components/sidebar/index";
import Footer from "shared/footer/Footer";
import NotFoundPage from "pages/not-found/NotFoundPage.js";
import "./style.scss";

const Settings = () => {
  const firstMenuItemPath = settingsNavItems
    ?.flatMap((group) => group?.items)
    ?.find((item) => item?.show)?.path;

  return (
    <Box id="settings-page">
      {/* Settings Page Sidebar */}
      <SettingsSidebar settingsNavItems={settingsNavItems} />

      {/* Settings Page Content */}
      <Box id="settings-page-content-wrapper">
        <Box className="settings-page-content">
          <Switch>
            {/* Redirect to first menu item when accessing /settings */}
            <Route exact path="/settings">
              <Redirect to={firstMenuItemPath} />
            </Route>

            {/* Render the corresponding component for each item with its route */}
            {settingsNavItems
              ?.filter((group) => group?.items?.some((item) => item?.show))
              ?.map((group) =>
                group?.items
                  ?.filter((item) => item?.show)
                  ?.map((item) => (
                    <Route
                      key={item?.name}
                      path={item?.path}
                      component={item?.component}
                    />
                  )),
              )}

            {/* Not found page */}
            <Route path="*" exact component={NotFoundPage} />
          </Switch>
        </Box>

        {/* Main App Footer as it is excluded from App.js */}
        <Footer isSettingsContent={true} />
      </Box>
    </Box>
  );
};

export default Settings;
