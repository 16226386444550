import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { AiAgentsServices } from "services/api/aiAgents";
import { AiAgentsContext } from "../aiAgentsContext";

export const useResumeAiAgent = () => {
  const queryClient = useQueryClient();
  const { selectedAiAgentPublishedVersion } = useContext(AiAgentsContext);
  return useMutation({
    mutationFn: (queryData) => AiAgentsServices.resumeAiAgent(queryData),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["aiAgentDetails"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["aiAgentList"],
      });
      if (selectedAiAgentPublishedVersion) {
        await queryClient.invalidateQueries({
          queryKey: ["publishedVersionDetails"],
        });
      }
    },
  });
};
