import { useQuery } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useHistoricalRequestDatasources = ({ productId }) => {
  return useQuery({
    queryKey: ["historicalRequestDataSources", productId],
    queryFn: async () =>
      SettingsController.getHistoricalRequestDatasoruces({
        product_id: productId,
      }),
    enabled: !!productId,
    select: (data) => {
      const idsObject = {};
      data?.data?.sources?.data?.map(
        (item) => (idsObject[item?.attributes?.source] = item?.attributes?.id),
      );
      return { ...data?.data, ids: { ...idsObject } };
    },
  });
};
