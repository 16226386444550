import { useMutation, useQueryClient } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useVerifyCustomDomain = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => SettingsController?.verifyCustomDomain(),
    onSuccess: () =>
      queryClient?.invalidateQueries({ queryKey: ["companyInfo"] }),
    select: (data) => data?.data,
    retry: false,
  });
};
