import { useQuery } from "@tanstack/react-query";
import LuciController from "services/controllers/luciController";

export const useLuciBusinessDetailsInformel = () => {
  return useQuery({
    queryKey: ["LuciBusinessDetailsInformel"],
    queryFn: () => LuciController.LuciBusinessDetailsInformel(),
    select: (data) => data?.data?.informer,
  });
};
