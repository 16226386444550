import { useQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useGetMonitorsOrChannels = (
  userEditID,
  smIdOrCxmId,
  limit,
  pageNum,
  enabled,
) => {
  return useQuery({
    queryKey: ["accessMonitorsOrChannels", pageNum, smIdOrCxmId],
    queryFn: () => {
      if (userEditID)
        return CompaniesController.getMonitorsOrChannelsEdit(
          smIdOrCxmId,
          pageNum,
          limit,
          userEditID,
        );
      else
        return CompaniesController.getMonitorsOrChannels(
          smIdOrCxmId,
          pageNum,
          limit,
        );
    },
    enabled: !!pageNum && !!smIdOrCxmId && enabled,
    retry: false,
  });
};
