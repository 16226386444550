import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, TextField } from "@mui/material";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const InputSearch = ({
  searchText,
  setSearchText,
  setValidationError,
  validationError,
  setSearchInput,
  setPage,
  page,
  history,
}) => {
  const intl = useIntl();
  const [searchBoxClicked, setSearchBoxClicked] = useState(false);

  const handleEnterKeyPress = (e) => {
    if (e?.key === "Enter" && !validationError) {
      // Set redirected_monitor from history of needs attention click with NULL
      history?.push({ ...history?.location, needsAttentionData: null });
      setSearchInput(searchText);
      e.target.blur();
      if (page !== 1) setPage(1);
    }
  };
  // input search
  const searchIconOnClick = () => {
    if (!validationError) {
      // Set redirected_monitor from history of needs attention click with NULL
      history?.push({ ...history?.location, needsAttentionData: null });
      setSearchInput(searchText);
      if (page !== 1) setPage(1);
    }
  };
  return (
    <Box className="search-company">
      <Box className="search-box company-accounts">
        <TextField
          className="search-field"
          label={CheckValueLocale("search_by_account_identifier", "", {}, intl)}
          variant="outlined"
          value={searchText}
          onChange={(e) => {
            const invalidText = isPublicSpecialCharactersOrEmojis(
              e?.target?.value,
              undefined,
              true,
            );
            setValidationError(invalidText);
            setSearchText(e.target.value);
          }}
          onBlur={() => setSearchBoxClicked(false)}
          onFocus={() => setSearchBoxClicked(true)}
          onKeyPress={handleEnterKeyPress}
          id="company-accounts-search-field"
        />
        <Box
          className={
            "search-icon-container " +
            (searchBoxClicked ? "search-icon-container-blue" : "")
          }
          onClick={() => searchIconOnClick()}
          id="social-media-search-by-name-icon-box"
        >
          <FontAwesomeIcon
            className="search-icon"
            icon={faSearch}
            id="social-media-search-by-name-icon"
          />
        </Box>
      </Box>
      {validationError ? (
        <Box
          variant="p"
          className="search-input-validation-error manage-channels"
        >
          {CheckValueLocale("spam_keywords_rules", "", {}, intl)}
        </Box>
      ) : null}
    </Box>
  );
};

export default InputSearch;
