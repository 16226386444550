import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, classNames } from "utils/helpers";
import "./autoCompleteDropdown.scss";

const defaultRenderTags = (value, _getTagProps, isOpen, intl) => {
  if (!isOpen && value?.length > 0) {
    return (
      <Typography className="autocomplete-select-tags">
        {`${value?.length} ${CheckValueLocale("selected_num", "", {}, intl)}`}
      </Typography>
    );
  }
  return null;
};

const defaultRenderItem = (option, selected, nameKey, multiple, intl) => {
  return (
    <>
      {multiple ? <Checkbox checked={selected} /> : null}
      <Box className="autocomplete-select-option-title">
        <Typography className={classNames(!multiple && "single-option")}>
          {CheckValueLocale(option?.[nameKey], "", {}, intl)}
        </Typography>
      </Box>
    </>
  );
};

export const AutoCompleteDropdown = ({
  options,
  emptyListMessage,
  label,
  containerClassName = "",
  CustomPaper,
  autoCompleteWrapperClassName = "default-wrapper",
  nameKey = "name",
  idKey = "id",
  renderTags = defaultRenderTags,
  renderItem = defaultRenderItem,
  multiple = true,
  slotProps,
  value,
  renderGroup,
  groupBy,
  isLoading,
  loadingMessage,
  disabled,
  ...props
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (_event, newSelectedOptions) => {
    props?.onChange && props?.onChange?.(newSelectedOptions);
  };

  const optionItemRender = (props, option, { selected }) => {
    return (
      <li
        {...props}
        className={`${props.className} autocomplete-select-option-item`}
        key={option?.[idKey]}
      >
        {renderItem(option, selected, nameKey, multiple, intl)}
      </li>
    );
  };

  const getOptionSelected = (option, anotherOption) => {
    return option?.[idKey] === anotherOption?.[idKey];
  };

  const inputRender = (params) => (
    <TextField
      {...params}
      label={label}
      error={props?.error}
      helperText={props?.helperText}
      className={classNames(
        "autocomplete-dropdown-input",
        open || value?.length === 0 ? "" : "selected",
      )}
    />
  );
  return (
    <Box
      className={classNames(
        containerClassName,
        "autocomplete-dropdown-section",
      )}
    >
      <FormControl
        className={classNames(
          "autocomplete-dropdown-wrapper",
          autoCompleteWrapperClassName,
        )}
      >
        <Autocomplete
          disableClearable
          disableCloseOnSelect={!!multiple}
          autoComplete
          autoHighlight={false}
          className="autocomplete-dropdown-select"
          options={options}
          onClose={handleClose}
          onOpen={handleOpen}
          value={value}
          getOptionLabel={(option) => option?.[nameKey]}
          onChange={handleChange}
          renderOption={renderGroup ? undefined : optionItemRender}
          renderGroup={renderGroup}
          groupBy={groupBy}
          isOptionEqualToValue={getOptionSelected}
          multiple={multiple}
          renderInput={inputRender}
          disabled={disabled}
          noOptionsText={isLoading ? loadingMessage : emptyListMessage}
          renderTags={(value, getTagProps) =>
            renderTags(value, getTagProps, open, intl)
          }
          classes={{
            paper: classNames(
              "autocomplete-dropdown-paper-menu",
              props?.paperClassName,
            ),
            listbox: classNames(
              "autocomplete-dropdown-menu",
              props?.listboxClassName,
            ),
          }}
          size="small"
          PaperComponent={CustomPaper}
          slotProps={slotProps}
        />
      </FormControl>
    </Box>
  );
};
