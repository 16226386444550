import {
  Box,
  Typography,
  Tooltip,
  Zoom,
  Button,
  Collapse,
  Grid,
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import "./postsAndReplies.scss";
import {
  CheckValueLocale,
  isArabic,
  isEmptyObj,
  extractDisplayNameFromDataSource,
  sanitizeEngagementChatMessage,
  formatTextToHtml,
  postTimeWithFormatAgo,
} from "utils/helpers";
import React, { useEffect, useState } from "react";
import ReplyModal from "../../replyModal/replyModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faInstagram,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faExternalLink, faBan } from "@fortawesome/pro-regular-svg-icons";
import love from "images/engagements-images/love.svg";
import reacts from "images/engagements-images/reacts.svg";
import replay from "images/engagements-images/replay.svg";
import ezgif from "images/engagements-images/ezgif.gif";

import { useIntl } from "react-intl";
import moment from "moment";
import EngagementsController from "services/controllers/engagementsController";
import { sanitize } from "dompurify";
import profileImg from "images/engagements-images/profileImg.png";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dummyImage from "images/shared-images/image-placeholder.svg";
import dummyVideo from "images/shared-images/video-placeholder.svg";
import ProfileUser from "../profileUser/profileUser";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import TranslationRating from "../translationRating/translationRating";
import EditAndDeleteButtons from "./editAndDeleteButtons/editAndDeleteButtons";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";
import EditReply from "./editReply/editReply";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  slaData,
  clearSla,
} from "utils/redux/features/Engagements/EngagementsSlice.js";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { MediaPreviewer } from "../../mediaPreviewer/mediaPreviewer";
import LucButton from "shared/lucButton/lucButton";
import SentimentCategoriesThemes from "components/sentimentCategoriesThemes";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import { useCategoriesOptions } from "components/sentimentCategoriesThemes/hooks/useCategoriesOptions";
import { useSentimentsOptions } from "components/sentimentCategoriesThemes/hooks/useSentimentsOptions";
import { useEditInteraction } from "../hooks/useEditInteraction";

const socialIcons = (type) => {
  const types = {
    facebook: <FontAwesomeIcon icon={faFacebookSquare} />,
    twitter: <FontAwesomeIcon icon={faTwitterSquare} />,
    instagram: <FontAwesomeIcon icon={faInstagram} />,
  };
  return types[type];
};

const PostsAndReplies = ({
  setSelectedCardIteractionID,
  selectedCard,
  setSelectedCard,
  itemSelected,
  headerData,
  setOpenDetailsSideBar,
  text,
  setText,
  setOpenDetailsSideBarReplay,
  setOpenSlaSideBar,
  openSidebar,
  setOpenSidebar,
  checkAiSuggestions,
  setCheckAiSuggestions,
  selectedValue,
  setSelectedValue,
  handelOpenSidebar,
  translationActive,
  setTranslationActive,
  translationSource,
  setTranslationSource,
  translationTarget,
  setTranslationTarget,
  setStats,
  stats,
  languagesArraySource,
  languagesArrayTarget,
  openDetailsSideBarReplay,
  savedSuggTab,
  aiRepsTab,
  setIsCashedReplays,
  isSidebarsOpened,
  showAllIcon,
  aiAgentList,
  setRefresh,
  setOpenSnackBarAiAgent,
  setSnackBarDataAiAgent,
  setInjectedMessage,
  aiAgentId,
  replyBoxInputTextRef,
  slaKey,
  socket,
}) => {
  let statusItemCard = selectedCard
    ? selectedCard?.system_replies?.status
    : selectedCard?.status;
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses?.arrow}`]: {
      color: "rgba(97, 97, 97, 0.9)",
    },
    [`& .${tooltipClasses?.tooltip}`]: {
      backgroundColor: "rgba(97, 97, 97, 0.9)",
      fontSize: "12px",
      lineHeight: "14px",
      padding: "10px 8px",
      fontWeight: "500",
    },
  }));
  const intl = useIntl();
  const [showThems, setShowThems] = useState(false);
  const [showParentThems, setShowParentThems] = useState(false);
  const [replyModalData, setReplyModalData] = useState([
    {
      selectedValue: "",
      replyTime: "",
    },
  ]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showErrorSnackBar, setShowErrorSnackBar] = useState(false);
  const [postData, setPostData] = useState({});
  const [selectedInteractionsParent, setSelectedInteractionsParent] = useState(
    {},
  );
  const [selectedInteractions, setSelectedInteractions] = useState({});
  const [interactionsLoadingParent, setInteractionsLoadingParent] = useState(
    {},
  );
  const [interactionsLoading, setInteractionsLoading] = useState({});
  const [isReplied, setIsReplied] = useState(false);
  const [openTranslationCollapse, setOpenTranslationCollapse] = useState({});
  const [openReplyCollapse, setOpenReplyCollapse] = useState({});
  const [translationStatus, setTranslationStatus] = useState({});
  const [translationButtonLoading, setTranslationButtonLoading] = useState({});
  const [translationButtonResult, setTranslationButtonResult] = useState({});
  const [translateStatus, setTranslateStatus] = useState(false);
  const [disabledDeleteBtn, setDisabledDeleteBtn] = useState(false);

  const [openDeleteModalStats, setOpenDeleteModalStats] = useState({
    openDeleteModal: false,
    snackBarAction: "",
    snackBarSeverity: "",
    id: "",
  });
  const [snackBarData, setSnackBarData] = useState({
    severity: "",
    message: "",
    title: "",
  });
  const [openSnackBarDelete, setOpenSnackBarDelete] = useState(false);
  const [isReplyDeleted, setIsReplyDeleted] = useState(false);
  const [showEditDeleteBtns, setShowEditDeleteBtns] = useState(false);
  const [isDeletedResponse, setIsDeletedResponse] = useState(false);
  // media previewer state
  const [showMediaPreviewer, setShowMediaPreviewer] = useState(false);
  const [media, setMedia] = useState([]);
  const [defaultMediaIndex, setDefaultMediaIndex] = useState(0);

  const [maxChar, setMaxChar] = useState(280);
  const [editText, setEditText] = useState(
    selectedCard?.system_replies?.replier_info?.text,
  );
  const [btnLoading, setBtnLoading] = useState(false);
  const [activeReplyEditEngagement, setActiveReplyEditEngagement] =
    useState(false);
  const [editReplyDisable, setEditReplyDisable] = useState(false);
  const [editTooltipActive, setEditTooltipActive] = useState(false);
  const [editTooltipDate, setEditTooltipDate] = useState("");
  const dispatch = useDispatch();
  const reduxSlaData = useSelector((state) => state?.engagements?.sla);
  const userAssignedToChat = reduxSlaData?.[slaKey]?.userId;
  const signedInUser = localStorage?.getItem("user_id");
  const authorizedToEditAndDelete = +signedInUser == +userAssignedToChat;
  let companyTimeZone = window?.localStorage?.companyInfo;
  const editDateTooltip = (editTooltipDate) => {
    const editDateTooltip = moment
      .unix(editTooltipDate)
      .utc()
      .format("LT")
      .split(" ");
    return (
      <Box className="edit-date-tooltip">
        <Box component="span">
          {moment.unix(editTooltipDate).utc().format("YYYY/MM/DD")}
        </Box>
        <Box component="span">{CheckValueLocale("at", "", {}, intl)}</Box>
        <Box component="span">
          {editDateTooltip?.length
            ? ` ${editDateTooltip[0]} ${CheckValueLocale(
                editDateTooltip[1] == "AM"
                  ? "hashtag_period_am"
                  : "hashtag_period_pm",
                "",
                {},
                intl,
              )}`
            : null}
        </Box>
      </Box>
    );
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModalStats({
      ...openDeleteModalStats,
      openDeleteModal: false,
    });
  };

  const handleOpenDeleteModal = (event, id) => {
    event.stopPropagation();
    setOpenDeleteModalStats({
      ...openDeleteModalStats,
      openDeleteModal: true,
      id,
    });
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBarDelete(false);
  };

  //Handle Delte Reply
  const handleDeleteReply = (replyId) => {
    const dataSourceId = +postData?.channel_id;
    const monitorId = +postData?.tracker_id;
    const postType = postData?.system_replies?.replier_info?.item_type;
    const parentId = postData?.tweet_id || postData?.id;
    setDisabledDeleteBtn(true);
    EngagementsController.deleteEngagementPost(
      replyId,
      dataSourceId,
      monitorId,
      postType,
      window.localStorage.getItem("engagementsProductId"),
      parentId,
    ).then((res) => {
      if (res?.data?.status == 200 || res?.data?.status == 204) {
        setPostData({
          ...postData,
          replies: [],
          system_replies: {
            ...(postData?.system_replies || {}),
            replier_info: {},
          },
        });
        setSelectedCard((prev) => ({
          ...prev,
          replies: [],
          system_replies: {
            ...(prev?.system_replies || {}),
            replier_info: {},
          },
        }));
        setIsReplied(false);
        setDisabledDeleteBtn(false);
        handleCloseDeleteModal();
        setIsReplyDeleted(true);
        setShowEditDeleteBtns(false);

        setIsDeletedResponse(res?.data?.data?.is_deleted);
        setOpenSnackBarDelete(true);
        setSnackBarData({
          severity: "success",
          title: "delete_reply_success_msg",
        });
      } else {
        handleCloseDeleteModal();
        setDisabledDeleteBtn(false);
        setSnackBarData({
          title: "something_went_wrong",
          severity: "error",
        });
        setOpenSnackBarDelete(true);
      }
    });
  };

  //Handle Edit reply
  const handleEditReply = (replyId) => {
    const formData = new FormData();
    let objData = {
      id: replyId,
      data_source_id: postData?.channel_id,
      monitor_model_id: postData?.tracker_id,
      text: editText,
      product_id: window.localStorage.getItem("engagementsProductId"),
      parent_id: postData?.tweet_id || postData?.id,
    };
    formData.append("published", JSON.stringify(objData));
    setBtnLoading(true);
    setEditReplyDisable(true);
    EngagementsController.editReply(formData).then((res) => {
      if (res?.data?.status == 200 || res?.data?.status == 204) {
        setOpenSnackBarDelete(true);
        setActiveReplyEditEngagement(false);
        setEditTooltipActive(true);
        setEditReplyDisable(false);
        setBtnLoading(false);
        setEditTooltipDate(res?.data?.data?.date);
        setSnackBarData({
          severity: "success",
          title: "reply_edited_successfully",
        });
      } else {
        setOpenSnackBarDelete(true);
        setActiveReplyEditEngagement(false);
        setEditReplyDisable(false);
        setBtnLoading(false);
        setSnackBarData({
          title: "something_went_wrong",
          severity: "error",
        });
      }
    });
  };

  //Delete Reply Message
  const deleteReplyMsg = (postData) => {
    return (
      <Box className="delete-msg-wrapper">
        {postData?.system_replies?.replier_info !== null ? (
          <Box className="delete-msg-reply-by">
            {`${CheckValueLocale("replied_by", "", {}, intl)}: `}
            <Box component="span">
              {postData?.system_replies?.replier_info?.email}
            </Box>
          </Box>
        ) : null}
        <Box className="delete-msg-comments">
          {" "}
          <FontAwesomeIcon icon={faBan} />
          {CheckValueLocale("comment_was_deleted", "", {}, intl)}{" "}
        </Box>
      </Box>
    );
  };

  // handle sla frt counter when create replay
  useEffect(() => {
    let slaObj = { ...reduxSlaData };
    let slaSelectedObj = {
      ...slaObj[
        `${itemSelected?.tweet_id ? itemSelected?.tweet_id : itemSelected?.id}-${itemSelected?.tracker_id}`
      ],
    };
    // first we check if frt counter is live , and if it is and user create a replay we update frtSec to stop it
    if (
      slaSelectedObj?.slaId &&
      !slaSelectedObj?.frtSec &&
      postData?.replies?.[0]?.created_at
    ) {
      slaSelectedObj.frtSec =
        postData?.replies?.[0]?.created_at - itemSelected?.created_at;
      slaObj[
        `${itemSelected?.tweet_id ? itemSelected?.tweet_id : itemSelected?.id}-${itemSelected?.tracker_id}`
      ] = {
        ...slaSelectedObj,
      };
      dispatch(slaData(slaObj));
    }
  }, [postData]);

  const { data: themesOptions } = useThemesOptions({ locale: intl?.locale });
  const { data: categoriesOptions } = useCategoriesOptions({
    locale: intl?.locale,
  });
  const { data: sentimentsOptions } = useSentimentsOptions({
    locale: intl?.locale,
  });

  useEffect(() => {
    setIsCashedReplays(true);
    setPostData({ ...selectedCard });
    setSelectedInteractions((prev) => ({
      ...prev,
      themes: selectedCard?.themes || [],
      sub_themes: selectedCard?.sub_themes || [],
    }));
    setSelectedInteractionsParent((prev) => ({
      ...prev,
      themes: selectedCard?.parent_info?.themes || [],
      sub_themes: selectedCard?.parent_info?.sub_themes || [],
    }));

    if (sentimentsOptions && selectedCard?.sentiment) {
      const sentimentValue =
        sentimentsOptions?.[selectedCard?.sentiment]?.toString() ||
        selectedCard?.sentiment;
      setSelectedInteractions((prev) => ({
        ...prev,
        sentiment: sentimentValue,
      }));
    }
    if (sentimentsOptions && selectedCard?.parent_info?.sentiment) {
      const sentimentValue =
        sentimentsOptions?.[selectedCard?.parent_info?.sentiment]?.toString() ||
        selectedCard?.parent_info?.sentiment;
      setSelectedInteractionsParent((prev) => ({
        ...prev,
        sentiment: sentimentValue,
      }));
    }
  }, [selectedCard, sentimentsOptions]);

  const { mutate: editInteractions, isPending: editInteractionLoading } =
    useEditInteraction();

  const handleChangeInteraction = (interaction_type, newValue, index) => {
    if (index === -2) {
      setInteractionsLoadingParent((prev) => ({
        ...prev,
        [interaction_type]: true,
      }));
    } else {
      setInteractionsLoading((prev) => ({ ...prev, [interaction_type]: true }));
    }
    editInteractions(
      {
        queryData: {
          product_id: +window.localStorage.getItem("engagementsProductId"),
          monitor_id: +selectedCard?.tracker_id,
          id:
            index === -2
              ? selectedCard?.parent_info?.tweet_id
              : selectedCard?.tweet_id,
          data_source_name: selectedCard?.data_source,
          new_label: newValue,
          old_label:
            index == -2
              ? selectedInteractionsParent?.[interaction_type]
              : selectedInteractions?.[interaction_type],
          interaction_type,
        },
      },
      {
        onSuccess: () => {
          if (index === -2)
            setSelectedInteractionsParent((prev) => ({
              ...prev,
              [interaction_type]: newValue,
            }));
          else {
            setSelectedInteractions((prev) => ({
              ...prev,
              [interaction_type]: newValue,
            }));
          }
        },
        onSettled: () => {
          if (index === -2) {
            setInteractionsLoadingParent((prev) => ({
              ...prev,
              [interaction_type]: false,
            }));
          } else {
            setInteractionsLoading((prev) => ({
              ...prev,
              [interaction_type]: false,
            }));
          }
        },
      },
    );
  };

  const handleFullDateTimeFormat = (date) => {
    let dateHandler = moment
      .unix(date)
      .utc()
      .format("DD/MM/YYYY, hh:mm A")
      .split(" ");
    let dateFormat = `${dateHandler?.[0]} ${dateHandler?.[1]} ${CheckValueLocale(dateHandler?.[2] == "PM" ? "post_date_period_pm" : "post_date_period_am", "", {}, intl)}`;
    return dateFormat;
  };

  const posts = (obj, haveRep, mainPost, index) => {
    let text =
      postData?.data_source == "TWITTER" && obj?.tweet_full_text
        ? obj?.tweet_full_text
        : obj?.text;
    // inc case of twitter and there are med, twitter is adding a link in the end of the text message,
    // we want to remove this link from the text message if it exists
    // this link represent the tweet link, so we will the get the tweet url from the response and replace it with empty string in the text message
    if (obj?.data_source == "TWITTER" && obj?.media?.length > 0) {
      obj?.media?.forEach?.((mediaItem) => {
        const tweetURL = mediaItem?.url;
        text = text?.replace(` ${tweetURL}`, "");
      });
    }
    let mediaSize =
      obj.media?.length == 1
        ? text
          ? 114
          : 150
        : obj.media?.length == 2 || obj.media?.length == 3
          ? text
            ? 80
            : 150
          : obj.media?.length == 4
            ? text
              ? 60
              : 130
            : 60;

    let date = moment
      ?.unix(obj?.created_at)
      ?.format(intl.locale == "en" ? "YYYY/MM/DD hh a" : "DD/MM/YYYY hh a")
      .split(" ");
    let footerDate = moment.unix(obj?.created_at).utc().format("LT").split(" ");
    let sumOfValues = (obj) => {
      var sum = 0;
      for (var el in obj) {
        if (obj?.hasOwnProperty(el)) {
          sum += obj[el];
        }
      }
      return sum;
    };
    let reactsCount = obj?.heart_count
      ? `${obj?.heart_count}`
      : obj?.reactions && !isEmptyObj(obj?.reactions)
        ? sumOfValues(obj?.reactions)
        : "0";

    const translationButtonHandler = (text) => {
      setTranslationStatus({
        ...translationStatus,
        [index]: true,
      });
      setTranslationButtonLoading({
        ...translationButtonLoading,
        [index]: true,
      });
      const queryData = {
        text,
        target_language: translationTarget,
        source_language:
          translationSource !== "auto_detect" ? translationSource : null,
        product_id: window.localStorage.getItem("engagementsProductId"),
      };
      EngagementsController.detectTranslate(queryData).then((res) => {
        if (!res?.errorMsg) {
          setTranslationButtonResult({
            ...translationButtonResult,
            [index]: res?.data,
          });
          setOpenTranslationCollapse({
            ...openTranslationCollapse,
            [index]: true,
          });
        } else {
          setTranslationButtonResult({
            ...translationButtonResult,
            [index]: null,
          });
          setTranslationStatus({
            ...translationStatus,
            [index]: false,
          });
          setStats({
            ...stats,
            open: true,
            title: CheckValueLocale(
              "request_access_error_heading",
              "",
              {},
              intl,
            ),
            body: CheckValueLocale("request_access_error_desc", "", {}, intl),
            severity: "error",
          });
          setTimeout(() => {
            setStats({
              ...stats,
              open: false,
            });
          }, 7000);
        }
        setTranslationButtonLoading({
          ...translationButtonLoading,
          [index]: false,
        });
      });
    };

    const translationCollapseHandler = () => {
      setOpenTranslationCollapse({
        ...openTranslationCollapse,
        [index]: !openTranslationCollapse[index],
      });
    };

    const translationReplyHandler = (index) => {
      setOpenReplyCollapse({
        ...openReplyCollapse,
        [index]: !openReplyCollapse[index],
      });
    };
    const displayName = extractDisplayNameFromDataSource(
      selectedCard,
      obj?.name ? obj?.name : "user",
    );
    const handleMediaClick = (media, itemIndex) => {
      if (media?.length) {
        const newMedia = media?.map((item) => {
          const isVideo = !!(
            item?.isVideo || item?.media_type?.toLowerCase() == "video"
          );
          return {
            url: item?.media_url,
            type: isVideo ? "video" : "image",
          };
        });
        setShowMediaPreviewer(true);
        setMedia(newMedia);
        setDefaultMediaIndex(itemIndex);
      }
    };
    const showThemsTarget = index === -2 ? showParentThems : showThems;

    // Hnadle media Grid styles
    const getGridSize = (length) => {
      if (length === 1) return 12;
      if (length === 2) return 6;
      return 4; // 3 or more elements, 33.33% width each
    };
    return (
      <Box className="posts-rep">
        {showMediaPreviewer ? (
          <MediaPreviewer
            showMediaPreviewer={showMediaPreviewer}
            setShowMediaPreviewer={setShowMediaPreviewer}
            media={media}
            defaultIndex={defaultMediaIndex}
          />
        ) : null}
        <Box className="posts-main-img">
          <div className="post-header-user">
            <img
              src={obj?.profile_image_url ? obj?.profile_image_url : profileImg}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = profileImg;
              }}
            />
            <Box>
              <Box className="posts-main-user">{displayName}</Box>
              <Box className="post-date">
                {handleFullDateTimeFormat(obj?.created_at)}
              </Box>
            </Box>
          </div>

          {obj?.tweet_link ? (
            <Tooltip
              title={
                CheckValueLocale("open_on", "", {}, intl) +
                CheckValueLocale(obj?.data_source, "", {}, intl)
              }
              placement="bottom"
              arrow
            >
              <Box className="posts-main-url">
                <a href={obj?.tweet_link} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faExternalLink} />
                </a>
              </Box>
            </Tooltip>
          ) : null}

          {/* Main post */}
          {mainPost ? (
            <EditAndDeleteButtons
              handleOpenDeleteModal={(e) =>
                handleOpenDeleteModal(e, postData?.tweet_id)
              }
              setActiveReplyEditEngagement={setActiveReplyEditEngagement}
              deleteTooltip={"delete"}
              isDeletedResponse={isDeletedResponse}
              editTooltip={"edit"}
              isTweetLink={obj?.tweet_link}
              hideEditIcon={
                postData?.data_source == "TWITTER" ||
                postData?.data_source == "INSTAGRAM" ||
                !(postData?.data_source == "FACEBOOK" && obj?.is_account_owner)
              }
              hideDeleteIcon={
                (postData?.data_source == "FACEBOOK" &&
                  !(
                    (postData?.data_source == "FACEBOOK" &&
                      headerData?.monitor_type == "MANAGED_PAGE") ||
                    (postData?.data_source == "FACEBOOK" &&
                      obj?.is_account_owner)
                  )) ||
                (postData?.data_source == "INSTAGRAM" &&
                  !(
                    (postData?.data_source == "INSTAGRAM" &&
                      headerData?.monitor_type == "MANAGED_ACCOUNT") ||
                    (postData?.data_source == "INSTAGRAM" &&
                      obj?.is_account_owner)
                  )) ||
                (headerData?.product == "SM" &&
                  postData?.data_source == "TWITTER") ||
                (headerData?.product == "CXM" &&
                  postData?.data_source == "TWITTER" &&
                  !obj?.is_account_owner)
              }
            />
          ) : null}

          {/* //Replies */}
          {!mainPost && showEditDeleteBtns ? (
            <EditAndDeleteButtons
              handleOpenDeleteModal={(e) =>
                handleOpenDeleteModal(
                  e,
                  obj?.isSocket
                    ? postData?.replies[0]?.item_id
                    : postData?.system_replies?.interaction_id,
                )
              }
              setActiveReplyEditEngagement={setActiveReplyEditEngagement}
              deleteTooltip={"delete"}
              isDeletedResponse={isDeletedResponse}
              editTooltip={"edit"}
              isTweetLink={obj?.tweet_link}
              disabled={
                statusItemCard === "complete" || !authorizedToEditAndDelete
              }
              isStatusComplete={statusItemCard === "complete"}
              hideEditIcon={
                postData?.data_source == "TWITTER" ||
                !(
                  postData?.system_replies?.replier_info?.is_account_owner ||
                  obj?.is_account_owner
                ) ||
                postData?.data_source == "INSTAGRAM"
              }
              hideDeleteIcon={
                (postData?.data_source == "TWITTER" &&
                  !headerData?.product == "CXM" &&
                  !(
                    postData?.system_replies?.replier_info?.is_account_owner ||
                    obj?.is_account_owner
                  )) ||
                !(
                  postData?.system_replies?.replier_info?.is_account_owner ||
                  obj?.is_account_owner ||
                  (postData?.data_source == "FACEBOOK" &&
                    headerData?.monitor_type == "MANAGED_PAGE")
                ) ||
                !(
                  postData?.system_replies?.replier_info?.is_account_owner ||
                  obj?.is_account_owner ||
                  (postData?.data_source == "INSTAGRAM" &&
                    headerData?.monitor_type !== "MANAGED_ACCOUNT")
                ) ||
                (headerData?.product == "SM" &&
                  postData?.data_source == "TWITTER")
              }
            />
          ) : null}
        </Box>
        <Box
          className={
            mainPost && !haveRep
              ? "main-post last-child no-rep"
              : haveRep
                ? "main-post"
                : "main-post last-child"
          }
        >
          <Box className="card-text-media">
            {obj?.media?.length && obj?.media[0] ? (
              <Box
                className="cards-media"
                minWidth={
                  obj?.media.length < 4
                    ? mediaSize * obj?.media.length + 5
                    : mediaSize * 2 + 5
                }
              >
                <Grid className="custom-mui-grid" container spacing={2}>
                  {obj?.media?.map((item, itemIndex) =>
                    itemIndex <= 1 || itemIndex === 2 ? (
                      <Grid
                        item
                        xs={getGridSize(Math.min(obj?.media?.length))}
                        key={itemIndex}
                        onClick={() => handleMediaClick(obj?.media, itemIndex)}
                      >
                        {item?.media_url ? (
                          item?.isVideo ||
                          item?.media_type?.toLowerCase() === "video" ? (
                            <Box className="engagement-chat-message-media-video-container">
                              <video
                                width={"100%"}
                                height={200}
                                controls={false}
                                className="engagement-chat-message-media-video"
                              >
                                <source
                                  src={item?.media_url}
                                  type="video/mp4"
                                />
                                <source
                                  src={item?.media_url}
                                  type="video/ogg"
                                />
                                <source
                                  src={item?.media_url}
                                  type="video/avi"
                                />
                                <img
                                  src={item?.media_url}
                                  alt="Video Thumbnail"
                                />
                              </video>
                              {itemIndex === 2 && obj?.media?.length > 3 ? (
                                <Box
                                  className="engagement-chat-message-media-max-reached"
                                  onClick={() =>
                                    handleMediaClick(obj?.media, itemIndex)
                                  }
                                >
                                  <span>{`+${obj?.media?.length - 3}`}</span>
                                </Box>
                              ) : (
                                <Box className="play-button-container">
                                  <Box className="play-button">
                                    <FontAwesomeIcon icon={faPlay} />
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          ) : (
                            <Box className="engagement-chat-message-media-image-wrapper">
                              <img
                                className="engagement-chat-message-media-image"
                                width={"100%"}
                                height={200}
                                src={item?.media_url}
                                alt="Image Thumbnail"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = dummyImage;
                                }}
                              />
                              {itemIndex === 2 && obj?.media?.length > 3 ? (
                                <Box
                                  className="engagement-chat-message-media-max-reached"
                                  onClick={() =>
                                    handleMediaClick(obj?.media, itemIndex)
                                  }
                                >
                                  <span>{`+${obj?.media?.length - 3}`}</span>
                                </Box>
                              ) : null}
                            </Box>
                          )
                        ) : null}
                      </Grid>
                    ) : null,
                  )}
                </Grid>
              </Box>
            ) : null}
            {/* Main post text */}
            {mainPost ? (
              <Box
                className={isArabic(text) ? "post-ar" : "post-en"}
                dangerouslySetInnerHTML={{
                  __html:
                    selectedCard?.data_source === "TWITTER"
                      ? formatTextToHtml(sanitizeEngagementChatMessage(text)) // in public x platform only (posts & replies)
                      : sanitizeEngagementChatMessage(text),
                }}
              />
            ) : null}
            {/* reply on post text */}
            {!mainPost && isReplyDeleted && !isReplied ? (
              deleteReplyMsg(postData)
            ) : !mainPost ? (
              <>
                {activeReplyEditEngagement ? (
                  <EditReply
                    editReplyDisable={editReplyDisable}
                    maxChar={maxChar}
                    selectedCard={selectedCard}
                    postData={postData}
                    handleEditReply={() =>
                      handleEditReply(
                        obj?.isSocket
                          ? postData?.replies[0]?.item_id
                          : postData?.system_replies?.interaction_id,
                      )
                    }
                    btnLoading={btnLoading}
                    setActiveReplyEditEngagement={setActiveReplyEditEngagement}
                    setEditText={setEditText}
                    editText={editText}
                    onOpen={() =>
                      setEditText(
                        obj?.isSocket ? postData?.replies[0]?.text : editText,
                      )
                    }
                  />
                ) : (
                  <>
                    {editTooltipActive ? (
                      <Box className={isArabic(text) ? "post-ar" : "post-en"}>
                        <Box
                          component="span"
                          dangerouslySetInnerHTML={{
                            __html: sanitizeEngagementChatMessage(editText),
                          }}
                        />
                        <BootstrapTooltip
                          title={editDateTooltip(editTooltipDate)}
                          arrow
                          placement="top"
                          TransitionComponent={Zoom}
                        >
                          <Box component="span" className="edited-tooltip">
                            ({CheckValueLocale("edited", "", {}, intl)})
                          </Box>
                        </BootstrapTooltip>
                      </Box>
                    ) : (
                      <Box
                        className={isArabic(text) ? "post-ar" : "post-en"}
                        dangerouslySetInnerHTML={{
                          __html: sanitizeEngagementChatMessage(text),
                        }}
                      />
                    )}
                  </>
                )}
              </>
            ) : null}
          </Box>
          {translationActive && !obj?.original_replay ? (
            <Box className="card-translation-wrapper">
              <p
                id="engagements-posts-translation-btn"
                className="translation-button"
                onClick={() => translationButtonHandler(text)}
              >
                {CheckValueLocale("translate_from", "", {}, intl)} (
                {CheckValueLocale(translationSource, "", {}, intl)}){" "}
                {CheckValueLocale("to_lang", "", {}, intl)} (
                {CheckValueLocale(translationTarget, "", {}, intl)})
              </p>
              {translationStatus[index] ? (
                <Box className="card-translation-box">
                  {translationButtonLoading[index] ? (
                    <p className="translation-loading">
                      {CheckValueLocale("loading_please_wait", "", {}, intl)}
                    </p>
                  ) : (
                    <Box className="card-translation-collapse">
                      <Box className="collapse-heading">
                        <p className="translation-lang">
                          {CheckValueLocale("from_lang", "", {}, intl)}
                          <span>
                            {" "}
                            {CheckValueLocale(
                              translationButtonResult[index]?.source_language,
                              "",
                              {},
                              intl,
                            )}{" "}
                          </span>
                          {CheckValueLocale("to_lang", "", {}, intl)}
                          <span>
                            {" "}
                            {CheckValueLocale(
                              translationTarget,
                              "",
                              {},
                              intl,
                            )}{" "}
                          </span>
                        </p>
                        <span
                          className="arrow-icon-translation"
                          onClick={translationCollapseHandler}
                        >
                          {openTranslationCollapse[index] ? (
                            <ExpandMore />
                          ) : (
                            <ExpandLess />
                          )}
                        </span>
                      </Box>
                      <Collapse in={openTranslationCollapse[index]}>
                        <p className="translation-text">
                          {translationButtonResult[index]?.translation}
                        </p>
                        <TranslationRating
                          translationSource={
                            translationButtonResult[index]?.source_language
                          }
                          translationTarget={translationTarget}
                          translation={
                            translationButtonResult[index]?.translation
                          }
                          selectedCard={selectedCard}
                          engagementId={selectedCard?.tweet_id}
                        />
                      </Collapse>
                    </Box>
                  )}
                </Box>
              ) : null}
            </Box>
          ) : null}
          {obj?.original_replay ? (
            <Box className="card-translation-box">
              <Box className="card-translation-collapse">
                <Box className="collapse-heading">
                  <span className="translation-lang">
                    <span>
                      {CheckValueLocale("your_original_replay", "", {}, intl)}
                    </span>
                  </span>
                  <span
                    className="arrow-icon-translation"
                    onClick={() => translationReplyHandler(index)}
                  >
                    {!openReplyCollapse[index] ? (
                      <ExpandMore />
                    ) : (
                      <ExpandLess />
                    )}
                  </span>
                </Box>
                <Collapse in={!openReplyCollapse[index]}>
                  <span className="translation-text">
                    {obj?.original_replay}
                  </span>
                </Collapse>
              </Box>
            </Box>
          ) : null}
          {!obj?.isSocket ? (
            <>
              <Box className="cards-footer">
                {(isReplyDeleted || activeReplyEditEngagement) &&
                !mainPost ? null : mainPost || !mainPost ? (
                  <Box className="card-reacts">
                    <Box className={postData?.data_source?.toLowerCase()}>
                      {postData?.data_source?.toLowerCase() == "facebook" ? (
                        <img src={reacts} />
                      ) : (
                        <FavoriteBorderIcon />
                      )}

                      {reactsCount}
                    </Box>
                    <Box>
                      <img src={replay} />
                      {obj?.reply_count
                        ? `${obj?.reply_count}`
                        : obj?.total_comments
                          ? `${obj?.total_comments}`
                          : "0"}
                    </Box>
                  </Box>
                ) : null}

                {mainPost ? (
                  <>
                    <Box className="card-sentiment">
                      <SentimentCategoriesThemes
                        sentimentData={{
                          btnId: "engagement-card-sentiment-btn",
                          options: sentimentsOptions,
                          selected:
                            index == -2
                              ? selectedInteractionsParent?.sentiment
                              : selectedInteractions?.sentiment,
                          handleChange: (newValue) =>
                            handleChangeInteraction(
                              "sentiment",
                              newValue,
                              index,
                            ),
                          loading:
                            index === -2
                              ? interactionsLoadingParent?.["sentiment"]
                              : interactionsLoading?.["sentiment"],
                        }}
                        dividers={[2, 3]}
                      />
                    </Box>
                    {showThemsTarget ? (
                      <SentimentCategoriesThemes
                        categoriesData={{
                          btnId: "engagement-card-categories-btn",
                          options: categoriesOptions,
                          selected:
                            index == -2
                              ? selectedInteractionsParent?.themes
                              : selectedInteractions?.themes,
                          handleChange: (newValue) =>
                            handleChangeInteraction("themes", newValue, index),
                          loading:
                            index === -2
                              ? interactionsLoadingParent?.["themes"]
                              : interactionsLoading?.["themes"],
                        }}
                        themesData={{
                          btnId: "engagement-card-themes-btn",
                          options: themesOptions,
                          selected:
                            index == -2
                              ? selectedInteractionsParent?.sub_themes
                              : selectedInteractions?.sub_themes,
                          handleChange: (newValue) =>
                            handleChangeInteraction(
                              "sub_themes",
                              newValue,
                              index,
                            ),
                          loading:
                            index === -2
                              ? interactionsLoadingParent?.["sub_themes"]
                              : interactionsLoading?.["sub_themes"],
                        }}
                        dividers={[1]}
                      />
                    ) : (
                      <LucButton
                        variant="flat"
                        size="small"
                        onClick={() =>
                          index === -2
                            ? setShowParentThems(true)
                            : setShowThems(true)
                        }
                      >
                        {CheckValueLocale("show_more_btn", "", {}, intl)}
                      </LucButton>
                    )}
                  </>
                ) : null}
                {!mainPost && activeReplyEditEngagement ? (
                  <Box className="delete-msg-wrapper">
                    <Box className="delete-msg-reply-by">
                      {`${CheckValueLocale("replied_by", "", {}, intl)}: `}
                      <Box component="span">
                        {postData?.system_replies?.replier_info?.email}
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </>
          ) : null}
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (
      !(
        selectedCard.system_replies.is_replied === 1 ||
        headerData?.paused === 1 ||
        isReplied
      ) &&
      (savedSuggTab || aiRepsTab)
    ) {
      handelOpenSidebar();
    }
  }, [selectedCard, headerData, isReplied, savedSuggTab, aiRepsTab]);

  useEffect(() => {
    let status_logs = selectedCard?.system_replies?.status_logs?.length
      ? selectedCard?.system_replies?.status_logs?.sort(function (a, b) {
          return new Date(a?.created_at) - new Date(b?.created_at);
        })
      : [];
    setPostData({
      ...selectedCard,
      system_replies: {
        ...selectedCard?.system_replies,
        status_logs: status_logs?.length ? [...status_logs] : [],
      },
      replies:
        selectedCard?.replies?.length && !isEmptyObj(selectedCard?.replies[0])
          ? [...selectedCard?.replies]
          : postData?.replies?.length
            ? postData?.replies
            : selectedCard?.system_replies?.replier_info &&
                !isEmptyObj(selectedCard?.system_replies?.replier_info)
              ? [{ ...selectedCard?.system_replies?.replier_info }]
              : [],
    });
  }, [selectedCard]);

  const dateWithTimeZone = (date) => {
    let unixDate = moment(date).unix();
    let intialDate = moment
      .unix(unixDate)
      .format("YYYY/MM/DD hh:mm a")
      .split(" ");
    let dateText = `${intialDate[0]} - ${intialDate[1]} ${CheckValueLocale(
      intialDate[2] == "pm" ? "hashtag_period_pm" : "hashtag_period_am",
      "",
      {},
      intl,
    )} ${
      companyTimeZone >= 0
        ? `(${CheckValueLocale("gmt", "", {}, intl)} +${companyTimeZone})`
        : `(${CheckValueLocale("gmt", "", {}, intl)} ${companyTimeZone})`
    }`;
    return dateText;
  };

  const [value, setVal] = useState(2);
  const [tipValue, setTipValue] = useState(2);
  const valueTooltip = [];
  useEffect(() => {
    setTranslationStatus({});
    setOpenTranslationCollapse({});
    setTranslationButtonLoading({});
    setTranslationButtonResult({});
  }, [translationTarget, translationSource]);

  const mainIndexPost = -1;
  return (
    <Box className="main-engagment-posts">
      <Box
        className={
          selectedCard.system_replies.is_replied === 1 ||
          headerData?.paused === 1 ||
          isReplied
            ? "main-posts-contant no-rep"
            : `main-posts-contant ${!openSidebar ? "max-padding" : ""}`
        }
      >
        <Box className="main-contant">
          {postData?.parent_info?.tweet_id ? (
            <Box className="main-parent-post">
              {posts(postData?.parent_info, 0, true, -2)}
            </Box>
          ) : null}
          <Box
            className={`main-middle-post  ${postData?.replies?.length ? "middle-reply-style" : ""}`}
          >
            {posts(postData, postData?.replies?.length, true, mainIndexPost)}
          </Box>
          {postData?.replies?.map((item, index) => {
            return (
              <Box
                key={index}
                className="main-post-tree"
                onMouseEnter={() => setShowEditDeleteBtns(true)}
                onMouseLeave={() => setShowEditDeleteBtns(!showEditDeleteBtns)}
              >
                {posts(
                  item,
                  item?.replies?.length,
                  false,
                  index + "" + mainIndexPost,
                )}
                {item?.replies?.map((itemChild, indexChild) => {
                  return (
                    <Box className="main-border-tree">
                      {postData?.replies?.length - 1 != index && (
                        <Box className="border-tree"></Box>
                      )}
                      <Box className="main-post-tree">
                        {posts(
                          itemChild,
                          itemChild?.replies?.length,
                          false,
                          indexChild + "" + index + "" + mainIndexPost,
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            );
          })}
          {postData?.system_replies?.status_logs?.length ? (
            postData?.system_replies?.status_logs[
              postData?.system_replies?.status_logs?.length - 1
            ]?.status == "complete" ? (
              <Box className="main-post-state">
                <Box>
                  {CheckValueLocale(
                    "marked_as_completed",
                    "",
                    {
                      name: postData?.system_replies?.status_logs[
                        postData?.system_replies?.status_logs?.length - 1
                      ]?.user_email,
                      date: dateWithTimeZone(
                        postData?.system_replies?.status_logs[
                          postData?.system_replies?.status_logs?.length - 1
                        ]?.created_at,
                      ),
                    },
                    intl,
                  )}
                  {postData?.system_replies?.completion_reason == "other" ? (
                    <Box
                      component={"span"}
                      className="completion-reason-replies"
                    >
                      {`- ${CheckValueLocale(
                        postData?.system_replies?.completion_reason,
                        "",
                        {},
                        intl,
                      )}`}{" "}
                      {`(${postData?.system_replies?.other_reason}) `}
                    </Box>
                  ) : (
                    ` - ${CheckValueLocale(
                      postData?.system_replies?.completion_reason,
                      "",
                      {},
                      intl,
                    )} `
                  )}
                </Box>
              </Box>
            ) : postData?.system_replies?.status_logs[
                postData?.system_replies?.status_logs?.length - 1
              ]?.status == "active" ? (
              <Box className="main-post-state">
                <Box>
                  {CheckValueLocale(
                    "marked_as_active",
                    "",
                    {
                      name: postData?.system_replies?.status_logs[
                        postData?.system_replies?.status_logs?.length - 1
                      ]?.user_email,
                      date: dateWithTimeZone(
                        postData?.system_replies?.status_logs[
                          postData?.system_replies?.status_logs?.length - 1
                        ]?.created_at,
                      ),
                    },
                    intl,
                  )}
                </Box>
              </Box>
            ) : postData?.system_replies?.status_logs[
                postData?.system_replies?.status_logs?.length - 1
              ]?.status == "reopen" ? (
              <Box className="main-post-state">
                <Box>
                  {CheckValueLocale(
                    "marked_as_reopen",
                    "",
                    {
                      name: postData?.system_replies?.status_logs[
                        postData?.system_replies?.status_logs?.length - 1
                      ]?.user_email,
                      date: dateWithTimeZone(
                        postData?.system_replies?.status_logs[
                          postData?.system_replies?.status_logs?.length - 1
                        ]?.created_at,
                      ),
                    },
                    intl,
                  )}
                </Box>
              </Box>
            ) : null
          ) : null}
        </Box>
      </Box>
      {statusItemCard === "complete" ||
      headerData?.paused === 1 ||
      postData?.replies?.length ? (
        <Box className="main-posts-footer no-reply-footer">
          <Box className="main-no-reply">
            <Box
              className={
                headerData?.paused === 1
                  ? "main-sent-reply paused"
                  : "main-sent-reply"
              }
            >
              {headerData?.paused === 1 ? (
                <ErrorOutlineIcon />
              ) : (
                <CheckCircleIcon />
              )}
              {CheckValueLocale(
                headerData?.paused !== 1
                  ? statusItemCard === "complete"
                    ? "mark_completed"
                    : "reply_sent"
                  : headerData?.type == "monitor"
                    ? "paused_monitors_header"
                    : "paused_channels_header",
                "",
                {},
                intl,
              )}
            </Box>
            <Typography className="no-reply-class">
              {CheckValueLocale(
                headerData?.paused !== 1
                  ? statusItemCard === "complete"
                    ? "mark_completed_msg"
                    : "no_reply_text"
                  : headerData?.type == "monitor"
                    ? "paused_monitors_contant"
                    : "paused_channels_contant",
                "",
                { name: headerData?.monitor_name },
                intl,
              )}
              {headerData?.paused == 1 ? (
                <Link
                  target="_blank"
                  to={
                    headerData?.type == "monitor"
                      ? "/social/monitor_list"
                      : "/manage-channels/list"
                  }
                  className="paused-rediraction"
                >
                  {CheckValueLocale(
                    headerData?.type == "monitor"
                      ? "monitors_list"
                      : "manage_channels",
                    "",
                    {},
                    intl,
                  )}
                  <FontAwesomeIcon icon={faExternalLink} />
                </Link>
              ) : null}
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Box className="main-posts-footer-parant">
            {!openSidebar && authorizedToEditAndDelete ? (
              <Button
                id="engagements-ai-suggestions-btn"
                className="ai-suggestions"
                onClick={() => handelOpenSidebar()}
              >
                <img src={ezgif} />
                {CheckValueLocale("ai_suggestions", "", {}, intl)}
              </Button>
            ) : null}
            <ReplyModal
              setSelectedCardIteractionID={setSelectedCardIteractionID}
              setShowSuccessModal={setShowSuccess}
              showSuccessModal={showSuccess}
              selectedCard={selectedCard}
              setPostData={setPostData}
              postData={postData}
              headerData={headerData}
              setIsReplied={setIsReplied}
              setIsDeletedResponse={setIsDeletedResponse}
              setOpenDetailsSideBar={setOpenDetailsSideBar}
              setOpenDetailsSideBarReplay={setOpenDetailsSideBarReplay}
              setOpenSlaSideBar={setOpenSlaSideBar}
              text={text}
              setText={setText}
              setOpenSidebar={setOpenSidebar}
              checkAiSuggestions={checkAiSuggestions}
              setCheckAiSuggestions={setCheckAiSuggestions}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              translationActive={translationActive}
              setTranslationActive={setTranslationActive}
              translationSource={translationSource}
              setTranslationSource={setTranslationSource}
              translationTarget={translationTarget}
              setTranslationTarget={setTranslationTarget}
              stats={stats}
              setStats={setStats}
              languagesArraySource={languagesArraySource}
              languagesArrayTarget={languagesArrayTarget}
              translateStatus={translateStatus}
              setTranslateStatus={setTranslateStatus}
              openSidebar={openSidebar}
              openDetailsSideBarReplay={openDetailsSideBarReplay}
              isSidebarsOpened={isSidebarsOpened}
              showAllIcon={showAllIcon}
              aiAgentList={aiAgentList}
              setRefresh={setRefresh}
              setOpenSnackBarAiAgent={setOpenSnackBarAiAgent}
              setSnackBarDataAiAgent={setSnackBarDataAiAgent}
              setInjectedMessage={setInjectedMessage}
              aiAgentId={aiAgentId}
              replyBoxInputTextRef={replyBoxInputTextRef}
              reduxSlaKeys={slaKey}
              itemSelected={itemSelected}
              socket={socket}
            />
          </Box>
        </>
      )}
      {/* Delete Modal Popup */}
      <PopupModal
        title={CheckValueLocale("delete_reply", "", {}, intl)}
        body={CheckValueLocale("delete_reply_body", "", {}, intl)}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("delete", "", {}, intl)}
        open={openDeleteModalStats?.openDeleteModal}
        close={handleCloseDeleteModal}
        disabled={disabledDeleteBtn}
        accept={() => handleDeleteReply(openDeleteModalStats?.id)}
        rightColor="error"
        warning
        minWidth="600px"
        addClasses="delete-engagement-buttons"
      />

      <SnackBar
        open={openSnackBarDelete}
        handleClose={handleCloseSnackBar}
        title={CheckValueLocale(snackBarData?.title, "", {}, intl)}
        severity={snackBarData?.severity}
        message={CheckValueLocale(snackBarData?.message, "", {}, intl)}
      />
    </Box>
  );
};

export default PostsAndReplies;
