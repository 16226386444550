import { Box, Typography } from "@mui/material";
import PopupModal from "components/popupModal";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";
import { useEditPublishedVersion } from "pages/engagements/components/aiAgents/aiAgentsHooks/useEditPublishedVersion";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { sanitize } from "dompurify";
import { useQueryClient } from "@tanstack/react-query";

const EditPublishedVersionModal = ({ open, onClose, versionId }) => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const { setAiAgentSnackBar, setSelectedAiAgentPublishedVersion } =
    useContext(AiAgentsContext);

  const { mutate: editPublishedVersion, isPending } = useEditPublishedVersion();

  const handleEditPublishedVersion = () => {
    editPublishedVersion(
      {
        queryData: {
          version_id: versionId,
        },
      },
      {
        onSuccess: async () => {
          onClose();
          await queryClient.invalidateQueries({ queryKey: ["aiAgentDetails"] });
          setSelectedAiAgentPublishedVersion(null);
        },
        onError: () => {
          setAiAgentSnackBar({
            success: false,
            title: CheckValueLocale("failed_error_message", "", {}, intl),
            message: CheckValueLocale("try_again_error_message", "", {}, intl),
          });
        },
      },
    );
  };

  const modalBody = (
    <Box>
      <Typography
        className="edit-published-ai-agent-modal-body"
        dangerouslySetInnerHTML={{
          __html: sanitize(
            CheckValueLocale("edit_published_ai_agent_body", "", {}, intl),
          ),
        }}
      />
    </Box>
  );

  return (
    <PopupModal
      title={CheckValueLocale("edit_your_published_version", "", {}, intl)}
      body={modalBody}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("move_to_draft", "", {}, intl)}
      disabled={isPending}
      isRightBtnLoading={isPending}
      open={open || false}
      close={!isPending ? onClose : null}
      accept={handleEditPublishedVersion}
      classeName={"ai-agent-popupModal"}
      dialogContentId="ai-agent-edit-published-version"
    />
  );
};

export default EditPublishedVersionModal;
