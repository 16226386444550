import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Services from "services/api/Services";
import PageHeader from "components/pageHeader";

const HashtagsTitle = ({ hashtags }) => {
  const intl = useIntl();

  return (
    <Box className="hashtags-title-wrapper">
      <PageHeader title="instagram_hashtags_manager" />
      {hashtags?.length && Services.showThirdCompany ? (
        <LucButton
          type="primary"
          variant="flat"
          size="small"
          onClick={() =>
            window.open(
              "https://knowledge.lucidya.com/الأسئلة-الشائعة#مدير-هاشتاقات-انستقرام",
            )
          }
          startIcon={<FontAwesomeIcon icon={faExternalLink} />}
        >
          {CheckValueLocale("learn_how_to_use", "", {}, intl)}
        </LucButton>
      ) : null}
    </Box>
  );
};
export default HashtagsTitle;
