import { useShowMore } from "hooks/useShowMore";
import { TeamsDetails } from "./steps/teamsDetailsStep";
import { AccoutnsAndMonitors } from "./accoutnsAndMonitorsStep";
import TeamsMembersStep from "./steps/teamsMembersStep";

// this component will hold all the show more state and logic for every section that has show more
// and will render the current step
export const RenderCurrentStep = ({ step, ...props }) => {
  const showMoreMembersObj = useShowMore({
    data: props?.members?.data,
    pagination: props?.members?.pagination,
  });
  switch (step) {
    case 0: // handle step one to add team
      return <TeamsDetails {...props} />;
    case 1: // handle step two to add team
      return (
        <TeamsMembersStep {...props} showMoreMembersObj={showMoreMembersObj} />
      );
    case 2: // handle step three to add team (this part is not available if you need to add go to step and remove the comment)
      return <AccoutnsAndMonitors {...props} />;
    default:
      return null;
  }
};
