import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import {
  Box,
  Card,
  CardContent,
  Input,
  FormHelperText,
  Typography,
  InputAdornment,
} from "@mui/material";
import styles from "../commonStyle.module.css";

const ShortTextQuestion = (props) => {
  const { question, questionNo, setErrorForm } = props;

  const intl = useIntl();
  const data = question;
  const [answer, setAnswer] = useState("");
  const [errorLimit, setErrorLimit] = useState("");

  const handleAnswers = (e) => {
    const value = e.target.value;
    setAnswer(e.target.value);
    if (data?.question?.constraints?.max_chars > 0) {
      if (value?.length > data?.question?.constraints?.max_chars) {
        setErrorLimit("error_msg_free_text-survet");
        setErrorForm((prev) => ({
          ...prev,
          [questionNo]: true,
        }));
      } else {
        setErrorLimit("");
        setErrorForm((prev) => ({
          ...prev,
          [questionNo]: false,
        }));
      }
    }
  };

  return (
    <Card className="cardStyle">
      <CardContent>
        <Typography className={styles.questionStyle}>
          <Box
            component="span"
            className="questionNoStyle"
          >{`${questionNo}.`}</Box>
          {data?.question?.text}
          {data?.question?.constraints?.required && (
            <Box component="span" className={styles.impQuestion}>
              *
            </Box>
          )}
        </Typography>
        <Box className={styles.subtitle}>{data?.question?.description}</Box>
        <Box>
          <Input
            id="standard-basic"
            placeholder={CheckValueLocale(
              "type_your_answer_here",
              "",
              {},
              intl,
            )}
            variant="standard"
            className={styles.textFieldStyle}
            value={answer}
            onChange={handleAnswers}
            error={!!errorLimit}
            endAdornment={
              <InputAdornment
                position="end"
                className={!!errorLimit ? "error-msg-survey" : ""}
              >
                {data?.question?.constraints?.max_chars > 0
                  ? `${answer?.length || 0}/${data?.question?.constraints?.max_chars}`
                  : ""}
              </InputAdornment>
            }
          />
          <FormHelperText className="error-msg-survey">
            {CheckValueLocale(
              errorLimit,
              "",
              { max: data?.question?.constraints?.max_chars },
              intl,
            )}
          </FormHelperText>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ShortTextQuestion;
