import { useQuery } from "@tanstack/react-query";
import EngagementsController from "services/controllers/engagementsController";

export const useInboundRoutingsList = () => {
  return useQuery({
    queryKey: ["inboundRoutingsList"],
    queryFn: () => EngagementsController.getInboundRoutings(),
    select: (data) => data?.data?.data?.map((item) => item?.attributes),
    retry: false,
  });
};
