import InstagramHashtags from "../api/instagramHashtags";

let InstagramHashtagsController = {};

InstagramHashtagsController.getHashtags = (
  hashtag_search,
  status,
  page,
  sort_by,
  order_by,
) => {
  var queryData = {
    hashtag_search,
    status,
    page,
    sort_by,
    order_by,
  };

  return new Promise(function (resolve, reject) {
    InstagramHashtags.getHashtags(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
          pagination: response?.data?.pagination,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

InstagramHashtagsController.deletHashtag = (hashtagId) => {
  return new Promise(function (resolve, reject) {
    InstagramHashtags.deletHashtag(hashtagId)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({
          errorMsg: error,
        });
      });
  });
};

InstagramHashtagsController.getHashtagsHeader = () => {
  return new Promise(function (resolve, reject) {
    InstagramHashtags.getHashtagsHeader()
      .then(function (response) {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch(function (error) {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default InstagramHashtagsController;
