import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { InputWithShrinkLabel } from "./inputWithShrinkLabel/inputWithShrinkLabel";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "./teamsSharedStyle.scss";
import InputLabelFormik from "pages/settings/pages/userManagementPage/subPages/shardComponents/userForm/components/inputLabelFormik/inputLabelFormik";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const TeamInformation = ({
  formik,
  options = [],
  isProductsDisabled,
}) => {
  const intl = useIntl();

  return (
    <Box className="team-information-container">
      <Box className="team-information-form">
        <Box className="team-name-input">
          <InputLabelFormik
            value={formik?.values?.team_name}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            labelId={"team_name"}
            name={"team_name"}
            placeHolderId={"team_name_placeholder"}
            errorMsg={
              formik?.touched?.team_name ? formik?.errors?.team_name : ""
            }
            required
          />
        </Box>

        <Box className="product-select">
          <InputLabel className="team-product-selected-label">
            {CheckValueLocale("select_product_label", "", {}, intl)}
          </InputLabel>
          <Select
            id="select-small-product"
            name={"selected_product"}
            className="select-with-shrink-label"
            value={formik?.values?.selected_product}
            onChange={formik?.handleChange}
            MenuProps={MenuProps}
            disabled={isProductsDisabled}
          >
            {options?.map((option) => {
              return (
                <MenuItem value={option?.id} key={option?.id}>
                  {CheckValueLocale(option?.name, "", {}, intl)}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Box>
    </Box>
  );
};
