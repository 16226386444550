import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import _ from "lodash";
import { Container, Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useNotificationsList } from "./hooks/useNotificationsList";
import { useNotificationsCount } from "./hooks/useNotificationsCount";
import Pagination from "@mui/material/Pagination";
import SnackBar from "components/snackBar";
import ListHeader from "./list/Header";
import NotificationList from "./list/List";
import "./list/list.scss";
import Services from "services/api/Services.js";
import UserAccount from "services/controllers/userAccountController";
import CircularLoading from "components/circularProgress";

const NotificationsNew = () => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({});
  const [page, setPage] = useState(1);
  const [tempNotificationType, setTempNotificationType] = useState([]);
  const [filterData, setFilterData] = useState({
    notificationType: [],
    startDate: null,
    endDate: null,
  });

  // Get notifications count and oldest date to be applied in filters (Date Range Picker)
  const { data: notificationsCount, isError: countError } =
    useNotificationsCount();

  // Get notifications list with any change in filters
  const {
    data: notificationsData,
    isPending: listLoading,
    isError: listError,
    refetch,
  } = useNotificationsList({
    page,
    per_page: 12,
    ...(filterData?.notificationType?.length
      ? { notification_type: filterData?.notificationType?.join(",") } // To be joined by comma if more than one selected
      : {}), // To be not sent untill the user selects a notification type
    ...(filterData?.startDate ? { start_date: filterData?.startDate } : {}), // To be not sent untill the user selects a date
    ...(filterData?.endDate ? { end_date: filterData?.endDate } : {}), // To be not sent untill the user selects a date
  });

  // Show error snackbar in case of any error occured in list or count
  useEffect(() => {
    if (listError || countError) {
      setSnackbar({
        open: true,
        severity: "error",
        title: "something_went_wrong",
        message: "try_again",
      });
    } else setSnackbar({});
  }, [listError, countError]);

  const handelFilters = (newFilterData) => {
    if (_.isEqual(newFilterData, filterData) !== true) {
      setPage(1);
      setFilterData({ ...newFilterData });
    } else refetch();
  };

  const HandelRedirection = async (monitor) => {
    setLoading(true);
    let redirectionUrl = Services.switchUrl;

    let dataSourcesLinksView = {
      KEYWORD: {
        TWITTER: "metrics",
        FACEBOOK: "fb",
        INSTAGRAM: "instagram",
        TALKWALKER: "news",
      },
      MANAGED_PAGE: {
        FACEBOOK: "managed_page/facebook/account-analysis",
      },
      MANAGED_ACCOUNT: {
        INSTAGRAM: "managed_account/instagram/account-analysis",
      },
      ACCOUNT_ANALYSIS: {
        TWITTER: "account_analysis",
        INSTAGRAM: "account_analysis/instagram",
        FACEBOOK: "public_pages/facebook",
      },
      REGION: {
        TWITTER: "metrics",
      },
    };

    let monitor_link =
      "#/" +
      dataSourcesLinksView[monitor.monitor_type_name][
        monitor.monitor_active_sources[0]
      ];
    let redirectLink = `${monitor_link}/${monitor.monitor_id}/${monitor.created_at}/`;

    await UserAccount.getSmData().then((result) => {
      if (result.data !== undefined) {
        localStorage.setItem("user_token", result.data.data.jwt);
        window.open(
          redirectionUrl +
            redirectLink +
            "?lang=" +
            localStorage.lang +
            "&sm_token=" +
            result.data.data.jwt,
          "_self",
        );
      }
    });
  };

  return (
    <Container maxWidth="xl">
      <Box mt={5} mb={5} className="notifications-main-box">
        <ListHeader
          handelFilters={handelFilters}
          filterData={filterData}
          oldDate={notificationsCount?.oldest_date}
          notificationType={tempNotificationType}
          setNotificationType={setTempNotificationType}
        />
        {listLoading || loading ? (
          <CircularLoading />
        ) : (
          <>
            <NotificationList
              list={notificationsData?.data || []}
              HandelRedirection={HandelRedirection}
              selectNotificationType={tempNotificationType}
            />
            {notificationsData?.pagination?.pages > 1 && (
              <Pagination
                className="pagination-monitor-list pagination-notification-list"
                variant="outlined"
                page={page}
                count={notificationsData?.pagination?.pages}
                onChange={(e, page) => setPage(page)}
              />
            )}
          </>
        )}
      </Box>
      {snackbar?.open ? (
        <SnackBar
          open={snackbar?.open}
          severity={snackbar?.severity}
          title={CheckValueLocale(snackbar?.title, "", {}, intl)}
          message={CheckValueLocale(snackbar?.message, "", {}, intl)}
        />
      ) : null}
    </Container>
  );
};

export default NotificationsNew;
