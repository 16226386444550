import { useMutation, useQueryClient } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useDeleteCustomDomain = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (queryData) =>
      SettingsController?.deleteCustomDomain(queryData),
    onSuccess: () =>
      queryClient?.invalidateQueries({ queryKey: ["companyInfo"] }),
    select: (data) => data?.data,
    retry: false,
  });
};
