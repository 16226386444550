import { useQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useGetAllTeams = (enabled) => {
  return useQuery({
    queryKey: ["allTeams"],
    queryFn: () => CompaniesController.getAllTeams(),
    enabled: enabled,
    retry: false,
  });
};
