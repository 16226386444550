import { Box, Grid } from "@mui/material";
import ProductAddonItem from "./components/productAddonItem";

const ProductsTab = (props) => {
  const { data, activeProducts, setSnackbar, setExpanded } = props;

  const productsData = [
    {
      title: "subscriptions_social_listening_title",
      sub_title: "subscriptions_social_listening_sub_title",
      productKey: "social-listening",
      usageId: data?.sm_package?.attributes?.combined_packages
        ? "combined-package"
        : "social-listening",
      active: activeProducts?.includes("sm"),
      demoKey: "request_social_listening_demo",
      upgradeKey: "request_social_listening_upgrade",
      learnMoreUrl: "https://knowledge.lucidya.com/لوسيديا-الرصد-الاجتماعي",
    },
    {
      title: "subscriptions_omnichannel_title",
      sub_title: "subscriptions_omnichannel_sub_title",
      productKey: "omnichannel",
      usageId: data?.ca_package?.attributes?.combined_packages
        ? "combined-package"
        : "omnichannel",
      active: activeProducts?.includes("cxm"),
      demoKey: "request_channel_analytics_demo",
      upgradeKey: "request_channel_analytics_upgrade",
      learnMoreUrl: "https://knowledge.lucidya.com/تحليل-قنوات-التواصل",
    },
    {
      title: "subscriptions_engagements_title",
      sub_title: "subscriptions_engagements_sub_title",
      productKey: "engagement",
      usageId: "engagement",
      active: activeProducts?.includes("engagements"),
      demoKey: "request_engagement_demo",
      upgradeKey: "request_engagement_upgrade",
      learnMoreUrl: "https://knowledge.lucidya.com/التفاعل",
    },
    {
      title: "subscriptions_survey_title",
      sub_title: "subscriptions_survey_sub_title",
      productKey: "survey",
      usageId: "survey",
      active: activeProducts?.includes("survey"),
      demoKey: "request_survey_demo",
      upgradeKey: "request_survey_upgrade",
      learnMoreUrl: "https://knowledge.lucidya.com/الإستبيانات",
    },
    {
      title: "subscriptions_cdp_title",
      sub_title: "subscriptions_cdp_sub_title",
      productKey: "cdp",
      usageId: "cdp",
      active: activeProducts?.includes("cdp"),
      demoKey: "request_audience_demo",
      upgradeKey: "request_audience_upgrade",
      learnMoreUrl: "https://knowledge.lucidya.com/منصة-بيانات-العملاء",
    },
    {
      title: "subscriptions_ai_api_title",
      sub_title: "subscriptions_ai_api_sub_title",
      productKey: "ai-api",
      usageId: "ai-api",
      active: activeProducts?.includes("ai_api"),
      demoKey: "request_ai_api_demo",
      upgradeKey: "request_ai_api_upgrade",
      learnMoreUrl:
        "https://knowledge.lucidya.com/kb-search-results?term=AI+API",
    },
  ];

  return (
    <Box id="products-tab-content">
      <Grid spacing={2} container>
        {[...(productsData || [])]?.map((item, index) => {
          return (
            <ProductAddonItem
              key={index}
              item={item}
              setSnackbar={setSnackbar}
              setExpanded={setExpanded}
            />
          );
        })}
      </Grid>
    </Box>
  );
};

export default ProductsTab;
