import { Box, Divider } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { faCopy, faEye, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import CustomizedTooltip from "components/customizedTooltip";
import DeleteCustomDomainModal from "./deleteCustomDomainModal";

const CustomDomainInfo = ({ customDomainDetails, handleView, setSnackbar }) => {
  const intl = useIntl();
  const [copied, setCopied] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    navigator?.clipboard?.writeText(customDomainDetails?.custom_domain);
  };

  const status = customDomainDetails?.frontend_status;

  const actionButtons = [
    {
      tooltip_title: copied ? "linked_copy" : "copy",
      tooltip_id: `custom-domain-${copied ? "copied" : "copy"}-tooltip`,
      btn_id: "copy-custom-domain-btn",
      icon: faCopy,
      handleClick: handleCopy,
    },
    {
      tooltip_title: "view",
      btn_id: "view-custom-domain-btn",
      icon: faEye,
      handleClick: handleView,
    },
    {
      tooltip_title: "delete",
      btn_id: "delete-custom-domain-btn",
      btn_className: openDeleteModal ? "active" : "",
      icon: faTrashCan,
      handleClick: () => setOpenDeleteModal(true),
    },
  ];

  return (
    <>
      <Box className="custom-domain-wrapper">
        <Box className="custom-domain-name-wrapper">
          <FontAwesomeIcon icon={faGlobe} className="custom-globle-icon" />
          {customDomainDetails?.custom_domain || ""}
          {status ? (
            <Box className={`custom-domain-status ${status}`}>
              {CheckValueLocale(
                status === "pending"
                  ? "custom_domain_pending"
                  : status === "connected"
                    ? "custom_domain_verified"
                    : "custom_domain_failed",
                "",
                {},
                intl,
              )}
            </Box>
          ) : null}
        </Box>

        <Box className="custom-domain-actions-wrapper">
          {actionButtons?.map((item, index) => (
            <Box key={index} className="custom-domain-action-btn-wrapper">
              <CustomizedTooltip
                id={item?.tooltip_id || ""}
                title={
                  <Box className="item-title-tooltip">
                    {CheckValueLocale(item?.tooltip_title, "", {}, intl)}
                  </Box>
                }
                placement="bottom"
                arrow
              >
                <Box>
                  <LucButton
                    id={item?.btn_id}
                    className={`custom-domain-action-btn ${item?.btn_className || ""}`}
                    variant="flat"
                    type="secondary"
                    minWidth={42}
                    onClick={item?.handleClick}
                  >
                    <FontAwesomeIcon icon={item?.icon} />
                  </LucButton>
                </Box>
              </CustomizedTooltip>

              {/* To hide this divider for the last button */}
              {index !== actionButtons.length - 1 ? (
                <Divider orientation="vertical" />
              ) : null}
            </Box>
          ))}
        </Box>
      </Box>

      <DeleteCustomDomainModal
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        setSnackbar={setSnackbar}
      />
    </>
  );
};

export default CustomDomainInfo;
