import HomePage from "services/api/homePage.js";

var HomePageController = {};

HomePageController.getHomePageMonitors = () => {
  return new Promise(function (resolve) {
    HomePage.getHomePageMonitors()
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
HomePageController.getHomePageNeedsAttention = () => {
  return new Promise(function (resolve) {
    HomePage.getHomePageNeedsAttention()
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
HomePageController.getHomePageReport = () => {
  return new Promise(function (resolve) {
    HomePage.getHomePageReport()
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
HomePageController.getCsmInfo = () => {
  return new Promise((resolve, reject) => {
    HomePage.getCsmInfo()
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};
HomePageController.getDashboards = () => {
  return new Promise(function (resolve) {
    HomePage.getDashboards()
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        resolve({ data: [], errorMsg: error });
      });
  });
};
export default HomePageController;
