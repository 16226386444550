import { api } from "./newServices";

const getAiAgentList = () => {
  return api.get("api/v5/ai_agents/ai_agents");
};

const createAiAgent = (queryData) => {
  return api.post("api/v5/ai_agents/ai_agents", queryData);
};

const getAiAgentDetails = (id) => {
  return api.get(`api/v5/ai_agents/ai_agents/${id}`);
};

const deleteAiAgent = ({ id, queryData }) => {
  return api.delete(`api/v5/ai_agents/ai_agents/${id}`, { data: queryData });
};

const dublicateAiAgent = (id) => {
  return api.post(`api/v5/ai_agents/ai_agents/${id}/duplicate`);
};

const resumeAiAgent = (queryData) => {
  return api.post("api/v5/ai_agents/ai_agents/resume_ai_agent", queryData);
};
const resumePublish = (queryData) => {
  return api.post(
    "api/v5/ai_agents/ai_agents/resume_publish_ai_agent",
    queryData,
  );
};

const pauseAiAgent = (queryData) => {
  return api.post("api/v5/ai_agents/ai_agents/pause_ai_agent", queryData);
};

const editAiAgent = ({ id, queryData }) => {
  return api.put(`api/v5/ai_agents/ai_agents/${id}`, queryData);
};

const getSurveysPublishedList = () => {
  return api.get("api/v3/surveys/published_list");
};

const getPublishedVersions = (id, queryData) => {
  return api.get(`api/v5/ai_agents/ai_agents/${id}/get_versions`, {
    params: queryData,
  });
};

const getAiAgentImage = (id) => {
  return api.get(`api/v5/ai_agents/ai_agents/${id}/download_image`);
};

const getResourcesList = (queryData) => {
  return api.get("api/v5/ai_agents/ai_agent_resources", { params: queryData });
};

const addResource = (queryData) => {
  return api.post("api/v5/ai_agents/ai_agent_resources", queryData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const deleteResource = (id) => {
  return api.delete(`api/v5/ai_agents/ai_agent_resources/${id}`);
};

const forceRefreshResource = (id) => {
  return api.post(`api/v5/ai_agents/ai_agent_resources/${id}/force_refresh`);
};

const downloadResource = (id, queryData) => {
  return api.get(`api/v5/ai_agents/ai_agent_resources/${id}/download_file`, {
    params: queryData,
  });
};

const editResourceFile = (id, queryData) => {
  return api.put(`api/v5/ai_agents/ai_agent_resources/${id}`, queryData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const publishAiAgent = (id) => {
  return api.post(`api/v5/ai_agents/ai_agents/${id}/publish`);
};

const getPublishedVersionDetails = (id) => {
  return api.get(`api/v5/ai_agents/ai_agents/${id}/show_version`);
};

const editPublishedVersion = (queryData) => {
  return api.post("api/v5/ai_agents/ai_agents/change_draft", queryData);
};

const autoAssignAiAgent = (queryData) => {
  return api.post(
    "api/v5/ai_agents/ai_agents/ai_agent_to_auto_assign",
    queryData,
  );
};

const unassignedConversationAiAgent = (queryData) => {
  return api.post(
    "api/v5/ai_agents/ai_agents/ai_agent_unassigned_conversation",
    queryData,
  );
};

const getAiAgentsLightList = () => {
  return api.get("api/v5/ai_agents/ai_agents/ai_agents_light_list");
};

const runAiAgentTest = (id) => {
  return api.post(`api/v5/ai_agents/ai_agents/${id}/run_test`);
};

const downloadSwaggerTemplate = () => {
  return api.get("api/v5/ai_agents/ai_agents/ai_agent_guide");
};

const getCountries = () => {
  return api.get("api/v5/ai_agents/ai_agents/ai_agents_sources");
};

export const AiAgentsServices = {
  getAiAgentList,
  createAiAgent,
  getAiAgentDetails,
  deleteAiAgent,
  dublicateAiAgent,
  resumeAiAgent,
  pauseAiAgent,
  editAiAgent,
  getSurveysPublishedList,
  getPublishedVersions,
  getAiAgentImage,
  getResourcesList,
  addResource,
  deleteResource,
  forceRefreshResource,
  downloadResource,
  editResourceFile,
  publishAiAgent,
  getPublishedVersionDetails,
  editPublishedVersion,
  autoAssignAiAgent,
  unassignedConversationAiAgent,
  getAiAgentsLightList,
  runAiAgentTest,
  downloadSwaggerTemplate,
  getCountries,
  resumePublish,
};
