//to handle header name for first column in the sheet for pieChart widget
const handleNameForFirstColumnPieChart = (widgetName) => {
  switch (widgetName) {
    case "top_languages":
      return "language";
    case "associated_topics":
      return "topic";
    case "top_countries":
      return "countries";
    case "top_cities":
      return "cities";
    case "gender_distribution":
      return "gender";
    case "channel_accounts":
      return "accounts";
    default:
      return widgetName; // "dialects" , "sub_dialects" ,"posts_content_style" , "account_types"
  }
};

// "handleTranslatName" to handle label name in the first column for each row in sheet
const handleTranslatName = (name, widgetName, xlsLanguage) => {
  switch (widgetName) {
    // with these widget the translation depend on Front-end
    case "dialects_subdialects":
    case "dialects":
    case "sub_dialects":
    case "gender_distribution":
    case "account_types":
    case "content_type":
    case "posts_content_style":
    case "associated_topics":
      return xlsLanguage[name] ? xlsLanguage[name] : name;

    default:
      return name; // translat from backend (Ex : top_languages,top_countries,..)
  }
};

/////////////////// PieChart (aggregate && defualt ) ////////////////////////////////////
// widgetData ===> [{name: "label" , value: number} , ..........]
// return ===> (aggregate- defualt) ---->  [[headerNameFirstColumn , number] , [ label1 , 50] , ............]
export const handleDataPieChartToExcel = (
  widgetData,
  widgetName,
  xlsLanguage,
  customizedType,
) => {
  try {
    // if all pie chart with excel sheet are constant two column (name , number)
    const dataListExcelSheet = [
      [
        xlsLanguage?.[handleNameForFirstColumnPieChart(widgetName)],
        xlsLanguage?.number,
      ],
    ];

    //to set after second colume "number" add word "aggregate" with translation
    if (customizedType == "aggregate")
      dataListExcelSheet[0][1] += " - " + xlsLanguage?.aggregate;

    //to set the Body of excel
    widgetData?.forEach((item) => {
      dataListExcelSheet?.push([
        handleTranslatName(item?.name, widgetName, xlsLanguage),
        item?.value,
      ]);
    });

    return dataListExcelSheet;
  } catch {}
};

/////////////////// PieChart (comparison) ////////////////////////////////////
//when used comparison with pie chart , it will be StackedBarChart
//widgetData (the same with themes)--->  { data: [{ name:"monitorName1" , value:[10 , 0 , ...] } , .....]  , pie_data:[ {name:"label1" , value: 100} , ...] }
// return ===>[[headerName  , number-monitor1 , number-monitor..... ] , ["label1" , 10 , 10..... ] , ............]
export const handlePieChartComparisonToExcel = (
  widgetData,
  widgetName,
  xlsLanguage,
) => {
  try {
    // if all pie chart with excel sheet are constant two column (name ,number-1, number-2 , ....)
    const dataListExcelSheet = [
      [xlsLanguage?.[handleNameForFirstColumnPieChart(widgetName)]],
    ];

    //to push header name for Each column in the first row "value - monitorName"
    widgetData?.data?.forEach((monitor) => {
      dataListExcelSheet?.[0]?.push(
        `${xlsLanguage?.number} - ${monitor?.name}`,
      );
    });

    //this loop to insert new row for each theme .
    //"handleTranslatName" to push label name in the first column
    //widgetData?.data ---> [{name: "monitor1" , value:[5(label1) , 3(label2) , 10(label3)]}]
    widgetData?.pie_data?.forEach((label, index) => {
      const newRow = [handleTranslatName(label?.name, widgetName, xlsLanguage)];
      widgetData?.data?.forEach((monitor) => {
        newRow?.push(monitor?.value?.[index]);
      });
      dataListExcelSheet?.push(newRow);
    });

    return dataListExcelSheet;
  } catch {}
};
