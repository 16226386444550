import { Box, Divider, Typography } from "@mui/material";
import BreadCrumb from "components/breadCrumb";
import PageHeader from "components/pageHeader";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { CheckValueLocale } from "utils/helpers";
import { useState } from "react";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import { useDeleteTeam } from "../../hooks/useDeleteTeam";

const links = [
  {
    name: "settings",
    url: "settings",
    addNameLocal: true,
  },
  {
    name: "teams_list",
    url: "settings/teams",
    addNameLocal: true,
  },
];

export const ViewTeamHeader = ({ team, id }) => {
  const intl = useIntl();
  const history = useHistory();
  const [teamsSnackBar, setTeamsSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const [stats, setStats] = useState({
    team_id: null,
    openDeleteModal: false,
    teamAiAgentName: null,
  });
  const selectedTeamHasAiAgents = stats?.teamAiAgentName?.length > 0;

  const { mutate: handleDeleteTeam, isPending: isPendingDeleteTeam } =
    useDeleteTeam(stats?.team_id);
  const deleteTeam = (teamId) => {
    handleDeleteTeam(
      { teamId },
      {
        onSuccess: () => {
          setTeamsSnackBar({
            message: "team_deleted_successfully",
            severity: "success",
            openSnackBar: true,
          });
          // push to teams list
          history.push("/settings/teams?status=deleted_successfully");
        },
        onError: (error) => {
          const isConnectedToAIAgent =
            error?.errorMsg?.response?.data?.error?.detail ===
            "used_team_by_ai_agent";

          setTeamsSnackBar({
            message: isConnectedToAIAgent
              ? "delete_team_connected_to_ai_agent_desc"
              : "try_again_error_message",
            severity: "error",
            title: isConnectedToAIAgent
              ? "delete_team_connected_to_ai_agent_title"
              : "failed_error_message",
            openSnackBar: true,
          });
          handleCloseDeleteModal();
        },
      },
    );
  };

  const handleTeamDeletionOrCancel = () => {
    if (selectedTeamHasAiAgents) {
      handleCloseDeleteModal();
    } else {
      deleteTeam(stats?.team_id);
    }
  };

  const handleCloseDeleteModal = () => {
    if (isPendingDeleteTeam) return;
    setStats({
      openDeleteModal: false,
    });
  };

  const handleDeleteUserAccount = () => {
    setStats({
      team_id: id,
      openDeleteModal: true,
      teamAiAgentName: team?.used_by_ai_agent_name || null,
    });
  };

  const handleCloseSnackBar = () => {
    setTeamsSnackBar({
      openSnackBar: false,
    });
  };

  return (
    <>
      <Box className="view-team-header">
        <Box className="view-team-header-breadcrumb">
          <BreadCrumb id="breadcrumb-style-monitors" links={links} />
          <PageHeader title={team?.team_name} withBreadCrumb={true} />
        </Box>
        <Box className="view-team-header-description">
          <LucButton
            id="view-team-header-btn"
            variant="flat"
            onClick={handleDeleteUserAccount}
            startIcon={<FontAwesomeIcon icon={faTrash} />}
          >
            <Typography as="span" className="view-team-header-btn-title">
              {CheckValueLocale("delete", "", {}, intl)}
            </Typography>
          </LucButton>

          <Link
            to={{
              pathname: `/settings/teams/edit/${id}`,
              state: { id: id, from: "teamDetails" },
            }}
            className="edit-team-header-link"
          >
            <LucButton
              variant="filled"
              id="edit-team-header-btn"
              startIcon={<FontAwesomeIcon icon={faPencil} />}
            >
              <Typography as="span" className="edit-team-header-btn-title">
                {CheckValueLocale("edit", "", {}, intl)}
              </Typography>
            </LucButton>
          </Link>
        </Box>
      </Box>
      <Divider />
      {stats.openDeleteModal ? (
        <PopupModal
          title={CheckValueLocale("delete_team", "", {}, intl)}
          body={
            selectedTeamHasAiAgents ? (
              <>
                <Box>
                  {CheckValueLocale(
                    "delete_team_connected_ai_agent_message",
                    "",
                    {
                      teamName: team?.team_name,
                      aiAgentName: stats?.teamAiAgentName
                        ?.map((name) => `[${name}]`)
                        .join(", "),
                    },
                    intl,
                  )}
                </Box>
                <h2 className="delete-team-with-ai-agents-confirm">
                  {CheckValueLocale(
                    "delete_team_with_ai_agents_confirmation",
                    "",
                    {},
                    intl,
                  )}
                </h2>
              </>
            ) : (
              <Box className="shared-dialog-body">
                <p>
                  {CheckValueLocale(
                    "delete_team_body",
                    "",
                    { team_name: team?.team_name },
                    intl,
                  )}
                </p>
                <h2 className="sub-text-desc">
                  {CheckValueLocale("deleting_msg", "", {}, intl)}
                </h2>
              </Box>
            )
          }
          open={stats.openDeleteModal}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale(
            selectedTeamHasAiAgents ? "okay" : "delete_confirm",
            "",
            {},
            intl,
          )}
          close={handleCloseDeleteModal}
          accept={handleTeamDeletionOrCancel}
          warning={!selectedTeamHasAiAgents}
          classeName="delete-team-modal actions-buttons-shared"
          disabled={isPendingDeleteTeam}
          minWidth="600px"
        />
      ) : null}
      <SnackBar
        open={teamsSnackBar?.openSnackBar}
        severity={teamsSnackBar?.severity}
        message={
          <Box className="team-snackbar-message">
            {CheckValueLocale(teamsSnackBar?.message, "", {}, intl)}
          </Box>
        }
        title={CheckValueLocale(teamsSnackBar?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </>
  );
};
