import { useMutation, useQueryClient } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useConfigureZendesk = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (queryData) => SettingsController.configureZendesk(queryData),
    onSuccess: () =>
      queryClient?.invalidateQueries({ queryKey: ["zendeskData"] }),
  });
};
