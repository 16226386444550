import { hourNumberToString } from "pages/settings/pages/userManagementPage/subPages/shardComponents/tabs/workingHoursForDay";

export const transformWorkingHours = (workingHours) => {
  return Object.entries(workingHours).reduce((acc, [day, dayRanges]) => {
    const validRanges = [];
    dayRanges?.forEach((range) => {
      if (range?.from && range?.to) {
        validRanges.push({
          from: hourNumberToString(range?.from),
          to: hourNumberToString(range?.to),
        });
      }
    });
    if (validRanges?.length) {
      acc[day?.toLowerCase()] = validRanges;
    }
    return acc;
  }, {});
};
