import { useQuery } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";

export const useAiAgentResources = ({
  ai_agent_id,
  limit_per_page,
  page,
  sortColumn,
  sortDirection,
  enabled = true,
}) => {
  const loggedUserId = localStorage.getItem("user_id");
  return useQuery({
    queryKey: [
      "aiAgentResources",
      { ai_agent_id, limit_per_page, page, sortColumn, sortDirection },
    ],
    queryFn: () =>
      AiAgentsServices.getResourcesList({
        userId: loggedUserId,
        ai_agent_id,
        limit_per_page,
        page,
        sort_by: sortColumn,
        order_by: sortDirection,
      }),
    enabled,
  });
};
