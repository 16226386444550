import { Box, Divider } from "@mui/material";
import BreadCrumb from "components/breadCrumb";
import PageHeader from "components/pageHeader";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { CheckValueLocale, classNames, handlelUserRoles } from "utils/helpers";
import { useEffect, useState } from "react";
import PopupModal from "components/popupModal";
import SnackBar from "components/snackBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faSync,
  faTrash,
  faUnlink,
} from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import { sanitize } from "dompurify";
import { useDeactiveUser } from "../../../hooks/useDeactiveUser";
import { useActiveUser } from "../../../hooks/useActiveUser";
import { useDeleteUser } from "../../../hooks/useDeleteUser";

const links = [
  {
    name: "settings",
    url: "settings",
    addNameLocal: true,
  },
  {
    name: "user_list",
    url: "settings/user_managements",
    addNameLocal: true,
  },
];

export const ViewUserHeader = ({ id, user }) => {
  const intl = useIntl();
  const history = useHistory();
  const [userSnackBar, setUserSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [stats, setStats] = useState({
    userId: null,
    openDeleteModal: false,
  });

  const isEditButtonDisabled = isLoading || user?.status === "invited";
  const handleTeamDeletion = () => {
    deleteUser(id);
  };
  const handleCloseDeleteModal = () => {
    if (isLoading) return;
    setStats({
      openDeleteModal: false,
    });
  };
  const handleDeleteUserAccount = () => {
    setStats({
      userId: id,
      openDeleteModal: true,
    });
  };
  const handleCloseSnackBar = () => {
    setUserSnackBar({
      openSnackBar: false,
    });
  };

  // Deactivate User
  const { mutate: handleDeactiveUser, isPending: isDeactiveUserPending } =
    useDeactiveUser(id);
  const deactiveUser = () => {
    handleDeactiveUser(
      { userId: id },
      {
        onSuccess: () => {
          setUserSnackBar({
            message: "user_deactivation_success",
            severity: "success",
            openSnackBar: true,
          });
        },
        onError: () => {
          setUserSnackBar({
            message: "user_deactivation_failed",
            severity: "error",
            openSnackBar: true,
          });
        },
      },
    );
  };

  // Activate User
  const { mutate: handleActiveUser, isPending: isActiveUserPending } =
    useActiveUser(id);
  const activeUser = () => {
    handleActiveUser(
      { userId: id },
      {
        onSuccess: () => {
          setUserSnackBar({
            message: "user_activation_success",
            severity: "success",
            openSnackBar: true,
          });
        },
        onError: () => {
          setUserSnackBar({
            message: "user_activation_failed",
            severity: "error",
            openSnackBar: true,
          });
        },
      },
    );
  };

  // Delete User
  const { mutate: handleDeleteUser, isPending: isDeleteUserPending } =
    useDeleteUser(true);
  const deleteUser = (userId) => {
    handleDeleteUser(
      { userId },
      {
        onSuccess: () => {
          history.push(
            "/settings/user_managements?status=deleted_successfully",
          );
        },
        onError: () => {
          setUserSnackBar({
            message: "try_again_error_message",
            severity: "error",
            title: "failed_error_message",
            openSnackBar: true,
          });
          handleCloseDeleteModal();
        },
      },
    );
  };

  useEffect(() => {
    setIsLoading(
      isDeactiveUserPending || isActiveUserPending || isDeleteUserPending,
    );
  }, [isDeactiveUserPending, isActiveUserPending, isDeleteUserPending]);
  return (
    <>
      <Box className="view-user-header">
        <Box className="view-user-header-breadcrumb">
          <BreadCrumb id="breadcrumb-style-monitors" links={links} />
          <PageHeader title={user?.name} withBreadCrumb={true} />
        </Box>
        <Box className="view-user-header-description">
          {handlelUserRoles("GENERAL", "DESTROY_USER") ? (
            <LucButton
              id="view-user-header-btn"
              variant="flat"
              onClick={handleDeleteUserAccount}
              disabled={isLoading}
              startIcon={<FontAwesomeIcon icon={faTrash} />}
            >
              {CheckValueLocale("delete", "", {}, intl)}
            </LucButton>
          ) : null}
          {user?.status !== "deactivated" ? (
            isEditButtonDisabled ? null : (
              <>
                <LucButton
                  variant="outline"
                  id="deactivate-user-header-btn"
                  onClick={deactiveUser}
                  startIcon={<FontAwesomeIcon icon={faUnlink} />}
                  disabled={isEditButtonDisabled}
                >
                  {CheckValueLocale("deactivate", "", {}, intl)}
                </LucButton>
              </>
            )
          ) : (
            <>
              <LucButton
                id="activate-user-header-btn"
                onClick={activeUser}
                disabled={isLoading}
                startIcon={<FontAwesomeIcon icon={faSync} />}
              >
                {CheckValueLocale("reactivate", "", {}, intl)}
              </LucButton>
            </>
          )}
          {isEditButtonDisabled ? null : (
            <Link
              to={{
                pathname: isEditButtonDisabled
                  ? ""
                  : `/settings/user_managements/edit/${id}`,
                state: { id: id },
              }}
              className={classNames(
                "edit-user-header-link",
                isEditButtonDisabled && "link-disabled",
              )}
            >
              <>
                <LucButton
                  id="edit-user-header-btn"
                  disabled={isEditButtonDisabled}
                  startIcon={<FontAwesomeIcon icon={faPencil} />}
                >
                  {CheckValueLocale("edit", "", {}, intl)}
                </LucButton>
              </>
            </Link>
          )}
        </Box>
      </Box>
      <Divider />
      <PopupModal
        title={CheckValueLocale(
          "delete_user_manage",
          "",
          {
            user_name: user?.name,
          },
          intl,
        )}
        body={
          <Box className="shared-dialog-body">
            <p
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  CheckValueLocale(
                    "delete_user_manage_body",
                    "",
                    { user_name: user?.name },
                    intl,
                  ),
                ),
              }}
            ></p>
            <h2 className="sub-text-desc">
              {CheckValueLocale("deleting_msg", "", {}, intl)}
            </h2>
          </Box>
        }
        open={stats.openDeleteModal}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
        close={handleCloseDeleteModal}
        accept={() => handleTeamDeletion()}
        warning
        classeName="delete-user-modal view-user-page actions-buttons-shared "
        disabled={isLoading}
        minWidth="600px"
      />
      <SnackBar
        open={userSnackBar?.openSnackBar}
        severity={userSnackBar?.severity}
        message={CheckValueLocale(userSnackBar?.message, "", {}, intl)}
        title={CheckValueLocale(userSnackBar?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </>
  );
};
