import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";

export const useCreateAiAgent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (queryData) => AiAgentsServices.createAiAgent(queryData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["aiAgentList"],
      });
    },
  });
};
