import { useMutation, useQueryClient } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useEditReporsBranding = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ formData }) =>
      SettingsController.editReportsBranding(formData),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["companyBranding"] }),
  });
};
