import Services from "services/api/Services.js";
import mqtt from "mqtt";
import _ from "lodash";

const mqttRabbitMQResponce = async (clientMQTT, data, setResponse) => {
  const { wsbroker, wsport } = Services;
  const url = `wss://${wsbroker}:${wsport}/ws`;
  const options = {
    reconnectPeriod: 0,
    connectTimeout: 30 * 1000,
    keepalive: 30,
    clientId: data?.queue_name,
    username: data?.username,
    password: data?.password,
  };

  if (clientMQTT?.current) {
    // remove the message listener to avoid multiple listeners
    clientMQTT?.current?.removeAllListeners?.("message");
    clientMQTT?.current?.end(true);
  }

  var client = mqtt.connect(url, options);

  clientMQTT.current = client;

  client.on("connect", function (e) {
    if (window.location.host !== "cxm.lucidya.com") {
      console.log("CONNECTION SUCCESS");
    }
    client.subscribe(data.routing_key, { qos: 1 });
  });

  client.on("message", function (topic, message) {
    if (clientMQTT?.current?.options?.clientId?.slice(9) == topic) {
      var data = JSON.parse(message);
      if (window.location.host !== "cxm.lucidya.com") {
        console.log(_.cloneDeep(data));
      }
      setResponse(data);
    }
  });

  client.on("disconnect", function () {
    if (window.location.host !== "cxm.lucidya.com") {
      console.log("DISCONNECTION");
    }
  });

  client.on("end", function () {
    if (window.location.host !== "cxm.lucidya.com") {
      console.log("CONNECTION END");
    }
  });
  client.on("close", function () {
    if (window.location.host !== "cxm.lucidya.com") {
      console.log("Connection closed unexpectedly.");
    }
  });

  client.on("error", function (error) {
    if (window.location.host !== "cxm.lucidya.com") {
      console.log("error" + error);
    }
  });
};

export default mqttRabbitMQResponce;
