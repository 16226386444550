import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import UserAvatar from "shared/unifiedUserImage/userAvatar";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";

const TeamMembersCell = ({ members }) => {
  const intl = useIntl();
  if (!members?.length)
    return (
      <Typography component="span" className="teams-table-no-members">
        {CheckValueLocale("no_members_found", "", {}, intl)}
      </Typography>
    );

  return (
    <Box className="teams-table-members">
      <>
        {members?.slice(0, 4)?.map((memberObj, index) => {
          const member = memberObj?.attributes;
          return (
            <Box className="teams-table-member" key={index}>
              <CustomizedTooltip
                title={member?.name}
                key={member?.id}
                placement="top"
                arrow
                styles={customizedStyledTooltip}
                className={"teams-members-preview-tooltip"}
              >
                <Box key={member?.id}>
                  <UserAvatar
                    fullName={member?.name}
                    avatarSrc={member?.avatar}
                    sizes={{ ratio: "30px", fontSize: "12px" }}
                  />
                </Box>
              </CustomizedTooltip>
            </Box>
          );
        })}
        {members?.length > 4 ? (
          <CustomizedTooltip
            title={members
              ?.slice(4)
              ?.map((memberObj) => memberObj?.attributes?.name)
              ?.join(", ")}
            placement="top"
            arrow
            styles={customizedStyledTooltip}
            className={
              "teams-members-preview-tooltip teams-members-preview-tooltip-more"
            }
          >
            <Box className="teams-table-member teams-table-member-more">
              +{Math?.min(members?.length - 4, 99)}
            </Box>
          </CustomizedTooltip>
        ) : null}
      </>
    </Box>
  );
};

export default TeamMembersCell;
