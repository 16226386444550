import { useQuery } from "@tanstack/react-query";
import InstagramHashtagsController from "services/controllers/instagramHashtagsController";

export const useGetHeaderHashtags = () => {
  return useQuery({
    queryKey: ["headerHashtags"],
    queryFn: () => InstagramHashtagsController.getHashtagsHeader(),
    select: (data) => data?.data,
    retry: false,
  });
};
