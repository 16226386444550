import { useQuery } from "@tanstack/react-query";
import { handlelUserRoles } from "utils/helpers";
import SettingsController from "services/controllers/settingsController";

export const useHistoricalRequests = ({
  page = 1,
  sortColumn,
  sortDirection,
}) => {
  return useQuery({
    queryKey: ["historicalRequests", page, sortColumn, sortDirection],
    queryFn: async () =>
      SettingsController.getHistoricalRequests({
        page,
        sort_by: sortColumn,
        order_by: sortDirection,
      }),
    enabled:
      !!page &&
      (handlelUserRoles("SM", "CREATE_HISTORICAL_REQUEST") ||
        handlelUserRoles("CXM", "CREATE_HISTORICAL_REQUEST")),
    select: (data) => data?.data,
    retry: false,
  });
};
