import * as Yup from "yup";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { Box, TextField, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useConfigureIntercom } from "../hooks/useConfigureIntercom";
import { useIntercomData } from "../hooks/useIntercomData";
import PopupModal from "components/popupModal";
import CircularLoading from "components/circularProgress";

const IntercomIntegrationPopup = (props) => {
  const { dialogOpened, setDialogOpened, setSnackbar } = props;

  const intl = useIntl();

  const { data: intercomData, isPending: intercomDataLoading } =
    useIntercomData();

  const formik = useFormik({
    initialValues: {
      account_name: intercomData?.attributes?.user_name || "",
      token_secret: "",
    },
    validationSchema: Yup.object({
      account_name: Yup.string().required("this_field_required"),
      token_secret: Yup.string().required("this_field_required"),
    }),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => handleConfigure(),
  });

  const { mutate: configureIntercom, isPending: configureLoading } =
    useConfigureIntercom();

  const handleConfigure = () => {
    configureIntercom(
      { ...(formik?.values || {}) },
      {
        onSuccess: () => {
          handleClose();
          setSnackbar({
            open: true,
            severity: "success",
            title: "",
            message: "intercom_added_success",
          });
        },
        onError: () =>
          formik?.setFieldError(
            "token_secret",
            "third_party_intercom_access_token_error",
          ),
      },
    );
  };

  const handleClose = async () => {
    setDialogOpened(false);
    await formik?.resetForm();
  };

  const modalBody = (
    <Box id="third-party-integrations-configure-body">
      <Box>
        <Typography className="configure-title">
          {CheckValueLocale(
            "third_party_intercom_integration_desc",
            "",
            {},
            intl,
          )}
        </Typography>
        <Box className="configure-inputs-container">
          <Box className="configure-input-container">
            <Typography id="configure-input-label">
              {CheckValueLocale("account_name", "", {}, intl)}
              <Box component="span" className="required">
                *
              </Box>
            </Typography>
            <TextField
              id="account-name-input"
              name="account_name"
              variant="outlined"
              size="small"
              fullWidth
              disabled={configureLoading}
              value={formik?.values?.["account_name"] || ""}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              error={
                formik?.touched?.["account_name"] &&
                Boolean(formik?.errors?.["account_name"])
              }
              helperText={
                formik?.touched?.["account_name"] &&
                CheckValueLocale(formik?.errors?.["account_name"], "", {}, intl)
              }
            />
          </Box>
          <Box className="configure-input-container">
            <Typography id="configure-input-label">
              {CheckValueLocale(
                "third_party_intercom_access_token",
                "",
                {},
                intl,
              )}
              <Box component="span" className="required">
                *
              </Box>
            </Typography>
            <TextField
              id="account-name-input"
              name="token_secret"
              variant="outlined"
              size="small"
              fullWidth
              disabled={configureLoading}
              value={formik?.values?.["token_secret"] || ""}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              error={
                formik?.touched?.["token_secret"] &&
                Boolean(formik?.errors?.["token_secret"])
              }
              helperText={
                formik?.touched?.["token_secret"] &&
                CheckValueLocale(formik?.errors?.["token_secret"], "", {}, intl)
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <PopupModal
      id="third-party-integrations-configure-intercom"
      classeName="third-party-integrations-configure-intercom"
      open={dialogOpened}
      title={CheckValueLocale("third_party_intercom_integration", "", {}, intl)}
      body={modalBody}
      accept={formik?.handleSubmit}
      close={handleClose}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("configure", "", {}, intl)}
      isRightBtnLoading={configureLoading}
      disabled={intercomDataLoading}
      addClasses="actions-buttons-shared"
      minWidth="600px"
    />
  );
};

export default IntercomIntegrationPopup;
