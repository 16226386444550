import SocialListiningCreate from "./SocialListiningCreate";
import ChannelAnalyticsCreate from "./ChannelAnalyticsCreate";
import { useIntl } from "react-intl";
import { useCallback, useEffect, useState } from "react";
import ReportController from "services/controllers/reportController";
import { Snackbar } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 310,
      width: 475,
    },
  },
};

const StepTwo = ({
  platformType,
  step2FormFields,
  setStep2FormFields,
  fieldError,
  step2ChannelFormFields,
  setStep2ChannelFormFields,
  backBtn,
  setBackBtn,
  setCheckDisable,
  productId,
  isMonitorAction,
  preSelectedData,
  setStep3FormFields,
}) => {
  const intl = useIntl();
  const [cxmCategories, setCxmCategories] = useState([]);
  const [isRequestError, setIsRequestError] = useState(false);

  const updateFieldValue = (event) => {
    const {
      target: { value, name },
    } = event;
    if (platformType === "SM") {
      if (
        name === "remainingMonitorsDataSource" ||
        name === "KeywordDataSource"
      )
        setStep2FormFields((val) => ({
          ...val,
          [name]: value,
          monitor: isMonitorAction ? step2FormFields?.monitor : "",
        }));
      else setStep2FormFields((val) => ({ ...val, [name]: value }));
    }
    platformType === "CXM" &&
      setStep2ChannelFormFields((val) => ({ ...val, [name]: value }));
  };
  useEffect(() => {
    if (step2FormFields?.monitorType == "") {
      setCheckDisable(true);
    } else {
      setCheckDisable(false);
    }
  }, []);

  useEffect(() => {
    if (platformType === "CXM") {
      ReportController.getReportCategories(productId).then((res) => {
        setIsRequestError(!!res?.errorMsg);
        res?.data && setCxmCategories([...res?.data]);
      });
    }
  }, [platformType]);

  return (
    <>
      {platformType === "SM" && (
        <SocialListiningCreate
          step2FormFields={step2FormFields}
          setStep2FormFields={setStep2FormFields}
          fieldError={fieldError}
          updateFieldValue={updateFieldValue}
          MenuProps={MenuProps}
          backBtn={backBtn}
          setBackBtn={setBackBtn}
          setCheckDisable={setCheckDisable}
          isMonitorAction={isMonitorAction}
          preSelectedData={preSelectedData}
          setStep3FormFields={setStep3FormFields}
        />
      )}
      {platformType === "CXM" && (
        <ChannelAnalyticsCreate
          setStep2FormFields={setStep2FormFields}
          fieldError={fieldError}
          step2ChannelFormFields={step2ChannelFormFields}
          setStep2ChannelFormFields={setStep2ChannelFormFields}
          productId={productId}
          updateFieldValue={updateFieldValue}
          MenuProps={MenuProps}
          cxmCategories={cxmCategories}
          setCheckDisable={setCheckDisable}
        />
      )}
      {isRequestError && (
        <Snackbar
          lang={window?.localStorage?.lang}
          open={true}
          handleClose={() => {
            setIsRequestError(!!false);
          }}
          severity="error"
          message={CheckValueLocale(
            "something_went_wrong_try_agin",
            "",
            {},
            intl,
          )}
        />
      )}
    </>
  );
};

export default StepTwo;
