import { Box, TextField, Typography } from "@mui/material";
import { WorkingHours } from "pages/settings/pages/userManagementPage/subPages/shardComponents/tabs/workingHours";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const WorkHourAndDay = ({
  workingHours,
  setWorkingHours,
  setTabsErrors,
  tabsErrors,
  autoReplyMessages,
  setAutoReplyMessages,
  isMaxCharactersError,
}) => {
  const intl = useIntl();
  return (
    <Box className="user-tabs-container">
      <Box className="user-tabs-content create-team-working-hours-row-container">
        <Box className="create-team-working-hours-container">
          <WorkingHours
            tabsData={workingHours}
            setTabsData={setWorkingHours}
            setTabsErrors={setTabsErrors}
            tabsErrors={tabsErrors}
            workingHoursTimezoneText={
              <Typography
                onClick={() => window.open("/settings", "_blank")}
                className="create-team-working-hours-time-zone-title"
              >
                {CheckValueLocale(
                  "team_working_hours_company_timezone",
                  "",
                  {},
                  intl,
                )}
                <Typography
                  className="create-team-working-hours-time-zone-title view-settings"
                  component="span"
                >
                  {CheckValueLocale("view_settings", "", {}, intl)}
                </Typography>
              </Typography>
            }
            title={
              <Typography className="create-team-working-hours-extra-title">
                {CheckValueLocale("working_hours_days", "", {}, intl)}
              </Typography>
            }
            subtitle="set_team_working_days_hours"
            type="team"
          />
        </Box>
        <Box className="team-auto-complete-column-container">
          <Typography className="create-team-auto-reply-text-area-label">
            {CheckValueLocale("create_team_auto_reply_label", "", {}, intl)}
          </Typography>
          <TextField
            fullWidth
            className="create-team-auto-reply-text-area"
            multiline
            rows={4.7}
            value={autoReplyMessages}
            onChange={(e) => setAutoReplyMessages(e?.target?.value)}
            placeholder={CheckValueLocale(
              "auto_reply_placeholder",
              "",
              {},
              intl,
            )}
            error={isMaxCharactersError}
            helperText={
              isMaxCharactersError
                ? CheckValueLocale("auto_reply_error", "", {}, intl)
                : ""
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default WorkHourAndDay;
