import { useState } from "react";
import { useIntl } from "react-intl";
import Dropzone from "react-dropzone";
import { Box, Typography } from "@mui/material";
import { handlelUserRoles } from "utils/helpers";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/pro-solid-svg-icons";
import { faCloudArrowUp } from "@fortawesome/pro-regular-svg-icons";

const DropZoneArea = (props) => {
  const { formik, setImage, setOpenEditImageModal, editModal } = props;

  const intl = useIntl();

  const [fileName, setFileName] = useState("");

  const handleDrop = async (acceptedFiles) => {
    const file = acceptedFiles?.[0];

    // Validate logo file type and size less than 30 Mb
    if (!["image/png", "image/jpg", "image/jpeg"]?.includes(file?.type)) {
      setFileName(file?.name);
      return formik?.setFieldError("logo", "branding_logo_type_error");
    } else if (file?.size > 31457280)
      return formik?.setFieldError("logo", "branding_logo_size_error");

    try {
      if (editModal) setImage(file);
      else formik?.setFieldValue("logo", file);

      formik?.setFieldError("logo", undefined);
    } catch (error) {
      formik?.setFieldError("logo", error);
    }
  };

  const handleUploadError = (error) => {
    if (error?.[0]?.errors?.[0]?.code === "file-too-large")
      formik?.setFieldError("logo", "branding_logo_size_error");
    else formik?.setFieldError("logo", "branding_logo_type_error");
  };

  return (
    <Box className="app">
      <Dropzone
        multiple={false}
        onDrop={handleDrop}
        onDropRejected={handleUploadError}
        onFileDialogOpen={() => !editModal && setOpenEditImageModal(true)}
      >
        {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => {
          const additionalClass = isDragAccept
            ? "accept"
            : isDragReject
              ? "reject"
              : "";

          return (
            <Box
              {...getRootProps({ className: `dropzone ${additionalClass}` })}
            >
              <Box className="image-upload">
                {handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING") && (
                  <input {...getInputProps()} />
                )}
                {editModal ? <FontAwesomeIcon icon={faCloudArrowUp} /> : null}
                <Box>{CheckValueLocale("upload_logo", "", {}, intl)}</Box>
                {!editModal ? (
                  <FontAwesomeIcon icon={faCloudArrowDown} />
                ) : null}
              </Box>
            </Box>
          );
        }}
      </Dropzone>
      <Typography variant="p" className="logo-error">
        {CheckValueLocale(formik?.errors?.logo, "", { name: fileName }, intl)}
      </Typography>
    </Box>
  );
};

export default DropZoneArea;
