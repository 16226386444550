import * as Yup from "yup";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { Box, TextField, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useZendeskData } from "../hooks/useZendeskData";
import { useConfigureZendesk } from "../hooks/useConfigureZendesk";
import PopupModal from "components/popupModal";
import CircularLoading from "components/circularProgress";

const ZendeskIntegrationPopup = (props) => {
  const { dialogOpened, setDialogOpened, setSnackbar } = props;

  const intl = useIntl();

  const { data: zendeskData, isPending: zendeskDataLoading } = useZendeskData();

  const formik = useFormik({
    initialValues: {
      subdomain: zendeskData?.attributes?.sub_domain || "",
      username: zendeskData?.attributes?.username || "",
      api_token: "",
    },
    validationSchema: Yup.object({
      subdomain: Yup.string().required("this_field_required"),
      username: Yup.string().required("this_field_required"),
      api_token: Yup.string().required("this_field_required"),
    }),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => handleConfigure(),
  });

  const { mutate: configureZendesk, isPending: configureLoading } =
    useConfigureZendesk();

  const handleConfigure = () => {
    configureZendesk(
      { ...(formik?.values || {}) },
      {
        onSuccess: () => {
          handleClose();
          setSnackbar({
            open: true,
            severity: "success",
            title: "",
            message: "zendesk_added_success",
          });
        },
        onError: () =>
          setSnackbar({
            open: true,
            severity: "error",
            title: "something_went_wrong",
            message: "try_again",
          }),
      },
    );
  };

  const handleClose = async () => {
    setDialogOpened(false);
    await formik?.resetForm();
  };

  const modalBody = (
    <Box id="third-party-integrations-configure-body">
      <Box className="configure-inputs-container">
        <Box className="configure-input-container">
          <Typography id="configure-input-label">
            {CheckValueLocale("third_party_zendesk_sub_domain", "", {}, intl)}
            <Box component="span" className="required">
              *
            </Box>
          </Typography>
          <TextField
            id="account-name-input"
            name="subdomain"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="EX:Lucidya.zendesk.com"
            value={formik?.values?.["subdomain"] || ""}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            disabled={configureLoading}
            error={
              formik?.touched?.["subdomain"] &&
              Boolean(formik?.errors?.["subdomain"])
            }
            helperText={
              formik?.touched?.["subdomain"] &&
              CheckValueLocale(formik?.errors?.["subdomain"], "", {}, intl)
            }
          />
        </Box>
        <Box className="configure-input-container">
          <Typography id="configure-input-label">
            {CheckValueLocale("third_party_zendesk_email_admin", "", {}, intl)}
            <Box component="span" className="required">
              *
            </Box>
          </Typography>
          <TextField
            id="account-name-input"
            name="username"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="example@lucidya.com"
            value={formik?.values?.["username"] || ""}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            disabled={configureLoading}
            error={
              formik?.touched?.["username"] &&
              Boolean(formik?.errors?.["username"])
            }
            helperText={
              formik?.touched?.["username"] &&
              CheckValueLocale(formik?.errors?.["username"], "", {}, intl)
            }
          />
        </Box>
        <Box className="configure-input-container">
          <Typography id="configure-input-label">
            {CheckValueLocale("api_token", "", {}, intl)}
            <Box component="span" className="required">
              *
            </Box>
          </Typography>
          <TextField
            id="account-name-input"
            name="api_token"
            variant="outlined"
            size="small"
            fullWidth
            placeholder="API Example"
            value={formik?.values?.["api_token"] || ""}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            disabled={configureLoading}
            error={
              formik?.touched?.["api_token"] &&
              Boolean(formik?.errors?.["api_token"])
            }
            helperText={
              formik?.touched?.["api_token"] &&
              CheckValueLocale(formik?.errors?.["api_token"], "", {}, intl)
            }
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <PopupModal
      id="third-party-integrations-configure-zendesk"
      classeName="third-party-integrations-configure-zendesk"
      open={dialogOpened}
      title={CheckValueLocale("third_party_zendesk_integration", "", {}, intl)}
      body={modalBody}
      accept={formik?.handleSubmit}
      close={handleClose}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("configure", "", {}, intl)}
      isRightBtnLoading={configureLoading}
      disabled={zendeskDataLoading}
      addClasses="actions-buttons-shared"
      minWidth="600px"
    />
  );
};

export default ZendeskIntegrationPopup;
