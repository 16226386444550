import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Grid,
  Typography,
  Tooltip,
  Box,
  InputAdornment,
  Button,
  OutlinedInput,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { CheckValueLocale, isEmptyArray } from "utils/helpers";
import CircularLoading from "components/circularProgress";

import "../keywords.scss";
import SocialMediaAccountButton from "../../../Components/SocialMediaAuthButton/socialMediaAuthButton";

import KeywordTooltipChip from "../../../../MonitorsShared/SharedComponent/keywordTypeComponent/keywordTooltipChip";
import KeywordPopupModal from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedComponent/keywordPoupModal/keywordPopupModal";
import CopyIcon from "../../../../MonitorsShared/SharedComponent/keywordTypeComponent/copyIcon";
import {
  handleInputChange,
  checkPlaceHolderText,
  handleInputFieldName,
  checkAllInputsField,
  checkKeywordPopup,
  checkDirectionLang,
  handleInputMouseDown,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/kewordsSharedFunctions";
import {
  handleKeywordsPopUp,
  checkValue,
} from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/monitorsSharedFunctions";
import HashtagsLimitReached from "pages/settings/pages/InstagramHashtags/components/hashtagsHeader/hashtagsLimitReached";

const InstagramKeyword = (props) => {
  const {
    handleKeywords,
    keywords,
    indexes,
    handleDelete,
    handleValidation,
    handleData,
    wordCountInstagram,
    instagramAccounts,
    monitorInstagramHashtagLimit,
    monitorInstagramOldHashtags,
  } = props;
  const intl = useIntl();

  let keywordsInfo = ["hashtags", "spam_hashtags"];
  // input Foucsed Case
  const [focused, setFocused] = useState(false);

  const [err, setErr] = useState({
    errMsg: "",
    keyword: "",
  });

  useEffect(() => {
    setErr("");
    keywords[indexes]?.instagram?.map((obj) => {
      if (Object.values(obj)[1] !== "") {
        setErr(Object.keys(obj)[0]);
      }
    });
  }, [keywords]);

  const handleKeyDown = (event, keyword, index, clickType) => {
    if (
      (keyword === "hashtags" && !(event.target.value.trim().length === 0)) ||
      keyword === "spam_hashtags"
    ) {
      handleKeywords(
        event,
        keyword,
        "instagram",
        index,
        clickType,
        monitorInstagramHashtagLimit,
      );
    }
  };

  return (
    <Box className="data-source-keyword keywords-inputs-fields">
      {!instagramAccounts || props.isLoading ? (
        <Box sx={{ margin: "auto" }}>
          <CircularLoading />
        </Box>
      ) : isEmptyArray(instagramAccounts) ? (
        <>
          <SocialMediaAccountButton
            authHead={"authinticate_new_instagram_account"}
            companyApps={props.companyApps}
            authDataSourceName={"add_instagram_account_profile"}
            dataSourceName={"instagram"}
            isPublicAccount
            isCompanyAccount={false}
            redirectUrl={
              window.location.origin +
              "/settings/accounts_management/company_accounts"
            }
          />
        </>
      ) : (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {keywordsInfo.map((keyword, index) => {
            const instaValues = Object.values(
              keywords[indexes]?.instagram[index],
            );
            return (
              <>
                <Grid item xs={6}>
                  <Typography
                    variant="caption"
                    className="keywords keyword-head-title"
                  >
                    {
                      <Tooltip
                        title={CheckValueLocale(
                          "keywords_ig_spam_keywords_tooltip",
                          "",
                          {},
                          intl,
                        )}
                        arrow
                        placement="bottom"
                      >
                        <Typography
                          variant="caption"
                          className="media-input-label"
                        >
                          {CheckValueLocale(
                            handleInputFieldName(keyword),
                            "",
                            {},
                            intl,
                          )}
                        </Typography>
                      </Tooltip>
                    }
                  </Typography>

                  {/* input field */}
                  <OutlinedInput
                    variant="outlined"
                    className={` keywords-field keyword-input-field ${checkDirectionLang(
                      keywords[indexes].instagram[index]?.answer,
                    )}`}
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                      // Delete Error message when leaving the Keyword box
                      if (
                        keywords[indexes]?.instagram[index]?.answer?.length ===
                        0
                      ) {
                        keywords[indexes].instagram[index].errorMsg = "";
                      }
                      setFocused(false);
                    }}
                    endAdornment={
                      focused && (
                        <InputAdornment onMouseDown={handleInputMouseDown}>
                          <Button
                            className="add-btn-with-plus"
                            onClick={(event) =>
                              handleKeyDown(event, keyword, index, "btn")
                            }
                            value={
                              Object.keys(
                                keywords[indexes]?.instagram[index],
                              )[0] === keyword
                                ? keywords[indexes].instagram[index].answer
                                : ""
                            }
                            id="monitor-options-ig-add-keyword-btn"
                          >
                            {CheckValueLocale("add_plus", "", {}, intl)}
                          </Button>
                        </InputAdornment>
                      )
                    }
                    error={
                      keywords[indexes].instagram[index].errorMsg !== "" &&
                      Object.keys(keywords[indexes].instagram[index])[0] ===
                        keyword
                    }
                    placeholder={CheckValueLocale(
                      checkPlaceHolderText(keyword),
                      "",
                      {},
                      intl,
                    )}
                    onChange={(e) =>
                      handleInputChange(
                        e,
                        keyword,
                        "instagram",
                        index,
                        indexes,
                        keywords,
                        setErr,
                        handleValidation,
                      )
                    }
                    value={
                      Object.keys(keywords[indexes]?.instagram[index])[0] ===
                      keyword
                        ? keywords[indexes].instagram[index].answer
                        : ""
                    }
                    onKeyDown={(event) => handleKeyDown(event, keyword)}
                    disabled={
                      keyword === "hashtags"
                        ? wordCountInstagram >= monitorInstagramHashtagLimit
                        : false
                    }
                  />

                  {keywords[indexes]?.instagram?.map((obj) => {
                    if (
                      Object.values(obj)[1] !== "" &&
                      Object.keys(obj)[0] === keyword
                    ) {
                      return (
                        <Box className="err-msg">
                          {CheckValueLocale(
                            Object.values(obj)[1],
                            "",
                            { limit: monitorInstagramHashtagLimit },
                            intl,
                          )}
                        </Box>
                      );
                    }
                  })}
                  <Box className="keyword-spam-wrapper">
                    {keywords[indexes]?.instagram?.map((obj) => {
                      if (
                        Object.values(obj)[0].length > 0 &&
                        Object.keys(obj)[0] === keyword
                      ) {
                        return checkValue(Object.values(obj)[0]).map(
                          (labels, index) => (
                            <>
                              <KeywordTooltipChip
                                dataSource={"instagram"}
                                index={index}
                                labels={labels}
                                onDelete={(e) =>
                                  handleDelete(
                                    e,
                                    index,
                                    Object.keys(obj)[0],
                                    indexes,
                                    "instagram",
                                  )
                                }
                                usedLabel={monitorInstagramOldHashtags?.includes(
                                  labels?.replace("#", ""),
                                )}
                              />
                            </>
                          ),
                        );
                      }
                    })}
                    {checkAllInputsField(keyword, keywordsInfo) && (
                      <KeywordPopupModal
                        keywords={handleKeywordsPopUp(
                          keyword,
                          keywords[1].instagram[0]?.hashtags,
                          keywords[1].instagram[1]?.spam_hashtags,
                        )}
                        handleDelete={handleDelete}
                        dataSourceType={"instagram"}
                        keywordType={checkKeywordPopup(keyword)}
                        dataSourceIndex={1} // dataSource index to handle delete keyword
                        usedkeywords={monitorInstagramOldHashtags}
                      />
                    )}
                    {/* button for copy Spam keyword items */}
                    {instaValues[0].length !== 0 && (
                      <CopyIcon
                        checkCopyValue={instaValues[2]}
                        handleData={(e) =>
                          handleData(
                            e,
                            instaValues[0],
                            indexes,
                            index,
                            "instagram",
                          )
                        }
                      />
                    )}
                  </Box>
                </Grid>
              </>
            );
          })}
          {wordCountInstagram >= monitorInstagramHashtagLimit ? (
            <Box className="monitor-hashtags-limit-wrapper">
              <HashtagsLimitReached hashtagsManagerLink />
            </Box>
          ) : null}
        </Grid>
      )}
    </Box>
  );
};

export default InstagramKeyword;
