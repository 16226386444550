import Notifications from "../api/notifications.js";

var NotificationsController = {};

NotificationsController.getNotificationsList = (queryData) => {
  return new Promise((resolve, reject) => {
    Notifications.getNotificationsList(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

NotificationsController.getNotificationsCount = () => {
  return new Promise((resolve, reject) => {
    Notifications.getNotificationsCount()
      .then((response) => {
        resolve({ data: response?.data?.data });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

NotificationsController.getNotificationsDownloadLink = (id) => {
  return new Promise(function (resolve) {
    Notifications.getNotificationsDownloadLink(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

NotificationsController.getNotificationsXlsxDownload = (id) => {
  return new Promise((resolve, reject) => {
    Notifications.getNotificationsXlsxDownload(id)
      .then((response) => {
        resolve({ data: response?.data?.data });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

export default NotificationsController;
