import { useQuery } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";

export const useAiAgentDetails = (aiAgentSelected) => {
  return useQuery({
    queryKey: ["aiAgentDetails", aiAgentSelected],
    queryFn: () => AiAgentsServices.getAiAgentDetails(aiAgentSelected),
    enabled: !!aiAgentSelected,
  });
};
