import { useState } from "react";
import { useIntl } from "react-intl";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { CheckValueLocale } from "utils/helpers";
import IpChip from "./ipChip";

const IpPopupModal = ({ ipSubnetArr, ipSubnetArrLength, handleDelete }) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {ipSubnetArr?.length > ipSubnetArrLength && (
        <Button
          color="inherit"
          variant="outlined"
          className="show-all-ip"
          onClick={handleClickOpen}
          id="options-show-all-ip-btn"
        >
          {CheckValueLocale("show_all_kw", "", {}, intl)}
        </Button>
      )}
      <Dialog id="ipSubnet" open={open} onClose={handleClose}>
        <Box className="create-api-wrapper">
          <DialogTitle className="create-api-title">
            {CheckValueLocale("ip_subnet", "", {}, intl)}
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="create-api-content">
            <Box className="ip-chip-wrapper">
              {ipSubnetArr?.map((label, index) => (
                <IpChip
                  key={index}
                  onDelete={() => handleDelete(index)}
                  label={label}
                  index={index}
                />
              ))}
            </Box>
          </DialogContent>
          {/* dialog action */}
          <DialogActions className="create-api-actions">
            <Button
              variant="contained"
              className="lucidya-apis-button"
              onClick={handleClose}
              id="done-ip-btn"
            >
              {CheckValueLocale("done", "", {}, intl)}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default IpPopupModal;
