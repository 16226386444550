import { useMutation, useQueryClient } from "@tanstack/react-query";
import EngagementsController from "services/controllers/engagementsController";

export const useUpdateRoutingPriority = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, queryData }) =>
      EngagementsController.updateRoutingPriority(id, queryData),
    onSettled: () =>
      queryClient?.invalidateQueries({ queryKey: ["inboundRoutingsList"] }),
  });
};
