import { useMutation, useQueryClient } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

const getQueryKeyToInvalidate = (teamId) => {
  return teamId ? ["teamDetailsData"] : ["companyTeams"];
};

export const useDeleteTeam = (teamId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ teamId }) => CompaniesController.deleteTeam(teamId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["companyTeams"],
      });
    },
    onError: (error) => {
      const isConnectedToAIAgent =
        error?.errorMsg?.response?.data?.error?.detail ===
        "used_team_by_ai_agent";
      if (isConnectedToAIAgent) {
        queryClient.invalidateQueries({
          queryKey: getQueryKeyToInvalidate(teamId),
        });
      }
    },
  });
};
