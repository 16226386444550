import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PieChartWidget from "components/widgets/pieChartWidget";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import _ from "lodash";
import QuestionCard from "pages/Monitors/Components/questionCard/index";
import { Link } from "react-router-dom";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import PunchCardWidget from "components/widgets/punchCardWidget";
import {
  handleAggregateLegends,
  handleAggregatePieChart,
  handleAggregateBarCharts,
} from "utils/helpers/aggregateFunctions";
import {
  translateLocalsFromRabbitMQ,
  CheckValueLocale,
} from "utils/helpers/index";

const AuthorAnalyticsSection = (props) => {
  const intl = useIntl();
  const {
    insightsID,
    insightsResponse,
    dates,
    response,
    widgetsName,
    getIcons,
    metricsValues,
    monitorType,
    isKeywordType,
    dashboardID,
    topInfleuncersPreLoader,
    setTopInfleuncersPreLoader,
    topInfleuncers,
    setTopInfleuncers,
    topEngagersPreLoader,
    setTopEngagersPreLoader,
    topEngagers,
    setTopEngagers,
    topVerifiedEngagersPreLoader,
    setTopVerifiedEngagersPreLoader,
    topVerifiedEngagers,
    setTopVerifiedEngagers,
    topSourcesPreLoader,
    setTopSourcesPreLoader,
    topSources,
    setTopSources,
    genderDistributionPreLoader,
    setGenderDistributionPreLoader,
    genderDistribution,
    topCountriesPreLoader,
    setTopCountriesPreLoader,
    topCountries,
    setTopCountries,
    topCitiesPreLoader,
    setTopCitiesPreLoader,
    topCities,
    setTopCities,
    dialectsPreLoader,
    setDialectsPreLoader,
    subDialects,
    setSubDialects,
    dialects,
    setDialects,
    topLanguagesPreLoader,
    setTopLanguagesPreLoader,
    topLanguages,
    setTopLanguages,
    setReachFunnel,
    engagersVolume,
    setEngagersVolume,
    totalEngagersVolumeCount,
    setTotalEngagersVolumeCount,
    engagersVolumePreLoader,
    setEngagersVolumePreLoader,
    genderAgeDistribution,
    setGenderAgeDistribution,
    genderAgeDistributionPreLoader,
    setGenderAgeDistributionPreLoader,
    minValueAuthorActivity,
    setMinValueAuthorActivity,
    maxValueAuthorActivity,
    setMaxValueAuthorActivity,
    accountAuthorActivity,
    setAccountAuthorActivity,
    accountAuthorActivityPreLoader,
    setAccountAuthorActivityPreLoader,
    commentsMentions,
    setCommentsMentions,
    commentsMentionsCount,
    setCommentsMentionsCount,
    commentsMentionsPreLoader,
    setCommentsMentionsPreLoader,
    setPageReactionPieChartValue,
    setGenderAnalysisPieChart,
    genderAgePieChart,
    topAuthors,
    setTopAuthors,
    topAuthorsPreLoader,
    setTopAuthorsPreLoader,
    setAccountTypesPreLoader,
    accountTypes,
    setAccountTypes,
    accountTypesPreLoader,
    singleTweetAnalysis,
    isPublicAccount,
    unixDate,
  } = props;
  const [dialectsCopy, setDialectsCopy] = useState([]);
  const [subDialectsCopy, setSubDialectsCopy] = useState([]);
  const [topLanguageCopy, setTopLanguageCopy] = useState([]);
  const [topCountriesCopy, setTopCountriesCopy] = useState([]);
  const [topCitiesCopy, setTopCitiesCopy] = useState([]);
  useEffect(() => {
    switch (response?.eventName) {
      case "Facebook__EngagementsPage__account_author_activity":
        setMinValueAuthorActivity(response.eventData.min_value);
        setMaxValueAuthorActivity(response.eventData.max_value);
        setAccountAuthorActivity(response.eventData.account_author_activity);
        setAccountAuthorActivityPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;

      case "Twitter__EngagementsPage__top_languages":
      case "Talkwalker__EngagementsPage__top_languages":
      case "Facebook__EngagementsPage__top_languages":
        handleAggregatePieChart(
          response.eventData.top_languages,
          topLanguageCopy,
        );
        setTopLanguagesPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__EngagementsPage__dialects_and_sub_dialects":
      case "Facebook__EngagementsPage__dialects_and_sub_dialects":
      case "Instagram__EngagementsPage__dialects_and_sub_dialects":
        handleAggregatePieChart(response.eventData.dialects, dialectsCopy);
        handleAggregatePieChart(
          response.eventData.sub_dialects,
          subDialectsCopy,
        );
        setDialectsPreLoader((oldArray) => [...oldArray, response.data_source]);
        break;
      case "Twitter__EngagementsPage__top_cities":
      case "Instagram__EngagementsPage__top_cities":
      case "Facebook__EngagementsPage__top_cities":
        handleAggregatePieChart(response.eventData.top_cities, topCitiesCopy);
        setTopCitiesPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__EngagementsPage__top_countries":
      case "Talkwalker__EngagementsPage__top_countries":
      case "Instagram__EngagementsPage__top_countries":
      case "Facebook__EngagementsPage__top_countries":
        handleAggregatePieChart(
          response.eventData.top_countries,
          topCountriesCopy,
        );
        setTopCountriesPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;

      case "Twitter__EngagementsPage__gender_distribution":
        handleAggregatePieChart(
          response.eventData.top_genders,
          genderDistribution,
        );
        setGenderDistributionPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;

      case "Twitter__EngagementsPage__account_types":
        handleAggregatePieChart(response.eventData.account_types, accountTypes);
        setAccountTypesPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__EngagementsPage__top_sources":
        handleAggregatePieChart(response.eventData.top_sources, topSources);
        setTopSourcesPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;

      case "Twitter__EngagementsPage__top_verified_engagers":
        setTopVerifiedEngagers(response.eventData.top_verified_engagers);
        setTopVerifiedEngagersPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__EngagementsPage__top_engagers":
        setTopEngagers(response.eventData.top_engagers);
        setTopEngagersPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Twitter__EngagementsPage__top_influencers":
        setTopInfleuncers(response.eventData.top_influencers);
        setTopInfleuncersPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__EngagementsPage__engagers_volume":
        setEngagersVolume(
          response.eventData.engagers_volume.engagers_volume_over_time,
        );
        setTotalEngagersVolumeCount(
          response.eventData.engagers_volume.total_engagers,
        );
        setEngagersVolumePreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Instagram__EngagementsPage__top_engagers":
        setTopAuthors(response.eventData.top_engagers);
        setTopAuthorsPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      case "Facebook__EngagementsPage__gender_distribution":
      case "Instagram__EngagementsPage__gender_distribution":
        response.eventData.gender_distribution &&
          response.eventData.gender_distribution?.map((gd) => {
            Object.keys(gd)?.map((val, index) => {
              handleAggregateBarCharts(
                [
                  {
                    name: val,
                    value: gd[val],
                  },
                ],
                genderAgeDistribution,
              );
            });
          });

        handleAggregateLegends(
          response.eventData.gender_piechart,
          genderAgePieChart,
        );
        setGenderAgeDistributionPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;

      case "Twitter__EngagementsPage__top_posts_comments":
      case "Facebook__EngagementsPage__top_posts_comments":
      case "Instagram__EngagementsPage__top_posts_comments":
        response.eventData.top_posts?.map((post) => {
          post.datasourceTypePosts = response.data_source;
          setCommentsMentions((oldArray) => [...oldArray, post]);
        });
        setCommentsMentionsCount(
          commentsMentionsCount + response.eventData.top_posts_count,
        );
        setCommentsMentionsPreLoader((oldArray) => [
          ...oldArray,
          response.data_source,
        ]);
        break;
      default:
        break;
    }
  }, [response]);

  // in case of pieChart aggregate
  useEffect(() => {
    if (
      metricsValues?.dialects_subdialects &&
      _.isEqual(
        dialectsPreLoader?.sort(),
        [...metricsValues?.dialects_subdialects?.data_sources]?.sort(),
      ) === true
    ) {
      setDialects(dialectsCopy);
      setSubDialects(subDialectsCopy);
    }
    if (
      metricsValues?.top_languages &&
      _.isEqual(
        topLanguagesPreLoader?.sort(),
        [...metricsValues?.top_languages?.data_sources]?.sort(),
      ) === true
    ) {
      setTopLanguages(
        translateLocalsFromRabbitMQ("languages", topLanguageCopy),
      );
    }
    if (
      metricsValues?.top_countries &&
      _.isEqual(
        topCountriesPreLoader?.sort(),
        [...metricsValues?.top_countries?.data_sources]?.sort(),
      ) === true
    ) {
      setTopCountries(topCountriesCopy);
    }
    if (
      metricsValues?.top_cities &&
      _.isEqual(
        topCountriesPreLoader?.sort(),
        [...metricsValues?.top_cities?.data_sources]?.sort(),
      ) === true
    ) {
      setTopCities(topCitiesCopy);
    }
  }, [
    dialectsPreLoader,
    topLanguagesPreLoader,
    topCountriesPreLoader,
    topCitiesPreLoader,
  ]);

  useEffect(() => {
    // Avoid Initial State Calling
    if (props.resetAllFields !== 1) {
      setTopLanguageCopy([]);
      setTopCountriesCopy([]);
      setTopCitiesCopy([]);
      setDialectsCopy([]);
      setSubDialectsCopy([]);
      setTopLanguages([]);
      setTopCountries([]);
      setDialects([]);
      setSubDialects([]);
      setTopCities([]);
      setTopCountries([]);
      setGenderAnalysisPieChart([]);
      setAccountTypes([]);
      setTopSources([]);
      setTopVerifiedEngagers([]);
      setTopEngagers([]);
      setTopInfleuncers([]);
      setPageReactionPieChartValue([]);
      setReachFunnel([]);
      setEngagersVolume([]);
      setTotalEngagersVolumeCount(0);
      setGenderAgeDistribution([]);
      setMinValueAuthorActivity(0);
      setMaxValueAuthorActivity(0);
      setAccountAuthorActivity([]);
      setCommentsMentions([]);
      setCommentsMentionsCount(0);
      setTopAuthors([]);
      setTopLanguagesPreLoader([]);
      setDialectsPreLoader([]);
      setTopCitiesPreLoader([]);
      setTopCountriesPreLoader([]);
      setGenderDistributionPreLoader([]);
      setAccountTypesPreLoader([]);
      setTopSourcesPreLoader([]);
      setTopVerifiedEngagersPreLoader([]);
      setTopEngagersPreLoader([]);
      setTopInfleuncersPreLoader([]);
      setCommentsMentionsPreLoader([]);
      setEngagersVolumePreLoader([]);
      setGenderAgeDistributionPreLoader([]);
      setAccountAuthorActivityPreLoader([]);
      setTopAuthorsPreLoader([]);
    }
  }, [props.resetAllFields]);
  const isTwitterAccount = response?.data_source === "TWITTER" ? true : false;

  return (
    <>
      {widgetsName?.length > 2 && (
        <Typography className="section-name">
          {CheckValueLocale("authors_analytics", "", {}, intl).toUpperCase()}
        </Typography>
      )}
      {widgetsName?.includes("engagers_volume") && (
        <Box mt={3}>
          <LineChartWidget
            title={"engagers_volume"}
            titleToolTip={"engagers_volume_fb_tooltip"}
            totalLabel={"engagers"}
            totalValue={totalEngagersVolumeCount}
            data={engagersVolume}
            showGroupBy={true}
            toolTipLabel={"engagers"}
            showDownloadIcon
            bigImg
            hideLegend
            lineChartPreLoaderDataSources={engagersVolumePreLoader}
            preLoaderTrackerDataSources={[
              ...metricsValues?.engagers_volume?.data_sources,
            ]}
            iconAfterTitle={getIcons([
              ...metricsValues?.engagers_volume?.data_sources,
            ])}
            labelColor={"#2AAAE2"}
          />
        </Box>
      )}
      {widgetsName?.includes("gender_age_distribution") && (
        <Box my={3}>
          <StackedBarWidget
            title={"gender_age_dist"}
            titleToolTip={"gender_age_dist_ig_tooltip"}
            showDownloadIcon
            bigImg
            posts={genderAgeDistribution}
            colors={["#2AAAE2", "#FF4081"]}
            showZoomPercentage
            iconAfterTitle={getIcons([
              ...metricsValues?.gender_age_distribution?.data_sources,
            ])}
            postsColumnPreLoaderDataSources={genderAgeDistributionPreLoader}
            preLoaderTrackerDataSources={[
              ...metricsValues?.gender_age_distribution?.data_sources,
            ]}
            showScales
            dataPiechart={genderAgePieChart}
            hideLegend
            labels={["male", "female"]}
            showReportIcon
            reachFunnel
            isGenderAgeSorted
          />
        </Box>
      )}
      {widgetsName?.includes("engagers_activity") && (
        <Box my={3} className="channel-activity">
          <PunchCardWidget
            title="engagers_activity"
            titleToolTip={
              isPublicAccount
                ? "engagers_activity__account_fb_tooltip"
                : "engagers_activity_fb_tooltip"
            }
            clientActivity={accountAuthorActivity}
            minValueClient={minValueAuthorActivity}
            maxValueClient={maxValueAuthorActivity}
            showDownloadIcon
            bigImg
            iconAfterTitle={getIcons([
              ...metricsValues?.engagers_activity?.data_sources,
            ])}
            authorActivityPreLoaderDataSources={accountAuthorActivityPreLoader}
            preLoaderTrackerDataSources={[
              ...metricsValues?.engagers_activity?.data_sources,
            ]}
          />
        </Box>
      )}
      <Box mt={3}>
        <Grid container spacing={3}>
          {widgetsName?.includes("top_languages") && (
            <Grid item xs={6}>
              <PieChartWidget
                title={"top_languages"}
                titleToolTip={"top_languages_fb_tooltip"}
                data={topLanguages}
                areaStyle={true}
                toolTipLabel={"toolTipLabel"}
                showDownloadIcon
                showAI
                pieChartPreLoaderDataSources={topLanguagesPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.top_languages?.data_sources,
                ]}
                iconAfterTitle={getIcons([
                  ...metricsValues?.top_languages?.data_sources,
                ])}
              />
            </Grid>
          )}
          {widgetsName?.includes("dialects_subdialects") && (
            <Grid item xs={6}>
              <PieChartWidget
                title={"dialects_subdialects"}
                titleToolTip={"dialects_subdialects_tooltip"}
                data={dialects}
                subData={subDialects}
                areaStyle={true}
                toolTipLabel={"toolTipLabel"}
                showAI
                showDownloadIcon
                pieChartPreLoaderDataSources={dialectsPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.dialects_subdialects?.data_sources,
                ]}
                iconAfterTitle={getIcons([
                  ...metricsValues?.dialects_subdialects?.data_sources,
                ])}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          {widgetsName?.includes("top_countries") && (
            <Grid item xs={6}>
              <PieChartWidget
                title={"top_countries"}
                titleToolTip={
                  isPublicAccount || isKeywordType
                    ? "top_countries_tw_tooltip"
                    : "top_countries_fb_tooltip"
                }
                data={topCountries}
                areaStyle={true}
                toolTipLabel={"toolTipLabel"}
                showDownloadIcon
                color={["#009BDF", "#003D59", "#45B9E9", "#97D7F3"]}
                pieChartPreLoaderDataSources={topCountriesPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.top_countries?.data_sources,
                ]}
                iconAfterTitle={getIcons([
                  ...metricsValues?.top_countries?.data_sources,
                ])}
                showAI
              />
            </Grid>
          )}
          {widgetsName?.includes("top_cities") && (
            <Grid item xs={6}>
              <PieChartWidget
                title={"top_cities"}
                titleToolTip={
                  isPublicAccount || isKeywordType
                    ? "top_cities_tw_tooltip"
                    : "top_cities_fb_tooltip"
                }
                data={topCities}
                areaStyle={true}
                toolTipLabel={"toolTipLabel"}
                showDownloadIcon
                pieChartPreLoaderDataSources={topCitiesPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.top_cities?.data_sources,
                ]}
                iconAfterTitle={getIcons([
                  ...metricsValues?.top_cities?.data_sources,
                ])}
                showAI
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          {widgetsName?.includes("gender_distribution") && (
            <Grid
              item
              xs={
                monitorType === "MANAGED_PAGE" ||
                monitorType === "MANAGED_AACOUNT"
                  ? 12
                  : 6
              }
            >
              <PieChartWidget
                title={"gender_distribution"}
                titleToolTip={"gender_distribution_tw_tooltip"}
                data={genderDistribution}
                areaStyle={true}
                toolTipLabel={"toolTipLabel"}
                showDownloadIcon
                color={["#2AAAE2", "#FF4081"]}
                pieChartPreLoaderDataSources={genderDistributionPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.gender_distribution?.data_sources,
                ]}
                iconAfterTitle={getIcons([
                  ...metricsValues?.gender_distribution?.data_sources,
                ])}
                showAI
              />
            </Grid>
          )}
          {widgetsName?.includes("account_types") && (
            <Grid item xs={6}>
              <PieChartWidget
                title={"account_types"}
                titleToolTip={"account_types_tw_tooltip"}
                data={accountTypes}
                areaStyle={true}
                toolTipLabel={"toolTipLabel"}
                showDownloadIcon
                pieChartPreLoaderDataSources={accountTypesPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.account_types?.data_sources,
                ]}
                iconAfterTitle={getIcons([
                  ...metricsValues?.account_types?.data_sources,
                ])}
                showAI
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          {widgetsName?.includes("top_engagers") && (
            <Grid item xs={6}>
              <TableWidget
                productId={window.localStorage?.getItem("sm_id")}
                circularPP={true}
                itemsList
                title={"top_engagers"}
                titleToolTip={
                  isTwitterAccount
                    ? "top_engagers_tw_tooltip_twitter"
                    : "top_engagers_tw_tooltip"
                }
                secondaryTitle={"engagement_overview_title"}
                isXPlatform
                data={topEngagers}
                leftColomnTitle={"users"}
                rightColomnTitle={"followers"}
                tableChartPreLoaderDataSources={topEngagersPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.top_engagers?.data_sources,
                ]}
                twitterIcon={getIcons([
                  ...metricsValues?.top_engagers?.data_sources,
                ])}
                tablePagination
                count={topEngagers?.length}
                iconAfterTitle={getIcons([
                  ...metricsValues?.top_engagers?.data_sources,
                ])}
              />
            </Grid>
          )}
          {/* {widgetsName?.includes("top_sources") && (
            <Grid item xs={6}>
              <PieChartWidget
                title={"top_sources"}
                titleToolTip={"top_sources_tw_tooltip"}
                data={topSources}
                areaStyle={true}
                toolTipLabel={"toolTipLabel"}
                showDownloadIcon
                color={["#009BDF", "#003D59", "#45B9E9", "#97D7F3"]}
                pieChartPreLoaderDataSources={topSourcesPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.top_sources?.data_sources,
                ]}
                twitterIcon={getIcons([
                  ...metricsValues?.top_sources?.data_sources,
                ])}
                iconAfterTitle={getIcons([
                  ...metricsValues?.top_sources?.data_sources,
                ])}
              />
            </Grid>
          )} */}
          {widgetsName?.includes("top_verified_engagers") && (
            <Grid item xs={6}>
              <TableWidget
                circularPP={true}
                itemsList
                title={"top_verified_engagers"}
                titleToolTip={
                  isTwitterAccount
                    ? "top_verified_engagers_tw_tooltip_twitter"
                    : "top_verified_engagers_tw_tooltip"
                }
                secondaryTitle={"engagement_overview_title"}
                isXPlatform
                data={topVerifiedEngagers}
                leftColomnTitle={"users"}
                rightColomnTitle={"followers"}
                tableChartPreLoaderDataSources={topVerifiedEngagersPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.top_verified_engagers?.data_sources,
                ]}
                twitterIcon={getIcons([
                  ...metricsValues?.top_verified_engagers?.data_sources,
                ])}
                tablePagination
                count={topVerifiedEngagers?.length}
                iconAfterTitle={getIcons([
                  ...metricsValues?.top_verified_engagers?.data_sources,
                ])}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3} mb={4}>
          {widgetsName?.includes("top_influencers") && (
            <Grid item xs={6}>
              <TableWidget
                circularPP={true}
                itemsList
                title={"top_infleuncers"}
                titleToolTip={
                  isTwitterAccount
                    ? "top_infleuncers_tw_tooltip_twitter"
                    : "top_infleuncers_tw_tooltip"
                }
                secondaryTitle={"engagement_overview_title"}
                isXPlatform
                data={topInfleuncers}
                leftColomnTitle={"user"}
                rightColomnTitle={"followers"}
                tableChartPreLoaderDataSources={topInfleuncersPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.top_influencers?.data_sources,
                ]}
                twitterIcon={getIcons([
                  ...metricsValues?.top_influencers?.data_sources,
                ])}
                tablePagination
                count={topInfleuncers?.length}
                iconAfterTitle={getIcons([
                  ...metricsValues?.top_influencers?.data_sources,
                ])}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {widgetsName?.includes("top_authors") && (
              <TableWidget
                wordsList
                title={"top_authors"}
                titleToolTip={"top_authors_tooltip_dash"}
                data={topAuthors}
                leftColomnTitle={"username"}
                rightColomnTitle={"number_of_posts"}
                tableChartPreLoaderDataSources={topAuthorsPreLoader}
                preLoaderTrackerDataSources={[
                  ...metricsValues?.top_authors?.data_sources,
                ]}
                twitterIcon={getIcons([
                  ...metricsValues?.top_authors?.data_sources,
                ])}
                showDownloadIcon
                tablePagination
                showAtIcon
                count={topAuthors?.length}
                iconAfterTitle={getIcons([
                  ...metricsValues?.top_authors?.data_sources,
                ])}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      {!isKeywordType && widgetsName?.includes("top_posts_comments") && (
        <Box mt={3}>
          <Box className="top-posts-head">
            <Box>{CheckValueLocale("top_comments_mentions", "", {}, intl)}</Box>
            <Link
              to={{
                pathname: `/dashboard/${monitorType}/comments_mentions_page/${dashboardID}`,
                state: { dashboardLinkFlag: true },
              }}
              className="top-posts-all"
              id={`check-comments-mentions-${
                monitorType?.toLowerCase() ?? "monitor-type"
              }-comments-mentions-page-${dashboardID ?? "dashboard-id"}`}
            >
              {CheckValueLocale("check_all_comments_mentions", "", {}, intl)}({" "}
              {commentsMentionsCount} )
            </Link>
          </Box>
          <QuestionCard
            unixDate={unixDate}
            showProfile={true}
            productId={window.localStorage?.getItem("sm_id")}
            latestUnanswer={commentsMentions}
            unAnsweredQuestion={commentsMentionsCount}
            showRetweet={true}
            showScreenName={true}
            latestUnanswerPreloader={commentsMentionsPreLoader}
            preLoaderTrackerDataSources={[
              ...metricsValues?.top_posts_comments?.data_sources,
            ]}
            updateAnalysis={props?.updateAnalysis}
            singleTweetAnalysis={singleTweetAnalysis}
            isAggregateDashboard
            widget="comments_mentions"
            insightsID={insightsID}
            response={insightsResponse}
            dates={dates}
            hideWhenRetweeted={
              monitorType === "ACCOUNT_ANALYSIS" && monitorType === "KEYWORD"
            }
            showDefaultImg
            noOfChar={15}
          />
        </Box>
      )}
    </>
  );
};

export default AuthorAnalyticsSection;
