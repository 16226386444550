import { useQuery } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useCancellableDatasources = () => {
  return useQuery({
    queryKey: ["cancellableDatasources"],
    queryFn: async () => SettingsController.getCancellableDatasources(),
    select: (data) => data?.data?.data?.map((item) => item?.attributes),
    retry: false,
  });
};
