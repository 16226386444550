import moment from "moment";
import { CheckValueLocale } from "utils/helpers";

export const useGenerateUsageData = (activeProducts, data, intl) => {
  let usageData = [];

  // Social Listening and not combined
  if (
    activeProducts?.includes("sm") &&
    !data?.sm_package?.attributes?.combined_packages
  ) {
    const {
      max_monitors,
      package_used_monitors,
      subscribe_end,
      dashboards_limit,
      social_limit,
      social_usage,
      topics_limit,
      package_used_dashboards,
      package_used_topics,
      manual_topics_enabled,
    } = data?.sm_package?.attributes || {};

    usageData?.push({
      productKey: "social-listening",
      logo: ["social-listening"],
      title: "subscriptions_social_listening_title",
      renewalDate: moment(subscribe_end)?.format("DD-MM-YYYY") || "",
      upgradeKey: "request_social_listening_upgrade",
      usage: [
        { title: "monitors", total: max_monitors, used: package_used_monitors },
        {
          title: "dashboards",
          total: dashboards_limit,
          used: package_used_dashboards,
        },
        {
          title: "subscriptions_social_media_posts_used",
          tooltip: "subscriptions_all_received_interactions",
          total: social_limit,
          used: social_usage,
        },
        {
          title: "subscriptions_topics_used",
          total: topics_limit,
          used: package_used_topics,
          hide: !manual_topics_enabled,
        },
        {
          title: "subscriptions_news_and_blogs_posts_used",
          tooltip: "subscriptions_all_received_interactions",
          total: data?.nb_package?.attributes?.package_qouta,
          used: data?.nb_package?.attributes?.used_quota,
        },
      ],
    });
  }

  // Omnichannel and not combined
  if (
    activeProducts?.includes("cxm") &&
    !data?.ca_package?.attributes?.combined_packages
  ) {
    const {
      max_channels,
      package_used_monitors,
      max_interactions,
      interactions_usage,
      subscribe_end,
    } = data?.ca_package?.attributes || {};

    usageData?.push({
      productKey: "omnichannel",
      logo: ["omnichannel"],
      title: "subscriptions_omnichannel_title",
      renewalDate: moment(subscribe_end)?.format("DD-MM-YYYY") || "",
      upgradeKey: "request_channel_analytics_upgrade",
      usage: [
        { title: "channels", total: max_channels, used: package_used_monitors },
        {
          title: "subscriptions_interactions_used",
          tooltip: "subscriptions_all_received_interactions_emails",
          total: max_interactions,
          used: interactions_usage,
        },
      ],
    });
  }

  // Combined Package (Social Listening, Omnichannel)
  if (
    data?.sm_package?.attributes?.combined_packages &&
    data?.ca_package?.attributes?.combined_packages
  ) {
    const {
      max_monitors,
      package_used_monitors,
      dashboards_limit,
      package_used_dashboards,
      manual_topics_enabled,
      topics_limit,
      package_used_topics,
      social_limit,
      social_usage,
      subscribe_end,
    } = data?.sm_package?.attributes || {};

    usageData?.push({
      productKey: "combined-package",
      logo: ["social-listening", "omnichannel"],
      title: "subscriptions_combined_package_title",
      renewalDate: moment(subscribe_end)?.format("DD-MM-YYYY") || "",
      upgradeKey: "request_social_listening_upgrade",
      usage: [
        { title: "monitors", total: max_monitors, used: package_used_monitors },
        {
          title: "channels",
          total: data?.ca_package?.attributes?.max_channels,
          used: data?.ca_package?.attributes?.package_used_monitors,
        },
        {
          title: "dashboards",
          total: dashboards_limit,
          used: package_used_dashboards,
        },
        {
          title: "subscriptions_topics_used",
          total: topics_limit,
          used: package_used_topics,
          hide: !manual_topics_enabled,
        },
        {
          title: "subscriptions_posts_and_interactions_used",
          tooltip: "subscriptions_all_received_interactions_emails",
          total:
            Number(social_limit) +
            Number(data?.ca_package?.attributes?.max_interactions),
          used:
            Number(social_usage) +
            Number(data?.ca_package?.attributes?.interactions_usage),
        },
        {
          title: "subscriptions_news_and_blogs_posts_used",
          tooltip: "subscriptions_all_received_interactions_emails",
          total: data?.nb_package?.attributes?.package_qouta,
          used: data?.nb_package?.attributes?.used_quota,
        },
      ],
    });
  }

  // Survey
  if (activeProducts?.includes("survey")) {
    const { max_surveys, survey_usage, subscribe_end, package_type } =
      data?.survey_package?.attributes || {};

    usageData?.push({
      productKey: "survey",
      logo: ["survey"],
      title: "subscriptions_survey_title",
      renewalDate: moment(subscribe_end)?.format("DD-MM-YYYY") || "",
      upgradeKey: "request_survey_upgrade",
      usage: [
        {
          title:
            package_type?.toLowerCase() === "monthly"
              ? "subscriptions_survey_used_monthly"
              : "subscriptions_survey_used",
          total: max_surveys,
          used: survey_usage,
        },
      ],
    });
  }

  // CDP
  if (activeProducts?.includes("cdp")) {
    const { max_profiles, used_profile_count, subscribe_end } =
      data?.cdp_package?.attributes || {};

    usageData?.push({
      productKey: "cdp",
      logo: ["cdp"],
      title: "subscriptions_cdp_title",
      renewalDate: moment(subscribe_end)?.format("DD-MM-YYYY") || "",
      upgradeKey: "request_audience_upgrade",
      usage: [
        {
          title: "subscriptions_users_profiles_used",
          total: max_profiles,
          used: used_profile_count,
        },
      ],
    });
  }

  // Engagement
  if (activeProducts?.includes("engagements")) {
    const {
      max_admins,
      package_used_admins,
      subscribe_end,
      agent_replies_day_count,
      max_replies_per_day,
      agent_replies_15_mins_count,
      max_replies_per_15_minutes,
      rate_limit_24h_reset,
      rate_limit_15m_reset,
    } = data?.engagement_package?.attributes || {};

    const getRenewDate = (date, intl) => {
      if (date) {
        if (intl?.locale === "ar") {
          const isAm = moment.unix(date).utc().format("A") === "AM";
          return CheckValueLocale(
            "subscriptions_engagement_renewal_date_and_time",
            "",
            {
              br: <br />,
              date: moment.unix(date).utc().format("M-D-YYYY"),
              time: moment.unix(date).utc().format("h:mm"),
              period: isAm ? "صباحا" : "مساءً",
            },
            intl,
          );
        } else {
          return CheckValueLocale(
            "subscriptions_engagement_renewal_date_and_time",
            "",
            {
              br: <br />,
              date: moment.unix(date).utc().format("M-D-YYYY"),
              time: moment.unix(date).utc().format("h:mm A"),
            },
            intl,
          );
        }
      } else {
        return "";
      }
    };

    usageData?.push({
      productKey: "engagement",
      logo: ["engagement"],
      title: "subscriptions_engagements_title",
      renewalDate: moment(subscribe_end)?.format("DD-MM-YYYY") || "",
      upgradeKey: "request_engagement_upgrade",
      usage: [
        {
          title: "subscriptions_engagement_users_used",
          total: max_admins,
          used: package_used_admins,
        },
        {
          title: "subscriptions_engagement_dm_replies_24_hour",
          tooltip: getRenewDate(rate_limit_24h_reset, intl),
          total: max_replies_per_day,
          used: agent_replies_day_count,
        },
        {
          title: "subscriptions_engagement_dm_replies_15_min",
          tooltip: getRenewDate(rate_limit_15m_reset, intl),
          total: max_replies_per_15_minutes,
          used: agent_replies_15_mins_count,
        },
      ],
    });
  }

  // Luci
  if (activeProducts?.includes("luci")) {
    const { gpt_token, package_used_token, subscribe_end } =
      data?.luci_package?.attributes || {};

    usageData?.push({
      productKey: "luci",
      logo: ["luci"],
      title: "subscriptions_luci_title",
      renewalDate: moment(subscribe_end)?.format("DD-MM-YYYY") || "",
      upgradeKey: "request_luci_upgrade",
      usage: [
        {
          title: "subscriptions_luci_energy_used",
          total: gpt_token,
          used: package_used_token,
        },
      ],
    });
  }

  // AI API
  if (activeProducts?.includes("ai_api")) {
    const { ai_api_qouta, ai_api_qouta_used, subscribe_end } =
      data?.ai_api_package?.attributes || {};

    usageData?.push({
      productKey: "ai-api",
      logo: ["ai-api"],
      title: "subscriptions_ai_api_title",
      renewalDate: moment(subscribe_end)?.format("DD-MM-YYYY") || "",
      upgradeKey: "request_ai_api_upgrade",
      usage: ai_api_qouta?.map((item) => {
        return {
          title: `subscriptions_${item?.feature_name?.toLowerCase()}_ai_api_requests`,
          total: item?.max_requests_per_month,
          used: ai_api_qouta_used?.[`${item?.feature_name}_count`],
        };
      }),
    });
  }

  // AI Agent
  if (
    activeProducts?.includes("engagements") &&
    activeProducts?.includes("ai_agent")
  ) {
    const { max_handled_conversations, package_used_admins, subscribe_end } =
      data?.ai_agent_package?.attributes || {};
    usageData?.push({
      productKey: "ai-agent",
      logo: ["ai-agent"],
      title: "subscriptions_ai_agent_title",
      renewalDate: moment(subscribe_end)?.format("DD-MM-YYYY") || "",
      upgradeKey: "request_ai_agent_upgrade",
      usage: [
        {
          title: "subscriptions_ai_agent_max_handled_engagements",
          total: max_handled_conversations,
          used: package_used_admins,
        },
      ],
    });
  }

  // Lucidya API
  if (activeProducts?.includes("public_api")) {
    const { max_requests_per_month, package_request_used, subscribe_end } =
      data?.public_api_package?.attributes || {};

    usageData?.push({
      productKey: "public-api",
      logo: ["public-api"],
      title: "subscriptions_lucidya_api_title",
      renewalDate: moment(subscribe_end)?.format("DD-MM-YYYY") || "",
      upgradeKey: "request_public_api_upgrade",
      usage: [
        {
          title: "subscriptions_lucidya_api_requests",
          total: max_requests_per_month,
          used: package_request_used,
        },
      ],
    });
  }

  return usageData;
};
