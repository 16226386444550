import { useQuery } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useHistoricalProducts = ({ enableFetch }) => {
  return useQuery({
    queryKey: ["historicalRequestProducts"],
    queryFn: async () => SettingsController.getHistoricalRequestProducts(),
    select: (data) => {
      const idsObject = {};
      data?.data?.platforms?.data?.map(
        (item) => (idsObject[item?.attributes?.name] = item?.attributes?.id),
      );
      return {
        data: data?.data?.platforms?.data || [],
        ids: { ...(idsObject || {}) },
      };
    },
    enabled: enableFetch,
    retry: false,
  });
};
