import * as XLSX from "xlsx";

// fileExcelData ---> array of objects
// each object --> { data: [] , sheetName:"",widgetId:number }
const exportExcel = (
  fileExcelData,
  fileName,
  handleSnackBarSuccess,
  handleSnackBarError,
) => {
  try {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    //to set unique name for each sheet
    let countSheet = 1;
    fileExcelData?.forEach((sheet) => {
      // Convert data array to a worksheet
      const worksheet = XLSX.utils.aoa_to_sheet(sheet?.data);
      const newSheetName = (countSheet + "-" + sheet?.sheetName)?.slice(0, 31);

      // Append the worksheet to the workbook
      // Sheet names cannot exceed 31 chars and unique
      XLSX.utils.book_append_sheet(workbook, worksheet, newSheetName);
      countSheet++; // when add new sheet to excel file

      // this for handle dialects_subdialects in two sheet one for dialects and enther to subdialects
      if (sheet?.sheetNameSub) {
        const newSheetNameSub = (countSheet + "-" + sheet?.sheetNameSub)?.slice(
          0,
          31,
        );
        const worksheet = XLSX.utils.aoa_to_sheet(sheet?.subData);
        // Append the worksheet to the workbook
        // Sheet names cannot exceed 31 chars and unique
        XLSX.utils.book_append_sheet(workbook, worksheet, newSheetNameSub);
        countSheet++; // when add new sheet to excel file
      }
    });

    // Trigger download of the Excel file
    XLSX.writeFile(workbook, fileName + `.xlsx`);

    handleSnackBarSuccess();
  } catch (error) {
    handleSnackBarError();
  }
};

export default exportExcel;
