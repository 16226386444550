const hours_for_hover = [
  "12am",
  "1am",
  "2am",
  "3am",
  "4am",
  "5am",
  "6am",
  "7am",
  "8am",
  "9am",
  "10am",
  "11am",
  "12pm",
  "1pm",
  "2pm",
  "3pm",
  "4pm",
  "5pm",
  "6pm",
  "7pm",
  "8pm",
  "9pm",
  "10pm",
  "11pm",
];
const days = [
  "Friday", // Equals to 0 at channel Activity Array
  "Thursday", // Equals to 1 at channel Activity Array
  "Wednesday", // Equals to 2 at channel Activity Array
  "Tuesday", // Equals to 3 at channel Activity Array
  "Monday", // Equals to 4 at channel Activity Array
  "Sunday", // Equals to 5 at channel Activity Array
  "Saturday", // Equals to 6 at channel Activity Array
];

/////////////////// PunchCard (aggregate && defualt ) ////////////////////////////////////
// widgetData?.activity_data ===> [ [day , hour , value], ..........]
// return ===> (aggregate- defualt) ---->  [[day , hour , value] , [ "sunday" , "12am" , 10] , ............]
export const handlePunchCardToExcel = (
  widgetData,
  xlsLanguage,
  customizedType,
) => {
  try {
    const dataListExcelSheet = [
      [xlsLanguage?.day, xlsLanguage?.hour_group, xlsLanguage?.value],
    ];

    //to add the translate for "aggregate" to value column
    if (customizedType === "aggregate")
      dataListExcelSheet[0][2] += ` - ${xlsLanguage?.aggregate}`;

    widgetData?.activity_data?.forEach((item) => {
      dataListExcelSheet?.push([
        xlsLanguage?.[days?.[item?.[0]]], // to set day
        hours_for_hover?.[item?.[1]], // to set hour
        item?.[2], // to set value
      ]);
    });

    return dataListExcelSheet;
  } catch {}
};
