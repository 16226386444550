import { useQuery } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useGetSingleLucidyaApis = (id) => {
  return useQuery({
    queryKey: ["companySingleLucidyaApis", id],
    queryFn: () => SettingsController.getSingleLucidyaApis(id),
    select: (data) => data?.data,
    enabled: !!id,
    retry: false,
  });
};
