import moment from "moment";
import { Box, Divider, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import ProgressAndDetails from "./progressAndDetails/index.js";

const CxmCore = (props) => {
  const { info } = props;
  const intl = useIntl();

  const packageDetails = [
    {
      name: CheckValueLocale("company_package_name", "", {}, intl),
      value: info?.name || "",
    },
    {
      name: CheckValueLocale("subscription_start_date", "", {}, intl),
      value: info?.subscribe_start
        ? moment(info?.subscribe_start)?.format("DD-MM-YYYY")
        : "",
    },
    {
      name: CheckValueLocale("subscription_expiration_date", "", {}, intl),
      value: info?.subscribe_end
        ? moment(info?.subscribe_end)?.format("DD-MM-YYYY")
        : "",
    },
  ];

  return (
    <Box>
      <Typography variant="h6" className="settings-section-title">
        {CheckValueLocale("customer_experience_management_core", "", {}, intl)}
      </Typography>

      <Box className="cxm-core-container">
        <Box className="core-package-details">
          {packageDetails?.map((item, index) => (
            <>
              <Box key={index} className="details-data-box">
                <Typography variant="h6">{item?.name}</Typography>
                <Typography>{item?.value}</Typography>
              </Box>
              {index < packageDetails?.length - 1 ? (
                <Divider orientation="vertical" flexItem />
              ) : null}
            </>
          ))}
        </Box>
        <Box className="users-progress-container">
          <ProgressAndDetails
            title={"number_of_users"}
            total={info?.max_users || 0}
            used={info?.package_used_users || 0}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CxmCore;
