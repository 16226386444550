import { useState } from "react";
import { Box, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { useThirdPartyList } from "./hooks/useThirdPartyList";
import CircularLoading from "components/circularProgress";
import SnackBar from "components/snackBar";
import PageHeader from "components/pageHeader";
import inctercomImg from "images/products-addons/third-party/intercom.svg";
import zendeskImg from "images/products-addons/third-party/zendesk.svg";
import IntegrationItem from "./components/integrationItem";
import "./style.scss";

const ThirdPartyIntegrations = () => {
  const intl = useIntl();

  const [snackbar, setSnackbar] = useState({});

  const { data: thirdPartyList, isPending: listLoading } = useThirdPartyList();

  const dummyList = [
    {
      icon: inctercomImg,
      title: "Intercom",
      description: "intercom_definiton",
      active:
        thirdPartyList?.find((item) => item?.name === "INTERCOM")?.enabled ||
        false,
      demoKey: "request_intercom",
    },
    {
      icon: zendeskImg,
      title: "Zendesk",
      description: "zendesk_definiton",
      active:
        thirdPartyList?.find((item) => item?.name === "ZENDESK")?.enabled ||
        false,
      demoKey: "request_zendesk",
    },
  ];

  return (
    <Box id="settings-subscriptions-page">
      {listLoading ? (
        <CircularLoading />
      ) : (
        <Box id="settings-subscriptions-container">
          <PageHeader
            title="third_party_integrations"
            titleId="third-party-integrations"
          />

          <Grid container spacing={4}>
            {dummyList?.map((item) => (
              <Grid item xs={12} md={4} key={item?.title}>
                <IntegrationItem item={item} setSnackbar={setSnackbar} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <SnackBar
        open={snackbar?.open}
        handleClose={() => setSnackbar({})}
        severity={snackbar?.severity}
        title={CheckValueLocale(snackbar?.title, "", {}, intl)}
        message={CheckValueLocale(snackbar?.message, "", {}, intl)}
        alertStyle={{ maxWidth: "320px" }}
      />
    </Box>
  );
};

export default ThirdPartyIntegrations;
