import { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import filterIcon from "../../../images/filter-icon.svg";
import { CheckValueLocale } from "utils/helpers";
import {
  Box,
  SwipeableDrawer,
  Button,
  Typography,
  OutlinedInput,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  ListSubheader,
  Snackbar,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import MonitorsController from "services/controllers/monitorsController";
import ReportController from "services/controllers/reportController";
import CompaniesController from "services/controllers/companiesController";
import LucButton from "shared/lucButton/lucButton";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 376,
      width: 320,
    },
  },
};

const platformDropdownData = [
  {
    id: 1,
    name: "SM",
    title: "social_listening",
    active: true,
  },
  {
    id: 2,
    name: "CXM",
    title: "channel_analytics",
    active: true,
  },
];

const monitorKeys = {
  MANAGED_ACCOUNT: "managed_account",
  ACCOUNT_ANALYSIS: "public_account",
  KEYWORD: "keywords",
};

const frequencyList = [
  { id: 0, name: "daily" },
  { id: 1, name: "weekly" },
  { id: 2, name: "monthly" },
];
const statusList = [
  { id: 0, name: "paused" },
  { id: 1, name: "active" },
];
const filtersAttribute = {
  platform: [],
  monitorCategoryType: [],
  dataSourceChannelAccount: [],
  frequency: [],
  creator: [],
  status: [],
};

const ListFilters = ({ open, handelFilterAction, handelApplyFilter }) => {
  const intl = useIntl();

  const anchor = intl?.locale === "ar" ? "left" : "right";

  const [filters, setFilters] = useState(filtersAttribute);
  const [monitorsDropdownData, setMonitorsDropdownData] = useState([]);
  const [dataSourcesDropdownData, setDataSourcesDropdownData] = useState([]);
  const [creatorDropdownData, setCreatorDropdownData] = useState([]);
  const [isRequestError, setIsRequestError] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value, name },
    } = event;
    const updateValue = typeof value === "string" ? value?.split(",") : value;
    setFilters((val) => ({ ...val, [name]: updateValue }));
  };

  const handelResetFilters = () => {
    setFilters({ ...filtersAttribute });
  };

  const handelApplyFiltersAction = () => {
    handelApplyFilter({
      frequencies: filters?.frequency?.join(","),
      data_source_ids: filters?.dataSourceChannelAccount?.join(","),
      monitor_type_ids: filters?.monitorCategoryType?.join(","),
      statuses: filters?.status?.join(","),
      user_ids: filters?.creator?.join(","),
      product_ids: filters?.platform?.join(","),
    });
  };

  const getMonitorDropdownData = () => {
    MonitorsController.getAuthorizedMonitorType(1).then((res) => {
      setIsRequestError(!!res?.errorMsg);
      let monitorData = [];
      if (res?.data?.length) {
        res?.data?.map((val) => {
          if (
            val?.attributes?.monitor_type_name !== "MANAGED_PAGE" &&
            val?.attributes?.monitor_type_name !== "REGION"
          ) {
            monitorData.push({
              id: val?.attributes?.monitor_type_id,
              name: val?.attributes?.monitor_type_name,
              title: val?.attributes?.monitor_type_name
                ? monitorKeys[val?.attributes?.monitor_type_name]
                : val?.attributes?.monitor_type_name,
            });
          }
        });
        monitorData = [
          ...new Map(monitorData?.map((item) => [item["id"], item])).values(),
        ];
      }
      setMonitorsDropdownData(monitorData);
    });
  };

  const getDataSourcesDropdownData = () => {
    ReportController.getReportFilterDataSources().then((res) => {
      setIsRequestError(!!res?.errorMsg);
      res?.data &&
        setDataSourcesDropdownData([
          ...res?.data?.map((val) => ({
            id: val?.attributes?.id,
            name: val?.attributes?.source,
            title: val?.attributes?.source
              ? CheckValueLocale(
                  val?.attributes?.source?.toLowerCase(),
                  "",
                  {},
                  intl,
                )
              : "",
          })),
        ]);
    });
  };

  const getCreatorDropdownData = () => {
    CompaniesController.getCompanyActiveUsers().then((res) => {
      setIsRequestError(!!res?.errorMsg);
      res?.data?.data &&
        setCreatorDropdownData([
          ...res.data?.data
            ?.filter(
              (val) =>
                val?.attributes?.name !== "" && val?.attributes?.name !== null,
            )
            ?.map((val) => ({
              id: val?.attributes?.id,
              name: val?.attributes?.name,
            })),
        ]);
    });
  };

  useEffect(() => {
    getMonitorDropdownData();
    getDataSourcesDropdownData();
    getCreatorDropdownData();
  }, []);
  return (
    <SwipeableDrawer anchor={anchor} open={open} className="report-filters">
      <Box role="presentation">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "64px",
            padding: "0 27px",
            background: "#FFFFFF",
            filter: "drop-shadow(0px 0px 40px rgba(11, 24, 42, 0.071))",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <img src={filterIcon} />
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="report-filters-text"
              sx={{
                fontSize: "15px",
                lineHeight: "15px",
                color: "#456EB2",
                margin: 0,
                marginLeft: "5px",
              }}
            >
              {CheckValueLocale("filter", "", {}, intl)}
            </Typography>
          </Box>
          <LucButton
            variant="outline"
            size="small"
            type="secondary"
            onClick={() => handelFilterAction(false)}
            id="report-list-hide-btn"
          >
            {CheckValueLocale("hide", "", {}, intl)}
          </LucButton>
        </Box>
        <Box
          sx={{
            padding: "0 27px",
            height: "calc(100vh - 210px)",
            overflow: "auto",
          }}
        >
          <Box className="report-input-form-control">
            <InputLabel>
              {CheckValueLocale("platform", "", {}, intl)}
            </InputLabel>
            <Select
              fullWidth
              multiple
              name="platform"
              value={filters?.platform}
              onChange={handleChange}
              input={<OutlinedInput className="select-input-field" />}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return CheckValueLocale("select", "", {}, intl);
                }
                return platformDropdownData
                  ?.filter((val) => selected?.includes(val?.id))
                  ?.map((val) => {
                    return CheckValueLocale(val?.title, "", {}, intl);
                  })
                  ?.join(", ");
              }}
              MenuProps={MenuProps}
              className={
                filters?.platform?.length > 0 ? "filter-select-active" : ""
              }
              displayEmpty
            >
              {platformDropdownData?.map((val) => (
                <MenuItem
                  className="report-filter-menu-item"
                  key={`platform-key-${val?.id}`}
                  value={val?.id}
                  disabled={!val.active}
                  id={`report-filter-platform-menu-item-${
                    val?.title?.toLowerCase() ?? "title"
                  }`}
                >
                  <Checkbox checked={filters?.platform?.includes(val?.id)} />
                  <ListItemText
                    primary={CheckValueLocale(val?.title, "", {}, intl)}
                  />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="report-input-form-control">
            <InputLabel>
              {CheckValueLocale("monitor_type", "", {}, intl)}
            </InputLabel>
            <Select
              fullWidth
              multiple
              name="monitorCategoryType"
              value={filters?.monitorCategoryType}
              onChange={handleChange}
              input={<OutlinedInput className="select-input-field" />}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return CheckValueLocale("select", "", {}, intl);
                }
                return monitorsDropdownData
                  ?.filter((val) => selected?.includes(val?.id))
                  ?.map((val) => {
                    return CheckValueLocale(val?.title, "", {}, intl);
                  })
                  ?.join(", ");
              }}
              MenuProps={MenuProps}
              className={
                filters?.monitorCategoryType?.length > 0
                  ? "filter-select-active"
                  : ""
              }
              displayEmpty
            >
              {monitorsDropdownData?.map((val) => (
                <MenuItem
                  className="report-filter-menu-item"
                  key={`MT-${val?.id}`}
                  value={val?.id}
                  id={`report-filter-monitors-menu-item-${
                    val?.title?.toLowerCase() ?? "title"
                  }`}
                >
                  <Checkbox
                    checked={filters?.monitorCategoryType?.includes(val?.id)}
                  />
                  <ListItemText
                    primary={CheckValueLocale(val?.title, "", {}, intl)}
                  />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="report-input-form-control">
            <InputLabel>
              {CheckValueLocale("data_source", "", {}, intl)}
            </InputLabel>
            <Select
              fullWidth
              multiple
              name="dataSourceChannelAccount"
              value={filters?.dataSourceChannelAccount}
              onChange={handleChange}
              input={<OutlinedInput className="select-input-field" />}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return CheckValueLocale("select", "", {}, intl);
                }
                return dataSourcesDropdownData
                  ?.filter((val) => selected?.includes(val?.id))
                  ?.map((val) => {
                    return CheckValueLocale(
                      val?.title?.toLowerCase(),
                      "",
                      {},
                      intl,
                    );
                  })
                  ?.join(", ");
              }}
              MenuProps={MenuProps}
              className={
                filters.dataSourceChannelAccount?.length > 0
                  ? "filter-select-active"
                  : ""
              }
              displayEmpty
            >
              {dataSourcesDropdownData?.map((val) => (
                <MenuItem
                  className="report-filter-menu-item"
                  key={`MT-${val?.id}`}
                  value={val?.id}
                  id={`report-filter-data-source-menu-item-${
                    val?.title?.toLowerCase() ?? "title"
                  }`}
                >
                  <Checkbox
                    checked={filters?.dataSourceChannelAccount?.includes(
                      val?.id,
                    )}
                  />
                  <ListItemText
                    primary={
                      val?.title !== ""
                        ? CheckValueLocale(
                            val?.title?.toLowerCase(),
                            "",
                            {},
                            intl,
                          )
                        : ""
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="report-input-form-control">
            <InputLabel>
              {CheckValueLocale("frequency", "", {}, intl)}
            </InputLabel>
            <Select
              fullWidth
              multiple
              name="frequency"
              value={filters?.frequency}
              onChange={handleChange}
              input={<OutlinedInput className="select-input-field" />}
              renderValue={(selected) => {
                if (selected?.length === 0) {
                  return CheckValueLocale("select", "", {}, intl);
                }
                return frequencyList
                  ?.filter((val) => selected?.includes(val?.id))
                  ?.map((val) => CheckValueLocale(val?.name, "", {}, intl))
                  ?.join(", ");
              }}
              MenuProps={MenuProps}
              className={
                filters?.frequency?.length > 0 ? "filter-select-active" : ""
              }
              displayEmpty
            >
              {frequencyList?.map((val) => (
                <MenuItem
                  className="report-filter-menu-item"
                  key={`frequency-${val?.id}`}
                  value={val?.id}
                  id={`report-filter-frequency-menu-item-${
                    val?.name ?? "frequency-name"
                  }`}
                >
                  <Checkbox checked={filters?.frequency?.includes(val?.id)} />
                  <ListItemText
                    primary={CheckValueLocale(val?.name, "", {}, intl)}
                  />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className="report-input-form-control">
            <InputLabel>{CheckValueLocale("creator", "", {}, intl)}</InputLabel>
            <Select
              fullWidth
              multiple
              name="creator"
              value={filters?.creator}
              onChange={handleChange}
              input={<OutlinedInput className="select-input-field" />}
              renderValue={() => CheckValueLocale("select", "", {}, intl)}
              MenuProps={MenuProps}
              displayEmpty
            >
              {creatorDropdownData?.map((val) => (
                <MenuItem
                  className="report-filter-menu-item"
                  key={`creator-${val?.id}`}
                  value={val?.id}
                  id={`report-filter-creator-menu-item-${
                    val?.name ?? "creator-name"
                  }`}
                >
                  <Checkbox checked={filters?.creator?.includes(val?.id)} />
                  <ListItemText primary={val?.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          {filters?.creator?.length > 0 && (
            <Box sx={{ mt: "10px" }}>
              {creatorDropdownData
                ?.filter((val) => filters?.creator?.includes(val?.id))
                ?.map((val) => (
                  <Button
                    key={`creator-btn-${val?.id}`}
                    variant="outlined"
                    className="filter-selected-label"
                    endIcon={
                      <CancelIcon
                        onClick={() =>
                          setFilters((fval) => ({
                            ...fval,
                            creator: [
                              ...fval.creator?.filter(
                                (fval2) => fval2 !== val?.id,
                              ),
                            ],
                          }))
                        }
                        id="report-list-filter-creator-btn"
                      />
                    }
                  >
                    {val?.name}
                  </Button>
                ))}
            </Box>
          )}
          <Box className="report-input-form-control">
            <InputLabel>{CheckValueLocale("status", "", {}, intl)}</InputLabel>
            <Select
              fullWidth
              multiple
              name="status"
              value={filters?.status}
              onChange={handleChange}
              input={<OutlinedInput className="select-input-field" />}
              renderValue={() => CheckValueLocale("select", "", {}, intl)}
              MenuProps={MenuProps}
              displayEmpty
            >
              {statusList?.map((val, i) => (
                <MenuItem
                  className="report-filter-menu-item"
                  value={val?.name}
                  key={i}
                  id={`report-filter-status-menu-item-${
                    val?.name ?? "status-name"
                  }`}
                >
                  <Checkbox checked={filters?.status?.includes(val?.name)} />
                  <ListItemText
                    primary={CheckValueLocale(val?.name, "", {}, intl)}
                  />
                </MenuItem>
              ))}
            </Select>
          </Box>
          {filters?.status?.length > 0 && (
            <Box sx={{ mt: "10px" }}>
              {filters?.status?.map((val) => (
                <Button
                  variant="outlined"
                  className="filter-selected-label"
                  endIcon={
                    <CancelIcon
                      onClick={() =>
                        setFilters((fval) => ({
                          ...fval,
                          status: [
                            ...fval.status?.filter((fval2) => fval2 !== val),
                          ],
                        }))
                      }
                    />
                  }
                  id="report-list-filter-status-btn"
                >
                  {val}
                </Button>
              ))}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80px",
            padding: "0 27px",
            background: "#FFFFFF",
            filter: "drop-shadow(-5px -5px 40px rgba(11, 24, 42, 0.071))",
            zIndex: 1,
            right: "0",
            left: "0",
          }}
        >
          <LucButton
            size="small"
            fullWidth={true}
            onClick={handelApplyFiltersAction}
            id="report-filter-apply-btn"
          >
            {CheckValueLocale("apply", "", {}, intl)}
          </LucButton>
          <LucButton
            variant="flat"
            type="secondary"
            size="small"
            fullWidth={true}
            onClick={handelResetFilters}
            id="report-filter-clear-btn"
          >
            {CheckValueLocale("clear", "", {}, intl)}
          </LucButton>
        </Box>
      </Box>
      {isRequestError && (
        <Snackbar
          lang={window?.localStorage?.lang}
          open={true}
          handleClose={() => {
            setIsRequestError(!!false);
          }}
          severity="error"
          message={CheckValueLocale(
            "something_went_wrong_try_agin",
            "",
            {},
            intl,
          )}
        />
      )}
    </SwipeableDrawer>
  );
};

export default ListFilters;
