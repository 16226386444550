import Auth from "../api/auth";

var AuthController = {};

AuthController.twitterAuth = (
  consumer_key,
  redirect_url,
  product_id,
  oauth2_flow_init_url,
  is_company_account,
) => {
  var queryData = {
    consumer_key,
    redirect_url,
    product_id,
    oauth2_flow_init_url,
  };
  if (is_company_account || is_company_account == false) {
    queryData.is_company_account = is_company_account;
  }
  return new Promise(function (resolve) {
    Auth.twitterAuth(queryData);
  });
};

AuthController.facebookAuth = (
  consumer_key,
  redirect_url,
  product_id,
  oauth2_flow_init_url,
  is_company_account,
  data_source_name,
) => {
  var queryData = {
    consumer_key,
    redirect_url,
    product_id,
    oauth2_flow_init_url,
    data_source_name,
  };
  if (is_company_account || is_company_account == false) {
    queryData.is_company_account = is_company_account;
  }
  return new Promise(function (resolve) {
    Auth.facebookAuth(queryData);
  });
};

AuthController.gmailAuth = (
  consumer_key,
  redirect_url,
  product_id,
  oauth2_flow_init_url,
  is_company_account,
) => {
  var queryData = {
    consumer_key,
    redirect_url,
    product_id,
    oauth2_flow_init_url,
  };
  if (is_company_account) {
    queryData.is_company_account = is_company_account;
  }
  return new Promise(function (resolve) {
    Auth.gmailAuth(queryData);
  });
};

//GMB

AuthController.gmbAuth = (
  consumer_key,
  redirect_url,
  product_id,
  oauth2_flow_init_url,
  is_company_account,
) => {
  var queryData = {
    consumer_key,
    redirect_url,
    product_id,
    oauth2_flow_init_url,
  };
  if (is_company_account) {
    queryData.is_company_account = is_company_account;
  }
  return new Promise(function (resolve) {
    Auth.gmbAuth(queryData);
  });
};

AuthController.interCom = ({ token_secret, account_name }) => {
  const queryData = {
    token_secret,
    account_name,
  };
  return new Promise(function (resolve) {
    Auth.interComAuth(queryData)
      .then((response) => {
        resolve({
          response: response,
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//whatspp auth with [ Twilio ]
AuthController.whatsappAuth = (product_id, data_source_id) => {
  const queryData = {
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Auth.whatsappAuth(queryData)
      .then((response) => {
        resolve({
          response: response,
          data: response.data.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AuthController.unAuthAccount = (
  data_source,
  id,
  user_id,
  is_company_account,
) => {
  var queryData = {
    user_id,
    is_company_account,
  };
  return new Promise(function (resolve, reject) {
    Auth.unAuthAccount(data_source, id, queryData)
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AuthController.linkedInAuth = (
  consumer_key,
  redirect_url,
  product_id,
  oauth2_flow_init_url,
  companyAccount,
  data_source,
  redirectTo,
) => {
  var queryData = {
    consumer_key,
    redirect_url,
    product_id,
    oauth2_flow_init_url,
    data_source,
    redirectTo,
  };

  if (companyAccount || companyAccount === false) {
    queryData.companyAccount = companyAccount;
  }
  return new Promise(function (resolve) {
    Auth.linkedInAuth(queryData);
  });
};

AuthController.getGenesysRegions = (product_id, data_source_id) => {
  const queryData = {
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Auth.getGenesysRegions(queryData)
      .then((response) => {
        resolve({
          response: response,
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
AuthController.getGenesysInformer = (product_id, data_source_id) => {
  const queryData = {
    product_id,
    data_source_id,
  };
  return new Promise(function (resolve) {
    Auth.getGenesysInformer(queryData)
      .then((response) => {
        resolve({
          response: response,
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AuthController.genesysIntegrations = (
  product_id,
  data_source_id,
  client_id,
  client_secret,
  region_id,
) => {
  const queryData = {
    product_id,
    data_source_id,
    client_id,
    client_secret,
    region_id,
  };
  return new Promise((resolve) => {
    Auth.genesysIntegrations(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
AuthController.genesysLastStepAuth = (
  product_id,
  data_source_id,
  code_token,
  redirect_uri,
) => {
  const queryData = {
    product_id,
    data_source_id,
    code_token,
    redirect_uri,
  };
  return new Promise((resolve) => {
    Auth.genesysLastStepAuth(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

AuthController.tiktokAuth = (queryData) => {
  return new Promise((resolve) => {
    Auth.tiktokAuth(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default AuthController;
