const createImage = (file) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.setAttribute("crossOrigin", "anonymous");
    image.src = URL.createObjectURL(file);
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

async function urlToFile(url, fileName = "image.png", mimeType = "image/png") {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }
    const blob = await response.blob();
    return new File([blob], fileName, { type: mimeType });
  } catch (error) {}
}

async function convertUrlToFile(imageUrl) {
  try {
    const file = await urlToFile(imageUrl);
    return file;
  } catch (error) {}
}

export default async function getCroppedImg(
  imageFile,
  pixelCrop,
  oldLogoDetails,
  rotation = 0,
) {
  let imageSrc = imageFile;
  if (!(imageFile instanceof File)) {
    imageSrc = await convertUrlToFile(imageFile);
  }
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          let file = new File([blob], oldLogoDetails?.file_name || "image", {
            type: "image/png",
          });

          if (imageFile instanceof File) {
            file = new File([blob], imageFile?.name, { type: "image/png" });
          }
          resolve(file);
        } else {
          reject(new Error("Canvas to Blob conversion failed."));
        }
      },
      "image/png",
      0.9,
    );
  });
}
