import moment from "moment";
import { handleNameForSecondColumnLineAndBarChart } from "./exportBarChart";

/////////////////// Line chart (comparison) ////////////////////////////////////
// widgetData ===> [{data:{monitorName1_dataSource_id :[{name:unix , value: number},....] } , .....} , ..........]
// return ===> (comparison) [[date  , headerName-monitor1 , headerName-monitor2 ] , [unix , 10 , 30] , ............]
export const handleDataLineChartComparisonToExcel = (
  widgetData,
  widgetName,
  xlsLanguage,
) => {
  try {
    // dataListExcelSheet --> to push all widgetData for this widget in the one array
    const dataListExcelSheet = [[xlsLanguage?.date]];

    // to know count and name of monitors in this comparison
    const monitors = Object.keys(widgetData);

    //to set the count and name for other columns
    //need just the monitor name so used split("__")
    //ex : test__FACEBOOK__12354 ==> need "test"
    monitors?.forEach((monitor) => {
      dataListExcelSheet?.[0]?.push(
        `${xlsLanguage?.[handleNameForSecondColumnLineAndBarChart(widgetName)]} - ${monitor.split("__")?.[0]}`,
      );
    });

    //this loop to push widgetData row by row in excel sheet
    //monitors?.[0] --> used to hold the same length of array in other monitors
    //widgetData?.[monitors?.[0]] -->[{name:1732233600 , value:...} , .....]
    widgetData?.[monitors?.[0]]?.forEach((item, index) => {
      dataListExcelSheet?.push([]); // insert new record to this item before change values.

      dataListExcelSheet[index + 1][0] = moment
        .unix(item?.name)
        .utc()
        .format("YYYY-MM-DD"); // to change first value by date

      // to insert each value for each monitor in the correct index in this record
      monitors?.forEach((monitor, indexMonitor) => {
        dataListExcelSheet[index + 1][indexMonitor + 1] =
          widgetData?.[monitor]?.[index]?.value;
      });
    });

    return dataListExcelSheet;
  } catch {}
};
