import { useMutation, useQueryClient } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useUpdateCompanyInfo = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (queryData) => SettingsController?.updateCompanyInfo(queryData),
    onSuccess: () =>
      queryClient?.invalidateQueries({ queryKey: ["companyInfo"] }),
  });
};
