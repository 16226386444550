import {
  handleAddAggregate,
  handleTranslatSubThemes,
} from "../sharedFaunctionExcel";

/////////////////// ThemesWidget (aggregate && No customize) ////////////////////////////////////
// widgetData ( ===> { complaint:{count:number , (sub_themes/main_themes):{payment:20 , .....} ,
//                     complaint:{...............}}}
// return ===> (aggregate- defualt) ---->  [[ category/themes , ...(labelSubthemes/labelThemes)] , [ (labelThemes/labelSubthemes) , ......] , ............]
export const handleThemesWidgetToExcel = (
  widgetData,
  widgetName,
  xlsLanguage,
  customizedType,
  themesOptions,
) => {
  try {
    const dataListExcelSheet = [[xlsLanguage?.[widgetName]]];
    //if excel sheet for category , subThemes is hold "sub_themes"
    //if excel sheet for themes , subThemes is hold "main_themes"
    const subThemesTableName =
      widgetName === "widget_category" ? "sub_themes" : "main_themes";

    const listMainThemes = Object?.keys(widgetData); //to hold mainThemes name for first column
    const listSubThemes = []; //to hold subThemes name for first Row

    //this loop for hold the all subThemes name in array "listSubThemes"
    Object?.values(widgetData)?.forEach((value) => {
      Object?.entries(value)?.forEach(([key, subValue]) => {
        if (key === subThemesTableName) {
          const newLisSubThemes = Object?.keys(subValue);
          newLisSubThemes?.forEach((subtheme) => {
            if (!listSubThemes?.includes(subtheme)) {
              listSubThemes?.push(subtheme);
            }
          });
        }
      });
    });

    //this loop to push header name for first row in excel sheet
    listSubThemes?.forEach((subtheme) => {
      // handleTranslatSubThemes --> subThemes translation depend on backend.
      let nameHeader = handleTranslatSubThemes(
        themesOptions,
        subtheme,
        xlsLanguage,
      );
      // handleAddAggregate---> to check if customizedType is aggregate , it will add "aggreagte to name"
      dataListExcelSheet?.[0]?.push(
        handleAddAggregate(nameHeader, customizedType, xlsLanguage),
      );
    });
    //to push total in the final column for first row
    dataListExcelSheet?.[0]?.push(
      handleAddAggregate(xlsLanguage?.total, customizedType, xlsLanguage),
    );

    // this loop to push data in array for excel sheet
    //newRow --> to push the name of main theme in the first column
    listMainThemes?.forEach((mainThems) => {
      const newRow = [
        handleTranslatSubThemes(themesOptions, mainThems, xlsLanguage),
      ];
      //to push each value for this subTheme in this mainTheme if not fount push zero
      listSubThemes?.forEach((subTheme) => {
        newRow?.push(
          widgetData?.[mainThems]?.[subThemesTableName]?.[subTheme] || 0,
        );
      });
      newRow?.push(widgetData?.[mainThems]?.count); // to add total for this row

      dataListExcelSheet?.push(newRow); // to add row for final array for excel sheet
    });

    return dataListExcelSheet;
  } catch {}
};

/////////////////// ThemesWidget Comparison ////////////////////////////////////
// widgetData ( ===> {  data: [{ name:"monitorNAme" , value:[.....]}, {....}, .... ]
//                      pie_data : [ {name:"label" , value: totalLabel } , {....} , ... ]  }
// return ===> (Comparison) ---->  [[ category/themes , value-m1 , value-m2 , ... ] , [ (labelThemes/labelSubthemes) , ......] , ............]
export const handleThemesWidgetComparisonToExcel = (
  widgetData,
  widgetName,
  xlsLanguage,
  themesOptions,
) => {
  try {
    const dataListExcelSheet = [[xlsLanguage?.[widgetName]]];

    //to push header name for Each column in the first row "value - monitorName"
    widgetData?.data?.forEach((monitor) => {
      dataListExcelSheet?.[0]?.push(`${xlsLanguage?.value} - ${monitor?.name}`);
    });

    //this loop to insert new row for each theme .
    //"handleTranslatSubThemes" to push theme name in the first column
    //widgetData?.data ---> [{name: "monitor1" , value:[5(themes1) , 3(themes2) , 10(themes3)]}]
    widgetData?.pie_data?.forEach((themes, index) => {
      const newRow = [
        handleTranslatSubThemes(themesOptions, themes?.name, xlsLanguage),
      ];
      widgetData?.data?.forEach((monitor) => {
        newRow?.push(monitor?.value?.[index]);
      });
      dataListExcelSheet?.push(newRow);
    });

    return dataListExcelSheet;
  } catch {}
};
