import { useQuery } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";

export const useAiAgentPublishedVersionDetails = (publishedVersionId) => {
  return useQuery({
    queryKey: ["publishedVersionDetails", publishedVersionId],
    queryFn: () =>
      AiAgentsServices.getPublishedVersionDetails(publishedVersionId),
    enabled: !!publishedVersionId,
  });
};
