import { useQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useTeamList = (assignmentManualAssign) => {
  return useQuery({
    queryKey: ["teamList", assignmentManualAssign],
    queryFn: () => CompaniesController.getAllCompanyTeams(),
    select: (data) => data?.data?.teams,
    enabled: assignmentManualAssign,
  });
};
