import { useMutation } from "@tanstack/react-query";
import EngagementsController from "services/controllers/engagementsController";

export const useCreateSLA = () => {
  return useMutation({
    mutationFn: ({ queryData }) => EngagementsController.createSLA(queryData),
    select: (data) => data?.data,
    retry: false,
  });
};
