import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import "./teamsSharedStyle.scss";
import TeamGroupIcon from "images/shared-images/team-group.svg";
import { SelectableCard } from "./selectableCard";
import DefaultUser from "images/shared-images/user-management-default.svg";
import { Link } from "react-router-dom";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import UserAvatar from "shared/unifiedUserImage/userAvatar";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";

export const MemberCard = ({
  member = {},
  isSelected,
  handleSelectMember,
  selectable,
}) => {
  let { first_name, last_name, email, avatar, id, user_teams } = member;
  let name = member?.name
    ? member?.name
    : (first_name || "") + " " + (last_name || "");

  return (
    <SelectableCard
      selectable={selectable}
      isSelected={isSelected}
      onSelect={() => {
        handleSelectMember(id);
      }}
    >
      <Box className="member-card-info">
        <UserAvatar
          fullName={name}
          avatarSrc={avatar}
          sizes={{ ratio: "50px", fontSize: "16px" }}
        />
        <Box className="member-card-info-text">
          <Box>
            <Typography variant="h6" className="member-card-name">
              {name?.length > 25 ? (
                <Tooltip title={name} placement="top" arrow>
                  {`${name?.slice(0, 23)}...`}
                </Tooltip>
              ) : (
                name
              )}
            </Typography>
            <Typography variant="body2" className="member-card-email">
              {email?.length > 25 ? (
                <Tooltip title={email} placement="top" arrow>
                  {`${email?.slice(0, 23)}...`}
                </Tooltip>
              ) : (
                email
              )}
            </Typography>
          </Box>
          {user_teams?.length > 0 ? (
            <Box className="member-card-teams">
              <img
                src={TeamGroupIcon}
                alt="team"
                className="member-card-team-icon"
              />
              {user_teams?.slice(0, 1)?.map((team) => (
                <Typography
                  variant="body2"
                  key={team?.id}
                  className="member-card-team"
                >
                  {team?.name?.length > 18 ? (
                    <Tooltip title={name} placement="top" arrow>
                      {`${team?.name?.slice(0, 16)}...`}
                    </Tooltip>
                  ) : (
                    team?.name
                  )}
                </Typography>
              ))}
              {user_teams?.length > 1 ? (
                <CustomizedTooltip
                  title={user_teams
                    ?.slice(1)
                    ?.map((team) => team?.name)
                    .join(", ")}
                  placement="top"
                  arrow
                  styles={customizedStyledTooltip}
                  className={"teams-members-preview-tooltip"}
                >
                  <Typography variant="body2" className="member-card-team">
                    +{user_teams?.length - 1}
                  </Typography>
                </CustomizedTooltip>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Box>
    </SelectableCard>
  );
};

export const TeamMembers = ({
  members,
  handleSelectMember,
  selectedMembers,
  selectable = true, // if selectable is false, this means we are in the view team page
  title = "select_members",
  overallCount = 0,
}) => {
  const intl = useIntl();
  const selectedCounter =
    intl?.locale === "ar"
      ? `(${overallCount}/${selectedMembers?.length})`
      : `(${selectedMembers?.length}/${overallCount})`;

  return (
    <Box className="team-information-container">
      {selectable ? (
        <Typography className="team-information-label">
          {CheckValueLocale(title, "", {}, intl)}
          {members?.length > 0 ? ` ${selectedCounter}` : null}
        </Typography>
      ) : null}
      {members?.length > 0 ? (
        <Grid
          className="team-members-container"
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {members?.map((memberObj) => {
            const member = memberObj?.attributes;
            const isSelected = selectedMembers?.includes(member?.id);
            return (
              <Grid
                item
                xs={12}
                sm={6}
                lg={4}
                key={member?.id}
                className="team-members-container-item"
              >
                <MemberCard
                  member={member}
                  handleSelectMember={handleSelectMember}
                  isSelected={isSelected}
                  selectable={selectable}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box>
          <Typography className="empty-members-label">
            {CheckValueLocale("empty_members", "", {}, intl)}
          </Typography>
          <Link to="/settings/user_managements/add" className="add-user-link">
            <LucButton
              variant="outline"
              startIcon={<FontAwesomeIcon icon={faPlus} />}
            >
              {CheckValueLocale("new_user", "", {}, intl)}
            </LucButton>
          </Link>
        </Box>
      )}
    </Box>
  );
};
