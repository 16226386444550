import { useQuery } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";

export const useAiAgentList = () => {
  return useQuery({
    queryKey: ["aiAgentList"],
    queryFn: () => AiAgentsServices.getAiAgentList(),
    select: (data) => data?.data,
  });
};
