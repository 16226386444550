import { handleSentimentResponse } from "utils/helpers";
import { getPieDataReachNB } from "../statsDataHandling";

export const newsBlogsRespHandling = (
  response,
  handleWidgetResponse,
  dataSource,
) => {
  const eventNameObj = {
    Talkwalker__EngagementsPage__top_engaged_sites: {
      value: (response) => ({
        pie_data: response?.top_engaged_sites,
      }),
    },
    Talkwalker__EngagementsPage__top_sites: {
      value: (response) => ({
        pie_data: response?.top_sites,
      }),
    },
    Talkwalker__EngagementsPage__sentiment_analysis: {
      value: (response) => ({
        data: handleSentimentResponse(response?.sentiment_overtime),
        pie_data: response?.sentiment_piechart,
      }),
    },
    Talkwalker__EngagementsPage__top_languages: {
      value: (response) => ({
        pie_data: response?.top_languages,
      }),
    },
    Talkwalker__EngagementsPage__top_countries: {
      value: (response) => ({
        pie_data: response?.top_countries,
      }),
    },
    Talkwalker__EngagementsPage__volume_overtime: {
      value: (response) => ({
        data: response?.posts_over_time,
        total_count: response?.total_posts_count,
      }),
    },
    Talkwalker__EngagementsPage__reach_funnel: {
      value: (response) => ({
        data: response,
        pie_data: getPieDataReachNB(response),
      }),
    },
  };

  const eventNameKey = eventNameObj?.[response?.eventName];
  if (eventNameKey) {
    handleWidgetResponse(
      response?.metric_id,
      response?.monitor_id,
      dataSource,
      eventNameKey?.value(response?.eventData),
      response?.customized_metric_id,
    );
  }
};
