import { useQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useGetCompanySkills = () => {
  return useQuery({
    queryKey: ["companySkills"],
    queryFn: () => CompaniesController.getCompanySkills(),
    retry: false,
  });
};
