import { useQuery } from "@tanstack/react-query";
import EngagementsController from "services/controllers/engagementsController";

export const useRoutingAlgorithmList = () => {
  return useQuery({
    queryKey: ["routingAlgorithmList"],
    queryFn: () => EngagementsController.getRoutingAlgorithms(),
    select: (data) =>
      data?.data?.data?.map?.((algo) => {
        const { name, id } = algo?.attributes || {};
        const algoName = name?.toLowerCase()?.split(" ")?.join("_");
        return {
          id: id,
          name: "routing_" + algoName,
          desc: "routing_" + algoName + "_desc",
        };
      }),
  });
};
