import { CheckValueLocale } from "utils/helpers";

export const generateHistoricalTableHeader = (sortedColumnTypes, intl) => {
  return [
    {
      body: CheckValueLocale(
        "historical_request_table_header_id",
        "",
        {},
        intl,
      ),
      width: "6.85%",
      name: "id",
      type: sortedColumnTypes?.number,
    },
    {
      body: CheckValueLocale(
        "historical_request_table_header_product",
        "",
        {},
        intl,
      ),
      width: "7.14%",
      name: "product",
      type: sortedColumnTypes?.string,
    },
    {
      body: CheckValueLocale(
        "historical_request_table_header_requester",
        "",
        {},
        intl,
      ),
      width: "17.66%",
      name: "requested_by",
      type: sortedColumnTypes?.string,
    },
    {
      body: CheckValueLocale(
        "historical_request_table_header_requested_to",
        "",
        {},
        intl,
      ),
      width: "11.68%",
      name: "requested_to",
      type: sortedColumnTypes?.string,
    },
    {
      body: CheckValueLocale(
        "historical_request_table_header_date_range",
        "",
        {},
        intl,
      ),
      width: "11.20%",
      name: "date_range",
      type: sortedColumnTypes?.date,
    },
    {
      body: CheckValueLocale(
        "historical_request_table_header_collected_data",
        "",
        {},
        intl,
      ),
      width: "13.51%",
      name: "data_collected",
      type: sortedColumnTypes?.number,
    },
    {
      body: CheckValueLocale(
        "historical_request_table_header_duplicate_data",
        "",
        {},
        intl,
      ),
      width: "13.42%",
      name: "duplicate_data",
      type: sortedColumnTypes?.number,
    },
    {
      body: CheckValueLocale(
        "historical_request_table_header_status",
        "",
        {},
        intl,
      ),
      width: "12.07%",
      name: "status",
      type: sortedColumnTypes?.string,
    },
    {
      body: " ",
      width: "6.47%",
    },
  ];
};
