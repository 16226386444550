import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { useIntl } from "react-intl";
import Header from "../header/header";
import ApisList from "../apisList/apisList";
import CircularLoading from "components/circularProgress";

import { CheckValueLocale } from "utils/helpers";
import SnackBar from "components/snackBar";
import CreateApi from "../createApi/createApi";
import { LucidyaApisContext } from "../lucidyaApisContext";
import ApiActions from "../apiActions/apiActions";
import { useGetLucidyaApisList } from "../../hooks/useGetLucidyaApisList";
import { Box } from "@mui/material";

const Content = ({ id }) => {
  const intl = useIntl();
  const [success, setSuccess] = useState(null);
  const [snackBarHeading, setSnackBarHeading] = useState("");
  const [openCreateApiDialog, setOpenCreateApiDialog] = useState(false);
  const [openApiActionsDialog, setOpenApiActionsDialog] = useState(false);
  const [apiDialogType, setApiDialogType] = useState("");
  const [apiDialogItem, setApiDialogItem] = useState("");
  const [page, setPage] = useState(1);

  const handleCreateApiDialog = () =>
    setOpenCreateApiDialog(!openCreateApiDialog);
  const handleApiActionsDialog = () =>
    setOpenApiActionsDialog(!openApiActionsDialog);

  const {
    data: dataLucidyaApisList,
    isPending: loading,
    isError: isErrorLucidyaApisList,
  } = useGetLucidyaApisList(page, id);

  useEffect(() => {
    if (isErrorLucidyaApisList) {
      setSnackBarHeading("connection_failed");
      setSuccess(false);
    }
  }, [isErrorLucidyaApisList]);

  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };

  return (
    <LucidyaApisContext.Provider
      value={{
        setSuccess,
        setSnackBarHeading,
        handleCreateApiDialog,
        handleApiActionsDialog,
        setApiDialogType,
        apiDialogType,
        setApiDialogItem,
        apiDialogItem,
        apisListData: dataLucidyaApisList?.message?.data,
        page,
        setPage,
      }}
    >
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <Header
            tokenLimitReached={
              dataLucidyaApisList?.token_limit_reached !== undefined
                ? dataLucidyaApisList?.token_limit_reached
                : true
            }
          />
          <ApisList apisListData={dataLucidyaApisList?.message?.data} />
          {dataLucidyaApisList?.pagy?.pages > 1 ? (
            <Pagination
              onChange={handleChangePage}
              page={page}
              className="pagination-monitor-list settings-page-content-pagination"
              count={dataLucidyaApisList?.pagy?.pages}
              variant="outlined"
            />
          ) : null}
        </>
      )}
      {openCreateApiDialog ? (
        <CreateApi
          open={openCreateApiDialog}
          apisListData={dataLucidyaApisList?.message?.data}
        />
      ) : null}
      {openApiActionsDialog ? <ApiActions open={openApiActionsDialog} /> : null}
      {success != null ? (
        <SnackBar
          open={success != null ? true : false}
          handleClose={() => {
            setSuccess(null);
          }}
          severity={success ? "success" : "error"}
          message={
            <Box className="lucidya-apis-snackbar-wrapper">
              <Box component={"span"} className="lucidya-apis-snackbar-heading">
                {CheckValueLocale(snackBarHeading, "", {}, intl)}
              </Box>
            </Box>
          }
        />
      ) : (
        ""
      )}
    </LucidyaApisContext.Provider>
  );
};
export default Content;
