import { Grid } from "@mui/material";
import WidgetComponent from "./widgetComponent";
import { useContext, useEffect } from "react";
import { ExcelFileContext } from "../../components/exportButton/excel/context/ExcelFileContext";
import { handleDefualtFileExcelData } from "../../components/exportButton/excel/generateExcelFuncDahshoard";

const WidgetsContainer = (props) => {
  const { setFileExcelData } = useContext(ExcelFileContext);
  const {
    dashboardInfo,
    dashboardWidgets,
    widgetsMqttResponses,
    getCustomDashboardView,
    setSnackBarData,
    customizedWidgets,
  } = props;

  useEffect(() => {
    // to set defualt value for each widget in dashboard with order.
    handleDefualtFileExcelData(dashboardWidgets, setFileExcelData);
  }, [dashboardWidgets]);

  return (
    <Grid id="custom-dashboard-view-widgets-container" container>
      {dashboardWidgets
        ?.sort(
          (a, b) => (a?.attributes?.order ?? 0) - (b?.attributes?.order ?? 0),
        )
        ?.map((widget) => {
          return (
            <WidgetComponent
              key={widget?.id}
              widget={widget?.attributes}
              dashboardInfo={dashboardInfo}
              widgetsMqttResponses={widgetsMqttResponses}
              customizedWidgets={customizedWidgets}
              getCustomDashboardView={getCustomDashboardView}
              setSnackBarData={setSnackBarData}
            />
          );
        })}
    </Grid>
  );
};

export default WidgetsContainer;
