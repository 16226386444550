import { useQuery } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";

export const useCountries = () => {
  return useQuery({
    queryKey: ["countries"],
    queryFn: () => AiAgentsServices.getCountries(),
    select: (data) => data?.data,
  });
};
