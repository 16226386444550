// Common file used in whole app  !
// Don't repeat/update or remove this file!

import { useState, useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useScrollTrigger } from "@mui/material";
import {
  MenuList,
  MenuItem,
  Popover,
  TextField,
  ListItemText,
  Button,
  styled,
  Box,
} from "@mui/material";
import { useIntl } from "react-intl";
import moment from "moment";

import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { CheckValueLocale, delayFunction } from "utils/helpers";
import { handleSelectedDate } from "./dateRagePicker.hooks.js";
import "./dateRangePicker.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarRange } from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";
import Services from "services/api/Services.js";

const DateRangePicker = (props) => {
  const intl = useIntl();
  const [isDropOpen, setIsDropOpen] = useState(false);

  var PICKER_STATE = {
    TODAY: "today",
    YESTERDAY: "yesterday",
    LAST_7_DAYS: "last_7_days",
    LAST_15_DAYS: "last_15_days",
    LAST_30_DAYS: "last_30_days",
    CUSTOM: props?.isLiveDashboardPublic
      ? props?.customLiveDashboardPublic
      : "custom_dates",
    NO_RANGE: "select_date_range", // To add no range case for first time with flag noTimeApplied
  };
  if (props.allTimeEnabled) {
    PICKER_STATE = {
      ALL_TIME: "all_time",
      ...PICKER_STATE,
    };
  }
  if (props?.isUserActivity) {
    PICKER_STATE = {
      TODAY: "today",
      YESTERDAY: "yesterday",
      CUSTOM: "custom_dates",
    };
  }
  if (props.lastHourEnabled) {
    PICKER_STATE = {
      LAST_HOUR: "last_hour",
      ...PICKER_STATE,
    };
  }
  const eDate = moment();
  const [customDateValue, setCustomDateValue] = useState([null, null]);
  const [pickerState, setPickerState] = useState(
    props?.allTimeEnabled
      ? PICKER_STATE.ALL_TIME
      : props?.noTimeApplied // To show no range message for first time with flag noTimeApplied
        ? PICKER_STATE.NO_RANGE
        : props?.defaultDateRange
          ? props?.defaultDateRange
          : props.isUserActivity
            ? PICKER_STATE.TODAY
            : PICKER_STATE.LAST_7_DAYS,
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const [pickerStateApply, setPickerStateApply] = useState(
    props?.allTimeEnabled
      ? PICKER_STATE.ALL_TIME
      : props?.noTimeApplied // To show no range message for first time with flag noTimeApplied
        ? PICKER_STATE.NO_RANGE
        : props?.defaultDateRange
          ? props?.defaultDateRange
          : PICKER_STATE.LAST_7_DAYS,
  );
  const startCustomDate = moment(customDateValue[0])
    .tz("utc", true)
    .format("DD/MM/YYYY");
  const endCustomDate = moment(customDateValue[1])
    .tz("utc", true)
    .format("DD/MM/YYYY");

  useEffect(() => {
    let startDate;
    let endDate;

    if (
      pickerState === "Last 7 Days" ||
      pickerState === "آخر ٧ أيام" ||
      pickerState === PICKER_STATE.LAST_7_DAYS
    ) {
      startDate = moment().tz("utc", true).subtract(6, "d").startOf("day");
      endDate = moment().tz("utc", true);
      handleApply();
    } else if (pickerState === PICKER_STATE.TODAY) {
      startDate = moment().tz("utc", true).startOf("day");
      endDate = moment().tz("utc", true);
      handleApply();
    } else if (pickerState === PICKER_STATE.YESTERDAY) {
      startDate = moment().tz("utc", true).subtract(1, "d").startOf("day");
      endDate = moment().tz("utc", true).subtract(1, "d").endOf("day");
      handleApply();
    } else if (pickerState === PICKER_STATE.LAST_15_DAYS) {
      startDate = moment().tz("utc", true).subtract(14, "d").startOf("day");
      endDate = moment().tz("utc", true);
      handleApply();
    } else if (pickerState === PICKER_STATE.LAST_30_DAYS) {
      startDate = moment().tz("utc", true).subtract(29, "d").startOf("day");
      endDate = moment().tz("utc", true);
      handleApply();
    } else if (pickerState === PICKER_STATE.ALL_TIME) {
      startDate = moment.unix(parseInt(props.allTimeStartDate)).tz("utc", true);
      endDate = moment().tz("utc", true);
      handleApply();
    } else if (pickerState === PICKER_STATE.LAST_HOUR) {
      startDate = moment().subtract(1, "hours");
      endDate = moment();
      handleApply();
    } else if (pickerState === props?.customLiveDashboardPublic) {
      handleApply();
    } else if (pickerState === props?.noTimeApplied) {
      // To remove defaults of startDate and endDate for first render
      startDate = undefined;
      endDate = undefined;
      handleApply();
    } else {
      startDate = moment(customDateValue[0]).startOf("day");
      endDate =
        moment(customDateValue[1]).startOf("day") ||
        moment().tz("utc", true).startOf("day");
    }

    setCustomDateValue([startDate, endDate]);
  }, [pickerState, CheckValueLocale(pickerState, "", {}, intl)]);

  useEffect(() => {
    if (pickerState == "custom_dates") {
      handleApply();
    }
  }, [intl.locale]);

  useEffect(() => {
    document.body.classList.toggle("bodyhidden", isDropOpen);
  }, [isDropOpen]);

  useEffect(() => {
    if (pickerState == "custom_dates") {
      handleApply();
    }
  }, [intl.locale]);

  useEffect(() => {
    if (props.resetCalendar !== undefined && props.resetCalendar !== 1) {
      setPickerState(PICKER_STATE.LAST_7_DAYS);
    }
  }, [props.resetCalendar]);

  useEffect(() => {
    if (props?.defaultDateRange === "custom_dates") {
      let newStartDateValue = moment.unix(props?.startDate);
      let newEndDateValue = moment.unix(props?.endDate - 86400);
      setCustomDateValue([newStartDateValue, newEndDateValue]);
      setPickerStateApply(
        `${newStartDateValue?.format("DD/MM/YYYY")} - ${newEndDateValue?.format("DD/MM/YYYY")}`,
      );
    }
  }, [props?.defaultDateRange]);
  useEffect(() => {
    // In case of show, we need to set custom dates before set picker state
    if (
      PICKER_STATE[props?.exploreInitialDate] === PICKER_STATE.CUSTOM &&
      props?.isExplorePage
    ) {
      let newStartDateValue = moment.unix(props?.startDate);
      let newEndDateValue = moment.unix(props?.endDate - 86400);
      setCustomDateValue([newStartDateValue, newEndDateValue]);
      setPickerState(PICKER_STATE[props?.exploreInitialDate]);
      let startCustomDate = moment.unix(props?.startDate).format("DD/MM/YYYY");
      let endCustomDate = moment
        .unix(props?.endDate - 86400)
        .format("DD/MM/YYYY");
      setPickerStateApply(`${startCustomDate} - ${endCustomDate}`);
    }
  }, [props?.exploreInitialDate]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPickerState(pickerStateApply);
    props?.isExplorePage && props?.setDisableCreateExplore(false);
  };

  const handleClose = () => {
    setIsDropOpen(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "date-picker-popover" : undefined;

  const handleApply = () => {
    handleSelectedDate(
      pickerState,
      customDateValue,
      handleDateSubmit,
      props.allTimeStartDate,
      props.lastHourEnabled,
    );
    if (pickerState == PICKER_STATE.CUSTOM) {
      setCustomDateValue([customDateValue[0], customDateValue[1]]);
      if (props?.isLiveDashboardPublic) setPickerStateApply(pickerState);
      else setPickerStateApply(`${startCustomDate} - ${endCustomDate}`);
    } else {
      setPickerStateApply(pickerState);
    }

    delayFunction(50).then(() => {
      handleClose();
    });
  };

  const handleDateSubmit = (startDate, endDate) => {
    if (props.applyFilter !== undefined)
      if (props?.isLiveDashboard)
        props.applyFilter(startDate, endDate, pickerState);
      else props.applyFilter(startDate, endDate);
  };
  //explore page
  const trigger = useScrollTrigger();
  useEffect(() => {
    if (props?.isExplorePage) handleClose();
  }, [trigger]);
  useEffect(() => {
    if (props?.isExplorePage) {
      setPickerState(PICKER_STATE.LAST_7_DAYS);
    }
  }, [props?.isDatePickerChange]);
  const checkDay = new Date();
  useEffect(() => {
    if (props?.getSelectedDate) {
      props?.getSelectedDate(pickerStateApply);
    }
  }, [pickerStateApply]);
  const custemDate = () => {
    if (
      pickerState == PICKER_STATE.CUSTOM ||
      pickerState == `${startCustomDate} - ${endCustomDate}`
    ) {
      const customDateValueDayjs = [
        dayjs(customDateValue?.[0]?._d || customDateValue?.[0]),
        dayjs(customDateValue?.[1]?._d || customDateValue?.[1]),
      ];
      return (
        <Box pb={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CustomDatePicker
              disableHighlightToday
              displayStaticWrapperAs="desktop"
              value={customDateValueDayjs}
              maxDate={dayjs(eDate?._d)}
              minDate={
                props?.hasKeyword && Services.enableExtraSources
                  ? dayjs()?.subtract(30, "day")
                  : props?.oldestDate
                    ? dayjs(moment(props?.oldestDate)?._d)
                    : dayjs.unix("330421949")
              }
              onChange={(value) => {
                const newValue = [value?.[0]?.$d, value?.[1]?.$d];
                setPickerState(PICKER_STATE.CUSTOM);
                newValue[1] == undefined
                  ? setCustomDateValue([newValue[0], newValue[0]])
                  : setCustomDateValue(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </>
              )}
              id={`date-range-picker-${customDateValue ?? "custom-date-value"}`}
            />
          </LocalizationProvider>

          <Box pl={2} display="flex">
            <Button
              variant="contained"
              onClick={() => {
                handleApply();
                props?.isExplorePage &&
                  props?.activeExploreStep === 3 &&
                  props?.setDisableCreateExplore(false);
              }}
              className="apply__date"
              id="date-range-picker-apply-monitor-active-tab-id-date"
            >
              {CheckValueLocale("apply", "", {}, intl)}
            </Button>

            <Box sx={{ mx: 1 }} />
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{
                backgroundColor: "#E2E8F0",
                color: "#64748B",
                borderColor: "#E2E8F0",
                fontWeight: 400,
              }}
              id={`date-range-picker-cancel-${
                props?.monitorActiveTabId ?? "monitor-active-tab-id"
              }-date`}
            >
              {CheckValueLocale("cancel", "", {}, intl)}
            </Button>
          </Box>
        </Box>
      );
    }
  };

  return (
    <>
      <div
        className={`date-field ${props?.isActive !== false ? "active-date" : ""}`}
        id={props?.dateRangePickerID ?? "date-range-picker-id"}
        onClick={props?.isActive !== false && handleClick}
      >
        <FontAwesomeIcon icon={faCalendarRange} />

        <CustomField
          value={CheckValueLocale(pickerStateApply, "", {}, intl)}
          className="dateRange"
          inputProps={{ readOnly: true }}
          disabled={props?.isActive === false ? true : false}
        />
      </div>

      <CustomPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={
          "luc-date-picker " +
          props.smallBoxCustom +
          (pickerState == PICKER_STATE.CUSTOM && props.datePickerPosition) //this is extra className sent as a prop from main file to adjust position of menu opened
        }
      >
        <Box display="flex">
          {props.openCustomDateLeft === true && custemDate()}

          <Box width={170} className="date-list">
            <CustomMenuList>
              {Object.values(PICKER_STATE)
                ?.filter((item) => item !== "select_date_range") // To hide select_date_range item from dropdown menu
                ?.map((key, index) => (
                  <CustomMenuItem
                    disabled={
                      props?.isUserActivity &&
                      moment(checkDay)?.unix() <=
                        moment(props?.oldestDate)?.unix() &&
                      key !== "today"
                        ? true
                        : false
                    }
                    key={index}
                    selected={
                      pickerState === `${startCustomDate} - ${endCustomDate}`
                        ? key == PICKER_STATE.CUSTOM
                        : key === pickerState
                    }
                    onClick={() => {
                      setPickerState(key);
                      props?.defaultDateRange &&
                        props?.setDefaultDateRange(key);
                    }}
                    sx={{
                      height:
                        key == props?.customLiveDashboardPublic
                          ? "55px"
                          : "41px",
                    }}
                    id="date-range-picker-state-monitor-active-tab-id"
                  >
                    <ListItemText id="date-range-item-monitor-active-tab-id">
                      {CheckValueLocale(key, "", {}, intl)}
                    </ListItemText>
                  </CustomMenuItem>
                ))}
            </CustomMenuList>
          </Box>
          {props.openCustomDateRight === true && custemDate()}
        </Box>
      </CustomPopover>
    </>
  );
};

export default DateRangePicker;

const CustomMenuList = styled(MenuList)`
  .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
    min-height: auto;
    border-bottom: 1px solid #e2e8f0 !important;
  }
  .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root:last-child {
    border-bottom: 0 !important;
  }
  .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root.Mui-selected {
    background-color: #456eb2 !important;
    span {
      color: #fff;
    }
  }
`;
const CustomField = styled(TextField)`
  height: 41px;
  .MuiInputBase-root {
    font-size: 13px;
    background-color: #fff;
    border: 1px solid rgba(112, 112, 112, 0.2);
    font-weight: 400;
    color: #000;
    height: 41px;
    margin: 0px 20px;
    cursor: pointer;
    outline: 0;
    border: 0;
  }
  .MuiInputBase-root:focus {
    outline: 0;
    border: 0;
  }
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    cursor: pointer;
    outline: 0;
    border: 0;
  }
`;
const CustomMenuItem = styled(MenuItem)`
  .css-1uzh8eb-MuiTypography-root {
    color: #475569;
    font-size: 15px !important;
    font-weight: 400;
  }
  .ul {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
const CustomPopover = styled(Popover)`
  .MuiPopover-paper {
    box-shadow: 0px 0px 4px 0px #e2e8f0;
    border: 1px solid #e2e8f0;
    /* top: 130px !important; */
  }
`;
const CustomDatePicker = styled(StaticDateRangePicker)`
  .css-mvmu1r {
    margin: 0;
  }
  .css-14hwa9t-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected,
  .css-xgq401-MuiDateRangePickerDay-root:last-of-type,
  .css-xgq401-MuiDateRangePickerDay-root:first-of-type {
    border-radius: 0;
    color: rgba(249, 245, 245, 0.87);
  }
  .css-14hwa9t-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected {
    background-color: #397fbb;
    color: #fff;
  }
  .css-1m9erir-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:hover {
    border: none;
    border-radius: 0;
    background: #ebf4f8;
  }
  .css-1mn81ta-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:hover {
    background-color: transparent;
  }
  .css-xgq401-MuiDateRangePickerDay-root,
  .css-vwcpoq-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected,
  .css-vwcpoq-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:focus.Mui-selected,
  .css-1m9erir-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:focus {
    background-color: #ebf4f8;
    border-radius: 0;
    border: none;
  }

  .css-1m9erir-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day,
  .css-1mn81ta-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day,
  .css-14hwa9t-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day.Mui-selected {
  }
  .css-9ay3a3-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:not(
      .Mui-selected
    ),
  .css-14hwa9t-MuiButtonBase-root-MuiPickersDay-root-MuiDateRangePickerDay-day:focus.Mui-selected {
    background-color: #397fbb;
    color: #fff;
    border: none;
    border-radius: 0;
  }
  .css-7fdq88-MuiTypography-root,
  .css-1o490ea-MuiDateRangePickerDay-rangeIntervalPreview {
  }
  .css-fcs31k-MuiTypography-root {
    color: #475569;
    font-size: 15px;
  }
  .css-7fdq88-MuiTypography-root {
    color: #475569;
    font-size: 13px;
    font-weight: 600;
  }
  .PrivatePickersSlideTransition-root.css-170k9md {
    min-height: 250px;
  }
  .css-1tape97:not(:last-of-type) {
    border: none;
  }
  .MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight.MuiDateRangePickerDay-rangeIntervalDayHighlightStart {
    background-color: #ebf4f8;
    border-radius: 0;
  }
  .MuiButtonBase-root.MuiPickersDay-root.MuiDateRangePickerDay-day.MuiDateRangePickerDay-notSelectedDate.MuiDateRangePickerDay-dayInsideRangeInterval {
    background-color: #ebf4f8;
    border-radius: 0;
  }
  .css-f7iyql {
    padding-bottom: 0px;
    /* height: 30px; */
  }
  .css-1n2mv2k {
    padding-bottom: 0px;
    /* height: 30px; */
  }
  /* .MuiDateRangePickerDay-root {
    width: 30px;
    height: 30px;
    .MuiButtonBase-root {
    }
  } */
`;
