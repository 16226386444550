import Engagements from "../api/engagements.js";

var EngagementsController = {};

EngagementsController.getSavedMonitors = (id) => {
  let queryData = {
    product_id: id,
  };
  return new Promise(function (resolve) {
    Engagements.getSavedMonitors(queryData)
      .then((response) => {
        resolve({
          data: response?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getSingleInteraction = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.getSingleInteraction(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getInteractionDmHistory = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.getInteractionDmHistory(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getEngagementsData = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.getEngagementsData({
      ...queryData,
      filters: JSON.stringify(queryData?.filters),
    })
      .then((response) => {
        resolve({
          data: response?.data?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.putSavedMonitors = (monitors, channels, product_id) => {
  let queryData = {
    saved_monitors: {
      monitors,
      channels,
    },
  };
  return new Promise(function (resolve) {
    Engagements.putSavedMonitors(queryData, product_id)
      .then((response) => {
        resolve({
          data: response || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.editInteractions = (queryData) => {
  return new Promise((resolve, reject) => {
    Engagements.editInteractions(queryData)
      .then((response) => {
        resolve({ data: response || [] });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

EngagementsController.getMonitorsAndChannels = (id) => {
  let queryData = {
    product_id: id,
  };
  return new Promise(function (resolve) {
    Engagements.getMonitorsAndChannels(queryData)
      .then((response) => {
        resolve({
          data: response?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getCompletionReasons = (id) => {
  let queryData = {
    product_id: id,
  };
  return new Promise(function (resolve) {
    Engagements.getCompletionReasons(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.enabledFilters = (id) => {
  let queryData = {
    product_id: id,
  };
  return new Promise(function (resolve) {
    Engagements.enabledFilters(queryData)
      .then((response) => {
        resolve({
          data: response?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
EngagementsController.updateStatus = (queryData) => {
  // let queryData = {
  //   product_id,
  //   monitor_id,
  //   data_source_name,
  //   interaction_id,
  //   status,
  // };
  return new Promise(function (resolve) {
    Engagements.updateStatus(queryData)
      .then((response) => {
        resolve({
          data: response || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
EngagementsController.getSingleProfileData = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.getSingleProfileData(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getSingleUserProfileData = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.getSingleUserProfileData(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getCalendarData = (
  start_date,
  end_date,
  filters,
  product_id,
) => {
  let queryData = {
    start_date,
    end_date,
    filters: JSON.stringify(filters),
    product_id,
  };
  return new Promise(function (resolve) {
    Engagements.getCalendarData(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.uploadFile = (formDataFile) => {
  return new Promise(function (resolve) {
    Engagements.uploadFile(formDataFile)
      .then((response) => {
        resolve({
          data: response || [],
        });
      })
      .catch((error) => {
        resolve({
          data: error,
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getAiReplaies = (
  industry,
  business_type,
  business_location,
  brand_voice,
  ai_company_knowledge,
  knowledge_base_url,
  product_id,
) => {
  let queryData = {
    industry,
    business_type,
    business_location,
    brand_voice,
    ai_company_knowledge,
    knowledge_base_url,
    product_id,
  };
  return new Promise(function (resolve) {
    Engagements.getAiReplaies(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getPublishMediaValidations = (product_id) => {
  let queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Engagements.getPublishMediaValidations(queryData)
      .then((response) => {
        resolve({
          data: response?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getAiSuggestions = (
  product_id,
  context,
  client_name,
  agent_name,
  monitor_id,
  interaction_id,
  data_source_name,
  refresh,
) => {
  let queryData = {
    product_id,
    context,
    client_name,
    agent_name,
    monitor_id,
    interaction_id,
    data_source_name,
    refresh,
  };
  return new Promise(function (resolve) {
    Engagements.getAiSuggestions(queryData)
      .then((response) => {
        resolve({
          data: response || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
EngagementsController.editUserInfo = (data) => {
  let queryData = {
    product_id: data?.product_id,
    engagements_monitors: {
      monitors: data?.stepOne?.monitors?.join(","),
      channels: data?.stepOne?.cxm?.join(","),
    },
    engagements_accounts: {
      sm_accounts: {
        TWITTER: data?.stepOne?.TWITTER_SlAcc?.join(","),
        FACEBOOK: data?.stepOne?.FACEBOOK_SlAcc?.join(","),
        INSTAGRAM: data?.stepOne?.INSTAGRAM_SlAcc?.join(","),
      },
      ca_accounts: {
        TWITTER: data?.stepOne?.TWITTER_CxmAcc?.join(","),
        FACEBOOK: data?.stepOne?.FACEBOOK_CxmAcc?.join(","),
        INSTAGRAM: data?.stepOne?.INSTAGRAM_CxmAcc?.join(","),
      },
      publish_accounts: {
        TWITTER: data?.stepOne?.TWITTER_PubAcc?.join(","),
        FACEBOOK: data?.stepOne?.FACEBOOK_PubAcc?.join(","),
        INSTAGRAM: data?.stepOne?.INSTAGRAM_PubAcc?.join(","),
      },
    },
    ai_params: {
      industry: data?.stepTwo?.luci_industry,
      business_type: data?.stepTwo?.luci_business_type,
      business_location: data?.stepTwo?.luci_business_hq,
      brand_voice: data?.stepTwo?.brand_tone,
      ai_company_knowledge: data?.stepTwo?.aboutYour,
      // may need be later
      // should_learn: data?.stepTwo?.enabled,
      should_learn: false,
      knowledge_base_url: data?.stepTwo?.knowledge_base_url,
    },
    farewell_greeting_enabled: data?.farewell_greeting_enabled,
    farewell_greeting_messages: data?.farewell_greeting_messages,
  };

  return new Promise(function (resolve) {
    Engagements.editUserInfo(queryData)
      .then((response) => {
        resolve({
          data: response || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
EngagementsController.getAiSavedResponses = (
  product_id,
  context,
  monitor_id,
  interaction_id,
  data_source_name,
  refresh,
) => {
  let queryData = {
    product_id,
    context,
    monitor_id,
    interaction_id,
    data_source_name,
    refresh,
  };
  return new Promise(function (resolve) {
    Engagements.getAiSavedResponses(queryData)
      .then((response) => {
        resolve({
          data: response || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
EngagementsController.createPostPublish = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.createPostPublish(queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getUserInfo = (data) => {
  let queryData = {
    product_id: data?.product_id,
  };

  return new Promise(function (resolve) {
    Engagements.getUserInfo(queryData)
      .then((response) => {
        resolve({
          data: response || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
EngagementsController.enabledFiltersPublish = (product_id) => {
  let queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Engagements.enabledFiltersPublish(queryData)
      .then((response) => {
        resolve({
          data: response?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.deleteReply = (id, product_id) => {
  let queryData = { id, product_id };
  return new Promise(function (resolve) {
    Engagements.deleteReply(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//upcomimg data
EngagementsController.getUpcomingData = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.getUpcomingData(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getSavedLanguage = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.getSavedLanguage(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.showDetailsPublish = (id, product_id) => {
  let queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Engagements.showDetailsPublish(id, queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.saveTranslationLanguage = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.saveTranslationLanguage(queryData)
      .then(function (response) {
        resolve({
          data: response.data || [],
          status_code: response.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
EngagementsController.detectTranslate = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.detectTranslate(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.addRate = (
  product_id,
  monitor_id,
  data_source_name,
  rating,
  translation,
  source_language,
  target_language,
  engagement_id,
) => {
  let queryData = {
    product_id,
    monitor_id,
    data_source_name,
    rating,
    translation,
    source_language,
    target_language,
    engagement_id,
  };
  return new Promise(function (resolve) {
    Engagements.addRate(queryData)
      .then((response) => {
        resolve({
          data: response || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getAllDMAuthrizedAccounts = (product_id) => {
  let queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Engagements.getAllDMAuthrizedAccounts(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

//edit
EngagementsController.EditScheduledPost = (queryData, id) => {
  return new Promise(function (resolve) {
    Engagements.EditScheduledPost(queryData, id)
      .then((response) => {
        resolve({
          data: response || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.editPostPublished = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.editPostPublished(queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
EngagementsController.deleteScheduledPost = (id, product_id) => {
  let queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Engagements.deleteScheduledPost(id, queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
EngagementsController.deletePublishedPost = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.deletePublishedPost(queryData)
      .then((response) => {
        resolve({
          data: response,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getDMMediaValidations = (product_id) => {
  return new Promise(function (resolve) {
    Engagements.getDMMediaValidations(product_id)
      .then((response) => {
        resolve({
          data: response?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.deleteEngagementPost = (
  replyId,
  data_source_id,
  monitor_model_id,
  item_type,
  product_id,
  parent_id,
) => {
  return new Promise(function (resolve) {
    Engagements.deleteEngagementPost(
      replyId,
      data_source_id,
      monitor_model_id,
      item_type,
      product_id,
      parent_id,
    )
      .then((response) => {
        resolve({
          data: response,
          status_code: response?.status,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getSenderUserList = (
  product_id,
  data_source_name,
  page_number,
  query,
) => {
  return new Promise(function (resolve) {
    Engagements.getSenderUserList(
      product_id,
      data_source_name,
      page_number,
      query,
    )
      .then((response) => {
        resolve({
          data: response?.data.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.editReply = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.editReply(queryData)
      .then(function (response) {
        resolve({
          data: response,
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getLastUsedAccount = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.getLastUsedAccount(queryData)
      .then((response) => {
        resolve({
          data: response?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getEngagementsAnalyticsCredentials = (
  product_id,
  start_date,
  end_date,
  monitors,
) => {
  const queryData = {
    product_id,
    start_date,
    end_date,
    monitors,
  };
  return new Promise(function (resolve) {
    Engagements.getEngagementsAnalyticsCredentials(queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.CheckEngageMentAnalytics = (product_id) => {
  const queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Engagements.CheckEngageMentAnalytics(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.updateUserAgentStatus = (status) => {
  const queryData = {
    status,
  };
  return new Promise(function (resolve) {
    Engagements.updateUserAgentStatus(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getEnagementConditions = (product_id) => {
  const queryData = {
    product_id,
  };
  return new Promise(function (resolve) {
    Engagements.getEnagementConditions(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.checkNameExistence = (queryData) => {
  return new Promise((resolve, reject) => {
    Engagements.checkNameExistence(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

EngagementsController.getRoutingAlgorithms = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.getRoutingAlgorithms(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getCompanySLAs = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.getCompanySLAs(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.createInboundRouting = (queryData) => {
  return new Promise((resolve, reject) => {
    Engagements.createInboundRouting(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};
EngagementsController.getAgentCapacities = (queryData) => {
  return new Promise((resolve, reject) => {
    Engagements.getAgentCapacities(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

EngagementsController.updateAgentCapacities = (queryData) => {
  return new Promise((resolve, reject) => {
    Engagements.updateAgentCapacities(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

EngagementsController.createSLA = (queryData) => {
  return new Promise((resolve, reject) => {
    Engagements.createSLA(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

EngagementsController.getInboundRoutings = (queryData) => {
  return new Promise((resolve, reject) => {
    Engagements.getInboundRoutings(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

EngagementsController.getInboundRouting = (id) => {
  return new Promise((resolve, reject) => {
    Engagements.getInboundRouting(id)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

EngagementsController.deleteInboundRouting = (id) => {
  return new Promise((resolve, reject) => {
    Engagements.deleteInboundRouting(id)
      .then((response) => {
        resolve({ data: response, status_code: response?.status });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};
EngagementsController.updateRoutingPriority = (id, queryData) => {
  return new Promise((resolve, reject) => {
    Engagements.updateRoutingPriority(id, queryData)
      .then((response) => {
        resolve({ data: response, status_code: response?.status });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};
EngagementsController.updateInboundRouting = (id, queryData) => {
  return new Promise((resolve, reject) => {
    Engagements.updateInboundRouting(id, queryData)
      .then((response) => {
        resolve({ data: response, status_code: response?.status });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

EngagementsController.getUsers = (id) => {
  let queryData = {
    product_id: id,
  };
  return new Promise(function (resolve) {
    Engagements.getUsers(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getTeamUsers = (id, product_id) => {
  const queryData = { id: id, product_id: product_id };
  return new Promise(function (resolve) {
    Engagements.getTeamUsers(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.assignTeamsAndUsers = (queryData) => {
  return new Promise(function (resolve) {
    Engagements.assignTeamsAndUsers(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getEngagementAnalyticsData = (tabName, queryData) => {
  return new Promise(function (resolve) {
    Engagements.getEngagementAnalyticsData(tabName, queryData)
      .then((response) => {
        resolve({
          data: response?.data?.data || [],
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getKnowledgeBase = (product_id) => {
  const queryData = { product_id };
  return new Promise(function (resolve) {
    Engagements.getKnowledgeBase(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

EngagementsController.getLookupMessages = () => {
  return new Promise(function (resolve) {
    Engagements.getLookupMessages()
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};
export default EngagementsController;
