import translations from "../../../../../../../utils/locale/index";
import {
  handleDataBarAndLineChartToExcel,
  handleDataBarChartComparisonToExcel,
} from "./exportDataByTypeOfWidget/exportBarChart";
import { handleDataLineChartComparisonToExcel } from "./exportDataByTypeOfWidget/exportLineChartComparison";
import {
  handleDataPieChartToExcel,
  handlePieChartComparisonToExcel,
} from "./exportDataByTypeOfWidget/exportPieChart";
import {
  handleDataStackedBarChartsToExcel,
  handleStackedBarChartComparisonToExcel,
} from "./exportDataByTypeOfWidget/exportStackedBar";
import {
  handleDataStackedLineChartsToExcel,
  handleStackedLineComparisonToExcel,
} from "./exportDataByTypeOfWidget/exportStackedLineChart";
import { handleMediaWidgetToExcel } from "./exportDataByTypeOfWidget/exportMediaWidget";
import { handlePunchCardToExcel } from "./exportDataByTypeOfWidget/exportPunchCard";
import { handleTableWidgetToExcel } from "./exportDataByTypeOfWidget/exportTableWidget";
import {
  handleThemesWidgetToExcel,
  handleThemesWidgetComparisonToExcel,
} from "./exportDataByTypeOfWidget/exportThemesWidget";
import { returnOneNameForEachWidget } from "./sharedFaunctionExcel";

const ignoreWidgetsExcel = ["customer_care_performance"];

const handleDataByWidgetType = (
  widgetTypeChart,
  customizedType,
  widgetData,
) => {
  switch (widgetTypeChart) {
    case "barChartWidget":
      if (customizedType === "comparison") return widgetData?.dataExcel;
      return widgetData?.data;
    case "lineChartWidget":
      return widgetData?.data;

    case "pieChartWidget":
      if (customizedType === "comparison") return widgetData;
      return widgetData?.pie_data;

    default:
      return widgetData;
  }
};

//to set default of each widget in a sheet and order
export const handleDefualtFileExcelData = (
  dashboardWidgets,
  setFileExcelData,
) => {
  const widgetsDataForExcel = [];
  dashboardWidgets?.forEach((widget) => {
    // {data: [[]] , sheetName:"",widgetId:number ,monitorName :""  }
    widgetsDataForExcel?.push({
      data: [[]], // must be array of arrays
      sheetName: "", // will be the name of widget
      widgetId: +widget?.id, // to check what the array will change when call addFinalDataWidegtToExcel
      monitorName: widget?.attributes?.monitor_name, // to add this name for sheetName after translation in addFinalDataWidegtToExcel
    });
  });

  setFileExcelData(widgetsDataForExcel);
};

//to update excel file by real data widget , if it is no-custom or custom (aggregate or comparison)
export const addFinalDataWidegtToExcel = (
  widgetData,
  widgetName,
  widgetTypeChart,
  mainWidgetId,
  setFileExcelData,
  excelLanguage,
  themesOptions,
  customizedType,
) => {
  const xlsLanguage = translations[excelLanguage];
  // when used this not need to check anther name for the same widget
  const oneNameWidget = returnOneNameForEachWidget(widgetName);

  // to remove this widget from excel data array
  if (ignoreWidgetsExcel?.includes(returnOneNameForEachWidget(widgetName))) {
    setFileExcelData((prev) => {
      return prev?.filter((widget) => widget?.widgetId !== mainWidgetId);
    });
    return; // to exit from function
  }

  const finalDataToHandle = handleDataByWidgetType(
    widgetTypeChart,
    customizedType,
    widgetData,
  );

  // to set real final date to excel data array. if(not custom - aggregate - comparison)
  //two special cases with "dialects subdialects" and "themes" .for each  one widget insert two sheets in excel file
  setFileExcelData((prev) => {
    return prev?.map((widget) => {
      if (widget?.widgetId === mainWidgetId) {
        if (
          oneNameWidget === "dialects_subdialects" &&
          customizedType !== "comparison"
        ) {
          return {
            ...widget,
            data: transformToArrayForExcel(
              finalDataToHandle,
              "dialects",
              widgetTypeChart,
              xlsLanguage,
              customizedType,
            ),
            subData: transformToArrayForExcel(
              widgetData?.pie_sub_data,
              "sub_dialects",
              widgetTypeChart,
              xlsLanguage,
              customizedType,
            ),
            sheetName: `${xlsLanguage?.dialects} - ${widget?.monitorName}`,
            sheetNameSub: `${xlsLanguage?.sub_dialects} - ${widget?.monitorName}`,
          };
        } else if (oneNameWidget === "custom_omni_themes") {
          const themes =
            customizedType === "comparison"
              ? widgetData?.themes
              : widgetData?.data?.theme_analysis?.main_themes_view;
          const subThemes =
            customizedType === "comparison"
              ? widgetData?.sub_themes
              : widgetData?.data?.theme_analysis?.sub_themes_view;
          return {
            ...widget,
            data: transformToArrayForExcel(
              themes,
              "widget_category",
              widgetTypeChart,
              xlsLanguage,
              customizedType,
              themesOptions,
            ),
            subData: transformToArrayForExcel(
              subThemes,
              "widget_themes",
              widgetTypeChart,
              xlsLanguage,
              customizedType,
              themesOptions,
            ),
            sheetName: `${xlsLanguage?.widget_category} - ${widget?.monitorName}`,
            sheetNameSub: `${xlsLanguage?.widget_themes} - ${widget?.monitorName}`,
          };
        } else
          return {
            ...widget,
            data: transformToArrayForExcel(
              finalDataToHandle,
              oneNameWidget,
              widgetTypeChart,
              xlsLanguage,
              customizedType,
            ),
            sheetName: `${xlsLanguage?.[widgetName]} - ${widget?.monitorName}`,
          };
      }
      return { ...widget };
    });
  });
};

const transformToArrayForExcel = (
  data = [],
  widgetName,
  widgetTypeChart,
  xlsLanguage,
  customizedType,
  themesOptions,
) => {
  switch (widgetTypeChart) {
    case "barChartWidget":
      if (customizedType === "comparison")
        return handleDataBarChartComparisonToExcel(data, xlsLanguage);
      return handleDataBarAndLineChartToExcel(
        data,
        widgetName,
        xlsLanguage,
        customizedType,
      );
    case "lineChartWidget":
      if (customizedType === "comparison")
        return handleDataLineChartComparisonToExcel(
          data,
          widgetName,
          xlsLanguage,
        );
      else
        return handleDataBarAndLineChartToExcel(
          data,
          widgetName,
          xlsLanguage,
          customizedType,
        );

    case "pieChartWidget":
      if (customizedType === "comparison")
        return handlePieChartComparisonToExcel(data, widgetName, xlsLanguage);
      return handleDataPieChartToExcel(
        data,
        widgetName,
        xlsLanguage,
        customizedType,
      );

    case "stackedBarWidget":
      if (customizedType === "comparison")
        return handleStackedBarChartComparisonToExcel(
          data,
          widgetName,
          xlsLanguage,
        );
      return handleDataStackedBarChartsToExcel(
        data,
        widgetName,
        xlsLanguage,
        customizedType,
      );

    case "stackedLineWidget":
      if (customizedType === "comparison")
        return handleStackedLineComparisonToExcel(
          data,
          widgetName,
          xlsLanguage,
        );
      return handleDataStackedLineChartsToExcel(
        data,
        widgetName,
        xlsLanguage,
        customizedType,
      );

    case "mediaWidget":
      return handleMediaWidgetToExcel(data, widgetName, xlsLanguage);

    case "punchCardWidget":
      return handlePunchCardToExcel(data, xlsLanguage, customizedType);

    case "tableWidget":
      return handleTableWidgetToExcel(
        data,
        widgetName,
        xlsLanguage,
        customizedType,
      );

    case "themesWidget":
      if (customizedType === "comparison")
        return handleThemesWidgetComparisonToExcel(
          data,
          widgetName,
          xlsLanguage,
          themesOptions,
        );
      return handleThemesWidgetToExcel(
        data,
        widgetName,
        xlsLanguage,
        customizedType,
        themesOptions,
      );

    default:
      return [];
  }
};
