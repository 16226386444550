import { useQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useGetUserView = (userId) => {
  return useQuery({
    queryKey: ["userViewInfo", userId],
    queryFn: () => CompaniesController.getUserView(userId),
    enabled: !!userId,
    select: (data) => {
      if (!!data?.data) return data?.data;
      else return data;
    },
    retry: false,
  });
};
