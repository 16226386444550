import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { returnOneNameForEachWidget } from "../excel/sharedFaunctionExcel";

// Helper function to ensure all images are loaded
async function preloadImages(container) {
  const images = container.querySelectorAll("img");
  const promises = Array.from(images).map(
    (img) =>
      new Promise((resolve, reject) => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = resolve;
          img.onerror = () => {
            resolve(); // Skip failing images
          };
        }
      }),
  );
  return Promise.all(promises);
}

const toAddNewPagePdf = async (element, containerWidgets, pageWidth, doc) => {
  const canvas = await html2canvas(element, { scale: 2 });
  const imgData = canvas?.toDataURL("image/png");
  const imgHeight = (canvas?.height * pageWidth) / canvas?.width;
  await doc?.addPage();
  await doc?.addImage(imgData, "PNG", 0, 0, pageWidth, imgHeight);
  containerWidgets.replaceChildren(); // Clears all children
};

const exportPDF = async (
  props,
  fileName,
  setPdfLoading,
  handleSnackBarSuccess,
  handleSnackBarError,
) => {
  setPdfLoading(true);
  try {
    const doc = new jsPDF("p", "mm", "a4", true);

    //////////////// add cover to Report /////////////////
    const firstPageReport = document?.getElementById(
      "main-pdf-contant-dashboard",
    );
    const canvas = await html2canvas(firstPageReport, { scale: 2 });
    const pageWidth = 210;
    const imgHeight = (canvas?.height * pageWidth) / canvas?.width;
    const imgData = canvas?.toDataURL("image/png");
    await doc?.addImage(imgData, "PNG", 0, 0, pageWidth, imgHeight, "", "SLOW");

    //////////////// add widgets to Report /////////////////
    //this is the container for widgets
    const containerWidgetsReport = document?.getElementById(
      `main-pdf-contant-dashboard-widgets`,
    );
    //this is the page for PDF
    const pageContainerWidgetsReport = document?.getElementById(
      `main-pdf-contant-dashboard-widgets-page`,
    );
    let checkTwoSmallWidget = false; // to check if two small widget in the same row
    let countRowContainer = 0; // this count for check the count of rows should not add greater than 3 row in the same page
    //loop on all wigets in dashboard
    for (const widget of props?.dashboardWidgets) {
      //hold each widget by id from file "WidgetComponent"
      const widgetElement = document?.getElementById(
        `custom-dashbord-widget-${widget?.id}`,
      );

      const widgetName = returnOneNameForEachWidget(
        widget?.attributes?.widget_name,
      );

      if (
        !(
          ["top_videos", "top_images", "top_reels"]?.includes(widgetName) &&
          ["INSTAGRAM", "FACEBOOK"]?.includes(widget?.attributes?.data_source)
        )
      ) {
        // break; // don't add these widgets to PDF file
        // Ensure all images are loaded
        await preloadImages(widgetElement);

        // to check if this widget is the small "EX: pie-chart"
        const checkWidthWidget =
          widgetElement?.className?.includes("MuiGrid-grid-xs-6");

        let widgetHeight = widgetElement?.offsetHeight - 40; // need only height for widget so subtract 40px for padding

        if (
          checkWidthWidget &&
          containerWidgetsReport?.lastElementChild?.className?.includes(
            "half-width",
          ) &&
          checkTwoSmallWidget // to check if this widget is the second small widget ( if it is third will start from first row)
        ) {
          // if this widget and the last widget in containerWidgetsReport are small and this widget is the second small widget
          widgetHeight = 0;
          //if add in the same row subtract one from counter
          countRowContainer--;
        }

        const isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent,
        );
        // page in pdf 1200px , content header & border-footer (100px)
        if (
          1100 < containerWidgetsReport?.offsetHeight + widgetHeight ||
          countRowContainer ==
            (isSafari ? (window.innerWidth < 1200 ? 1 : 2) : 3)
        ) {
          // if height(1100) of PDF page is  the smaller than height of (containerWidgetsReport + newWidget)
          await toAddNewPagePdf(
            pageContainerWidgetsReport, // to add this page
            containerWidgetsReport, //to remove widgets for this page after add this page to pdf
            pageWidth,
            doc,
          ); // to add this page to pdf and add new blank page

          countRowContainer = 0; //reset count for new page
        }

        // to tranform the widget to image
        const canvasWidget = await html2canvas(
          widgetElement?.firstElementChild,
          {
            scale: 2,
            useCORS: true, // Allow cross-origin images
            allowTaint: false, // Prevent tainting issues
          },
        );
        const imgDataa = new Image();
        imgDataa.src = canvasWidget?.toDataURL("image/png");
        const newWidget = document?.createElement("div"); // to appand the image and can control the style
        newWidget.style.height = widgetElement?.offsetHeight - 40;
        countRowContainer++; // to add new row for this widget

        if (checkWidthWidget) {
          //to control the style for small widget
          newWidget?.classList.add("half-width");
          checkTwoSmallWidget = !checkTwoSmallWidget; // I toggle to check if the new widget is half-width
        } else {
          checkTwoSmallWidget = false; // reset to defualt when widget is not half-width
        }

        newWidget?.appendChild(imgDataa);
        containerWidgetsReport?.appendChild(newWidget); // to add new widget to container
      }
    }

    await toAddNewPagePdf(
      pageContainerWidgetsReport, // to add this page
      containerWidgetsReport, //to remove widgets for this page after add this page to pdf
      pageWidth,
      doc,
    ); // to add the final page before save
    await doc?.save(fileName ? fileName : "dashBoard"); // the final to save and print pdf

    handleSnackBarSuccess();
  } catch (error) {
    handleSnackBarError();
  } finally {
    setPdfLoading(false);
  }
};

export default exportPDF;
