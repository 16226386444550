import { Box } from "@mui/material";
import PopupModal from "components/popupModal";
import { CheckValueLocale, isEmptyValue } from "utils/helpers";
import { useIntl } from "react-intl";
import RoutingSelectTeam from "./routingSelectTeam";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { useFormik } from "formik";
import { DeleteValidationSchema } from "pages/engagements/components/aiAgents/aiAgentsValidation/deleteValidationSchema";
import { usePauseAiAgent } from "pages/engagements/components/aiAgents/aiAgentsHooks/usePauseAiAgent";
import { useContext } from "react";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";

const PauseAiAgentModal = ({
  aiAgentDetails,
  openPopupPause,
  setOpenPopupPause,
}) => {
  const intl = useIntl();
  const { setAiAgentSnackBar } = useContext(AiAgentsContext);
  const { mutate: pauseAiAgent, isPending: pauseAiAgentLoading } =
    usePauseAiAgent();

  const pauseInitialValues = {
    auto_assignment: "unassigned",
    team_id: null,
    auto_assign_algorithm_id: null,
    auto_reply: "",
  };

  const formik = useFormik({
    initialValues: pauseInitialValues,
    validationSchema: DeleteValidationSchema,
  });

  const handlePauseAiAgent = () => {
    const { auto_assignment, team_id, auto_assign_algorithm_id, auto_reply } =
      formik?.values;
    let queryData = {
      id: aiAgentDetails?.id,
      auto_assignment,
      auto_reply,
    };
    if (auto_assignment !== "unassigned") {
      queryData = {
        ...queryData,
        team_id,
        auto_assign_algorithm_id,
      };
    }
    pauseAiAgent(queryData, {
      onSuccess: () => {
        setAiAgentSnackBar({
          success: true,
          title: CheckValueLocale(
            "ai_agent_paused_success_title",
            "",
            {},
            intl,
          ),
          message: CheckValueLocale(
            "ai_agent_paused_success_message",
            "",
            { name: aiAgentDetails?.name },
            intl,
          ),
        });
      },
      onError: () => {
        setAiAgentSnackBar({
          success: false,
          title: CheckValueLocale("failed_error_message", "", {}, intl),
          message: CheckValueLocale("try_again_error_message", "", {}, intl),
        });
      },
      onSettled: () => {
        closeModal();
      },
    });
  };

  const pauseAiAgentButtonDisabled =
    formik?.values?.auto_assignment === "manual_assign"
      ? !isEmptyValue(formik?.errors || {})
      : false;

  const closeModal = () => {
    setOpenPopupPause(null);
    formik?.resetForm();
  };

  let modalBody = (
    <Box>
      <Box className="ai-agent-paused-hint-box">
        <FontAwesomeIcon icon={faCircleExclamation} />
        <Box component="p" className="ai-agent-modal-hint">
          {CheckValueLocale(
            "paused_ai_agent_desc",
            "",
            { name: aiAgentDetails?.name },
            intl,
          )}
        </Box>
      </Box>
      <RoutingSelectTeam
        formik={formik}
        validForAutoAssign={aiAgentDetails?.valid_for_auto_assign}
      />
    </Box>
  );

  return (
    <PopupModal
      title={CheckValueLocale("pause_ai_agent", "", {}, intl)}
      body={modalBody}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("confirm_pause_ai_agent", "", {}, intl)}
      disabled={pauseAiAgentButtonDisabled || pauseAiAgentLoading}
      isRightBtnLoading={pauseAiAgentLoading}
      open={openPopupPause || false}
      close={!pauseAiAgentLoading ? closeModal : null}
      accept={handlePauseAiAgent}
      classeName={"ai-agent-popupModal"}
      dialogContentId="pause-ai-agent"
    />
  );
};
export default PauseAiAgentModal;
