// to add "aggregate" to header name when this widget is aggregate
export const handleAddAggregate = (headerName, customizedType, xlsLanguage) => {
  let nameHeader = headerName;
  if (customizedType === "aggregate") {
    nameHeader += ` - ${xlsLanguage?.aggregate}`;
  }

  return nameHeader;
};

//to translate sub themes name from backend API
export const handleTranslatSubThemes = (
  themesOptions,
  subTheme,
  xlsLanguage,
) => {
  const themesOption = themesOptions?.find(
    (option) => option?.value == subTheme,
  );
  if (themesOption) {
    return themesOption?.[`label_${document.documentElement.lang}`];
  }

  return xlsLanguage?.[subTheme] || subTheme;
};

// to return unique widget name for group of (widgetName)
export const returnOneNameForEachWidget = (widgetName) => {
  const ignoreNameCXM = widgetName?.includes("custom_omni_")
    ? widgetName?.slice("custom_omni_"?.length)
    : widgetName;
  switch (ignoreNameCXM) {
    //////////// pie chart /////////////
    case "top_languages":
    case "comments_top_languages":
      return "top_languages";

    case "posts_associated_topics":
    case "comments_associated_topics":
      return "associated_topics";

    case "top_countries":
    case "comments_top_countries":
      return "top_countries";

    case "top_cities":
    case "comments_top_cities":
      return "top_cities";

    case "gender_distribution":
      return "gender_distribution";

    case "account_types":
    case "comments_account_types":
      return "account_types";

    case "content_type":
    case "posts_content_type":
      return "account_types";

    case "dialects_subdialects":
    case "comments_dialects_sub_dialects":
    case "dialects":
    case "dialects_sub_dialects":
      return "dialects_subdialects";

    ////// themes widget //////////////
    case "themes":
    case "comments_themes":
      return "custom_omni_themes";

    ////// line chart //////////////
    case "posts_volume":
    case "posts_volume_overtime":
      return "posts_volume";

    case "comments_volume":
    case "comments_volume_overtime":
      return "comments_volume";

    case "page_views":
      return "page_views";

    case "engagers_volume":
      return "engagers_volume";

    case "videos_views":
      return "videos_views";

    case "video_volume":
      return "video_volume";

    case "conversation_interactions":
    case "interactions":
      return "conversation_interactions";

    /////// stackedLine widget ////////////////
    case "comments_sentiment_analysis":
    case "sentiment_analysis":
    case "posts_sentiment_analysis":
      return "sentiment_analysis";

    /////// table widget ////////////////
    case "comments_top_hashtags":
    case "comments_top_hash_tags":
    case "posts_top_hashtags":
    case "posts_top_hash_tags":
    case "top_hashtags":
      return "top_hashtags";
    case "comments_top_keywords":
    case "posts_top_keywords":
    case "top_keywords":
      return "top_keywords";
    case "comments_top_engagers":
    case "top_engagers":
      return "top_engagers";
    case "comments_top_verified_engagers":
    case "top_verified_engagers":
      return "top_verified_engagers";
    case "comments_top_influencers":
    case "top_influencers":
      return "top_influencers";

    /////// media widget ////////////////////
    case "top_images":
    case "posts_top_images":
    case "posts_posts_top_images":
      return "top_images";
    case "top_videos":
    case "posts_top_videos":
    case "posts_posts_top_videos":
      return "top_videos";

    /////// StackedBar widget ////////////////////
    case "reach_funnel":
    case "posts_reach_funnel":
      return "reach_funnel";

    default:
      return ignoreNameCXM;
  }
};
