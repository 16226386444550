import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";

const SectionBrandingHeader = ({ titleId }) => {
  const intl = useIntl();
  return (
    <Box className="item-heading">
      <Typography className="branding-head">
        {CheckValueLocale(titleId, "", {}, intl)}
      </Typography>
    </Box>
  );
};

export default SectionBrandingHeader;
