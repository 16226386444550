import { useEffect, useState } from "react";
import { CircularProgress, Box, Typography, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale, commasAfterDigit } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import "./style.scss";

const ProgressAndDetails = (props) => {
  const intl = useIntl();
  const { value, total, used, title, tooltip, sub_title } = props;

  const [roundedValue, setRoundedValue] = useState(0);

  useEffect(() => {
    if (value) {
      // If value is provided, round it and set it as the rounded value
      const roundedValue = Math.round(value) || 0;
      setRoundedValue(roundedValue);
    } else if (used && total) {
      // If used and total are provided, calculate the rounded value
      const roundedValue = Math.round((used / total) * 100) || 0;
      setRoundedValue(roundedValue);
    } else if ((!used && !total) || (used && Number(total) === 0)) {
      // If no used and total are provided or total is 0 set it to 100
      setRoundedValue(100);
    } else setRoundedValue(0); // If no value, and (used or total) are not provided, set it to 0
  }, [value, used, total]);

  return (
    <Box id="progreess-and-details-container">
      <Box className="progress-wrapper">
        {/* Unused Value (track) */}
        <CircularProgress
          className="progress-unused-track"
          variant="determinate"
          size={65} // Width and Height 65px
          thickness={6} // Thickness 6px
          value={100} // Always 100 to represent the full circle
        />

        {/* Used Value */}
        <CircularProgress
          className="progress-used-track"
          variant="determinate"
          size={65}
          thickness={6}
          value={roundedValue >= 100 ? 100 : roundedValue}
          color={roundedValue < 100 ? "primary" : "error"}
        />

        {/* Value Box Inside Circle */}
        <Box className="progress-value-container">
          <Typography
            className={roundedValue >= 100 ? "max-value-reached" : ""}
          >
            {roundedValue}%
          </Typography>
        </Box>
      </Box>

      {title || (total !== undefined && used !== undefined) ? (
        <Box className="details-wrapper">
          {title ? (
            <Typography variant="h6">
              {CheckValueLocale(title, "", {}, intl)}
              {/* tooltip is a prop passed to component to show tooltip text */}
              {tooltip ? (
                <Tooltip
                  title={
                    <Box id="progress-title-tooltip">
                      {CheckValueLocale(tooltip, "", {}, intl)}
                    </Box>
                  }
                  placement="top"
                  arrow
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              ) : null}
            </Typography>
          ) : null}

          {total !== undefined && used !== undefined ? (
            <Typography>
              {commasAfterDigit(Math.round(used) || 0)}
              <Box component="span">{CheckValueLocale("of", "", {}, intl)}</Box>
              {commasAfterDigit(Math.round(total) || 0)}
            </Typography>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default ProgressAndDetails;
