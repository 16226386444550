import React, { useEffect, useState, useContext } from "react";
import { Box, Divider, Menu } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import excelImage from "images/excel-icon.svg";
import pdfImage from "images/pdf-icon.svg";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import SnackBar from "components/snackBar";
import exportPDF from "./pdf/exportPDF";
import exportExcel from "./excel/exportExcel";
import "./exportButton.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { ExcelFileContext } from "./excel/context/ExcelFileContext";

const ExportButton = ({ nameDashboard, loadingExport, ...props }) => {
  const intl = useIntl();
  const { fileExcelData, setSelectedLanguage } = useContext(ExcelFileContext);
  const [snackBar, setSnackBar] = useState({
    open: false,
    title: "",
    msg: "",
    severity: "success",
  });
  const [anchorExport, setAnchorExport] = useState(null);
  const openExport = Boolean(anchorExport);
  let menuDirection = intl?.locale === "en" ? "right" : "left";

  const [selectedType, setSelectedType] = useState({
    id: "pdf",
    name: "pdf",
    disabled: false,
  });
  //to get monitors used in this dashboard
  const monitors = [];
  props?.dashboardWidgets?.forEach((widget) => {
    if (!monitors?.includes(widget?.attributes?.monitor_name))
      monitors?.push(widget?.attributes?.monitor_name);
    widget?.attributes?.customized_widget?.attributes?.monitors?.forEach(
      (monitor) => {
        if (!monitors?.includes(monitor?.name)) monitors?.push(monitor?.name);
      },
    );
  });

  const [pdfLoading, setPdfLoading] = useState(false);

  const dropDownTypes = [
    {
      label: "type",
      options: [
        { id: "pdf", name: "pdf", disabled: false },
        { id: "excel", name: "excel", disabled: false },
      ],
      disabled: false,
    },
    {
      label: "language",
      options: [
        {
          id: "english",
          name: "en",
          disabled: intl?.locale !== "en" ? true : false,
        },
        {
          id: "arabic",
          name: "ar",
          disabled: intl?.locale !== "ar" ? true : false,
        },
      ],
      disabled: false,
    },
  ];

  const exportText = (type) => {
    const text = {
      pdf: (
        <Box className="export-file">
          <img src={pdfImage} alt="pdf" />
          {CheckValueLocale(type, "", {}, intl)}
        </Box>
      ),
      excel: (
        <Box className="export-file">
          <img src={excelImage} alt="excel" />
          {CheckValueLocale(type, "", {}, intl)}
        </Box>
      ),
      default: CheckValueLocale(type, "", {}, intl),
    };
    return text[type] || text["default"];
  };

  const handleOpenExport = (event) => {
    if (anchorExport !== event?.currentTarget)
      setAnchorExport(event?.currentTarget);
  };
  const handleCloseExport = () => setAnchorExport(null);

  const handleChecked = (type, val) => {
    let checked = false;
    if (type === "language") checked = true;
    else checked = selectedType?.name === val;
    return checked;
  };

  const handleSelection = (label, option) => {
    label === "type" && setSelectedType(option);
  };

  useEffect(() => {
    setSelectedLanguage({
      id: intl?.locale === "en" ? "english" : "arabic",
      name: intl?.locale,
      disabled: false,
    });
  }, [intl?.locale]);

  const handleClickExport = () => {
    if (selectedType?.name === "pdf")
      exportPDF(
        props,
        nameDashboard,
        setPdfLoading,
        handleSnackBarSuccess,
        handleSnackBarError,
      );
    else
      exportExcel(
        fileExcelData,
        nameDashboard,
        handleSnackBarSuccess,
        handleSnackBarError,
      );
  };

  const handleSnackBarSuccess = () => {
    setSnackBar({
      open: true,
      title: CheckValueLocale("benchmark_export_success_title", "", {}, intl),
      msg: "",
      severity: "success",
    });
  };
  const handleSnackBarError = () => {
    setSnackBar({
      open: true,
      title: CheckValueLocale("something_wenπt_wrong", "", {}, intl),
      msg: CheckValueLocale("try_again", "", {}, intl),
      severity: "error",
    });
  };
  return (
    <Box>
      <LucButton
        variant="outline"
        type="secondary"
        size="small"
        aria-owns={anchorExport}
        aria-haspopup="true"
        aria-expanded={openExport}
        onClick={handleOpenExport}
        startIcon={<FontAwesomeIcon icon={faArrowDownToLine} />}
        endIcon={<FontAwesomeIcon icon={faCaretDown} />}
        loading={(selectedType?.name === "pdf" && pdfLoading) || loadingExport}
        id="export-button-custom-dashboard"
      >
        {CheckValueLocale("export", "", {}, intl)}
      </LucButton>
      <Menu
        id="export-menu-custom-dashboard"
        MenuListProps={{
          "aria-labelledby": "export-button-custom-dashboard",
          onMouseLeave: handleCloseExport,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: menuDirection,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: menuDirection,
        }}
        anchorEl={anchorExport}
        open={openExport}
        onClose={handleCloseExport}
      >
        <Box className="tabs-container">
          <Box className="export-options-title">
            {CheckValueLocale("export_options", "", {}, intl)}
          </Box>
          <Box className="radio-item export-item">
            {dropDownTypes?.map((type, index) => {
              return (
                <Box key={index}>
                  <Box className={`export-label ${type?.label}`}>
                    {type?.options?.map((val, i) => {
                      return (
                        <React.Fragment key={i}>
                          {!val?.disabled &&
                          !(val?.name === "excel" && !fileExcelData?.length) ? (
                            <label className="radio-item">
                              <input
                                type="radio"
                                name={`export-${type?.label}`}
                                onClick={() =>
                                  handleSelection(type?.label, val)
                                }
                                checked={handleChecked(type?.label, val?.name)}
                                disabled={pdfLoading || loadingExport}
                              />
                              <Box component="span" className="export-choose">
                                {exportText(val?.name)}
                              </Box>
                            </label>
                          ) : null}
                        </React.Fragment>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
            <Divider />
            <LucButton
              id="export-file-btn"
              variant="filled"
              loading={selectedType?.name === "pdf" && pdfLoading}
              loadingPosition="start"
              disableRipple
              onClick={handleClickExport}
            >
              {CheckValueLocale("export_file", "", {}, intl)}
            </LucButton>
          </Box>
        </Box>
      </Menu>
      <SnackBar
        autoHideDuration={5000}
        message={snackBar?.msg}
        title={snackBar?.title}
        open={snackBar?.open}
        severity={snackBar?.severity}
        handleClose={() => setSnackBar({ open: false, title: "", msg: "" })}
      />
    </Box>
  );
};

export default ExportButton;
