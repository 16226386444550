import { useQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useGetTeams = ({ page, search }) => {
  return useQuery({
    queryKey: ["companyTeams", page, search],
    queryFn: () => CompaniesController.getTeams(page, search),
    enabled: !!page,
    select: (data) => data?.data,
    retry: false,
  });
};
