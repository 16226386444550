import Services from "./Services";

var Notifications = {};

Notifications.getNotificationsList = (queryData) => {
  return Services.getData("api/v5/notifications", queryData);
};

Notifications.getNotificationsCount = () => {
  return Services.getData("api/v5/notifications/count");
};

Notifications.getNotificationsDownloadLink = (id) => {
  return Services.getData(`api/v3/notifications/${id}`);
};

Notifications.getNotificationsXlsxDownload = (id) => {
  return Services.getData(`api/v5/notifications/${id}/xlsx_download`);
};
export default Notifications;
