import {
  Box,
  TextField,
  InputLabel,
  Typography,
  InputAdornment,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const CustomDomainStepOne = (props) => {
  const { formik } = props;

  const intl = useIntl();

  return (
    <Box className="custom-domain-add-domain-wrapper">
      <InputLabel>
        {CheckValueLocale("domain_name", "", {}, intl)}
        <Typography component="span" className="required">
          *
        </Typography>
      </InputLabel>
      <TextField
        id="custom-domain-name-input"
        name="custom_domain"
        placeholder={CheckValueLocale("custom_domain_example", "", {}, intl)}
        value={formik?.values?.custom_domain}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        error={formik?.errors?.custom_domain}
        helperText={
          formik?.errors?.custom_domain
            ? CheckValueLocale(formik?.errors?.custom_domain, "", {}, intl)
            : ""
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography component={"span"}>survey.</Typography>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default CustomDomainStepOne;
