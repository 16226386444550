//this function to set headers name of columns by "nameHeader"
//and to set keys name to access from each widget by  "keysToAccess"
//"indexAggregate" --> to set "aggregate" word after the header name of column for only values if the widget is aggregate
const handleNameHeader = (widgetName) => {
  switch (widgetName) {
    case "top_hashtags":
      return {
        nameHeader: ["hashtags", "xlsx_number_of_posts"],
        indexAggregate: [1],
        keysToAccess: ["id", "stats_count"],
      };

    case "top_keywords":
      return {
        nameHeader: ["keywords", "xlsx_number_of_posts"],
        indexAggregate: [1],
        keysToAccess: ["id", "stats_count"],
      };

    case "top_engagers":
      return {
        nameHeader: ["top_engagers", "users", "followers", "engagements"],
        indexAggregate: [2, 3],
        keysToAccess: ["id", "name", "followers_count", "total_engagements"],
      };

    case "top_verified_engagers":
      return {
        nameHeader: [
          "top_verified_engagers",
          "users",
          "followers",
          "engagements",
        ],
        indexAggregate: [2, 3],
        keysToAccess: ["id", "name", "followers_count", "total_engagements"],
      };

    case "top_influencers":
      return {
        nameHeader: ["top_infleuncers", "users", "followers", "engagements"],
        indexAggregate: [2, 3],
        keysToAccess: ["id", "name", "followers_count", "total_engagements"],
      };
    case "top_authors":
      return {
        nameHeader: ["username", "xlsx_number_of_posts"],
        indexAggregate: [1],
        keysToAccess: ["id", "stats_count"],
      };

    case "top_sites":
      return {
        nameHeader: ["links", "reach"],
        indexAggregate: [1],
        keysToAccess: ["id", "stats_count"],
      };

    case "top_engaged_sites":
      return {
        nameHeader: ["links", "nb_posts"],
        indexAggregate: [1],
        keysToAccess: ["id", "stats_count"],
      };
    case "most_engaged_contacts":
      return {
        nameHeader: ["users", "interactions"],
        indexAggregate: [1],
        keysToAccess: ["name", "value"],
      };
    default:
      return { nameHeader: [], indexAggregate: [], keysToAccess: [] };
  }
};

/////////////////// TableWidget (aggregate && defualt ) ////////////////////////////////////
// widgetData (each widgetName has different object in "pieData") ===> [{ pieData:[ {id: " " , stats_count: number} , ...] } ,,.]
// return ===> (aggregate- defualt) (depend on "handleNameHeader" function) ---->  [[ ...nameHeader ] , [ ...keysToAccess ] , ............]
export const handleTableWidgetToExcel = (
  widgetData,
  widgetName,
  xlsLanguage,
  customizedType,
) => {
  try {
    const { nameHeader, indexAggregate, keysToAccess } =
      handleNameHeader(widgetName);
    const dataListExcelSheet = [
      [...(nameHeader?.map((name) => xlsLanguage?.[name]) || [])],
    ];

    if (customizedType == "aggregate") {
      indexAggregate?.forEach((index) => {
        dataListExcelSheet[0][index] += ` - ${xlsLanguage?.aggregate}`;
      });
    }

    widgetData?.pie_data?.forEach((item) => {
      const newRow = keysToAccess?.map((label) => item?.[label]);
      dataListExcelSheet?.push(newRow);
    });

    return dataListExcelSheet;
  } catch {}
};
