import { Box, Typography } from "@mui/material";
import SectionBrandingHeader from "./sharedComponents/sectionBrandingHeader";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import enSampleImg from "images/en-branding-chart-sample.svg";
import arSampleImg from "images/ar-branding-chart-sample.svg";
import { useEffect } from "react";
import enSampleImgFalcon from "images/falcon/en-branding-chart-sample-falcon.svg";
import arSampleImgFalcon from "images/falcon/ar-branding-chart-sample-falcon.svg";
import Services from "services/api/Services";

const staticData = {
  header: { title: "branding_posts_interactions", date: "date_range" },
  subHeader: [
    { num: 50, desc: "max_interaction" },
    { num: 78, desc: "min_interaction" },
    { num: 22, desc: "avg_interaction_per_day" },
  ],
};

const PreviewSection = ({ companyBranding, fontName }) => {
  const intl = useIntl();

  //To handle fontFamily with Arabic as it override from App.scss file ...
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--branding-font-family",
      fontName,
    );
  }, [fontName]);

  return (
    <Box className="preview-branding-item">
      <SectionBrandingHeader titleId={"branding_preview_title"} />

      <Box className="slide-container">
        <Box
          className="slide"
          style={{ background: companyBranding?.selected_tertiary_color }}
        >
          <Box className="header" sx={{ fontFamily: `${fontName} !important` }}>
            <Box className="logo">
              <Box
                component={"img"}
                className={!!companyBranding?.logo ? "" : "no-logo"}
                src={
                  companyBranding?.logo instanceof File
                    ? URL.createObjectURL(
                        companyBranding?.logo ? companyBranding?.logo : "",
                      )
                    : companyBranding?.logo
                }
                alt="company-logo"
              />
            </Box>
            <Typography
              variant="h2"
              className="title"
              style={{ color: companyBranding?.primary_color }}
            >
              {CheckValueLocale(staticData?.header?.title, "", {}, intl)}
            </Typography>
            <Typography variant="p" className="date">
              {`(${CheckValueLocale(staticData?.header?.date, "", {}, intl)})`}
            </Typography>
          </Box>
          <Box className="sub-header">
            {staticData?.subHeader?.map((item) => {
              return (
                <Box
                  className="sub-header-item"
                  key={item?.num}
                  sx={{ fontFamily: `${fontName} !important` }}
                >
                  <Typography
                    variant="h3"
                    className="number"
                    style={{
                      color: companyBranding?.secondary_color
                        ? companyBranding?.secondary_color
                        : "#000000",
                    }}
                  >
                    {item?.num}
                  </Typography>
                  <Typography variant="p" className="description">
                    {CheckValueLocale(item?.desc, "", {}, intl)}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <img
            className="slide-chart"
            src={
              Services.project_name_en == "Lucidya"
                ? intl?.locale == "ar"
                  ? arSampleImg
                  : enSampleImg
                : Services.project_name_en === "Falcon"
                  ? intl?.locale == "ar"
                    ? arSampleImgFalcon
                    : enSampleImgFalcon
                  : ""
            }
            alt=""
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PreviewSection;
