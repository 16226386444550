import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  CheckValueLocale,
  handlelUserRoles,
  retrieveActiveProductsArray,
} from "utils/helpers";
import PageHeader from "components/pageHeader";
import CompanyAccountsTab from "./components/companyTab";
import UsersAccountsTab from "./components/usersTab";
import "./style.scss";

const AuthenticationPage = () => {
  const intl = useIntl();
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState("company_accounts");
  const handleSelectedTab = (e, newValue) => {
    setSelectedTab(newValue);
    history?.push({
      ...history?.location,
      pathname: `/settings/accounts_management/${newValue}`,
      needsAttentionData: null, // Set redirected_monitor from history of needs attention click with NULL
    });
  };

  let companyTabPremissions =
    handlelUserRoles("GENERAL", "VIEW_COMPANY_AUTH") &&
    (retrieveActiveProductsArray()?.includes("CXM") ||
      (retrieveActiveProductsArray()?.includes("SM") &&
        retrieveActiveProductsArray()?.includes("ENGAGEMENTS")));

  const checkCompanyAccountsActive = () => {
    if (companyTabPremissions) {
      if (window?.location?.pathname === "/settings/accounts_management") {
        setSelectedTab("company_accounts");
        return history?.push({
          ...history?.location,
          pathname: "/settings/accounts_management/company_accounts",
        });
      }
    } else if (!companyTabPremissions) {
      setSelectedTab("users_accounts");
      return history?.push({
        ...history?.location,
        pathname: "/settings/accounts_management/users_accounts",
      });
    }
  };

  useEffect(() => {
    if (
      history?.location?.pathname === "/settings/accounts_management/" ||
      history?.location?.pathname === "/settings/accounts_management" ||
      history?.location?.pathname ===
        "/settings/accounts_management/company_accounts" ||
      history?.location?.pathname ===
        "/settings/accounts_management/company_accounts/"
    ) {
      checkCompanyAccountsActive();
    } else return setSelectedTab("users_accounts");
  }, [history?.location?.pathname]);

  return (
    <Box id="accounts-management">
      <PageHeader
        title="settings_menu_authentication"
        titleId="accounts-management-header"
      />
      <Box className="tabs-container">
        <Box className="">
          <TabContext value={selectedTab}>
            <TabList
              onChange={handleSelectedTab}
              aria-label="basic tabs example"
            >
              <Tab
                className="tab-button"
                label={CheckValueLocale("company_accounts", "", {}, intl)}
                value="company_accounts"
                disabled={!companyTabPremissions}
              />
              <Tab
                className="tab-button"
                label={CheckValueLocale("users_accounts", "", {}, intl)}
                value="users_accounts"
                disabled={!handlelUserRoles("GENERAL", "VIEW_OTHERS_AUTH")}
              />
            </TabList>
            <TabPanel value="company_accounts">
              {history?.location?.pathname?.includes("company_accounts") ? (
                <CompanyAccountsTab />
              ) : null}
            </TabPanel>
            <TabPanel value="users_accounts">
              {history?.location?.pathname?.includes("users_accounts") ? (
                <UsersAccountsTab />
              ) : null}
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Box>
  );
};
export default AuthenticationPage;
