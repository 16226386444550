import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { Box, Container, Divider } from "@mui/material";
import "./main.scss";
import CircularLoading from "components/circularProgress";
import SnackBar from "components/snackBar";
import CreateDashboardFunction from "./components/createDashboardFunction";
import LiveDashBoardHeader from "./components/liveDashBoardHeader";
import { getDataSources } from "pages/channelAnalytics/channelAnalytics.hooks.js";
import LiveDashBoardFields from "./components/liveDashBoardFields";
import LiveDashBoardActions from "./components/liveDashBoardActions";
import LiveDashBoardDataSources from "./components/liveDashBoardDataSources";
import LiveDashBoardAccess from "./components/liveDashBoardAccess";
import PopupModal from "components/popupModal";
import LiveDashboardDeleted from "pages/liveDashboard/components/liveDashboardDeleted";
import dayjs from "dayjs";

const CreateLiveDashboard = () => {
  const intl = useIntl();
  const [errorMaxDateRange, setErrorMaxDateRange] = useState(false);

  const {
    liveDashboardName,
    selectedWidgets,
    expanded,
    setExpanded,
    loadingMonitorWidgets,
    monitorWidgets,
    isActionLoading,
    success,
    setSuccess,
    snackBarHeading,
    handleChangeCollapse,
    createLiveDashboardHandler,
    editLiveDashboardHandler,
    handleSelectWidget,
    getSourceName,
    monitorLiveDashboardDetails,
    loadingMonitorLiveDashboardDetails,
    handleLiveDashboardDetails,
    dataSources,
    setDataSources,
    deleteLiveDashboard,
    openPopupDelete,
    setOpenPopupDelete,
    isDeleteLoading,
    isEditLiveDashboard,
    getSelectedWidgetsLength,
    getDataSourceTabsSorted,
    getMonitorUrl,
    getSocialIcon,
    handleInputChange,
    liveDashboardNameError,
    dashboardAccessTypeId,
    setDashboardAccessTypeId,
    customDataRange,
    setCustomDataRange,
    dashboardAccessList,
    isLoadingAccessList,
    isDashboardDeleted,
  } = CreateDashboardFunction();

  const isOwnerUser =
    monitorLiveDashboardDetails?.[0]?.user_id ==
    localStorage.getItem("user_id");

  const enabledDeleteEdit =
    !!localStorage.getItem("is_lucidya_account") ||
    handlelUserRoles("SM", "DESTROY_DASHBOARD") ||
    (isOwnerUser && handlelUserRoles("SM", "EDIT_DASHBOARD")); // this for Moderator

  useEffect(() => {
    getDataSources(setDataSources);
  }, []);

  useEffect(() => {
    if (monitorWidgets) {
      const firstDataSource = Object.keys(monitorWidgets || {})?.[0];
      setExpanded(firstDataSource);
      handleChangeCollapse(firstDataSource);
    }
  }, [monitorWidgets]);

  useEffect(() => {
    if (Array?.isArray(monitorLiveDashboardDetails)) {
      handleLiveDashboardDetails();
    }
  }, [monitorLiveDashboardDetails]);

  useEffect(() => {
    setErrorMaxDateRange(false);
    // to reset custom date with any apdate in access type
    if (
      window?.location?.href?.includes("/edit-live-dashboard/") &&
      monitorLiveDashboardDetails?.[0]?.custom_date
    ) {
      const customDateRange =
        monitorLiveDashboardDetails?.[0]?.custom_date?.split("-");
      setCustomDataRange([
        dayjs?.unix(customDateRange?.[0]),
        dayjs?.unix(customDateRange?.[1]),
      ]);
    } else setCustomDataRange([dayjs()?.subtract(29, "days"), dayjs()]);
  }, [dashboardAccessTypeId]);

  const selectedWidgetsLength = Object.keys(selectedWidgets || {})?.find(
    (dataSource) => selectedWidgets?.[dataSource]?.length,
  );
  const actionButtonDisabled =
    !liveDashboardName ||
    isActionLoading ||
    !selectedWidgetsLength ||
    liveDashboardNameError ||
    errorMaxDateRange;

  const loadingCondition =
    loadingMonitorWidgets ||
    loadingMonitorLiveDashboardDetails ||
    !dataSources?.length;
  return (
    <>
      <Container maxWidth="xl" className="live-dashboard-container">
        {loadingCondition ? (
          <CircularLoading />
        ) : isDashboardDeleted ? (
          <LiveDashboardDeleted />
        ) : (
          <>
            <LiveDashBoardHeader
              isEditLiveDashboard={isEditLiveDashboard}
              monitorWidgets={monitorWidgets}
              getMonitorUrl={getMonitorUrl}
            />
            <Box className="create-live-dashboard-container">
              <LiveDashBoardFields
                liveDashboardName={liveDashboardName}
                isEditLiveDashboard={isEditLiveDashboard}
                handleInputChange={handleInputChange}
                liveDashboardNameError={liveDashboardNameError}
              />
              <Divider
                id="dashboard-fields-divider"
                className="live-dashboard-divider"
              />
              {isLoadingAccessList ? (
                <CircularLoading />
              ) : (
                <LiveDashBoardAccess
                  dashboardAccessList={dashboardAccessList}
                  dashboardAccessTypeId={dashboardAccessTypeId}
                  setDashboardAccessTypeId={setDashboardAccessTypeId}
                  customDataRange={customDataRange}
                  setCustomDataRange={setCustomDataRange}
                  disabledPrivateType={
                    !isOwnerUser &&
                    !localStorage.getItem("is_lucidya_account") &&
                    isEditLiveDashboard
                  }
                  setErrorMaxDateRange={setErrorMaxDateRange}
                  errorMaxDateRange={errorMaxDateRange}
                />
              )}

              <Divider
                id="dashboard-access-divider"
                className="live-dashboard-divider"
              />
              <LiveDashBoardDataSources
                monitorWidgets={monitorWidgets}
                getSourceName={getSourceName}
                handleChangeCollapse={handleChangeCollapse}
                expanded={expanded}
                selectedWidgets={selectedWidgets}
                handleSelectWidget={handleSelectWidget}
                getSelectedWidgetsLength={getSelectedWidgetsLength}
                getDataSourceTabsSorted={getDataSourceTabsSorted}
                getSocialIcon={getSocialIcon}
              />
              <LiveDashBoardActions
                isEditLiveDashboard={isEditLiveDashboard}
                actionButtonDisabled={actionButtonDisabled}
                isActionLoading={isActionLoading}
                editLiveDashboardHandler={editLiveDashboardHandler}
                createLiveDashboardHandler={createLiveDashboardHandler}
                setOpenPopupDelete={setOpenPopupDelete}
                enabledDeleteEdit={enabledDeleteEdit}
              />
            </Box>
            {success != null ? (
              <SnackBar
                open={success != null ? true : false}
                handleClose={() => {
                  setSuccess(null);
                }}
                severity={success ? "success" : "error"}
                title={CheckValueLocale(snackBarHeading, "", {}, intl)}
                message={CheckValueLocale(
                  "redirecting_to_your_live_dashboard",
                  "",
                  {},
                  intl,
                )}
              />
            ) : (
              ""
            )}
            {openPopupDelete ? (
              <PopupModal
                title={CheckValueLocale("delete_live_dashboard", "", {}, intl)}
                body={CheckValueLocale(
                  "delete_live_dashboard_text",
                  "",
                  {},
                  intl,
                )}
                leftBtn={CheckValueLocale("cancel", "", {}, intl)}
                rightBtn={CheckValueLocale("delete", "", {}, intl)}
                disabled={isDeleteLoading}
                isRightBtnLoading={isDeleteLoading}
                open={openPopupDelete || false}
                close={() => setOpenPopupDelete(null)}
                accept={deleteLiveDashboard}
                classeName={"popupModal-delet"}
                warning
              />
            ) : null}
          </>
        )}
      </Container>
    </>
  );
};

export default CreateLiveDashboard;
