import { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import ThemesAnalysis from "components/widgets/themes/themeAnalysis/ThemeAnalysis";
import { ExcelFileContext } from "../../../components/exportButton/excel/context/ExcelFileContext";

const CustomDashboardThemesWidget = (props) => {
  const intl = useIntl();
  const { setThemesOptions } = useContext(ExcelFileContext);

  const {
    widget,
    handledWidgetName,
    widgetTitleTooltip,
    widgetsMqttResponses,
    customizedWidgets,
    finalWidgetData,
    dashboardInfo,
    isControlWidget,
    getCustomDashboardView,
    setSnackBarData,
    allMonitorsIdWidget,
    customizedType,
    showControl,
  } = props;

  const { data: themesOptions } = useThemesOptions({ locale: intl?.locale });

  useEffect(() => {
    setThemesOptions(themesOptions);
  }, [themesOptions]);

  return (
    <ThemesAnalysis
      title={handledWidgetName}
      titleToolTip={widgetTitleTooltip || ""}
      data={finalWidgetData?.data?.theme_analysis || []}
      showDownloadIcon={!isControlWidget}
      bigImg
      themeAnalysisPreLoaderDataSources={
        finalWidgetData?.preLoaderMonitorsId || []
      }
      preLoaderTrackerDataSources={allMonitorsIdWidget || []}
      isCustomDashboard // To identify that widget will be rendered in Custom Dashboard
      isControlWidget={isControlWidget} // To Identify that widget will be rendered in widget control sidebar
      // The needed data in widget component for case of Custom Dashboard only
      customDashboardData={{
        dashboardInfo: dashboardInfo, // dashboard info containing {id (dashboard id), product, product_name,}
        handledWidgetName: handledWidgetName, // widget name after  transformation from useWidgetName
        widget: widget, // the widget metric returned from backend in view API
        customizedType: customizedType, // null, aggregate, comparison
        widgetsMqttResponses, // the main state for RabbitMQ responses
        customizedWidgets, // cuztomized widget state for RabbitMQ responses
        getCustomDashboardView, // call back view widgets after any update in page
        setSnackBarData, // set snackbar related to main index view
        showControl, // show widget control icon
      }}
      themesList={themesOptions}
    />
  );
};

export default CustomDashboardThemesWidget;
