import { Box } from "@mui/material";
import { useState } from "react";
import UserManagementTable from "./components/userManagementTable/userManagementTable";
import HeaderUserPage from "./components/headerUserPage/headerUserPage";
import "./style.scss";

const UserManagementPage = () => {
  const [companyReachedMaxUser, setCompanyReachedMaxUser] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);

  return (
    <Box id="user_management">
      <HeaderUserPage
        companyReachedMaxUser={companyReachedMaxUser}
        companyUsers={companyUsers}
        setCompanyUsers={setCompanyUsers}
      />
      <UserManagementTable
        setCompanyReachedMaxUser={setCompanyReachedMaxUser}
        companyUsers={companyUsers}
        setCompanyUsers={setCompanyUsers}
      />
    </Box>
  );
};
export default UserManagementPage;
