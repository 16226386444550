import LiveDashboard from "../api/liveDashboard";

var LiveDashboardController = {};

LiveDashboardController.getMonitorWidgets = (queryData) => {
  return new Promise(function (resolve, reject) {
    LiveDashboard.getMonitorWidgets(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

LiveDashboardController.getMonitorLiveDashboardDetails = (queryData) => {
  return new Promise(function (resolve, reject) {
    LiveDashboard.getMonitorLiveDashboardDetails(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({
          data: error?.response?.data?.data,
          errorMsg: error?.response?.data?.msg || error?.response?.data?.error,
        });
      });
  });
};

LiveDashboardController.getMonitorLiveDashboardPublicDetails = (queryData) => {
  return new Promise(function (resolve, reject) {
    LiveDashboard.getMonitorLiveDashboardPublicDetails(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        reject({
          data: error?.response?.data?.data,
          errorMsg: error?.response?.data?.msg || error?.response?.data?.error,
        });
      });
  });
};

LiveDashboardController.getMonitorLiveDashboardList = (queryData) => {
  return new Promise(function (resolve, reject) {
    LiveDashboard.getMonitorLiveDashboardList(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

LiveDashboardController.createLiveDashboard = (queryData) => {
  return new Promise(function (resolve, reject) {
    LiveDashboard.createLiveDashboard(queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

LiveDashboardController.editLiveDashboard = (queryData) => {
  return new Promise(function (resolve) {
    LiveDashboard.editLiveDashboard(queryData)
      .then(function (response) {
        resolve({
          data: response?.data || [],
          status_code: response?.status,
        });
      })
      .catch(function (error) {
        resolve({
          data: [],
          errorMsg: error,
        });
      });
  });
};

LiveDashboardController.deleteLiveDashboard = (queryData) => {
  return new Promise(function (resolve, reject) {
    LiveDashboard.deleteLiveDashboard(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
          status: response?.request?.status,
        });
      })
      .catch((error) => {
        reject({
          errorMsg: error,
          status: error?.request?.status,
        });
      });
  });
};

LiveDashboardController.getAccessTypes = (queryData) => {
  return new Promise(function (resolve, reject) {
    LiveDashboard.getAccessTypes(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

LiveDashboardController.authenticationPublicLiveDashboard = (queryData) => {
  return new Promise(function (resolve, reject) {
    LiveDashboard.authenticationPublicLiveDashboard(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error?.response?.data?.msg || error?.response?.data?.error,
        });
      });
  });
};

LiveDashboardController.getDatasources = () => {
  return new Promise((resolve, reject) => {
    LiveDashboard.getDatasources()
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

export default LiveDashboardController;
