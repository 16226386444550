export const timeOffsets = [
  { name: "(UTC/GMT -1:00) Azores, Praia", value: -1 },
  {
    name: "(UTC/GMT 0:00) Dublin, Edinburgh, Lisbon, London, Monrovia, São Tomé",
    value: 0,
  },
  {
    name: "(UTC/GMT +1:00) Bratislava, Budapest, Prague, Brussels, Madrid, Paris, Skopje, Warsaw",
    value: 1,
  },
  {
    name: "(UTC/GMT +2:00) Athens, Bucharest, Beirut, Cairo, Chisinau, Gaza, Hebron, Harare, Pretoria",
    value: 2,
  },
  {
    name: "(UTC/GMT +3:00) Amman, Baghdad, Damascus, Istanbul, Kuwait, Riyadh, Moscow, Nairobi",
    value: 3,
  },
  {
    name: "(UTC/GMT +4:00) Abu Dhabi, Muscat, Astrakhan, Baku, Tbilisi, Samara, Port Louis, Saratov",
    value: 4,
  },
  {
    name: "(UTC/GMT +5:00) Ashgabat, Astana, Tashkent, Islamabad, Karachi, Yekaterinburg",
    value: 5,
  },
  { name: "(UTC/GMT +6:00) Bishkek, Dhaka, Omsk", value: 6 },
  {
    name: "(UTC/GMT +7:00) Bangkok, Hanoi, Jakarta, Hovd, Krasnoyarsk, Novosibirsk, Tomsk",
    value: 7,
  },
  {
    name: "(UTC/GMT +8:00) Beijing, Chongqing, Hong Kong, Singapore, Perth, Taipei, Ulaanbaatar",
    value: 8,
  },
  {
    name: "(UTC/GMT +9:00) Chita, Osaka, Sapporo, Tokyo, Pyongyang, Seoul, Yakutsk",
    value: 9,
  },
  {
    name: "(UTC/GMT +10:00) Brisbane, Canberra, Melbourne, Sydney, Guam, Hobart, Vladivostok",
    value: 10,
  },
  {
    name: "(UTC/GMT +11:00) Bougainville Island, Chukwurah, Magadan, Norfolk Island, Sakhalin",
    value: 11,
  },
  {
    name: "(UTC/GMT +12:00) Anadyr, Auckland, Wellington, Coordinated Universal Time +12",
    value: 12,
  },
];
