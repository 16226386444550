import moment from "moment";

// to return header name for secnod Column by check widget name
export const handleNameForSecondColumnLineAndBarChart = (widgetName) => {
  switch (widgetName) {
    // line charts
    case "posts_volume":
    case "conversation_interactions":
      return "posts_word";
    case "comments_volume":
      return "comments";
    case "page_views":
      return "impression";
    case "engagers_volume":
      return "engagers";
    case "videos_views":
      return "views";
    case "video_volume":
      return "video_content_type";
    ////////////////////////
    default:
      return "number"; // all bar charts is nubmer
  }
};

/////////////////// Bar (aggregate && defualt && comparison) & Line (aggregate && defualt) ////////////////////////////////////
// widgetData ===> [{name: unix , value: number} , ..........]
// return ===> (aggregate- defualt) [[date , headerName] , [10-2-2022 , 20] , ............]
// return ===> (comparison) [[monitor  , headerName ] , [nameMonitor , 100] , ............]
export const handleDataBarAndLineChartToExcel = (
  widgetData,
  widgetName,
  xlsLanguage,
  customizedType,
) => {
  try {
    // if all bar chart with excel sheet are constant two column (date , number)
    // using "handleNameForSecondColumnLineAndBarChart" -->  to return header name for secnod Column by check widget name
    const dataListExcelSheet = [
      [
        xlsLanguage?.date,
        xlsLanguage?.[handleNameForSecondColumnLineAndBarChart(widgetName)],
      ],
    ];

    //to set header of excel file
    if (customizedType === "aggregate")
      dataListExcelSheet[0][1] += " - " + xlsLanguage?.aggregate;

    //to set the Body of excel with check customizedType
    // aggregate , defualt ---> [ date , number]
    widgetData?.forEach((item) => {
      dataListExcelSheet?.push([
        moment.unix(item?.name).utc().format("YYYY-MM-DD"),
        Math.floor(item?.value),
      ]);
    });

    return dataListExcelSheet;
  } catch {}
};

export const handleDataBarChartComparisonToExcel = (
  widgetData,
  xlsLanguage,
) => {
  try {
    // if all bar chart with excel sheet are constant two column (date , number)
    const dataListExcelSheet = [[xlsLanguage?.date]];
    const finalDataArray = []; //to push all data in the value array for each row

    widgetData?.forEach((monitor, indexMonitor) => {
      // to set number with monitor name in the header
      dataListExcelSheet?.[0]?.push(
        `${xlsLanguage?.number} - ${monitor?.monitorName}`,
      );

      monitor?.data?.forEach((item, indexRow) => {
        if (indexMonitor === 0) {
          //set value for (first label and first monitor)
          finalDataArray?.push([
            moment.unix(item?.name).utc().format("YYYY-MM-DD"),
            Math.floor(item?.value),
          ]);
        } else {
          //to push the value in the same row (date)
          finalDataArray?.[indexRow]?.push(Math.floor(item?.value));
        }
      });
    });

    //to push each row after append all data for all monitors
    finalDataArray?.forEach((row) => {
      dataListExcelSheet?.push(row);
    });
    return dataListExcelSheet;
  } catch {}
};
