import { useQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useGetTeamDetailsData = (teamId) => {
  return useQuery({
    queryKey: ["teamDetailsData", teamId],
    queryFn: () => CompaniesController.getTeam(teamId),
    enabled: !!teamId,
    retry: false,
  });
};
