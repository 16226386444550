import _ from "lodash";

// TODO we need to try setState here
// ******************* PIE CHART Common Function **************************** //
// This function will be used to handle aggregate data for piecharts charts
// parameters:
// newArrayToAggregate (Array) required: The value of new array that should be added to array that will hold all data
// finalResult (Array) required: The value of final array that will hold all data to be displayed in chart
// Note: Each array should be array of objects where each object should have Name & Value
const handleAggregatePieData = (
  newArrayToAggregate,
  finalResult,
  keyName = "name",
  keyValue = "value",
  sort,
) => {
  let newArrayToBeAdded = [];
  var found = false;
  if (finalResult?.length > 0) {
    newArrayToAggregate?.forEach(function (a) {
      finalResult?.some(function (b) {
        found = false;
        if (a?.[keyName] === b?.[keyName]) {
          b[keyValue] += a?.[keyValue];
          found = true;
          return true;
        }
      });
      if (!found) {
        newArrayToBeAdded.push(_.cloneDeep(a));
      }
    });
  } else {
    finalResult.push(..._.cloneDeep(newArrayToAggregate));
  }
  finalResult.push(..._.cloneDeep(newArrayToBeAdded));
  if (sort) {
    finalResult?.sort((a, b) => (a?.[keyValue] < b?.[keyValue] ? 1 : -1));
  }
};
// TODO we need to try setState here
// ******************* Table Charts Common Function **************************** //
// This function will be used to handle aggregate data for table charts
// parameters:
// newArrayToAggregate (Array) required: The value of new array that should be added to array that will hold all data
// finalResult (Array) required: The value of final array that will hold all data to be displayed in chart
//keyName: (Ex: id) to check if the same to aggegate
//keysValueArray: array of key to aggregate with the same key in another monitor
// Note: Each array should be array of objects where each object should have id & value (it may be more than one)
const handleAggregateTableData = (
  newArrayToAggregate,
  finalResult,
  keyName,
  keysValueArray,
  sort,
) => {
  let newArrayToBeAdded = [];
  var found = false;
  if (finalResult?.length > 0) {
    newArrayToAggregate?.forEach(function (a) {
      finalResult?.some(function (b) {
        found = false;
        if (a?.[keyName] === b?.[keyName]) {
          keysValueArray?.forEach((keyValue) => {
            b[keyValue] += a[keyValue];
          });
          found = true;
          return true;
        }
      });
      if (!found) {
        newArrayToBeAdded.push(_.cloneDeep(a));
      }
    });
  } else {
    finalResult.push(..._.cloneDeep(newArrayToAggregate));
  }
  finalResult.push(..._.cloneDeep(newArrayToBeAdded));
  if (sort) {
    finalResult?.sort((a, b) =>
      a?.[keysValueArray?.[0]] < b?.[keysValueArray?.[0]] ? 1 : -1,
    );
  }
};
// ************************************************************************************** //
// ******************* Line Chart Common Function *************************************** //
// This function will be used to handle aggregate data for Line chart that has more than one line
// parameters:
// newObjectToAggregate (object) required: the object that contains keys where each key has array of objects, for example: {reach: [], impression: {}}
// finalResult (object) required: The value of final object that will hold all data to be displayed in chart
const handleAggregateLineChartMultipleValues = (
  newObjectToAggregate,
  finalResult,
  sort,
) => {
  let found;
  if (Object.keys(finalResult)?.length !== 0) {
    for (const fianlResultObject in finalResult) {
      for (const newObjectToAggregateObject in newObjectToAggregate) {
        if (fianlResultObject === newObjectToAggregateObject) {
          // Merge Equal data Together
          if (finalResult[fianlResultObject]?.length > 0) {
            var newArrayToBeAdded = [];
            newObjectToAggregate[newObjectToAggregateObject]?.forEach(
              function (a) {
                finalResult[fianlResultObject]?.some(function (b) {
                  found = false;
                  if (a?.name === b?.name) {
                    for (const key in b) {
                      // to sum anther keys EX ( value , native_value (to show Box Labels))
                      if (key !== "name") {
                        b[key] += a?.[key];
                      }
                    }
                    found = true;
                    return true;
                  }
                });
                if (!found) {
                  newArrayToBeAdded.push(_.cloneDeep(a));
                }
              },
            );
            finalResult[fianlResultObject].push(
              ..._.cloneDeep(newArrayToBeAdded),
            );
          } else {
            finalResult[fianlResultObject].push(
              ..._.cloneDeep(newObjectToAggregate[newObjectToAggregateObject]),
            );
          }
        }
      }
    }
  } else {
    for (const key in newObjectToAggregate) {
      finalResult[key] = _.cloneDeep(newObjectToAggregate?.[key]);
    }
  }
  if (sort) {
    finalResult?.sort((a, b) => (a.name > b.name ? 1 : -1));
  }
};

// ************************************************************************************** //
// ******************* Stacked Bar Chart Common Function *************************************** //
// This function will be used to handle aggregate data legends
// parameters:
// newArrayToAggregate (Array) required: The value of new array that should be added to array that will hold all data
// finalResult (Array) required: The value of final array that will hold all data to be displayed as bar chart
const handleAggregateStaskedBarCharts = (
  newArrayToAggregate,
  finalResult,
  sort,
) => {
  var found;
  if (finalResult?.length > 0 && newArrayToAggregate?.length > 0) {
    var newArrayToBeAdded = [];
    newArrayToAggregate?.forEach(function (a) {
      finalResult?.some(function (b) {
        found = false;
        if (a?.name === b?.name) {
          let sum = b?.value?.map((num, idx) => {
            return num + (a?.value?.[idx] ? a?.value?.[idx] : 0);
          });

          b.value = sum;
          found = true;
          return true;
        }
      });
      if (!found) {
        newArrayToBeAdded.push(_.cloneDeep(a));
      }
    });
    finalResult.push(..._.cloneDeep(newArrayToBeAdded));
  } else {
    finalResult.push(..._.cloneDeep(newArrayToAggregate));
  }
  if (sort) {
    finalResult?.sort((a, b) => (a.name > b.name ? 1 : -1));
  }
};
// ************************************************************************************** //
// ******************* PunchCard Common Function *************************************** //
const handleAggregatePunchCardCharts = (newArrayToAggregate, finalResult) => {
  if (finalResult?.length > 0) {
    newArrayToAggregate?.forEach(function (a, index) {
      finalResult[index][2] += a[2];
    });
  } else {
    finalResult.push(..._.cloneDeep(newArrayToAggregate));
  }
};
// ************************************************************************************** //
// ******************* ThemesCharts Common Function *************************************** //
// This function will be used to handle aggregate data ThemesChart
// parameters:
// newObjectToAggregate (Object) required: the object that contains keys where each key has objects or Values(number),
//                                        for example: { theme_analysis:{},theme_trend:{}}
// finalResult (object) required: The value of final object that will hold all data to be displayed in chart
// **** handleSumForTowObjects : to aggregate any values(number) in any level object
const handleAggregateThemesCharts = (newObjectToAggregate, finalResult) => {
  if (Object.keys(finalResult)?.length !== 0) {
    handleSumForTowObjects(newObjectToAggregate, finalResult);
  } else {
    for (const key in newObjectToAggregate) {
      finalResult[key] = _.cloneDeep(newObjectToAggregate?.[key]);
    }
  }
};

const handleSumForTowObjects = (newObjectToAggregate, finalResult) => {
  for (const key in newObjectToAggregate) {
    if (finalResult?.[key] === undefined) {
      finalResult[key] = newObjectToAggregate?.[key];
    } else {
      if (
        typeof finalResult?.[key] === "number" &&
        typeof newObjectToAggregate?.[key] === "number"
      ) {
        finalResult[key] += newObjectToAggregate?.[key];
      } else {
        handleSumForTowObjects(newObjectToAggregate?.[key], finalResult?.[key]);
      }
    }
  }
};

// **************************************************************************************.

// ******************* with all type of charts *************************************** //
// handleMapAggregate to handle mapping on each element in the list and call specific aggregate function with chart type
// parameters:
//         --monitorsWigetslist : array of arrays or objects
//         --handleAggregateSetChart : name of specific aggregate function to callBack
//         --typeOf : to pass "object" if typeOf(monitorsWigetslist) is object
// return : final array after aggregate.
const handleMapAggregate = (
  monitorsWigetslist,
  handleAggregateSetChart,
  typeOf = "array",
) => {
  const finalResult = typeOf === "array" ? [] : {};
  monitorsWigetslist?.map((widgetData) => {
    handleAggregateSetChart(widgetData, finalResult);
  });
  return finalResult;
};

// ******************* Pie Chart Function *************************************** //
// handleFormatDataToPieChart to handle pie_data format for pass to aggregate.
// parameters:
// customizedMonitorsWigets :all monitors data widget. array of objects [{pie_data:[...] , monitorId:.. , dataSource:.... , ... }]
// return : final object for pass to chart.
// variables :
//            --monitorsWigetslist to create array of arrays(final pie_data  to pass AggregateFunctions) [{name : ... , value:..} , ...]
//            --datawidgetList to save return from handleMapAggregate
//     if have pie_sub_data
//            --pieSubMonitorsWigetslist to create array of arrays(final pie_sub_data  to pass AggregateFunctions) [{name : ... , value:..} , ...]
//            --pieSupDatawidgetList to save return from handleMapAggregate
const handleFormatDataToPieChart = (customizedMonitorsWigets) => {
  const monitorsWigetslist = customizedMonitorsWigets?.map((dataItem) => [
    ...(_.cloneDeep(dataItem?.pie_data) || []),
  ]);
  const datawidgetList = handleMapAggregate(
    monitorsWigetslist,
    handleAggregatePieData,
  );
  // this block run if this widget have sub data
  if (customizedMonitorsWigets?.[0]?.pie_sub_data) {
    const pieSubMonitorsWigetslist = customizedMonitorsWigets?.map(
      (dataItem) => [...(_.cloneDeep(dataItem?.pie_sub_data) || [])],
    );
    const pieSupDatawidgetList = handleMapAggregate(
      pieSubMonitorsWigetslist,
      handleAggregatePieData,
    );
    // Return data to the same shape in the widget if it have pie_sub_data
    return { pie_data: datawidgetList, pie_sub_data: pieSupDatawidgetList };
  }
  // Return data to the same shape in the widget if it haven't pie_sub_data
  return { pie_data: datawidgetList };
};

// ******************* Bar & Line Chart Function *************************************** //
// handleFormatDataToBarAndLineCharts to handle data format for pass to aggregate and calculating total for anther keys in object with the same keys in anther monitors.
// parameters: (customizedMonitorsWigets) :all monitors data widget. array of objects [{data:[...] , total: Number , monitorId:.. , dataSource:.... , ... }]
// return : final object for pass to chart.
// variables :
//            --monitorsWigetslist to create array of arrays(final data to pass AggregateFunctions) [{name : ... , value:..} , ...]
//            --antherKeyToSumTotal to create object of anther
//            --barSupDatawidgetList to save return from handleMapAggregate
const handleFormatDataToBarAndLineCharts = (customizedMonitorsWigets) => {
  const antherKeyToSumTotal = {};
  const monitorsWigetslist = [];

  customizedMonitorsWigets?.forEach((dataItem) => {
    monitorsWigetslist?.push(_.cloneDeep(dataItem?.data) || []);
    for (const key in dataItem) {
      if (key !== "data" && key !== "monitorId" && key !== "dataSource") {
        antherKeyToSumTotal[key] =
          +(antherKeyToSumTotal[key] || 0) + dataItem[key];
      }
    }
  });

  const barSupDatawidgetList = handleMapAggregate(
    monitorsWigetslist,
    handleAggregatePieData, // to aggregate two array of object ex: array1[{name : .... , value:...} , .....] array2[{name:..., value:...} , ...]
  );
  return { ...antherKeyToSumTotal, data: barSupDatawidgetList };
};

// ******************* StackedLine Chart Function *************************************** //
// handleFormatDataToStackedLineCharts to handle data and pie_data format for pass to aggregate.
// parameter: (customizedMonitorsWigets) :all monitors data widget. array of objects [{data:{...} , pie_data:[...] , monitorId:.. , dataSource:.... , ... }]
// return : final object for pass to chart.
// variables :
//            --monitorsWigetslistData to create array of objects(final data to pass AggregateFunctions) [{Positive :[] , :Negative:[] , Neutral:[]} , ...]
//            --monitorsWigetslistPieData to create array of arrays(final pie_data to pass AggregateFunctions) [[{name : ... , value:..} , ...],...]
//            --lineDatawidgetListData to save return from handleMapAggregate with monitorsWigetslistData
//            --linePieDatawidgetListPieData to save return from handleMapAggregate with monitorsWigetslistPieData
const handleFormatDataToStackedLineCharts = (customizedMonitorsWigets) => {
  const monitorsWigetslistData = [];
  const monitorsWigetslistPieData = [];

  customizedMonitorsWigets?.forEach((dataItem) => {
    monitorsWigetslistData?.push(_.cloneDeep(dataItem?.data) || {});
    monitorsWigetslistPieData?.push(_.cloneDeep(dataItem?.pie_data) || []);
  });

  //lineDatawidgetListData to save return from handleMapAggregate
  const lineDatawidgetListData = handleMapAggregate(
    monitorsWigetslistData, // this array have objects not arrays so I pass "object" to declare  finalResult variable is an object
    handleAggregateLineChartMultipleValues,
    "object",
  );
  const linePieDatawidgetListPieData = handleMapAggregate(
    monitorsWigetslistPieData,
    handleAggregatePieData,
  );
  return {
    data: lineDatawidgetListData,
    pie_data: linePieDatawidgetListPieData,
  };
};

// ******************* StackedBar Chart Function *************************************** //
// handleFormatDataToStackedLineCharts to handle data and pie_data format for pass to aggregate.
// parameter: (customizedMonitorsWigets) :all monitors data widget. array of objects [{data:[...] , pie_data:[...] , monitorId:.. , dataSource:.... , ... }, ...]
// return : final object for pass to chart.
// variables :
//            --monitorsWigetslistData to create array of arrays(final data to pass AggregateFunctions) [ [{name :... , :value:[]} , ...] , ...]
//            --monitorsWigetslistPieData to create array of arrays(final pie_data to pass AggregateFunctions) [ [{name : ... , value:...} , ...], ...]
//            --listLabelsEachMonitor to create array of labels data each monitor [["label1" , "label2" , "label3"] ,["label1" , "label5"] ....]
//            --antherKeyToSumTotal to create object of anther
//            --lineDatawidgetListData to save return from handleMapAggregate with monitorsWigetslistData
//            --linePieDatawidgetListPieData to save return from handleMapAggregate with monitorsWigetslistPieData
const handleFormatDataToStackedBarCharts = (customizedMonitorsWigets) => {
  const monitorsWigetslistData = [],
    monitorsWigetslistPieData = [],
    listLabelsEachMonitor = [],
    antherKeyToSumTotal = {};

  customizedMonitorsWigets?.forEach((dataItem) => {
    monitorsWigetslistData?.push(_.cloneDeep(dataItem?.data) || []);
    monitorsWigetslistPieData?.push(_.cloneDeep(dataItem?.pie_data) || []);
    listLabelsEachMonitor?.push(dataItem?.pie_data?.map((item) => item?.name));
    for (const key in dataItem) {
      if (key !== "data" && key !== "monitorId" && key !== "dataSource") {
        antherKeyToSumTotal[key] =
          +(antherKeyToSumTotal[key] || 0) + dataItem[key];
      }
    }
  });

  const PieDatawidgetListPieData = handleMapAggregate(
    monitorsWigetslistPieData,
    handleAggregatePieData,
  );

  handelDataWidgetWithSortLabels(
    PieDatawidgetListPieData,
    monitorsWigetslistData,
    listLabelsEachMonitor,
  );
  const DatawidgetListData = handleMapAggregate(
    monitorsWigetslistData,
    handleAggregateStaskedBarCharts,
  );

  return {
    ...antherKeyToSumTotal,
    data: DatawidgetListData,
    pie_data: PieDatawidgetListPieData,
  };
};

// handelDataWidgetWithSortLabels to sort and add values to array of labels in each object(day) in monitor data.
// parameter:
//          **(pieDAta) : is an array of objects for all monitors after aggregated [{name:"label1" , value:4135} , ....]
//          **(dataWidgets) : is an array of arrays for data each monitor [[{name:unix , value:[1,2,3]} , ....] ,[{name:unix , value:[1,5]} , ....] ....]
//          **(listLabelsEachMonitor) : is an array of arrays for labels data each monitor [["label1" , "label2" , "label3"] ,["label1" , "label5"] ....]
// variables :
//            --(labelsIndex) to create object of labels with index in pieData  {label1 : 2 , label2 : 1 , label3 : 3 , label5 : 0 }
//            --(labelsMonitor) to create array of labels for each monitor ["label1" , "label2" , "label3"]
//            --(responseValues) to create array of zeros with length of all labels in pieData [0,0,0,0]
//            --(indexLabel) to save the index of this label if it is found in this monitor to set the value of this label in responseValues.
const handelDataWidgetWithSortLabels = (
  pieData,
  dataWidgets,
  listLabelsEachMonitor,
) => {
  const labelsIndex = {};
  pieData?.forEach((item, index) => {
    labelsIndex[item?.name] = index;
  });
  dataWidgets?.forEach((widget, index) => {
    const labelsMonitor = listLabelsEachMonitor?.[index];
    widget?.forEach((item, indexItem) => {
      const responseValues = Array(Object.keys(labelsIndex)?.length)?.fill(0);
      Object.keys(labelsIndex)?.forEach((label) => {
        const indexLabel = labelsMonitor?.indexOf(label);
        if (indexLabel !== -1)
          responseValues[labelsIndex?.[label]] = item?.value?.[indexLabel];
      });
      dataWidgets[index][indexItem].value = responseValues;
    });
  });
};

// ******************* Table Chart Function *************************************** //
// handleFormatDataToTableChart to handle pie_data format for pass to aggregate.
// parameters:
// customizedMonitorsWigets :all monitors data widget. array of objects [{pie_data:[...] , monitorId:.. , dataSource:.... , ... }]
// return : final object for pass to chart.
// variables :
//            --monitorsWigetslist to create array of arrays(final pie_data  to pass AggregateFunctions) [{name : ... , value:..} , ...]
//            --finalResult to save return from handleAggregatePieData, it is sorted.
const handleFormatDataToTableChart = (customizedMonitorsWigets) => {
  const monitorsWigetslist = customizedMonitorsWigets?.map((dataItem) => [
    ...(_.cloneDeep(dataItem?.pie_data) || []),
  ]);

  const finalResult = [];
  const keysValueArray =
    monitorsWigetslist?.find((monitor) => monitor?.length)?.[0]?.[
      "stats_count"
    ] !== undefined
      ? ["stats_count"]
      : monitorsWigetslist?.find((monitor) => monitor?.length)?.[0]?.[
            "followers_count"
          ] !== undefined
        ? ["followers_count", "total_engagements"]
        : ["value"];
  monitorsWigetslist?.map((widgetData) => {
    handleAggregateTableData(
      widgetData,
      finalResult,
      "id",
      keysValueArray,
      true,
    );
  });

  return { pie_data: finalResult };
};

// ******************* Punch Chart Function *************************************** //
// handleFormatDataToPunchChart to handle activity_data format for pass to aggregate.
// parameters:
// customizedMonitorsWigets :all monitors data widget. array of objects [{activity_data:[...] , monitorId:.. , dataSource:.... , ... }]
// return : final object for pass to chart.
// variables :
//            --monitorsWigetslist to create array of arrays(final activity_data  to pass AggregateFunctions) [[[0,0,0] , ...],...]
//            --antherKeyToMinMax to save max and min value.
//            --datawidgetListPieData to save return from handleAggregatePunchCardCharts,
const handleFormatDataToPunchChart = (customizedMonitorsWigets) => {
  const monitorsWigetslist = [],
    antherKeyToMinMax = {};

  customizedMonitorsWigets?.forEach((dataItem) => {
    monitorsWigetslist?.push(_.cloneDeep(dataItem?.activity_data) || []);
    for (const key in dataItem) {
      if (key == "max_value" || key == "min_value") {
        antherKeyToMinMax[key] = handelMinMaxTwoValues(
          antherKeyToMinMax?.[key],
          dataItem?.[key],
          key,
        );
      }
    }
  });

  //to sort all monitors by days and hours before aggregate
  monitorsWigetslist?.forEach((monitor) =>
    monitor?.sort((a, b) => {
      if (a?.[0] === b?.[0]) {
        return a?.[1] - b?.[1]; // to sort by hours
      }
      return a?.[0] - b?.[0]; // to sort by days
    }),
  );

  const datawidgetListPieData = handleMapAggregate(
    monitorsWigetslist,
    handleAggregatePunchCardCharts,
  );

  return { ...antherKeyToMinMax, activity_data: datawidgetListPieData };
};
const handelMinMaxTwoValues = (oldValue, newValue, key) => {
  if (oldValue !== undefined) {
    if (oldValue < newValue && key == "max_value") return newValue;
    else if (oldValue > newValue && key == "min_value") return newValue;
    else return oldValue;
  } else {
    return newValue;
  }
};

// ******************* Themes Chart Function *************************************** //
// handleFormatDataToThemesChart to handle data format for pass to aggregate.
// parameters:
// customizedMonitorsWigets :all monitors data widget. array of objects [{data:{...} , monitorId:.. , dataSource:.... , ... }]
// return : final object for pass to chart.
// variables :
//            --monitorsWigetslist to create array of objects(final data  to pass AggregateFunctions)
//                                 [{ theme_analysis:{},theme_trend:{}},...]
//            --datawidgetListPieData to save return from handleAggregatePunchCardCharts,
//                                 { theme_analysis:{},theme_trend:{}}
const handleFormatDataToThemesChart = (customizedMonitorsWigets) => {
  const monitorsWigetslist = [];

  customizedMonitorsWigets?.forEach((dataItem) => {
    monitorsWigetslist?.push(_.cloneDeep(dataItem?.data) || {});
  });

  const datawidgetListPieData = handleMapAggregate(
    monitorsWigetslist,
    handleAggregateThemesCharts,
    "object",
  );

  return { data: datawidgetListPieData };
};

export {
  handleAggregatePunchCardCharts,
  handleFormatDataToPieChart,
  handleFormatDataToBarAndLineCharts,
  handleFormatDataToStackedLineCharts,
  handleFormatDataToStackedBarCharts,
  handleFormatDataToTableChart,
  handleFormatDataToPunchChart,
  handleFormatDataToThemesChart,
};
