import { useQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useGetCompanySubscriptions = () => {
  return useQuery({
    queryKey: ["companySubscriptions"],
    queryFn: () => CompaniesController.getCompanySubscriptions(),
    enabled: false,
    select: (data) =>
      data.data?.data?.attributes?.engagement_package?.attributes,
    retry: false,
  });
};
