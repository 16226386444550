import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Box } from "@mui/material";
import PageHeader from "components/pageHeader";

const HeaderUserPage = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const { companyReachedMaxUser } = props;

  return (
    <Box>
      <Box className="user-management-button">
        <PageHeader title="user_managements" />

        {handlelUserRoles("GENERAL", "CREATE_USER") && (
          <Box>
            <LucButton
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              disabled={companyReachedMaxUser}
              onClick={() => history?.push("/settings/user_managements/add")}
              id="settings-user-managment-table-add-user-btn"
            >
              {CheckValueLocale("new_user", "", {}, intl)}
            </LucButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HeaderUserPage;
