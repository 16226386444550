import { useQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useEngagPermissions = (enabled) => {
  return useQuery({
    queryKey: ["engagPermissions", enabled], // put enabled here to don't return true with isFetched when go to anther file(component)
    queryFn: () => CompaniesController.getEngagPermissions(),
    enabled: enabled,
    retry: false,
  });
};
