import moment from "moment";
import _ from "lodash";

//to handle header name for first column in the sheet for stckedBar widget
const handleNameForFirstColumn = (widgetName) => {
  switch (widgetName) {
    case "gender_age_distribution":
      return "age";
    case "reach_funnel":
    case "posts_reach":
      return "reach_funnel";

    default:
      return "date";
  }
};

// to handle data in the first column for each row
const handleNameForFirstColumnWithEachRow = (
  nameFirstColumn,
  xlsLanguage,
  name,
) => {
  return nameFirstColumn == "date"
    ? moment.unix(name).utc().format("YYYY-MM-DD")
    : xlsLanguage?.[name]
      ? xlsLanguage?.[name]
      : name;
};

// to calc total and push in the end
const handleTotalAndPush = (newItem) => {
  const totalRow = newItem?.value?.reduce((total, element) => {
    return total + Math.floor(element);
  }, 0);
  newItem?.value?.push(totalRow);
};

/////////////////// StackedBar (aggregate && defualt ) ////////////////////////////////////
// widgetData ===> [ {data:[ {name:unix/label , value: [numer1 , number2]} , ... ] , pieData:[ {name:"label1" , value: number} , ...] }]
// return ===> (aggregate- defualt) ---->  [[date , "label1"  , "label2" ...., total] , [ "5-5-2025" , 30 , 10 .... , 100] , ............]
export const handleDataStackedBarChartsToExcel = (
  widgetData,
  widgetName,
  xlsLanguage,
  customizedType,
) => {
  try {
    //set first header name for the sheet is "date"
    const nameFirstColumn = handleNameForFirstColumn(widgetName);
    const dataListExcelSheet = [[xlsLanguage?.[nameFirstColumn]]];

    //set name of header for anther columns
    const listHeaders = widgetData?.pie_data?.map((item) => item?.name) || [];

    // to add colume for total (not  add total with "posts_reach")
    widgetName !== "posts_reach" && listHeaders?.push("total");

    if (customizedType === "aggregate") {
      //to add translate of "aggregate" to name
      listHeaders?.forEach((name) => {
        dataListExcelSheet?.[0]?.push(
          `${xlsLanguage?.[name]} - ${xlsLanguage?.aggregate}`,
        );
      });
    } else {
      listHeaders?.forEach((name) => {
        dataListExcelSheet?.[0]?.push(xlsLanguage?.[name]);
      });
    }

    //set data for each item in data
    widgetData?.data?.forEach((item) => {
      //set date in the first column
      const newRow = [
        handleNameForFirstColumnWithEachRow(
          nameFirstColumn,
          xlsLanguage,
          item?.name,
        ),
      ];

      let totalCount = 0;
      listHeaders?.forEach((name, indexColumn) => {
        if (name == "total") {
          newRow?.push(totalCount);
        } else {
          newRow?.push(Math.floor(item?.value?.[indexColumn]));
          totalCount += Math.floor(item?.value?.[indexColumn]);
        }
      });

      dataListExcelSheet?.push(newRow);
    });

    return dataListExcelSheet;
  } catch {}
};

/////////////////// StackedBar ( Comparison ) ////////////////////////////////////
// widgetData?.dataExcel ===> [ {monitorName: "" , data:[ {name:unix/label , value: [numer1 , number2]} , ... ] , pieData:[ {name:"label1" , value: number} , ...] }]
// return ===> (aggregate- defualt) ---->  [[date , "label1-m1"  , "label2-m1" ...., total-m1 , "label1-m2"  , "label2-m2" ...., total-m2 ] , [ "5-5-2025" , 30 , 10 .... , 100 , ..........] , ............]
// special case : with "reach_funnel" ---> no have pie_data , i will set static ---> ["Retweets", "original", "total"]
///"reach_funnel" widgetData --> {authors:[{ moniror1 :"" , value:[1,2] }] , potential_impression:[{ moniror1 :"" , value:[10,20] }] , potential_reach: [{ moniror1 :"" , value:[5,4] }] }
/// return ==> [ [reach_funnel , "Retweets-m1" , "original-m1" , "total-m1" ,.....] , [authors , 10 , 40  ,50] , [potential_impression ,...] , [potential_reach,...] ]
export const handleStackedBarChartComparisonToExcel = (
  widgetData,
  widgetName,
  xlsLanguage,
) => {
  try {
    const nameFirstColumn = handleNameForFirstColumn(widgetName);
    const dataListExcelSheet = [[xlsLanguage?.[nameFirstColumn]]];
    const finalDataArray = []; //to push all data in the value array for each row

    if (widgetName == "reach_funnel" || widgetName === "posts_reach") {
      //set labels static with comparison
      const headerNameForEachMonitor =
        widgetName === "posts_reach"
          ? ["original"]
          : ["Retweets", "original", "total"];

      //widgetData is object each key is will insert new row in excel ex: potential_impression
      Object?.entries(widgetData)?.forEach(([key, value], index) => {
        //to insert new row with first column value (key)
        finalDataArray?.push([xlsLanguage?.[key] || key]);

        //cloneDeep for value of this key
        const cloneDeepValue = _.cloneDeep(value);
        cloneDeepValue?.forEach((monitor) => {
          //check if the first row to push headers name for each column
          if (index == 0) {
            dataListExcelSheet?.[0]?.push(
              ...headerNameForEachMonitor?.map(
                (label) => `${xlsLanguage?.[label]} - ${monitor?.name}`,
              ),
            );
          }

          //total for each key in this monitor
          //used Math.floor to the values in excel sheet are the same with the values in the widget
          const totalValue = monitor?.value?.reduce(
            (total, labelValue) => total + Math.floor(labelValue),
            0,
          );
          //push all values for this monitor
          if (widgetName === "posts_reach") {
            finalDataArray?.[index]?.push(
              ...[
                //used Math.floor to the values in excel sheet are the same with the values in the widget
                ...(monitor?.value?.map((item) => Math.floor(item)) || []),
              ],
            );
          } else {
            finalDataArray?.[index]?.push(
              ...[
                //used Math.floor to the values in excel sheet are the same with the values in the widget
                ...(monitor?.value?.map((item) => Math.floor(item)) || []),
                totalValue,
              ],
            );
          }
        });
      });
    } else {
      const { dataExcel } = widgetData;

      dataExcel?.forEach((monitor, indexMonitor) => {
        //set name of header for anther columns
        const listHeaders = monitor?.pie_data?.map((item) => item?.name) || [];
        // to add colume for total
        listHeaders?.push("total");
        // to add headers name in first row
        listHeaders?.forEach((name) => {
          dataListExcelSheet?.[0]?.push(
            `${xlsLanguage?.[name] || name} - ${monitor?.monitorName}`,
          );
        });

        //to push all data for all monitors in the same row for the same name
        if (indexMonitor === 0) {
          monitor?.data?.forEach((item) => {
            const newItem = _.cloneDeep(item);
            //to push total value for monitor after labels column
            handleTotalAndPush(newItem);

            //push the new row in excel sheet for each "newItem?.name"
            finalDataArray?.push([
              handleNameForFirstColumnWithEachRow(
                nameFirstColumn,
                xlsLanguage,
                newItem?.name,
              ),
              //used Math.floor to the values in excel sheet are the same with the values in the widget
              ...(newItem?.value?.map((item) => Math.floor(item)) || []),
            ]);
          });
        } else {
          monitor?.data?.forEach((item, index) => {
            const newItem = _.cloneDeep(item);
            //to push total value for monitor after labels column
            handleTotalAndPush(newItem);

            //just push the values for this "newItem?.name"
            //used Math.floor to the values in excel sheet are the same with the values in the widget
            finalDataArray?.[index]?.push(
              ...[...(newItem?.value?.map((item) => Math.floor(item)) || [])],
            );
          });
        }
      });
    }

    //to push each row after append all data for all monitors
    finalDataArray?.forEach((row) => {
      dataListExcelSheet?.push(row);
    });
    return dataListExcelSheet;
  } catch {}
};
