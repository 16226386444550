import { Box } from "@mui/material";
import BreadCrumb from "components/breadCrumb";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import StepsHeader from "./components/header/stepsHeader";
import StepOne from "./components/stepOne";
import StepTwo from "./components/stepTwo";
import StepsFooter from "./components/footer";
import "./style.scss";
import StepThree from "./components/stepThree";
import useAddNewUserOrEdit from "./sharedFunction/useAddNewUserOrEdit";

const links = [
  {
    name: "settings",
    url: "settings",
  },
  {
    name: "user_list",
    url: "settings/user_managements",
  },
];

const addNewUser = () => {
  const intl = useIntl();

  const {
    formik,
    stepTwoData,
    setStepTwoData,
    steps,
    activeStep,
    setActiveStep,
    accTableData,
    setAccTableData,
    accTableSelectedData,
    setAccTableSelectedData,
    accTableDetailsData,
    setAccTableDetailsData,
    tableCols,
    advancedTabsData,
    setAdvancedTabsData,
    infoTabsData,
    setInfoTabsData,
    tabsErrors,
    setTabsErrors,
    teamsData,
    setTeamsData,
    callApiDone,
    getTablesData,
    nextLoading,
    setNextLoading,
    accEngagId,
  } = useAddNewUserOrEdit();

  return (
    <Box className="main-add-user">
      <BreadCrumb links={links} />
      <Box className="add-user-tit">
        {CheckValueLocale("create_new_user", "", {}, intl)}
      </Box>
      <StepsHeader steps={steps} activeStep={activeStep} />
      <Box className="main-step-contant">
        {activeStep == 0 ? (
          <StepOne formik={formik} />
        ) : activeStep == 1 ? (
          <StepTwo stepTwoData={stepTwoData} setStepTwoData={setStepTwoData} />
        ) : (
          <StepThree
            accTableData={accTableData}
            accTableSelectedData={accTableSelectedData}
            setAccTableSelectedData={setAccTableSelectedData}
            accTableDetailsData={accTableDetailsData}
            setAccTableDetailsData={setAccTableDetailsData}
            setAccTableData={setAccTableData}
            tableCols={tableCols}
            advancedTabsData={advancedTabsData}
            setAdvancedTabsData={setAdvancedTabsData}
            infoTabsData={infoTabsData}
            setInfoTabsData={setInfoTabsData}
            tabsErrors={tabsErrors}
            setTabsErrors={setTabsErrors}
            teamsData={teamsData}
            setTeamsData={setTeamsData}
          />
        )}
      </Box>
      <StepsFooter
        isStepOneValid={formik?.dirty && formik?.isValid}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={steps}
        callApiDone={callApiDone}
        getTablesData={getTablesData}
        nextLoading={nextLoading}
        setNextLoading={setNextLoading}
        accTableSelectedData={accTableSelectedData}
        stepTwoData={stepTwoData}
        stepOneData={formik?.values}
        accEngagId={accEngagId}
        advancedTabsData={advancedTabsData}
        infoTabsData={infoTabsData}
        tabsErrors={tabsErrors}
        teamsData={teamsData}
      />
    </Box>
  );
};

export default addNewUser;
