import { useQuery } from "@tanstack/react-query";
import InstagramHashtagsController from "services/controllers/instagramHashtagsController";

export const useGetHashtags = ({
  searchInput,
  statusHashtags,
  page,
  sortColumn,
  sortDirection,
}) => {
  return useQuery({
    queryKey: [
      "companyHashtags",
      searchInput,
      statusHashtags,
      page,
      sortColumn,
      sortDirection,
    ],
    queryFn: () =>
      InstagramHashtagsController.getHashtags(
        searchInput,
        statusHashtags,
        page,
        sortColumn,
        sortDirection,
      ),
    enabled: !!page,
    retry: false,
  });
};
