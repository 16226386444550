import { useQuery } from "@tanstack/react-query";
import HomePageController from "services/controllers/homePageController";

export const useCustomerSuccessInfo = () => {
  return useQuery({
    queryKey: ["csmInfo"],
    queryFn: () => HomePageController?.getCsmInfo(),
    select: (data) => data?.data?.data?.data,
    retry: false,
  });
};
