import { useMutation } from "@tanstack/react-query";
import EngagementsController from "services/controllers/engagementsController";

export const useUpdateAgentCapacities = () => {
  return useMutation({
    mutationFn: ({ queryData }) =>
      EngagementsController.updateAgentCapacities(queryData),
    retry: false,
  });
};
