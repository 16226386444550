import { Box } from "@mui/material";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const TeamsBodyCell = ({ user }) => {
  const intl = useIntl();
  const teams = user?.attributes?.user_teams?.length
    ? user?.attributes?.user_teams
    : [];
  return teams?.length ? (
    <Box className="users-teams">
      {teams?.[0]?.name?.length > 30 ? (
        <CustomizedTooltip
          title={teams?.[0]?.name}
          arrow
          placement="top"
          styles={customizedStyledTooltip}
        >
          {teams?.[0]?.name?.substring(0, 30)}...
        </CustomizedTooltip>
      ) : (
        teams?.[0]?.name
      )}
      {teams?.length > 1 ? (
        <Box>
          ,
          <CustomizedTooltip
            title={teams
              ?.slice(1, teams?.length)
              ?.map((team) => team?.name)
              ?.join(", ")}
            arrow
            placement="top"
            id="user-teams-tooltip"
            styles={customizedStyledTooltip}
          >
            +{teams?.length - 1}
          </CustomizedTooltip>
        </Box>
      ) : null}
    </Box>
  ) : (
    <Box className="empty-users-teams">
      {CheckValueLocale("not_assigned", "", {}, intl)}
    </Box>
  );
};

export default TeamsBodyCell;
