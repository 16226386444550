import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { useDeleteCustomDomain } from "pages/settings/pages/companyPage/hooks/useDeleteCustomDomain";
import PopupModal from "components/popupModal";

const DeleteCustomDomainModal = (props) => {
  const { openModal, setOpenModal, setSnackbar } = props;

  const intl = useIntl();

  const { mutate: deleteCustomDomain, isPending: deleteLoading } =
    useDeleteCustomDomain();

  const handleDeleteCustomDomain = () => {
    deleteCustomDomain(
      { queryData: { custom_domain: "" } },
      {
        onSuccess: () => {
          setSnackbar({
            open: true,
            severity: "success",
            title: "",
            message: "custom_remove_success",
          });
        },
        onError: () => {
          setSnackbar({
            open: true,
            severity: "error",
            title: "something_went_wrong",
            message: "try_again",
          });
        },
        onSettled: () => setOpenModal(false),
      },
    );
  };

  return (
    <>
      <PopupModal
        open={openModal}
        title={CheckValueLocale("custom_domain_remove", "", {}, intl)}
        body={
          <Box className="shared-dialog-body">
            <p>{CheckValueLocale("remove_custom_domain_body", "", {}, intl)}</p>
            <h2 className="sub-text-desc">
              {CheckValueLocale("deleting_msg", "", {}, intl)}
            </h2>
          </Box>
        }
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("delete_confirm", "", {}, intl)}
        close={() => setOpenModal(false)}
        isRightBtnLoading={deleteLoading}
        accept={handleDeleteCustomDomain}
        warning={true}
        minWidth="600px"
        classeName="actions-buttons-shared"
      />
    </>
  );
};
export default DeleteCustomDomainModal;
