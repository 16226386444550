import PopupModal from "components/popupModal";
import { useIntl } from "react-intl";
import { CheckValueLocale, classNames } from "utils/helpers";
import BodyCreateAgentCapacity from "./bodyCreateAgentCapacity";
import "./createAgentCapacityPopup.scss";
import { useEffect, useRef, useState } from "react";

import { useGetAgentCapacities } from "../hooks/useGetAgentCapacities";
import { useUpdateAgentCapacities } from "../hooks/useUpdateAgentCapacities";
import SnackBar from "components/snackBar";

function CreateAgentCapacityPopup({
  openCreateAgentCapacityPopup,
  setOpenCreateAgentCapacityPopup,
}) {
  const intl = useIntl();
  const [agentEngagementsCount, setAgentEngagementsCount] = useState(0);
  const [listAgent, setListAgent] = useState([]);
  const [disabledUpdate, setDisabledUpdate] = useState(false);
  const [dispalyDistribute, setDispalyDistribute] = useState(false);
  const [dispalyCustomizePerAgentSection, setDispalyCustomizePerAgentSection] =
    useState(false);
  const [agentCapacitySnackbar, setAgentCapacitySanckbar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnakbar: false,
  });
  const agentCapacityRef = useRef({});
  // this state will hold the value if the user change any value in the agent capacity
  const [hasUserChangeAnyValue, setHasUserChangeAnyValue] = useState(false);

  // get the agent capacities from the backend and set the state

  const { data: agentCapacitiesData, isPending: isLoadingAgents } =
    useGetAgentCapacities({
      // call the getAgentCapacities when the openCreateAgentCapacityPopup open and the isLoadingAgents is true
      enableFetch: Boolean(openCreateAgentCapacityPopup),
    });

  useEffect(() => {
    const isAgentCountIsNull = agentCapacitiesData?.agent_count === null;
    setDispalyDistribute(!isAgentCountIsNull);
    setAgentEngagementsCount(agentCapacitiesData?.agent_count || 0);
    const listAgent = agentCapacitiesData?.users?.map((agent) => {
      const customValue =
        agent?.agent_count || agent?.agent_count === 0
          ? agent?.agent_count
          : agentCapacitiesData?.agent_count || 0;
      return {
        id: agent?.id,
        agentName: agent?.name,
        customValue,
        customFlag: false,
      };
    });
    setListAgent(listAgent);
    agentCapacityRef.current = {
      agentEngagementsCount: agentCapacitiesData?.agent_count,
      listAgent,
      dispalyDistribute: !isAgentCountIsNull,
    };
  }, [agentCapacitiesData]);

  const { mutate: updateAgentCapacities, isPending: isUpdatingAgentCapacity } =
    useUpdateAgentCapacities();

  // confirm the create agent capacity and send the request to the backend
  const confirmCreateAgentCapacity = () => {
    let agentCapacitiesBody = {};
    // if the dispalyDistribute is false, it means the user didn't set the agent capacity yet
    // so we should set the is_deleted to true
    if (!dispalyDistribute) {
      agentCapacitiesBody = {
        is_deleted: true,
      };
    } else {
      agentCapacitiesBody.agent_count = agentEngagementsCount;
      // if the dispalyCustomizePerAgentSection is true, it means the user set the agent capacity per agent
      if (dispalyCustomizePerAgentSection) {
        agentCapacitiesBody.users = listAgent.map((agent) => {
          return {
            user_id: agent?.id,
            agent_count: agent?.customValue,
          };
        });
      }
    }

    updateAgentCapacities(
      { queryData: agentCapacitiesBody },
      {
        onSuccess: () => {
          setAgentCapacitySanckbar({
            message: "your_capacites_updated_successfully",
            severity: "success",
            openSnackBar: true,
          });
          resetState();
        },
        onError: () =>
          setAgentCapacitySanckbar({
            message: "your_capacites_updated_failed",
            severity: "error",
            openSnackBar: true,
          }),
      },
    );
  };

  const handleCloseSnackBar = () => {
    setAgentCapacitySanckbar({
      openSnackBar: false,
    });
  };

  const resetState = () => {
    setOpenCreateAgentCapacityPopup(null);
    setDispalyCustomizePerAgentSection(false);
  };
  const closeCreateAgentCapacityPopup = () => {
    if (isUpdatingAgentCapacity) return;
    resetState();
  };

  useEffect(() => {
    const errorCustomCount =
      listAgent?.filter(
        (agent) =>
          agent?.customValue != null &&
          (agent?.customValue < 0 || agent?.customValue === ""),
      )?.length > 0;
    setDisabledUpdate(
      agentEngagementsCount < 0 ||
        agentEngagementsCount === "" ||
        agentEngagementsCount === null ||
        errorCustomCount,
    );
  }, [listAgent, agentEngagementsCount]);

  // check if the user change any value in the agent capacity
  useEffect(() => {
    const oldAgentCapacity = agentCapacityRef?.current;
    if (oldAgentCapacity?.dispalyDistribute !== dispalyDistribute) {
      setHasUserChangeAnyValue(true);
      return;
    }
    if (oldAgentCapacity?.agentEngagementsCount != agentEngagementsCount) {
      setHasUserChangeAnyValue(true);
      return;
    }
    const hasAgentsCountChanged =
      dispalyCustomizePerAgentSection &&
      oldAgentCapacity?.listAgent?.some(
        (agent, index) => agent?.customValue != listAgent[index]?.customValue,
      );
    if (hasAgentsCountChanged) {
      setHasUserChangeAnyValue(true);
    } else {
      setHasUserChangeAnyValue(false);
    }
  }, [
    agentEngagementsCount,
    listAgent,
    dispalyDistribute,
    dispalyCustomizePerAgentSection,
  ]);

  return (
    <>
      {openCreateAgentCapacityPopup ? (
        <PopupModal
          title={<TitleCreateAgentCapacity />}
          body={
            <BodyCreateAgentCapacity
              agentEngagementsCount={agentEngagementsCount}
              setAgentEngagementsCount={setAgentEngagementsCount}
              listAgent={listAgent}
              setListAgent={setListAgent}
              disabled={isUpdatingAgentCapacity}
              loading={isLoadingAgents}
              setDispalyDistribute={setDispalyDistribute}
              dispalyDistribute={dispalyDistribute}
              dispalyCustomizePerAgentSection={dispalyCustomizePerAgentSection}
              setDispalyCustomizePerAgentSection={
                setDispalyCustomizePerAgentSection
              }
            />
          }
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale("update_agent_capacity_btn", "", {}, intl)}
          disabled={
            (dispalyDistribute && disabledUpdate) ||
            isLoadingAgents ||
            !hasUserChangeAnyValue
          }
          open={openCreateAgentCapacityPopup || false}
          close={closeCreateAgentCapacityPopup}
          accept={confirmCreateAgentCapacity}
          isRightBtnLoading={isUpdatingAgentCapacity}
          disableLeftBtn={isUpdatingAgentCapacity}
          classeName={"inbound-routing-popup-create-agent-capacity"}
          addClasses={"inbound-routing-popup-footer-agent-capacity"}
          modalStyle={classNames(
            "inbound-routing-popup-form-body-agent-capacity",
            isLoadingAgents && "loading",
          )}
        />
      ) : null}
      <SnackBar
        open={agentCapacitySnackbar?.openSnackBar}
        severity={agentCapacitySnackbar?.severity}
        message={CheckValueLocale(agentCapacitySnackbar?.message, "", {}, intl)}
        title={CheckValueLocale(agentCapacitySnackbar?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </>
  );
}

const TitleCreateAgentCapacity = () => {
  const intl = useIntl();
  return <>{CheckValueLocale("create_agent_capacity_title", "", {}, intl)}</>;
};
export default CreateAgentCapacityPopup;
