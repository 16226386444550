import { useEffect } from "react";
import { ShowMoreButton } from "../../../components/showMoreButton";
import { TeamMembers } from "../../../components/teamMembers";
import { useGetTeamMembers } from "../../../hooks/useGetTeamMembers";

const TeamsMembersStep = ({
  members,
  handleSelectMember,
  selectedMembers,
  setMembers,
  showMoreMembersObj = {},
}) => {
  const {
    isSettled,
    setIsSettled,
    setShowedData: setShowedMembers,
    showedData: showedMembers,
    showMore,
    setShowMore,
    isLoading,
    setIsLoading,
    onShowLess,
    nextPage,
    hasPagination,
  } = showMoreMembersObj;

  const { mutate: handleTeamMembers, isPending: isPendingTeamMembers } =
    useGetTeamMembers();
  setIsLoading(isPendingTeamMembers);
  const onShowMore = () => {
    if (isSettled) {
      setShowedMembers(members?.data);
      setShowMore(false);
      return;
    }

    handleTeamMembers(
      {
        nextPage,
      },
      {
        onSuccess: (res) => {
          const newMembers = res?.data?.users;
          const newMembersData = [...members?.data, ...newMembers?.data];
          const nextPage = newMembers?.pagination?.next;
          setMembers({
            data: newMembersData,
            pagination: newMembers?.pagination,
          });
          setShowedMembers(newMembersData);
          if (!nextPage) {
            setIsSettled(true);
            setShowMore(false);
          }
        },
      },
    );
  };

  return (
    <>
      <TeamMembers
        members={showedMembers}
        selectedMembers={selectedMembers}
        handleSelectMember={handleSelectMember}
        overallCount={members?.pagination?.count}
      />
      <ShowMoreButton
        show={hasPagination}
        loading={isLoading}
        hasMore={showMore}
        onShowLess={onShowLess}
        onShowMore={onShowMore}
      />
    </>
  );
};

export default TeamsMembersStep;
