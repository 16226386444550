import { useQuery } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const usePackagesQuota = ({ enableFetch }) => {
  return useQuery({
    queryKey: ["packagesQuota"],
    queryFn: () => SettingsController.getPackagesQuota(),
    select: (data) =>
      data?.data?.platforms?.sort((a, b) => b?.name?.localeCompare(a?.name)), // To make SM in the first instead of CXM
    enabled: enableFetch,
    retry: false,
  });
};
