import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";
import { AiAgentsContext } from "../aiAgentsContext";
import { useContext } from "react";

export const usePauseAiAgent = () => {
  const queryClient = useQueryClient();
  const { selectedAiAgentPublishedVersion } = useContext(AiAgentsContext);
  return useMutation({
    mutationFn: (queryData) => AiAgentsServices.pauseAiAgent(queryData),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["aiAgentDetails"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["aiAgentList"],
      });
      if (selectedAiAgentPublishedVersion) {
        await queryClient.invalidateQueries({
          queryKey: ["publishedVersionDetails"],
        });
      }
    },
  });
};
