import { useMutation, useQueryClient } from "@tanstack/react-query";
import AuthController from "services/controllers/authController";

export const useUnAuthAccount = (companyAccount) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ dataSource, accountId, user_id, is_company_account }) =>
      AuthController.unAuthAccount(
        dataSource,
        accountId,
        user_id,
        is_company_account,
      ),
    onSuccess: () => {
      if (companyAccount) {
        queryClient.invalidateQueries({
          queryKey: ["companyAccounts"],
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ["usersAccounts"],
        });
      }
    },
  });
};
