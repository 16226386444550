import Settings from "../api/settings";

var SettingsController = {};

// --------------------------------------------------------------------------------------------------
// ------------------------------------------ Company ------------------------------------------
// --------------------------------------------------------------------------------------------------

// Get Company Info
SettingsController.getCompanyInfo = () => {
  return new Promise((resolve, reject) => {
    Settings.getCompanyInfo()
      .then((response) => {
        resolve({ data: response?.data?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Edit Company Info
SettingsController.updateCompanyInfo = (queryData) => {
  return new Promise((resolve, reject) => {
    Settings.updateCompanyInfo(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Add Custom Domain
SettingsController.addCustomDomain = (queryData) => {
  return new Promise((resolve, reject) => {
    Settings.addCustomDomain(queryData)
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Verify Custom Domain
SettingsController.verifyCustomDomain = () => {
  return new Promise((resolve, reject) => {
    Settings.verifyCustomDomain()
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Delete Custom Domain
SettingsController.deleteCustomDomain = (queryData) => {
  return new Promise((resolve, reject) => {
    Settings.deleteCustomDomain(queryData)
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// --------------------------------------------------------------------------------------------------
// --------------------------------------- Subscriptions ---------------------------------------
// --------------------------------------------------------------------------------------------------

// Get Company Subscriptions
SettingsController.getCompanySubscriptions = () => {
  return new Promise((resolve, reject) => {
    Settings.getCompanySubscriptions()
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch(() => reject());
  });
};

// Request Demo Or Upgrade Package
SettingsController.requestDemoOrUpgrade = (queryData) => {
  return new Promise((resolve, reject) => {
    Settings.requestDemoOrUpgrade(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

SettingsController.getCompanyProducts = () => {
  return new Promise(function (resolve, reject) {
    Settings.getCompanyProducts()
      .then((response) => {
        resolve({
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// --------------------------------------------------------------------------------------------------
// --------------------------------------- Historical Request Page ---------------------------------------
// --------------------------------------------------------------------------------------------------

// Get Company Packages Quota
SettingsController.getPackagesQuota = () => {
  return new Promise((resolve, reject) => {
    Settings.getPackagesQuota()
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch(() => reject());
  });
};

// Get Historical Requests
SettingsController.getHistoricalRequests = (queryData) => {
  return new Promise((resolve, reject) => {
    Settings.getHistoricalRequests(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch(() => reject());
  });
};

// Get Historical Request Status
SettingsController.getHistoricalRequestStatus = () => {
  return new Promise((resolve, reject) => {
    Settings.getHistoricalRequestStatus()
      .then((response) => {
        resolve({ data: response?.data, status: response?.status });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Get Historical Request Cancellable Datasources
SettingsController.getCancellableDatasources = () => {
  return new Promise((resolve, reject) => {
    Settings.getCancellableDatasources()
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Get Historical Request Products
SettingsController.getHistoricalRequestProducts = () => {
  return new Promise((resolve, reject) => {
    Settings.getHistoricalRequestProducts()
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Get Historical Request Data Sources
SettingsController.getHistoricalRequestDatasoruces = (queryData) => {
  return new Promise((resolve, reject) => {
    Settings.getHistoricalRequestDatasoruces(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Get Historical Request Monitors
SettingsController.getHistoricalRequestMonitors = (queryData) => {
  return new Promise((resolve, reject) => {
    Settings.getHistoricalRequestMonitors(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Get Historical Request Available Date For X-Platform
SettingsController.getAvailableDataXPlatform = (queryData) => {
  return new Promise((resolve, reject) => {
    Settings.getAvailableDataXPlatform(queryData)
      .then((response) => {
        resolve({ data: response?.data, status: response?.status });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Create Historical Request
SettingsController.createHistoricalRequest = (queryData) => {
  return new Promise((resolve, reject) => {
    Settings.createHistoricalRequest(queryData)
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// Retry Historical Request
SettingsController.retryHistoricalRequest = (requestId) => {
  return new Promise((resolve, reject) => {
    Settings.retryHistoricalRequest(requestId)
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// Stop Historical Request
SettingsController.stopHistoricalRequest = (requestId) => {
  return new Promise((resolve, reject) => {
    Settings.stopHistoricalRequest(requestId)
      .then((response) => {
        resolve({ data: response });
      })
      .catch((error) => {
        reject({ error });
      });
  });
};

// --------------------------------------------------------------------------------------------------
// --------------------------------------- Reports Branding Page ---------------------------------------
// --------------------------------------------------------------------------------------------------

// GET Reports Branding
SettingsController.getReportsBranding = () => {
  return new Promise((resolve, reject) => {
    Settings.getReportsBranding()
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Edit Reports Branding
SettingsController.editReportsBranding = (reportsBranding) => {
  return new Promise((resolve, reject) => {
    Settings.editReportsBranding(reportsBranding)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Delete Branding
SettingsController.deleteReportsBranding = (id) => {
  return new Promise((resolve, reject) => {
    Settings.deleteReportsBranding(id)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// GET Fonts List
SettingsController.getFonts = () => {
  return new Promise((resolve, reject) => {
    Settings.getFonts()
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Reset Branding Defaults
SettingsController.getBrandingDefaults = () => {
  return new Promise((resolve, reject) => {
    Settings.getBrandingDefaults()
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// --------------------------------------------------------------------------------------------------
// --------------------------------------- LucidyaApi Page ---------------------------------------
// --------------------------------------------------------------------------------------------------

SettingsController.getLucidyaApisList = (page, id) => {
  var queryData = {
    page,
    product_id: id,
  };
  return new Promise((resolve, reject) => {
    Settings.getLucidyaApisList(queryData)
      .then((response) => {
        resolve({
          data: response?.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Delete lucidya api
SettingsController.deleteLucidyaApisRequest = (id) => {
  return new Promise((resolve, reject) => {
    Settings.deleteLucidyaApisRequest(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// inActivate lucidya api
SettingsController.inActivateLucidyaApisRequest = (id) => {
  return new Promise((resolve, reject) => {
    Settings.inActivateLucidyaApisRequest(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// activate lucidya api
SettingsController.activateLucidyaApisRequest = (id) => {
  return new Promise((resolve, reject) => {
    Settings.activateLucidyaApisRequest(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// regenerate lucidya api
SettingsController.regenerateLucidyaApisRequest = (id) => {
  return new Promise((resolve, reject) => {
    Settings.regenerateLucidyaApisRequest(id)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// Get lucidya apis Types list
SettingsController.getLucidyaApisTypes = () => {
  return new Promise(function (resolve, reject) {
    Settings.getLucidyaApisTypes()
      .then((response) => {
        resolve({
          data: response.data.data,
        });
      })
      .catch((error) => {
        reject({
          data: [],
          errorMsg: error,
        });
      });
  });
};

// get single lucidya api
SettingsController.getSingleLucidyaApis = (id) => {
  const queryData = {
    id,
  };
  return new Promise(function (resolve, reject) {
    Settings.getSingleLucidyaApis(queryData)
      .then((response) => {
        resolve({
          response: response,
          data: response?.data?.data,
        });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// create lucidya api
SettingsController.createLucidyaApisRequest = (queryData) => {
  return new Promise((resolve, reject) => {
    Settings.createLucidyaApisRequest(queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// edit lucidya api
SettingsController.editLucidyaApisRequest = (id, queryData) => {
  return new Promise((resolve, reject) => {
    Settings.editLucidyaApisRequest(id, queryData)
      .then((response) => {
        resolve({
          data: response.data,
        });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// --------------------------------------------------------------------------------------------------
// --------------------------------------- 3rd Party Integrations ---------------------------------------
// --------------------------------------------------------------------------------------------------

// 3rd Party Integrations List
SettingsController.thirdPartyList = () => {
  return new Promise((resolve, reject) => {
    Settings.thirdPartyList()
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Get 3rd Party Integrations Intercom Data
SettingsController.getIntercomData = () => {
  return new Promise((resolve, reject) => {
    Settings.getIntercomData()
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Configure Intercom Integration
SettingsController.configureIntercom = (queryData) => {
  return new Promise((resolve, reject) => {
    Settings.configureIntercom(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Get 3rd Party Integrations Zendesk Data
SettingsController.getZendeskData = () => {
  return new Promise((resolve, reject) => {
    Settings.getZendeskData()
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

// Configure Zendesk Integration
SettingsController.configureZendesk = (queryData) => {
  return new Promise((resolve, reject) => {
    Settings.configureZendesk(queryData)
      .then((response) => {
        resolve({ data: response?.data });
      })
      .catch((error) => {
        reject({ data: [], errorMsg: error });
      });
  });
};

export default SettingsController;
