import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { CheckValueLocale, getSocialIcon } from "utils/helpers";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./accessTable.scss";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import ShowMoreBtn from "./showMoreBtn";
import EmptyAccess from "./emptyAccess";
import DefaultUser from "images/shared-images/user-management-default.svg";

const AccessTable = ({
  name,
  accTableData,
  accTableSelectedData,
  setAccTableSelectedData,
  accTableDetailsData,
  setAccTableDetailsData,
  setAccTableData,
  tableCols,
}) => {
  const intl = useIntl();

  const handleChange = (name, row, cell) => {
    let selectedData = { ...accTableSelectedData };
    if (cell == "FULL_ACCESS") {
      if (selectedData?.[name]?.[row]?.includes(cell)) {
        delete selectedData?.[name]?.[row];
      } else {
        if (row == "engag_acc_title") {
          selectedData[name][row] = [...tableCols["engagement_features0"]];
        } else if (row == "saved_reps_mang") {
          selectedData[name][row] = [...tableCols["engagement_features2"]];
        } else if (row == "engag_tabs") {
          selectedData[name][row] = [...tableCols["engagement_features1"]];
        } else {
          selectedData[name][row] = [...tableCols["account_access"]];
        }
      }
    } else {
      if (name == "engagement_features" || name == "account_access") {
        if (selectedData?.[name]?.[row]?.includes(cell)) {
          selectedData[name][row] = [
            ...selectedData?.[name]?.[row]?.filter(
              (item) => item != cell && item != "FULL_ACCESS",
            ),
          ];
          if (!selectedData?.[name]?.[row]?.length) {
            delete selectedData?.[name]?.[row];
          }
        } else {
          if (
            (row == "engag_acc_title" &&
              selectedData?.[name]?.[row]?.length ==
                tableCols["engagement_features0"]?.length - 2) ||
            (row == "saved_reps_mang" &&
              selectedData?.[name]?.[row]?.length ==
                tableCols["engagement_features2"]?.length - 2) ||
            (name == "account_access" &&
              selectedData?.[name]?.[row]?.length ==
                tableCols["account_access"]?.length - 2) ||
            (row == "engag_tabs" &&
              selectedData?.[name]?.[row]?.length ==
                tableCols["engagement_features1"]?.length - 2)
          ) {
            selectedData[name][row] = [
              "FULL_ACCESS",
              ...selectedData?.[name]?.[row],
              cell,
            ];
          } else {
            selectedData[name][row] = selectedData?.[name]?.[row]
              ? [...selectedData?.[name]?.[row], cell]
              : [cell];
          }
        }
      } else {
        if (selectedData[name][row]?.includes(cell)) {
          delete selectedData?.[name]?.[row];
        } else {
          selectedData[name][row] = [cell];
        }
      }
    }
    if (row === "engag_acc_title") {
      if (
        !selectedData["engagement_features"]["engag_acc_title"]?.includes(
          "INBOX",
        )
      ) {
        delete selectedData["engagement_features"]["engag_tabs"];
      }
      if (
        !selectedData["engagement_features"]["engag_acc_title"]?.includes(
          "INBOX",
        ) &&
        !selectedData["engagement_features"]["engag_acc_title"]?.includes(
          "SL_ENGAGEMENT",
        ) &&
        !selectedData["engagement_features"]["engag_acc_title"]?.includes(
          "OC_ENGAGEMENT",
        ) &&
        Object.keys(selectedData["account_access"])?.length
      ) {
        Object.keys(selectedData["account_access"])?.map((key) => {
          let arr = selectedData["account_access"][key]?.filter(
            (item) => item != "REPLY" && item != "FULL_ACCESS",
          );
          if (arr?.length) {
            selectedData["account_access"][key] = [...arr];
          } else {
            delete selectedData["account_access"][key];
          }
        });
      }
      if (
        !selectedData["engagement_features"]["engag_acc_title"]?.includes(
          "PUBLISH",
        ) &&
        Object.keys(selectedData["account_access"])?.length
      ) {
        Object.keys(selectedData["account_access"])?.map((key) => {
          let arr = selectedData["account_access"][key]?.filter(
            (item) => item != "PUBLISH" && item != "FULL_ACCESS",
          );
          if (arr?.length) {
            selectedData["account_access"][key] = [...arr];
          } else {
            delete selectedData["account_access"][key];
          }
        });
      }
    }

    setAccTableSelectedData({ ...selectedData });
  };

  return (
    <Box className="main-acc-table">
      {accTableData[name]?.length ? (
        <Box className="main-table-container">
          {accTableData[name]?.map((row, i) => {
            return (
              <Box className="acc-table-row" key={`${name}_${i}`}>
                <Box className="acc-table-first-cell">
                  {name == "engagement_features" ? (
                    <Box className="engag-acc-cell">
                      {CheckValueLocale(row, "", {}, intl)}
                      {row == "saved_reps_mang" ? (
                        <Tooltip
                          id="engag-acc-cell-tooltip"
                          title={CheckValueLocale(
                            `${row}_tooltip`,
                            "",
                            {},
                            intl,
                          )}
                          arrow
                          placement="top"
                        >
                          <ErrorIcon />
                        </Tooltip>
                      ) : null}
                    </Box>
                  ) : name == "account_access" ? (
                    <Box className="account-acc-cell">
                      <Box className="account-acc-img">
                        <img
                          src={row?.profileImg || DefaultUser}
                          onError={(e) => {
                            e.target.src = DefaultUser;
                          }}
                        />
                        <Box
                          className={`account-acc-data-source ${row?.data_source}`}
                        >
                          {getSocialIcon(row?.data_source?.toUpperCase())}
                        </Box>
                      </Box>
                      <Box className="account-acc-data-details">
                        <Box className="access-table-direction-list">
                          {row?.name?.length > 30 ? (
                            <Tooltip title={row?.name} arrow placement="top">
                              {`${row?.name?.substring(0, 30)}...`}
                            </Tooltip>
                          ) : (
                            row?.name
                          )}
                        </Box>
                        <Box className="account-acc-data-handle access-table-direction-list">
                          {row?.handleName?.length > 30 ? (
                            <Tooltip
                              title={row?.handleName}
                              arrow
                              placement="top"
                            >
                              {`${row?.handleName?.substring(0, 30)}...`}
                            </Tooltip>
                          ) : (
                            row?.handleName
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box className="monitors-acc-cell">
                      <Box className="monitors-acc-cell-name access-table-direction-list">
                        {row?.name?.length > 30 ? (
                          <Tooltip title={row?.name} arrow placement="top">
                            {`${row?.name?.substring(0, 30)}...`}
                          </Tooltip>
                        ) : (
                          row?.name
                        )}
                      </Box>
                      <Box className="monitors-acc-details">
                        <Box className="monitors-acc-dott"></Box>
                        <Box className="monitors-acc-type">
                          {CheckValueLocale("cxm_type", "", {}, intl)}
                        </Box>
                        <Box className="monitors-acc-type-name">
                          {CheckValueLocale(row?.type, "", {}, intl)}
                        </Box>
                      </Box>
                      <Box className="monitors-acc-details">
                        <Box className="monitors-acc-dott"></Box>
                        <Box className="monitors-acc-type">
                          {CheckValueLocale("cxm_data_source", "", {}, intl)}
                        </Box>
                        <Box className="monitors-acc-data-source">
                          {row?.data_source?.map((item) => (
                            <Box key={item} className={item}>
                              {getSocialIcon(item?.toUpperCase())}
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
                {tableCols[
                  name == "engagement_features"
                    ? `engagement_features${i}`
                    : name
                ]?.map((cell, x) => {
                  return (
                    <Box
                      key={`${name}_${cell}_${x}_${i}_${
                        name == "engagement_features" ? row : row?.id
                      }`}
                      className={`acc-table-check-cell ${name} ${cell} ${
                        tableCols[
                          name == "engagement_features"
                            ? `engagement_features${i}`
                            : name
                        ]?.length -
                          1 ==
                        x
                          ? "last-cell"
                          : "last-cell"
                      }`}
                    >
                      <FormControlLabel
                        key={
                          name == "engagement_features"
                            ? `formControl_${name}_${row}`
                            : `formControl_${name}_${row?.id}`
                        }
                        control={
                          <Checkbox
                            checked={
                              accTableSelectedData[name][
                                name == "engagement_features" ? row : row?.id
                              ]?.includes(cell) ||
                              accTableSelectedData[name][
                                name == "engagement_features" ? row : row?.id
                              ]?.includes("FULL_ACCESS") ||
                              false
                            }
                            disabled={
                              (name === "account_access" &&
                                ((cell === "REPLY" &&
                                  !accTableSelectedData["engagement_features"][
                                    "engag_acc_title"
                                  ]?.includes("INBOX") &&
                                  !accTableSelectedData["engagement_features"][
                                    "engag_acc_title"
                                  ]?.includes("SL_ENGAGEMENT") &&
                                  !accTableSelectedData["engagement_features"][
                                    "engag_acc_title"
                                  ]?.includes("OC_ENGAGEMENT")) ||
                                  (cell === "PUBLISH" &&
                                    !accTableSelectedData[
                                      "engagement_features"
                                    ]["engag_acc_title"]?.includes(
                                      "PUBLISH",
                                    )) ||
                                  (cell === "FULL_ACCESS" &&
                                    (!accTableSelectedData[
                                      "engagement_features"
                                    ]["engag_acc_title"]?.includes("PUBLISH") ||
                                      (!accTableSelectedData[
                                        "engagement_features"
                                      ]["engag_acc_title"]?.includes("INBOX") &&
                                        !accTableSelectedData[
                                          "engagement_features"
                                        ]["engag_acc_title"]?.includes(
                                          "SL_ENGAGEMENT",
                                        ) &&
                                        !accTableSelectedData[
                                          "engagement_features"
                                        ]["engag_acc_title"]?.includes(
                                          "OC_ENGAGEMENT",
                                        )))))) ||
                              (row === "engag_tabs" &&
                                !accTableSelectedData["engagement_features"][
                                  "engag_acc_title"
                                ]?.includes("INBOX"))
                            }
                            onChange={() =>
                              handleChange(
                                name,
                                name == "engagement_features" ? row : row?.id,
                                cell,
                              )
                            }
                          />
                        }
                        label={
                          name == "engagement_features" &&
                          cell !== "FULL_ACCESS" &&
                          (row == "engag_acc_title" || row == "engag_tabs") ? (
                            <Tooltip
                              id="engag-acc-cell-tooltip"
                              title={CheckValueLocale(
                                `${cell}_tooltip`,
                                "",
                                {},
                                intl,
                              )}
                              arrow
                              placement="top"
                            >
                              <Box className="tooltip-underline">
                                {CheckValueLocale(cell, "", {}, intl)}
                              </Box>
                            </Tooltip>
                          ) : (
                            CheckValueLocale(cell, "", {}, intl)
                          )
                        }
                      />
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      ) : (
        <EmptyAccess name={name} />
      )}
      {name !== "engagement_features" &&
      accTableData[name]?.length &&
      accTableDetailsData[`${name}_counter`] > 6 ? (
        <ShowMoreBtn
          accTableDetailsData={accTableDetailsData}
          setAccTableDetailsData={setAccTableDetailsData}
          accTableData={accTableData}
          name={name}
          setAccTableData={setAccTableData}
        />
      ) : null}
    </Box>
  );
};
export default AccessTable;
