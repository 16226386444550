import { useMutation, useQueryClient } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useEditLucidyaApis = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, queryData }) =>
      SettingsController.editLucidyaApisRequest(id, queryData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["companyLucidyaApisList"],
      });
    },
  });
};
