import { Box, Grid, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import AiAgentsFields from "../aiAgentsFields";
import { useIntl } from "react-intl";
import { LocalizationProvider } from "@mui/x-date-pickers";
import {
  DateRangePicker,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const AgentAdvancedSettings = ({
  formik,
  aiAgentshandleChange,
  isFormDisabled,
  customHandleChange,
}) => {
  const intl = useIntl();
  const dateError =
    formik?.errors?.event_start_date || formik?.errors?.event_end_date;

  const getDateRangeValues = () => {
    if (formik?.values?.event_start_date && formik?.values?.event_end_date) {
      return [
        dayjs(formik?.values?.event_start_date),
        dayjs(formik?.values?.event_end_date),
      ];
    }
    return [null, null];
  };
  const dateRangeValues = getDateRangeValues();

  const transformDate = (date) => {
    return dayjs(date)?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  };

  const handleDateRangeChange = (e) => {
    const startDate = e[0];
    const endDate = e[1];
    if (startDate) {
      customHandleChange("event_start_date", transformDate(startDate));
    }
    if (endDate) {
      customHandleChange("event_end_date", transformDate(endDate));
    }
  };
  const enableSpecialSeasonalGreeting = formik?.values?.seasonal_greetings;

  return (
    <Grid container columnSpacing={3} rowSpacing="30px">
      <Grid item xs={12}>
        <Box className="ai-agents-form-box-description" component="p">
          {CheckValueLocale("ai_agent_advanced_settings", "", {}, intl)}
        </Box>
      </Grid>
      <Grid item xs={5}>
        <Box className="ai-agents-form-box-description" component="p">
          {CheckValueLocale(
            "ai_agent_advanced_settings_description",
            "",
            {},
            intl,
          )}
        </Box>
      </Grid>
      <Grid className="ai-agent-advanced-settings-wrapper" item xs={7}>
        <AiAgentsFields
          disabled={isFormDisabled}
          type="switch"
          id="enable-ai-agent-luci"
          switchLabel={CheckValueLocale(
            "ai_agent_enable_special_season",
            "",
            {},
            intl,
          )}
          name="seasonal_greetings"
          checked={enableSpecialSeasonalGreeting}
          onChange={() =>
            customHandleChange(
              "seasonal_greetings",
              !enableSpecialSeasonalGreeting,
            )
          }
          error={!!formik?.errors?.seasonal_greetings}
          onBlur={formik?.handleBlur}
          helperText={CheckValueLocale(
            formik?.errors?.seasonal_greetings,
            "",
            {},
            intl,
          )}
        />
        {enableSpecialSeasonalGreeting ? (
          <>
            <AiAgentsFields
              disabled={isFormDisabled}
              type="text"
              id="instructions-ai-agents-luci"
              label={CheckValueLocale(
                "ai_agent_seasonal_event_name",
                "",
                {},
                intl,
              )}
              placeholder={CheckValueLocale(
                "ai_agent_seasonal_event_name_placeholder",
                "",
                {},
                intl,
              )}
              name="seasonal_event_name"
              value={formik?.values?.seasonal_event_name}
              onChange={aiAgentshandleChange}
              error={!!formik?.errors?.seasonal_event_name}
              onBlur={formik?.handleBlur}
              helperText={CheckValueLocale(
                formik?.errors?.seasonal_event_name,
                "",
                {},
                intl,
              )}
            />
            <Box className="ai-agent-seasonal-date-picker-wrapper">
              <Typography component="span" className="ai-agents-form-label">
                {CheckValueLocale("ai_agent_date_range_label", "", {}, intl)}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  format="DD/MM/YYYY"
                  slots={{ field: SingleInputDateRangeField }}
                  value={dateRangeValues}
                  onChange={handleDateRangeChange}
                  className={dateError ? "Mui-error" : ""}
                  disabled={isFormDisabled}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      error: !!dateError,
                      helperText: CheckValueLocale(
                        formik?.errors?.event_start_date ||
                          formik?.errors?.event_end_date,
                        "",
                        {},
                        intl,
                      ),
                      placeholder: CheckValueLocale(
                        "pick_date_range",
                        "",
                        {},
                        intl,
                      ),
                      InputProps: {
                        endAdornment: (
                          <FontAwesomeIcon
                            className="ai-agent-date-picker-icon"
                            icon={faCalendar}
                          />
                        ),
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
            <AiAgentsFields
              disabled={isFormDisabled}
              type="textarea"
              id="additional-details-ai-agents-luci"
              label={CheckValueLocale(
                "ai_agent_custom_greeting_message_label",
                "",
                {},
                intl,
              )}
              placeholder={CheckValueLocale("write_here", "", {}, intl)}
              name="event_greeting_message"
              value={formik?.values?.event_greeting_message}
              onChange={aiAgentshandleChange}
              error={!!formik?.errors?.event_greeting_message}
              onBlur={formik?.handleBlur}
              helperText={CheckValueLocale(
                formik?.errors?.event_greeting_message,
                "",
                {},
                intl,
              )}
            />
          </>
        ) : null}
      </Grid>
      <Grid item xs={5}>
        <Box className="ai-agents-form-box-description" component="p">
          {CheckValueLocale("ai_agent_additional_details_label", "", {}, intl)}
        </Box>
      </Grid>
      <Grid item xs={7}>
        <AiAgentsFields
          disabled={isFormDisabled}
          type="textarea"
          id="additional-details-ai-agents-luci"
          label={CheckValueLocale(
            "provide_additional_details_label",
            "",
            {},
            intl,
          )}
          placeholder={CheckValueLocale("write_here", "", {}, intl)}
          name="business_additional_details"
          value={formik?.values?.business_additional_details}
          onChange={aiAgentshandleChange}
          error={!!formik?.errors?.business_additional_details}
          onBlur={formik?.handleBlur}
          helperText={CheckValueLocale(
            formik?.errors?.business_additional_details,
            "",
            {},
            intl,
          )}
        />
      </Grid>
    </Grid>
  );
};

export default AgentAdvancedSettings;
