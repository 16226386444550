import { useState } from "react";
import { Box, TextField, InputAdornment, InputLabel } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import CustomizedTooltip from "components/customizedTooltip";

const CustomDomainStepTwo = ({ label, value }) => {
  const intl = useIntl();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(value);
  };

  return (
    <Box className="custom-domain-verify-wrapper">
      <InputLabel>{CheckValueLocale(label, "", {}, intl)}</InputLabel>
      <Box>
        <TextField
          value={value}
          variant="outlined"
          size="small"
          disabled
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <CustomizedTooltip
                  id={copied ? "custom-domain-copied-tooltip" : ""}
                  title={
                    <Box className="item-title-tooltip">
                      {CheckValueLocale(
                        copied ? "custom_domain_copied" : "copy",
                        "",
                        {},
                        intl,
                      )}
                    </Box>
                  }
                  placement="bottom"
                  arrow
                >
                  <Box>
                    <LucButton
                      variant="contained"
                      id={`custom-domain-details-${label}-copy`}
                      onClick={handleCopy}
                    >
                      {CheckValueLocale("copy", "", {}, intl)}
                    </LucButton>
                  </Box>
                </CustomizedTooltip>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default CustomDomainStepTwo;
