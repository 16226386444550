import { workgingHoursData, days } from "../shardComponents/tabs/workingHours";
import { hourStringToNumber } from "../shardComponents/tabs/workingHoursForDay";

const languagesAndSkillsDefaultValue = { value: "", subValue: "" };

/**
 * this function is used to adapt the data from the api to the data that the component needs to work
 * especially for the advanced info tabs including languages, skills and working hours
 * @param {object} userData - the userData object from the api e.g {user_languages, user_skills, user_working_time}
 */
export const advancedInfoTabsAdapter = (userData) => {
  const languages =
    userData?.user_languages?.map((language) => {
      return {
        value: { ...language, id: language?.language_id },
        subValue: language?.proficiency,
      };
    }) || [];
  const skills =
    userData?.user_skills?.map((skill) => {
      return {
        value: { ...skill, id: skill?.skill_id, name: skill?.title },
        subValue: skill?.skill_level,
      };
    }) || [];
  const workingHoursObject =
    userData?.user_working_time?.[0]?.working_time || {};
  const workingHours = {};
  Object.entries(days).forEach(([day, value]) => {
    let currentDayRanges = workgingHoursData[value];
    if (workingHoursObject[day]?.length) {
      currentDayRanges = workingHoursObject[day]?.map((range) => {
        return {
          from: hourStringToNumber(range?.from),
          to: hourStringToNumber(range?.to),
          selected: true,
        };
      });
    }
    workingHours[value] = currentDayRanges;
  });

  return {
    languages: languages?.length
      ? languages
      : [{ ...languagesAndSkillsDefaultValue }],
    skills: skills?.length ? skills : [{ ...languagesAndSkillsDefaultValue }],
    workingHours: workingHours,
  };
};

/**
 * this function is used to adapt the data from the api to the data that the component needs to work
 * especially for the info tabs including sentiments, themes, segments, topics and dialects
 * @param {object} userData - the userData object from the api e.g {user_sentiments, user_themes, user_segments, topics_monitors, user_dialects}
 */
export const inboxDataAdapter = (userData) => {
  const sentiments =
    userData?.user_sentiments?.map((sentiment) => sentiment?.value) || [];
  const themes = userData?.user_themes?.map((theme) => theme?.name) || [];
  const segments = userData?.user_segments?.map((segment) => segment?.id) || [];
  const topics =
    userData?.topics_monitors?.map((monitor) => ({
      ...monitor,
      name: monitor?.monitor_model_name,
    })) || [];
  const dialects =
    userData?.user_dialects?.map((dialect) => dialect?.name) || [];

  return {
    sentiments: sentiments,
    themes: themes,
    segments: segments,
    topics: topics?.length ? topics : [{ id: "", name: "", topics: [] }],
    dialects: dialects,
  };
};

// ****************************************************************************************************
// comparing functions

// will validate if the id is in the array
export const isTeamsDifferent = (selectedTeams, originalTeams) => {
  const teamIds = new Set(selectedTeams?.map((team) => team?.id));
  const originalTeamIds = new Set(originalTeams?.map((team) => team?.id));
  let isDifferent = teamIds?.size !== originalTeamIds?.size;
  if (!isDifferent) {
    teamIds?.forEach((id) => {
      if (!originalTeamIds?.has(id)) {
        isDifferent = true;
      }
    });
  }
  return isDifferent;
};

const isLanguagesOrSkillDifferent = (languages, originalLanguages) => {
  if (
    languages?.length !== originalLanguages?.length &&
    // if the last one is empty then it's not different
    !(
      languages?.length - originalLanguages?.length === 1 &&
      !languages?.[languages?.length - 1]?.value?.id
    )
  ) {
    return true;
  }
  const languagesSet = new Set(
    languages?.map((language) => language?.value?.id),
  );
  let isDifferent = false;
  originalLanguages.forEach((language, index) => {
    const originalLanguageId = language?.value?.id;
    const originalSubValue = language?.subValue || "";
    const newSubValue =
      languages?.find((l) => l?.value?.id === originalLanguageId)?.subValue ||
      "";
    if (
      !languagesSet?.has(originalLanguageId) ||
      newSubValue !== originalSubValue
    ) {
      isDifferent = true;
    }
  });
  return isDifferent;
};

export const isWorkingHoursDifferent = (workingHours, originalWorkingHours) => {
  let isDifferent = false;
  Object.entries(workingHours).forEach(([day, value]) => {
    if (value?.length !== originalWorkingHours[day]?.length) {
      isDifferent = true;
    } else {
      value?.forEach((range, index) => {
        if (
          range?.from !== originalWorkingHours[day][index]?.from ||
          range?.to !== originalWorkingHours[day][index]?.to
        ) {
          isDifferent = true;
        }
      });
    }
  });
  return isDifferent;
};

export const countAdvancedTabsDataDifferences = (
  advancedTabsData,
  originalData,
) => {
  const { languages, skills, workingHours } = advancedTabsData;
  const {
    languages: originalLanguages,
    skills: originalSkills,
    workingHours: originalWorkingHours,
  } = originalData;

  const isLanguagesDifferent = isLanguagesOrSkillDifferent(
    languages,
    originalLanguages,
  );
  const isSkillsDifferent = isLanguagesOrSkillDifferent(skills, originalSkills);
  const isWorkingDifferent = isWorkingHoursDifferent(
    workingHours,
    originalWorkingHours,
  );

  return isLanguagesDifferent + isSkillsDifferent + isWorkingDifferent;
};

const isArrayDifferent = (array, originalArray) => {
  if (array?.length !== originalArray?.length) {
    return true;
  }
  const arraySet = new Set(array);
  let isDifferent = false;
  array?.forEach((item, index) => {
    if (!arraySet?.has(originalArray[index])) {
      isDifferent = true;
    }
  });
  return isDifferent;
};

const isTopicsDifferent = (topics, originalTopics) => {
  if (
    topics?.length !== originalTopics?.length &&
    !(
      topics?.length - originalTopics?.length === 1 &&
      !topics?.[topics?.length - 1]?.id
    )
  ) {
    return true;
  }
  const topicsSet = new Set(topics?.map((topic) => topic?.id));
  let isDifferent = false;
  originalTopics.forEach((topic, index) => {
    const originalTopicId = topic?.id;
    if (
      !topicsSet?.has(originalTopicId) ||
      isTeamsDifferent(
        topic?.topics,
        topics?.find((t) => t?.id === originalTopicId)?.topics || [],
      )
    ) {
      isDifferent = true;
    }
  });
  return isDifferent;
};

export const countInfoTabsDataDifferences = (infoTabsData, originalData) => {
  const { sentiments, themes, segments, topics, dialects } = infoTabsData;
  const {
    sentiments: originalSentiments,
    themes: originalThemes,
    segments: originalSegments,
    topics: originalTopics,
    dialects: originalDialects,
  } = originalData;

  const isSentimentsDifferent = isArrayDifferent(
    sentiments,
    originalSentiments,
  );
  const isThemesDifferent = isArrayDifferent(themes, originalThemes);
  const isSegmentsDifferent = isArrayDifferent(segments, originalSegments);
  const isDialectsDifferent = isArrayDifferent(dialects, originalDialects);
  const isTopicsDiff = isTopicsDifferent(topics, originalTopics);
  return (
    isSentimentsDifferent +
    isThemesDifferent +
    isSegmentsDifferent +
    isDialectsDifferent +
    isTopicsDiff
  );
};
