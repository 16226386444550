import { Box, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import APIIcon from "images/product-icons/api-icon.svg";
import EngagementIcon from "images/product-icons/engagement-icon.svg";
import DCPIcon from "images/product-icons/cdp-icon.svg";
import OCIcon from "images/product-icons/oc.icon.svg";
import SettingIcon from "images/product-icons/settings-icon.svg";
import SLIcon from "images/product-icons/sl-icon.svg";
import Surveys from "images/product-icons/surveys-icon.svg";

export const iconObjectMapper = {
  ENGAGEMENTS: EngagementIcon,
  SM: SLIcon,
  CDP: DCPIcon,
  CXM: OCIcon,
  PUBLIC_API: APIIcon,
  GENERAL: SettingIcon,
  SURVEY: Surveys,
};
const ProductAccessCell = ({ product }) => {
  const icon = iconObjectMapper[product];
  const intl = useIntl();
  if (!icon) return "—";
  return (
    <Box className="teams-table-products">
      <Tooltip
        title={CheckValueLocale(product, "", {}, intl)}
        placement="top"
        arrow
      >
        <Box className="teams-table-product">
          <img src={icon} alt={product} />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default ProductAccessCell;
