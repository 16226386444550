import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const DateBodyCell = ({ user }) => {
  const intl = useIntl();
  return (
    <Box>
      {user?.attributes?.last_seen == null ? (
        CheckValueLocale("never_seen", "", {}, intl)
      ) : (
        <Box>
          <Box>
            {new Date(user?.attributes?.last_seen)
              ?.toLocaleDateString("en-GB")
              ?.replaceAll("/", "-")}
          </Box>
          <Box>
            {new Date(user?.attributes?.last_seen)?.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DateBodyCell;
