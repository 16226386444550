import { Box } from "@mui/material";
import BrandingHeader from "./components/brandingHeader";
import "./style.scss";
import { useGetCompanyBranding } from "./hooks/useGetCompanyBranding";
import BoxBranding from "./components/boxBranding";
import { useEffect, useState } from "react";
import { useFontsList } from "./hooks/useFontsList";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { useIntl } from "react-intl";
import { useEditReporsBranding } from "./hooks/useEditReporsBranding";
// import { useBrandingDefaults } from "./hooks/useBrandingDefaults";   // Commented untill input from Product Manager and BE
import CircularLoading from "components/circularProgress";
import SnackBar from "components/snackBar";
import SaveChangesFooter from "pages/settings/components/saveChangesFooter";
import { useFormik } from "formik";
import * as Yup from "yup";

const BrandingPage = () => {
  const intl = useIntl();

  const [resetModalOpened, setResetModalOpened] = useState(false);
  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: "",
    title: "",
    message: "",
  });

  const {
    data: brandingData,
    isPending: isReportsBrandingLoading,
    isError: isReportsBrandingError,
  } = useGetCompanyBranding();

  const companyBranding = brandingData?.attributes || {};
  const brandingId = brandingData?.id || null;

  // Commented untill input from Product Manager and BE
  // const { data: brandingDefaults, isLoading: brandingDefaultsLoading } =
  //   useBrandingDefaults();

  const { data: fontsList, isError: isfontsListError } = useFontsList();

  useEffect(() => {
    if (isfontsListError) handleSnackBarError();
  }, [isfontsListError]);

  const { mutate: handleEditReporsBranding, isPending: editLoading } =
    useEditReporsBranding();

  const handleSnackBarError = () => {
    setSnackBar({
      open: true,
      severity: "error",
      title: "somethings_went_wrong",
      message: "try_again",
    });
  };

  // Handle editing branding
  const editReportsBranding = () => {
    let formData = new FormData();
    let newBrandingData = {};

    for (var key of Object.keys(formik?.values)) {
      if (key === "logo") {
        if (!formik?.values?.logo)
          formData?.append("logo", null); // When the user removes the logo
        else if (formik?.values?.logo instanceof File)
          formData?.append("logo", formik?.values?.logo); // When the user upload or change in the current logo
      } else newBrandingData[key] = formik?.values?.[key];
    }
    formData?.append("report_branding", JSON?.stringify(newBrandingData));

    handleEditReporsBranding(
      { formData },
      {
        onSuccess: () => {
          setSnackBar({
            open: true,
            severity: "success",
            title: "",
            message: "branding_info_updated_success",
          });
        },
        onError: () => handleSnackBarError(),
      },
    );
  };

  const formik = useFormik({
    initialValues: {
      logo: companyBranding?.logo,
      logo_details: companyBranding?.logo_details,
      font_type_id: companyBranding?.font_type_id || null,
      primary_color: companyBranding?.primary_color || null,
      secondary_color: companyBranding?.secondary_color || null,
      tertiary_color: companyBranding?.tertiary_color || null,
      selected_tertiary_color: companyBranding?.selected_tertiary_color || null,
      opacity: companyBranding?.opacity || null,
    },
    validationSchema: Yup.object({
      font_type_id: Yup.number().required("required"),
      primary_color: Yup.string()
        .required("required")
        .test("", "error", (value) => value?.length > 3),
      secondary_color: Yup.string()
        .required("required")
        .test("", "error", (value) => value?.length > 3),
      tertiary_color: Yup.string()
        .required("required")
        .test("", "error", (value) => value?.length > 3),
      selected_tertiary_color: Yup.string().required("required"),
      opacity: Yup.number().required("required"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: () => editReportsBranding(),
  });

  return (
    <Box id="company-branding">
      <BrandingHeader companyBranding={companyBranding} />
      {isReportsBrandingLoading || isReportsBrandingError ? (
        <CircularLoading />
      ) : (
        <BoxBranding
          formik={formik}
          fontsList={fontsList}
          resetModalOpened={resetModalOpened}
          setResetModalOpened={setResetModalOpened}
          brandingId={brandingId}
          setSnackBar={setSnackBar}
        />
      )}

      {handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING") && formik?.dirty ? (
        <SaveChangesFooter
          handleSaveChanges={formik?.handleSubmit}
          disabledSaveChanges={!formik?.isValid}
          loadingSaveChanges={editLoading}
          handleCancelChanges={formik?.resetForm}
        />
      ) : null}

      <SnackBar
        message={CheckValueLocale(snackBar?.message, "", {}, intl)}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
        open={snackBar?.open || false}
        severity={snackBar?.severity}
        handleClose={() => setSnackBar({ open: false, title: "", message: "" })}
      />
    </Box>
  );
};

export default BrandingPage;
