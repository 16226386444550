import { useQuery } from "@tanstack/react-query";
import EngagementsController from "services/controllers/engagementsController";

export const useGetInboundRouting = ({ id }) => {
  return useQuery({
    queryKey: ["inboundRouting"],
    queryFn: () => EngagementsController.getInboundRouting(id),
    select: (data) => data?.data?.data?.attributes,
    enabled: !!id,
    retry: false,
  });
};
