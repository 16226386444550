import { removeSpecialCharacters } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/keywordLanguage";
import { isPublicSpecialCharactersOrEmojis } from "pages/socialListing/MonitorsOptions/MonitorsShared/SharedFunctions/components/specialCharOrEmojis";
import * as Yup from "yup";
export const ipValidation = (num) => {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    num,
  );
};

export const prefixValidation = (num) => {
  return /^[0-9]{1,2}$/.test(num);
};

export const validationSchemaLucidyaApi = () =>
  Yup.object().shape({
    api_type: Yup?.array()?.min(1, "api_type_field_required").required(),
    token_name: Yup?.string()
      ?.required("api_name_field_required")
      ?.min(3, "api_name_min_length_error")
      ?.max(100, "api_name_max_length_error")
      .test("no-special-chars", "spam_keywords_rules", (value) =>
        value
          ? !isPublicSpecialCharactersOrEmojis(value, undefined, true) &&
            removeSpecialCharacters(value)?.length > 0
          : true,
      ),
    ip_subnet: Yup?.string()
      ?.test("no-duplicate", "ip_subnet_canot_duplicated", function (value) {
        const { ip_subnet_arr } = this?.parent;
        if (!value) return true; // If value is empty, skip the check
        return !ip_subnet_arr.includes(value);
      })
      ?.test("valid-ip-format", "ip_invalid_format", (value) => {
        if (!value) return true;
        if (value.includes("/")) {
          const [ip, prefix] = value?.split("/");
          return ipValidation(ip) && prefixValidation(prefix);
        }
        return ipValidation(value);
      })
      ?.test(
        "ip_subnet_field_required",
        "ip_subnet_field_required",
        function () {
          const { ip_subnet_arr } = this?.parent;
          return !(ip_subnet_arr?.length === 0);
        },
      ),
  });

export const initialValuesLucidyaApi = {
  id: "",
  api_type: [],
  token_name: "",
  ip_subnet: "",
  ip_subnet_arr: ["0.0.0.0/0"],
};
