import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import DefaultUserAvtar from "images/shared-images/user-management-default.svg";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import AiAgentsFields from "../aiAgents/component/aiAgentsContent/aiAgentsFormContent/component/aiAgentsFields";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { useUnassignedConversationAiAgent } from "../aiAgents/aiAgentsHooks/useUnassignedConversationAiAgent";
import { UnassignedConversationValidationSchema } from "../aiAgents/aiAgentsValidation/unassignedConversationValidationSchema";
import { AssignHumanValidationSchema } from "../aiAgents/aiAgentsValidation/assignHumanValidationSchema";
import { useAssignTeamsAndUsers } from "../aiAgents/aiAgentsHooks/useAssignTeamsAndUsers";
import { useTeamList } from "../aiAgents/aiAgentsHooks/useTeamList";
import { useTeamUsersList } from "../aiAgents/aiAgentsHooks/useTeamUsersList";

const TeamUnavailableAiAgentModal = ({
  selectedCard,
  openPopupTeamUnavailable,
  setOpenPopupTeamUnavailable,
  setRefresh,
  setOpenSnackBarAiAgent,
  setSnackBarDataAiAgent,
  chatData,
  autoReplyMessage,
}) => {
  const intl = useIntl();
  const [autoAssignment, setAutoAssignment] = useState("unassigned");
  const [isTeamLoading, setIsTeamLoading] = useState(false);

  const assignmentManualAssign = autoAssignment === "manual_assign";

  const {
    data: teamList,
    isLoading: teamListLoading,
    refetch: refetchTeamList,
    isRefetching: isRefetchTeamList,
  } = useTeamList(assignmentManualAssign);

  const {
    mutate: unassignedConversationAiAgent,
    isPending: unassignedConversationAiAgentLoading,
  } = useUnassignedConversationAiAgent();

  const { mutate: assignTeamsAndUsers, isPending: assignTeamsAndUsersLoading } =
    useAssignTeamsAndUsers();

  const onOpenTeamDropdown = () => {
    setIsTeamLoading(true);
    refetchTeamList();
  };

  useEffect(() => {
    if (teamList && !teamListLoading && !isRefetchTeamList) {
      setIsTeamLoading(false);
    }
  }, [teamList, teamListLoading, isRefetchTeamList]);

  const closeModal = () => {
    setOpenPopupTeamUnavailable(null);
    unassignedFormik?.resetForm();
    manualAssignFormik?.resetForm();
  };

  const unassignedConversationInitialValues = {
    message: autoReplyMessage || "",
  };

  const submitUnassignedConversation = (values) => {
    let queryData = {
      id:
        selectedCard?.ai_agent?.ai_agent_id ||
        selectedCard?.user_email?.split("_")?.[2],
      conversation_id: selectedCard?.conversation_id,
      message: values?.message,
      monitor_id: selectedCard?.tracker_id,
      data_source:
        selectedCard?.data_source?.toLowerCase() +
        "_" +
        selectedCard?.channel_type?.toLowerCase(),
      client_info_id: selectedCard?.client_id,
      conversation_info: {
        status: selectedCard?.status,
        created_at: selectedCard?.created_at,
      },
    };
    unassignedConversationAiAgent(queryData, {
      onSuccess: () => {
        closeModal();
        setRefresh();
        setOpenSnackBarAiAgent(true);
        setSnackBarDataAiAgent({
          severity: "success",
          message: "reply_sent_moved_unassigned",
        });
      },
      onError: () => {
        setOpenSnackBarAiAgent(true);
        setSnackBarDataAiAgent({
          severity: "error",
          title: "failed_error_message",
          message: "try_again_error_message",
        });
      },
    });
  };

  const unassignedFormik = useFormik({
    enableReinitialize: true,
    initialValues: unassignedConversationInitialValues,
    validationSchema: UnassignedConversationValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => submitUnassignedConversation(values),
  });

  const manualAssignInitialValues = {
    new_user_team_id: null,
    new_user_id: null,
  };

  const submitManualAssign = (values) => {
    let chatDataCopy = [...chatData];
    let lastMsg =
      chatDataCopy
        ?.reverse()
        ?.find((item) => item?.direction === "inbound" && item?.content?.text)
        ?.content?.text || chatDataCopy?.reverse()?.[0]?.content?.text;
    let queryData = {
      new_user_id: values?.new_user_id,
      old_user_id: selectedCard?.assigning_info?.user_id,
      new_user_team_id: values?.new_user_team_id,
      old_user_team_id: selectedCard?.assigning_info?.team_id,
      interaction_id: selectedCard?.conversation_id,
      monitor_id: selectedCard?.tracker_id,
      data_source_name: selectedCard?.data_source,
      product_id: +window.localStorage.getItem("engagementsProductId"),
      message: {
        text: lastMsg || "",
        user: selectedCard?.client_name,
        url: selectedCard?.client_profile_image_url,
      },
      from_ai_agent: true,
    };
    assignTeamsAndUsers(queryData, {
      onSuccess: (data) => {
        if (!data?.errorMsg) {
          closeModal();
          setRefresh();
          setOpenSnackBarAiAgent(true);
          setSnackBarDataAiAgent({
            severity: "success",
            message: "engagement_team_succussfully",
          });
        } else {
          setOpenSnackBarAiAgent(true);
          setSnackBarDataAiAgent({
            severity: "error",
            title: "failed_error_message",
            message: "try_again_error_message",
          });
        }
      },
    });
  };

  const manualAssignFormik = useFormik({
    enableReinitialize: true,
    initialValues: manualAssignInitialValues,
    validationSchema: AssignHumanValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => submitManualAssign(values),
  });

  const queryData = {
    id: manualAssignFormik?.values?.new_user_team_id,
    product_id: window.localStorage.getItem("engagementsProductId"),
  };

  const teamOptionsList =
    teamList?.map((item) => {
      return item?.id;
    }) || [];

  const teamOptionsListObj =
    teamList?.map((item) => {
      return {
        name: item?.name,
        id: item?.id,
        user_teams_count: item?.user_teams_count,
      };
    }) || [];

  const { data: teamUsersList, isLoading: isTeamUsersListLoading } =
    useTeamUsersList(queryData);

  const teamUsersOptionsList =
    teamUsersList?.map((item) => {
      return item?.attributes?.id;
    }) || [];

  const teamUsersOptionsListObj =
    teamUsersList?.map((item) => {
      return {
        id: item?.attributes?.id,
        name: item?.attributes?.name,
        avatar: item?.attributes?.avatar,
        availability_status: item?.attributes?.availability_status,
      };
    }) || [];

  const onClickAddNewTeam = () => {
    window.open("/settings/teams/add", "_blank");
  };

  const onClickAddNewUser = () => {
    window.open("/settings/user_managements/add", "_blank");
  };

  let modalBody = (
    <Box>
      <Box component="p" className="ai-agent-modal-text">
        {CheckValueLocale("team_members_unavailable_hint", "", {}, intl)}
      </Box>
      <Box className="ai-agents-form-group">
        <RadioGroup
          className="ai-agents-form-radio"
          aria-labelledby="autoAssignment"
          name="autoAssignment"
          value={autoAssignment}
          onChange={(e) => {
            setAutoAssignment(e?.target?.value);
            unassignedFormik?.resetForm();
            manualAssignFormik?.resetForm();
          }}
        >
          <FormControlLabel
            value="unassigned"
            control={<Radio />}
            label={CheckValueLocale("unassigned_tab_aption", "", {}, intl)}
          />
          {autoAssignment === "unassigned" ? (
            <Box className="ai-agents-form-group-small">
              <AiAgentsFields
                type="textarea"
                id="auto-reply-customer"
                label={CheckValueLocale(
                  "send_message_to_your_customers",
                  "",
                  {},
                  intl,
                )}
                name="message"
                value={unassignedFormik?.values?.message}
                error={!!unassignedFormik.errors?.message}
                onBlur={unassignedFormik.handleBlur}
                helperText={CheckValueLocale(
                  unassignedFormik.errors?.message,
                  "",
                  {},
                  intl,
                )}
                onChange={unassignedFormik?.handleChange}
              />
            </Box>
          ) : null}
          <FormControlLabel
            value="manual_assign"
            control={<Radio />}
            label={
              <>
                {CheckValueLocale("manual_assign_tab_aption", "", {}, intl)}
                <Box component={"span"} className="ai-agents-form-hint-label">
                  {CheckValueLocale(
                    "manual_assign_tab_aption_hint",
                    "",
                    {},
                    intl,
                  )}
                </Box>
              </>
            }
          />
        </RadioGroup>
      </Box>
      {autoAssignment === "manual_assign" ? (
        <>
          <AiAgentsFields
            type="autoComplete"
            id="engagment-team-human-select"
            name="new_user_team_id"
            value={manualAssignFormik?.values?.new_user_team_id}
            optionsList={teamOptionsList?.filter(
              (id) => manualAssignFormik?.values?.new_user_team_id !== id,
            )}
            optionsListObj={teamOptionsListObj}
            handleChange={(e, value) => {
              manualAssignFormik?.setFieldValue("new_user_id", null);
              manualAssignFormik?.setFieldValue("new_user_team_id", value);
            }}
            error={!!manualAssignFormik.errors?.new_user_team_id}
            onBlur={manualAssignFormik.handleBlur}
            helperText={CheckValueLocale(
              manualAssignFormik.errors?.new_user_team_id,
              "",
              {},
              intl,
            )}
            label={CheckValueLocale("assign_team", "", {}, intl)}
            placeholder={CheckValueLocale("select", "", {}, intl)}
            emptyListMessage={CheckValueLocale("no_result", "", {}, intl)}
            customOptionLabel={CheckValueLocale(
              "routing_add_new_team",
              "",
              {},
              intl,
            )}
            onCustomOptionClick={onClickAddNewTeam}
            icon={faExternalLink}
            isLoading={isTeamLoading}
            onOpen={onOpenTeamDropdown}
            required
            renderOptionContent={(option) => {
              const optionObj = teamOptionsListObj?.find(
                (item) => item?.id == option,
              );
              return (
                <Box className={`assign-item-countainer-sla-sidebar`}>
                  <Box className="assign-item-details">
                    <Box>{optionObj?.name}</Box>
                    <Box className="assign-item-state team-assign-item">
                      {optionObj?.user_teams_count ? <Box></Box> : null}
                      {CheckValueLocale(
                        optionObj?.user_teams_count
                          ? "users_num"
                          : "users_unavailable",
                        "",
                        { num: optionObj?.user_teams_count },
                        intl,
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            }}
          />
          <AiAgentsFields
            type="autoComplete"
            id="engagment-user-human-select"
            name="new_user_id"
            value={manualAssignFormik?.values?.new_user_id}
            optionsList={
              isTeamUsersListLoading
                ? []
                : teamUsersOptionsList?.filter(
                    (id) => manualAssignFormik?.values?.new_user_id !== id,
                  )
            }
            optionsListObj={
              isTeamUsersListLoading ? [] : teamUsersOptionsListObj
            }
            handleChange={(e, value) =>
              manualAssignFormik?.setFieldValue("new_user_id", value)
            }
            error={!!manualAssignFormik.errors?.new_user_id}
            onBlur={manualAssignFormik.handleBlur}
            helperText={CheckValueLocale(
              manualAssignFormik.errors?.new_user_id,
              "",
              {},
              intl,
            )}
            label={CheckValueLocale("assign_user", "", {}, intl)}
            placeholder={CheckValueLocale("select", "", {}, intl)}
            emptyListMessage={CheckValueLocale("no_result", "", {}, intl)}
            customOptionLabel={CheckValueLocale("add_new_user", "", {}, intl)}
            onCustomOptionClick={onClickAddNewUser}
            icon={faExternalLink}
            isLoading={isTeamUsersListLoading}
            required
            renderOptionContent={(option) => {
              const optionObj = teamUsersOptionsListObj?.find(
                (item) => item?.id == option,
              );
              return (
                <Box className={`assign-item-countainer-sla-sidebar`}>
                  <Box className="assign-item-profile">
                    <img
                      src={optionObj?.avatar || DefaultUserAvtar}
                      onError={(e) => {
                        e.target.src = DefaultUserAvtar;
                      }}
                    />
                    <Box
                      className={
                        optionObj?.availability_status !== "unavailable"
                          ? "available"
                          : "unavailable"
                      }
                    ></Box>
                  </Box>
                  <Box className="assign-item-details">
                    <Box>{optionObj?.name}</Box>
                    <Box className="assign-item-state">
                      {CheckValueLocale(
                        optionObj?.availability_status !== "unavailable"
                          ? "available"
                          : "unavailable",
                        "",
                        {},
                        intl,
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            }}
          />
        </>
      ) : null}
    </Box>
  );

  const ButtonLoading =
    assignTeamsAndUsersLoading || unassignedConversationAiAgentLoading;
  return (
    <PopupModal
      title={CheckValueLocale("team_members_unavailable", "", {}, intl)}
      body={modalBody}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("confirm_team", "", {}, intl)}
      disabled={ButtonLoading}
      isRightBtnLoading={ButtonLoading}
      open={openPopupTeamUnavailable || false}
      close={closeModal}
      accept={() =>
        autoAssignment === "unassigned"
          ? unassignedFormik?.submitForm()
          : manualAssignFormik?.submitForm()
      }
      dialogContentId="pause-ai-agent"
    />
  );
};
export default TeamUnavailableAiAgentModal;
