import { Box, Tooltip } from "@mui/material";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { AiAgentsContext } from "../../aiAgentsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";

const SideBarBox = ({ aiAgent }) => {
  const intl = useIntl();
  const {
    aiAgentSelected,
    setAiAgentSelected,
    setSelectedAiAgentPublishedVersion,
    setAiAgentPublishError,
  } = useContext(AiAgentsContext);

  const isAiAgentActive = aiAgent?.id === aiAgentSelected;

  const handleChangeAiAgentSelected = () => {
    if (isAiAgentActive) return;
    setSelectedAiAgentPublishedVersion(null);
    setAiAgentSelected(aiAgent?.id);
    setAiAgentPublishError({
      message: "",
      files: "",
    });
  };

  const isAssigned = aiAgent?.auto_assignment === "unassigned";
  const hasInboundRouting = !!aiAgent?.inbound_routing;

  const isDMLimitReached = aiAgent?.x_rate_limit;
  const isLimitReached = aiAgent?.quota_limit;

  const isTooltipShown = isDMLimitReached || isLimitReached;
  const tooltipText = isDMLimitReached
    ? CheckValueLocale("ai_agent_dm_limit_reached_tooltip", "", {}, intl)
    : CheckValueLocale("ai_agent_limit_reached_tooltip", "", {}, intl);

  return (
    <Box
      className={`ai-agent-side-bar-box ${isAiAgentActive ? "side-bar-box-active" : ""}`}
      onClick={handleChangeAiAgentSelected}
    >
      <Box className="ai-agent-name-tooltip-container">
        <Box className="ai-agent-box-name" component="h3">
          {aiAgent?.name}
        </Box>
        {isTooltipShown ? (
          <Tooltip title={tooltipText} arrow placement="top">
            <FontAwesomeIcon
              className="ai-agent-name-tooltip-icon"
              icon={faCircleExclamation}
            />
          </Tooltip>
        ) : null}
      </Box>
      <Box className="ai-agent-box-footer">
        <Box
          className={`ai-agent-box-hint ${!hasInboundRouting ? "agent-not-assigned" : ""}`}
          component="p"
        >
          {isAssigned
            ? CheckValueLocale("routed_unassigned", "", {}, intl)
            : hasInboundRouting
              ? `${CheckValueLocale("routed_inbound", "", {}, intl)} "${aiAgent?.inbound_routing?.name}"`
              : CheckValueLocale("routed_no_inbound", "", {}, intl)}
        </Box>
        <Box className={`ai-agent-box-status box-status-${aiAgent?.status}`}>
          {CheckValueLocale(`ai_agent_status_${aiAgent?.status}`, "", {}, intl)}
        </Box>
      </Box>
    </Box>
  );
};

export default SideBarBox;
