import { useQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useGetCompanyUsers = ({ search, page, sort_by, order_by }) => {
  return useQuery({
    queryKey: ["companyUsers", search, page, sort_by, order_by],
    queryFn: () =>
      CompaniesController.getCompanyUsers(search, page, sort_by, order_by),
    select: (data) => {
      if (!!data?.data) return data?.data;
      else return data;
    },
    retry: false,
  });
};
