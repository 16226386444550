import { useQuery } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";

export const useSurveysPublishedList = (surveyActive) => {
  return useQuery({
    queryKey: ["surveysPublishedList"],
    queryFn: () => AiAgentsServices.getSurveysPublishedList(),
    select: (data) => data?.data?.surveys?.data,
    enabled: !!surveyActive,
  });
};
