import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import "./style.scss";

const SaveChangesFooter = (props) => {
  const {
    handleSaveChanges,
    disabledSaveChanges,
    loadingSaveChanges,
    handleCancelChanges,
  } = props;
  const intl = useIntl();

  return (
    <Box id="settings-save-changes-footer">
      <Box className="save-changes-footer-content">
        <Box className="btns-container">
          <LucButton
            id="settings-save-changes-footer-btn"
            disabled={disabledSaveChanges}
            loading={loadingSaveChanges}
            onClick={handleSaveChanges}
          >
            {CheckValueLocale("settings_save_changes", "", {}, intl)}
          </LucButton>
          <LucButton
            id="settings-cencel-changes-footer-btn"
            variant="outline"
            type="secondary"
            disabled={loadingSaveChanges}
            onClick={handleCancelChanges}
          >
            {CheckValueLocale("cancel", "", {}, intl)}
          </LucButton>
        </Box>
        <Typography>
          {CheckValueLocale("you_have_unsaved_changes", "", {}, intl)}
        </Typography>
      </Box>
    </Box>
  );
};

export default SaveChangesFooter;
