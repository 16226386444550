import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import CircularLoading from "components/circularProgress";
import "./teams.scss";
import { useEffect, useState } from "react";
import SnackBar from "components/snackBar";
import { TeamsHeader } from "./components/teamsHeader";
import { useHistory } from "react-router-dom";
import OptionsDropDown from "./components/tableCells/optionsDropdown";
import PopupModal from "components/popupModal";
import NoDataFoundProducts from "components/no-Data";
import TeamNameCell from "./components/tableCells/teamNameCell";
import TeamCreatedAtCell from "./components/tableCells/teamCreatedAtCell";
import ProductAccessCell from "./components/tableCells/productAccessCell";
import TeamMembersCell from "./components/tableCells/teamMembersCell";
import { useGetTeams } from "./hooks/useGetTeams";
import { TeamsTable } from "./components/teamsTable";
import { useDeleteTeam } from "./hooks/useDeleteTeam";

const Teams = () => {
  const intl = useIntl();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [tableBody, setTableBody] = useState([]);
  const [tableEmptyBody, setTableEmptyBody] = useState([]);

  const [stats, setStats] = useState({
    team_id: null,
    team_name: "",
    openDeleteModal: false,
    page: "",
    teamAiAgentName: null,
  });
  const [teamsSnackBar, setTeamsSnackBar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnackBar: false,
  });

  // Delete Team
  const { mutate: handleDeleteTeam, isPending: deleteTeamLoading } =
    useDeleteTeam();

  //get Teams for table
  const {
    data: teamsResponse,
    isPending: isPendingCompanyTeams,
    isError: isErrorCompanyTeams,
  } = useGetTeams({
    page,
    search,
  });

  const handleSearchIconClick = (searchInput) => {
    if (searchInput !== search) {
      setSearch(searchInput);
      setPage(1);
    }
  };
  const handleCloseSnackBar = () => {
    setTeamsSnackBar({
      openSnackBar: false,
    });
  };
  const handleChangePage = (event, page) => {
    if (Boolean(page) === false) return;
    setPage(page);
  };

  //handle delete team by hookAPI(useDeleteTeam)
  const deleteTeam = (teamId) => {
    handleDeleteTeam(
      { teamId },
      {
        onSuccess: () => {
          setTeamsSnackBar({
            message: "team_deleted_successfully",
            severity: "success",
            openSnackBar: true,
          });
          // if the team has been deleted, and current page is not the first page and it has only one team
          // then go to the previous page
          if (teamsResponse?.data.length === 1 && page !== 1) {
            setPage(page - 1);
          }
        },
        onError: (error) => {
          const isConnectedToAIAgent =
            error?.errorMsg?.response?.data?.error?.detail ===
            "used_team_by_ai_agent";

          setTeamsSnackBar({
            message: isConnectedToAIAgent
              ? "delete_team_connected_to_ai_agent_desc"
              : "try_again_error_message",
            severity: "error",
            title: isConnectedToAIAgent
              ? "delete_team_connected_to_ai_agent_title"
              : "failed_error_message",
            openSnackBar: true,
          });
        },
        onSettled: () => handleCloseDeleteModal(),
      },
    );
  };

  //handle data of teams for table from hookAPI(useGetTeams)
  useEffect(() => {
    if (isErrorCompanyTeams) {
      setTeamsSnackBar({
        message: "try_again_error_message",
        severity: "error",
        title: "failed_error_message",
        openSnackBar: true,
      });
    }
  }, [isErrorCompanyTeams]);

  const handleCloseDeleteModal = () => {
    setStats({
      openDeleteModal: false,
    });
  };

  const handleDeleteUserAccount = (team) => {
    setStats({
      team_id: team?.id,
      team_name: team?.name,
      openDeleteModal: true,
      page: page,
      teamAiAgentName: team?.used_by_ai_agent_name,
    });
  };

  useEffect(() => {
    const tabBody =
      teamsResponse?.data.length == 0
        ? []
        : teamsResponse?.data.map((teamObj) => {
            const team = teamObj?.attributes || {};
            return {
              data: [
                {
                  body: <TeamNameCell name={team?.name} id={team?.id} />,
                },
                {
                  body: <TeamCreatedAtCell createdAt={team?.created_at} />,
                },
                {
                  body: <ProductAccessCell product={team?.product} />,
                },
                {
                  body: <TeamMembersCell members={team?.users?.data} />,
                },
                {
                  body: (
                    <OptionsDropDown
                      team={team}
                      openDeleteModal={handleDeleteUserAccount}
                    />
                  ),
                },
              ],
            };
          });
    setTableBody(tabBody);
    setTableEmptyBody(emptyBody(teamsResponse?.data));
  }, [teamsResponse?.data, intl?.locale]);

  useEffect(() => {
    // if the search has a deleted_successfully message status,
    // then show a snackbar with the message deleted_successfully
    const search = history?.location?.search;
    if (search && search?.includes("status=deleted_successfully")) {
      // show the error message and then remove it from the url
      setTeamsSnackBar({
        message: "team_deleted_successfully",
        severity: "success",
        openSnackBar: true,
      });
      window.history.pushState({}, "", window.location.pathname);
    }
  }, []);

  const emptyBody = (teams = []) => {
    return (
      <>
        {search !== "" && teams?.length == 0 ? (
          <Box className="empty-item">
            <NoDataFoundProducts
              image={"noDataImg"}
              title={"no_data_found"}
              subTitle="monitor_list_search_empty_submsg"
            />
          </Box>
        ) : teams?.length == 0 ? (
          <Box className="empty-item">
            <NoDataFoundProducts
              image="teams"
              title="create_a_team"
              subTitle="create_a_team_dec"
            />
          </Box>
        ) : null}
      </>
    );
  };

  const selectedTeamHasAiAgents = stats?.teamAiAgentName?.length > 0;

  const handleDeleteOrCancel = () => {
    if (selectedTeamHasAiAgents) {
      handleCloseDeleteModal();
    } else {
      deleteTeam(stats?.team_id);
    }
  };

  if (isPendingCompanyTeams) {
    return <CircularLoading />;
  }

  return (
    <Box className="teams">
      <TeamsHeader
        teams={teamsResponse?.data || []}
        search={search}
        handleSearchIconClick={handleSearchIconClick}
      />
      <TeamsTable
        pagination={teamsResponse?.pagination || 1}
        handleChangePage={handleChangePage}
        page={page}
        tableBody={tableBody}
        tableEmptyBody={tableEmptyBody}
      />
      <SnackBar
        open={teamsSnackBar?.openSnackBar}
        severity={teamsSnackBar?.severity}
        message={
          <Box className="team-snackbar-message">
            {CheckValueLocale(teamsSnackBar?.message, "", {}, intl)}
          </Box>
        }
        title={CheckValueLocale(teamsSnackBar?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
      {stats.openDeleteModal ? (
        <PopupModal
          title={CheckValueLocale("delete_team", "", {}, intl)}
          body={
            <Box className="shared-dialog-body">
              {selectedTeamHasAiAgents ? (
                <>
                  <Box>
                    {CheckValueLocale(
                      "delete_team_connected_ai_agent_message",
                      "",
                      {
                        teamName: stats?.team_name,
                        aiAgentName: stats?.teamAiAgentName
                          ?.map((name) => `[${name}]`)
                          .join(", "),
                      },
                      intl,
                    )}
                  </Box>
                  <h2 className="delete-team-with-ai-agents-confirm">
                    {CheckValueLocale(
                      "delete_team_with_ai_agents_confirmation",
                      "",
                      {},
                      intl,
                    )}
                  </h2>
                </>
              ) : (
                <>
                  <p>
                    {CheckValueLocale(
                      "delete_team_body",
                      "",
                      { team_name: stats?.team_name },
                      intl,
                    )}
                  </p>
                  <h2 className="sub-text-desc">
                    {CheckValueLocale("deleting_msg", "", {}, intl)}
                  </h2>
                </>
              )}
            </Box>
          }
          open={stats.openDeleteModal}
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          rightBtn={CheckValueLocale(
            selectedTeamHasAiAgents ? "okay" : "delete_confirm",
            "",
            {},
            intl,
          )}
          close={handleCloseDeleteModal}
          accept={handleDeleteOrCancel}
          warning={!selectedTeamHasAiAgents}
          minWidth="600px"
          classeName="delete-team-modal actions-buttons-shared"
          isRightBtnLoading={deleteTeamLoading}
          disabled={deleteTeamLoading}
        />
      ) : null}
    </Box>
  );
};

export default Teams;
