import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import "./apiActions.scss";
import { CheckValueLocale } from "utils/helpers/index";
import { LucidyaApisContext } from "../lucidyaApisContext";
import GeneratedApi from "../createApi/generatedApi";
import LucButton from "shared/lucButton/lucButton";
import { useDeleteLucidyaApi } from "../../hooks/useDeleteLucidyaApi";
import { useInActiveLucidyaApi } from "../../hooks/useInActiveLucidyaApi";
import { useActiveLucidyaApi } from "../../hooks/useActiveLucidyaApi";
import { useRegenerateLucidyaApi } from "../../hooks/useRegenerateLucidyaApi";

const ApiActions = ({ open }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [apikeyResponse, setApikeyResponse] = useState({
    tokenName: "",
    token: "",
  });
  const [generated, setGenerateApi] = useState(false);

  const {
    handleApiActionsDialog,
    apiDialogType,
    apiDialogItem,
    setSuccess,
    setSnackBarHeading,
    apisListData,
    page,
    setPage,
  } = useContext(LucidyaApisContext);

  let textApiDialog = null;

  switch (apiDialogType) {
    case "deleteApiKey":
      textApiDialog = {
        title: "delete_api_key",
        heading: "delete_api_key_heading",
        headingHint: "delete_api_key_heading_hint",
        button: "delete_confirm",
        buttonType: "danger",
        buttonAction: () => deleteLucidyaApisRequest(),
      };
      break;
    case "inActivateApiKey":
      textApiDialog = {
        title: "de_activate_api_key",
        heading: "de_activate_api_key_heading",
        subHeading: "de_activate_api_key_sub_heading",
        button: "de_activate",
        buttonType: "danger",
        buttonAction: () => inActiveLucidyaApisRequest(),
      };
      break;
    case "activateApiKey":
      textApiDialog = {
        title: "activate_api_key",
        heading: "activate_api_key_heading",
        button: "activate",
        buttonType: "normal",
        buttonAction: () => activeLucidyaApisRequest(),
      };
      break;
    case "regenerateApiKey":
      textApiDialog = {
        title: "re_generate_api_key",
        heading: "re_generate_api_key_heading",
        subHeading: "re_generate_api_key_sub_heading",
        button: "re_generate",
        buttonType: "normal",
        buttonAction: () => regenerateLucidyaApisRequest(),
      };
      break;
    default:
      break;
  }
  const {
    title,
    heading,
    headingHint,
    subHeading,
    button,
    buttonType,
    buttonAction,
  } = textApiDialog;

  const { mutate: handleDeleteLucidyaApi } = useDeleteLucidyaApi();
  const { mutate: handleInActiveLucidyaApi } = useInActiveLucidyaApi();
  const { mutate: handleActiveLucidyaApi } = useActiveLucidyaApi();
  const { mutate: handleRegenerateLucidyaApi } = useRegenerateLucidyaApi();

  const deleteLucidyaApisRequest = async () => {
    setIsLoading(true);
    handleDeleteLucidyaApi(
      { id: apiDialogItem?.id },
      {
        onSuccess: () => {
          setSuccess(true);
          setSnackBarHeading("api_deleted_success");
          handleApiActionsDialog();
          if (apisListData?.length === 1 && page > 1) {
            setPage(page - 1);
          } else {
            setPage(1);
          }
        },
        onError: () => {
          setSuccess(false);
          setSnackBarHeading("api_deleted_error");
          handleApiActionsDialog();
        },
        onSettled: () => {
          setIsLoading(false);
        },
      },
    );
  };

  const inActiveLucidyaApisRequest = async () => {
    setIsLoading(true);
    handleInActiveLucidyaApi(
      { id: apiDialogItem?.id },
      {
        onSuccess: () => {
          setSuccess(true);
          setSnackBarHeading("api_de_activate_success");
          handleApiActionsDialog();
          setPage(1);
        },
        onError: () => {
          setSuccess(false);
          setSnackBarHeading("api_de_activate_error");
          handleApiActionsDialog();
        },
        onSettled: () => {
          setIsLoading(false);
        },
      },
    );
  };

  const activeLucidyaApisRequest = async () => {
    setIsLoading(true);
    handleActiveLucidyaApi(
      { id: apiDialogItem?.id },
      {
        onSuccess: () => {
          setSuccess(true);
          setSnackBarHeading("api_activate_success");
          handleApiActionsDialog();
          setPage(1);
        },
        onError: () => {
          setSuccess(false);
          setSnackBarHeading("api_activate_error");
          handleApiActionsDialog();
        },
        onSettled: () => {
          setIsLoading(false);
        },
      },
    );
  };

  const regenerateLucidyaApisRequest = async () => {
    setIsLoading(true);
    handleRegenerateLucidyaApi(
      { id: apiDialogItem?.id },
      {
        onSuccess: (result) => {
          setSuccess(true);
          setSnackBarHeading("api_re_generate_success");
          setApikeyResponse({
            tokenName: result?.data?.token_name,
            token: result?.data?.token,
          });
          setGenerateApi(true);
          setPage(1);
        },
        onError: () => {
          setSuccess(false);
          setSnackBarHeading("api_re_generate_error");
          handleApiActionsDialog();
        },
        onSettled: () => {
          setIsLoading(false);
        },
      },
    );
  };

  return (
    <>
      <Dialog open={open} onClose={handleApiActionsDialog}>
        <Box className="create-api-wrapper actions-buttons-shared ">
          <DialogTitle className="create-api-title">
            {CheckValueLocale(title, "", {}, intl)}
          </DialogTitle>
          {generated && apiDialogType === "regenerateApiKey" ? (
            <GeneratedApi apikeyResponse={apikeyResponse} />
          ) : (
            <>
              <DialogContent className="create-api-content">
                <p className="api-action-description">
                  {CheckValueLocale(
                    heading,
                    "",
                    { tokenName: apiDialogItem?.attributes?.token_name },
                    intl,
                  )}
                  {headingHint ? (
                    <span>{CheckValueLocale(headingHint, "", {}, intl)}</span>
                  ) : null}
                </p>
                {subHeading ? (
                  <p className="api-action-description">
                    {CheckValueLocale(subHeading, "", {}, intl)}
                  </p>
                ) : null}
              </DialogContent>
              <DialogActions className="create-api-actions">
                <LucButton
                  variant="flat"
                  type="secondary"
                  onClick={handleApiActionsDialog}
                  id={`${apiDialogType}-api-cancel-btn`}
                  className="cancel-popup-btn"
                >
                  {CheckValueLocale("cancel", "", {}, intl)}
                </LucButton>
                <LucButton
                  type={buttonType === "danger" ? "red" : "primary"}
                  onClick={buttonAction}
                  id={`${apiDialogType}-api-generate-btn`}
                  loading={isLoading}
                >
                  {CheckValueLocale(button, "", {}, intl)}
                </LucButton>
              </DialogActions>
            </>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default ApiActions;
