import { useContext, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { MoreVertSharp } from "@mui/icons-material";
import DropDownMenuOptions from "./dropDownMenuOptions";
import AIAgentDrawer from "./activityLogDrawer/activityLogDrawer";
import DeleteAiAgentModal from "./aiAgentModal/deleteAiAgentModal";
import AiPublishedVersionsDrawer from "./aiPublishedVersionsDrawer/aiPublishedVersionsDrawer";
import { useDublicateAiAgent } from "../../../aiAgentsHooks/useDublicateAiAgent";
import { useDeleteAiAgent } from "../../../aiAgentsHooks/useDeleteAiAgent";
import { AiAgentsContext } from "../../../aiAgentsContext";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";

const OptionsDropDown = ({ aiAgentDetails, isAiAgentPublished }) => {
  const intl = useIntl();
  const {
    setAiAgentSnackBar,
    setAiAgentSelected,
    setSelectedAiAgentPublishedVersion,
    setScrollType,
  } = useContext(AiAgentsContext);
  const [stats, setStats] = useState({
    anchorEl: null,
  });
  const [openPopupDelete, setOpenPopupDelete] = useState(false);
  const [isLogsDrawerOpen, setIsLogsDrawerOpen] = useState(false);
  const [isVersionDrawerOpen, setIsVersionDrawerOpen] = useState(false);

  const { mutate: dublicateAiAgent } = useDublicateAiAgent();

  const { mutate: deleteAiAgent } = useDeleteAiAgent();

  const open = Boolean(stats?.anchorEl);
  const handleClick = (event) => {
    setStats({
      ...stats,
      anchorEl: event?.currentTarget,
    });
  };
  const handleClose = () => {
    setStats({ ...stats, anchorEl: null });
  };

  const handleOpenLogsDrawer = () => {
    setIsLogsDrawerOpen(true);
    handleClose();
  };

  const handleOpenVersionsDrawer = () => {
    setIsVersionDrawerOpen(true);
    handleClose();
  };

  const handleOpenPopupDelete = () => {
    if (isAiAgentPublished) {
      setOpenPopupDelete(true);
      handleClose();
    } else {
      deleteAiAgent(
        { id: aiAgentDetails?.id },
        {
          onSettled: () => {
            handleClose();
          },
          onSuccess: () => {
            setAiAgentSnackBar({
              success: true,
              message: CheckValueLocale(
                "ai_agent_delete_success_message",
                "",
                { name: aiAgentDetails?.name },
                intl,
              ),
            });
            setAiAgentSelected(null);
          },
          onError: () => {
            setAiAgentSnackBar({
              success: false,
              title: CheckValueLocale("failed_error_message", "", {}, intl),
              message: CheckValueLocale(
                "try_again_error_message",
                "",
                {},
                intl,
              ),
            });
          },
        },
      );
    }
  };

  const handleDublicateAiAgent = () => {
    dublicateAiAgent(aiAgentDetails?.id, {
      onSuccess: (data) => {
        setAiAgentSnackBar({
          success: true,
          message: CheckValueLocale(
            "ai_agent_duplicated_success_message",
            "",
            {},
            intl,
          ),
        });
        setSelectedAiAgentPublishedVersion(null);
        setAiAgentSelected(data?.data?.id);
        setScrollType("dublicate");
      },
      onError: () => {
        setAiAgentSnackBar({
          success: false,
          title: CheckValueLocale("failed_error_message", "", {}, intl),
          message: CheckValueLocale("try_again_error_message", "", {}, intl),
        });
      },
      onSettled: () => {
        handleClose();
      },
    });
  };

  return (
    <Box className="btn-ai-agent-action">
      <IconButton
        aria-label="more"
        id="btn-ai-agent-action"
        aria-controls="long-menu"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        onClose={handleClose}
      >
        <MoreVertSharp />
      </IconButton>

      <DropDownMenuOptions
        stats={stats}
        handleClose={handleClose}
        open={open}
        handleOpenPopupDelete={handleOpenPopupDelete}
        handleOpenLogsDrawer={handleOpenLogsDrawer}
        handleOpenVersionsDrawer={handleOpenVersionsDrawer}
        handleDublicateAiAgent={handleDublicateAiAgent}
        aiAgentDetails={aiAgentDetails}
      />
      <AIAgentDrawer
        isOpen={isLogsDrawerOpen}
        toggleDrawer={(value) => setIsLogsDrawerOpen(value)}
        id={aiAgentDetails?.id}
      />
      <AiPublishedVersionsDrawer
        isOpen={isVersionDrawerOpen}
        toggleDrawer={(value) => setIsVersionDrawerOpen(value)}
        id={aiAgentDetails?.id}
        edited={aiAgentDetails?.edited}
      />
      <DeleteAiAgentModal
        openPopupDelete={openPopupDelete}
        setOpenPopupDelete={setOpenPopupDelete}
        aiAgentDetails={aiAgentDetails}
      />
    </Box>
  );
};
export default OptionsDropDown;
