import { Box } from "@mui/material";
import NoDataFoundProducts from "components/no-Data";
import NoDataLinks from "components/no-Data/noDataLinks";
const TableEmptyBody = ({ companyUsers, searchInput }) => {
  return (
    <>
      {searchInput !== "" && companyUsers?.length == 0 ? (
        <Box className="no-data-cdp">
          <NoDataFoundProducts
            image={"noDataImg"}
            title={"no_data_found"}
            subTitle="monitor_list_search_empty_submsg"
          />
        </Box>
      ) : companyUsers?.length == 0 ? (
        <Box className="empty-item">
          <NoDataFoundProducts
            image="UManagement"
            title="invite_your_team"
            description="invite_your_team_dec"
            linkOne="how_to_use"
            linkOneHref={NoDataLinks?.user_management}
            orCase="or_visit_the"
            linkTwo="knowledge_base"
          />
        </Box>
      ) : null}
    </>
  );
};

export default TableEmptyBody;
