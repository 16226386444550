import { useQuery } from "@tanstack/react-query";
import NotificationsController from "services/controllers/notificationsController";

export const useNotificationsCount = () => {
  return useQuery({
    queryKey: ["notificationsCount"],
    queryFn: () => NotificationsController.getNotificationsCount(),
    select: (data) => {
      window.localStorage.setItem("cdp_all_times", data?.data?.oldest_date);
      return {
        count: !!data?.data?.count
          ? data?.data?.count <= 9
            ? data?.data?.count
            : "+9"
          : 0,
        oldest_date: data?.data?.oldest_date || null,
      };
    },
    retry: false,
  });
};
