import { useQuery } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useHistoricalRequestStatus = ({ enableFetch }) => {
  return useQuery({
    queryKey: ["historicalRequestStatus"],
    queryFn: async () => SettingsController.getHistoricalRequestStatus(),
    enabled: !!enableFetch,
    select: (data) => data?.data?.processing,
    retry: false,
  });
};
