import { useQuery } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useThirdPartyList = () => {
  return useQuery({
    queryKey: ["thirdPartyList"],
    queryFn: () => SettingsController.thirdPartyList(),
    select: (data) => data?.data?.data,
    retry: false,
  });
};
