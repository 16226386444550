import { useIntl } from "react-intl";
import {
  Box,
  Typography,
  FormControl,
  TextField,
  Autocomplete,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { timeOffsets } from "./timeOffsets";

const CompanyInfo = (props) => {
  const { formik, userCanEdit } = props;
  const intl = useIntl();

  return (
    <Box>
      <Typography variant="h6" className="settings-section-title">
        {CheckValueLocale("company_info_title", "", {}, intl)}
      </Typography>
      <Box className="company-page-section-box">
        <Typography className="section-description">
          {CheckValueLocale("company_info_desc", "", {}, intl)}
        </Typography>
        <FormControl size="small" fullWidth>
          <Typography className="input-label">
            {CheckValueLocale("company_info_name", "", {}, intl)}
          </Typography>
          <TextField
            id="company-name-input"
            name="name"
            variant="outlined"
            size="small"
            disabled={!userCanEdit}
            value={formik?.values?.["name"] || ""}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={
              formik?.touched?.["name"] && Boolean(formik?.errors?.["name"])
            }
            helperText={
              formik?.touched?.["name"] &&
              CheckValueLocale(formik?.errors?.["name"], "", {}, intl)
            }
          />
        </FormControl>
        <FormControl size="small" fullWidth>
          <Typography className="input-label">
            {CheckValueLocale("company_info_timezone", "", {}, intl)}
          </Typography>
          <Autocomplete
            id="company-timezone-autocomplete"
            name="time_zone"
            options={timeOffsets || []}
            noOptionsText={CheckValueLocale("empty_options_list", "", {}, intl)}
            getOptionLabel={(option) => option?.name}
            renderOption={(props, option) => (
              <Box
                {...props}
                component="li"
                sx={{ direction: "ltr", textAlign: "left" }}
              >
                {option?.name}
              </Box>
            )}
            renderTags={() => null}
            renderInput={(params) => <TextField {...params} />}
            value={
              timeOffsets?.find(
                (item) => item?.value == formik?.values?.["time_zone"],
              ) || null
            }
            onChange={(e, val) =>
              formik?.setFieldValue("time_zone", val?.value)
            }
            error={
              formik?.touched?.["time_zone"] &&
              Boolean(formik?.errors?.["time_zone"])
            }
            helperText={
              formik?.touched?.["time_zone"] &&
              CheckValueLocale(formik?.errors?.["time_zone"], "", {}, intl)
            }
            ListboxProps={{ style: { maxHeight: 300 } }}
            autoHighlight
            disableClearable
            autoComplete
            fullWidth
            size="small"
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export default CompanyInfo;
