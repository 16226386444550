import { useQuery } from "@tanstack/react-query";
import EngagementsController from "services/controllers/engagementsController";

export const useGetAgentCapacities = ({ enableFetch }) => {
  return useQuery({
    queryKey: ["agentCapacities"],
    queryFn: () => EngagementsController.getAgentCapacities(),
    select: (data) => data?.data,
    enabled: enableFetch,
    retry: false,
  });
};
