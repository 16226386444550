import { Box, Typography } from "@mui/material";
import {
  CheckValueLocale,
  formatBytes,
  handlelUserRoles,
  truncate,
} from "utils/helpers";
import ModalImage from "react-modal-image";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/pro-regular-svg-icons";
import DropZoneArea from "./dropZone";
import SectionBrandingHeader from "./sharedComponents/sectionBrandingHeader";
import { useState } from "react";
import PopupModal from "components/popupModal";
import { useIntl } from "react-intl";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";
import EditImageModal from "./editImageModal/editImageModal";

const LogoBranding = (props) => {
  const { formik } = props;

  const intl = useIntl();

  const [openPopupDelete, setOpenPopupDelete] = useState(false);
  const [openEditImageModal, setOpenEditImageModal] = useState(false);

  // to remove file extension from file name
  const handleFileName = (text) =>
    truncate(text?.substring(0, text?.lastIndexOf(".")) || text, 15);

  const removeImage = () => {
    if (handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")) {
      formik?.setFieldValue("logo", null);
      formik?.setFieldError("logo", undefined);
      formik?.setFieldValue("logo_details", null);
      setOpenPopupDelete(false);
      setOpenEditImageModal(false);
    }
  };

  return (
    <Box className="branding-item">
      <SectionBrandingHeader titleId={"company_logo"} />
      <Box className="item-content">
        {formik?.values?.logo ? (
          <Box className="logo-image-container">
            <Box className="branding-logo-info">
              <ModalImage
                className="logo-image"
                small={
                  formik?.values?.logo instanceof File
                    ? URL.createObjectURL(
                        formik?.values?.logo ? formik?.values?.logo : "",
                      )
                    : formik?.values?.logo
                }
                large={
                  formik?.values?.logo instanceof File
                    ? URL.createObjectURL(
                        formik?.values?.logo ? formik?.values?.logo : "",
                      )
                    : formik?.values?.logo
                }
              />
              <Box className="logo-info">
                <Typography variant="h6" className="file-name">
                  {formik?.values?.logo?.name
                    ? handleFileName(formik?.values?.logo?.name)
                    : handleFileName(formik?.values?.logo_details?.file_name)}
                </Typography>
                <Typography className="file-size">
                  {`${(formik?.values?.logo?.type
                    ? formik?.values?.logo?.type
                    : formik?.values?.logo_details?.file_type
                  )
                    ?.split("/")?.[1]
                    ?.toUpperCase()} image - ${formatBytes(formik?.values?.logo?.size ? formik?.values?.logo?.size : formik?.values?.logo_details?.file_size)}`}
                </Typography>
              </Box>
            </Box>
            <Box className="action-branding-logo">
              <CustomizedTooltip
                title={CheckValueLocale("edit", "", {}, intl)}
                arrow
                placement="bottom"
                styles={customizedStyledTooltip}
              >
                <Box component="span">
                  <LucButton
                    id="edit-logo-button"
                    type="secondary"
                    variant="flat"
                    size="small"
                    onClick={() => setOpenEditImageModal(true)}
                    minWidth={32}
                    disabled={
                      !handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")
                    }
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </LucButton>
                </Box>
              </CustomizedTooltip>
              <CustomizedTooltip
                title={CheckValueLocale("remove", "", {}, intl)}
                arrow
                placement="bottom"
                styles={customizedStyledTooltip}
              >
                <Box component="span">
                  <LucButton
                    id="remove-logo-button"
                    type="secondary"
                    variant="flat"
                    size="small"
                    onClick={() => setOpenPopupDelete(true)}
                    minWidth={32}
                    disabled={
                      !handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </LucButton>
                </Box>
              </CustomizedTooltip>
            </Box>
          </Box>
        ) : (
          <DropZoneArea
            formik={formik}
            setOpenEditImageModal={setOpenEditImageModal}
          />
        )}
      </Box>

      <PopupModal
        title={CheckValueLocale("remove_logo_title_popup", "", {}, intl)}
        body={CheckValueLocale("remove_logo_body_popup", "", {}, intl)}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("remove", "", {}, intl)}
        open={openPopupDelete || false}
        close={() => setOpenPopupDelete(null)}
        addClasses="remove-logo-popup actions-buttons-shared"
        accept={removeImage}
        warning
      />

      {openEditImageModal ? (
        <EditImageModal
          formik={formik}
          open={open}
          handleClose={() => setOpenEditImageModal(false)}
        />
      ) : null}
    </Box>
  );
};

export default LogoBranding;
