import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";

export const useDeleteAiAgent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => AiAgentsServices.deleteAiAgent(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["aiAgentList"],
      });
    },
  });
};
