import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { Box, Card, CardContent, TextField, Typography } from "@mui/material";
import styles from "../commonStyle.module.css";

const NumberQuestion = (props) => {
  const { question, questionNo, setErrorForm } = props;

  const intl = useIntl();
  const data = question;
  const [answer, setAnswer] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [maxMinCheck, setMaxMinCheck] = useState(0);

  const handleAnswers = (e) => {
    const value = e.target.value;
    const maxNumber = question?.question?.constraints?.max_number;
    const minNumber = question?.question?.constraints?.min_number;
    setAnswer(value);
    if (value !== 0 && !value) setErrorMsg("");
    else if (maxNumber?.checked && value > +maxNumber?.value) {
      setErrorMsg("max_number_field_survey_error");
      setMaxMinCheck(+maxNumber?.value);
    } else if (minNumber?.checked && value < +minNumber?.value) {
      setErrorMsg("min_number_field_survey_error");
      setMaxMinCheck(+minNumber?.value);
    } else setErrorMsg("");
  };

  useEffect(() => {
    setErrorForm((prev) => ({
      ...prev,
      [questionNo]: !!errorMsg,
    }));
  }, [errorMsg]);

  return (
    <Card className="cardStyle">
      <CardContent>
        <Typography className={styles.questionStyle}>
          <Box
            component="span"
            className="questionNoStyle"
          >{`${questionNo}.`}</Box>
          {data?.question?.text}
          {data?.question?.constraints?.required && (
            <Box component="span" className={styles.impQuestion}>
              *
            </Box>
          )}
        </Typography>
        <Box className={styles.subtitle}>{data?.question?.description}</Box>
        <Box>
          <TextField
            placeholder={CheckValueLocale(
              "type_your_answer_here",
              "",
              {},
              intl,
            )}
            variant="standard"
            type="number"
            className={styles.textFieldStyle}
            value={answer}
            onChange={handleAnswers}
            error={!!errorMsg}
            helperText={CheckValueLocale(
              errorMsg,
              "",
              { numCheck: maxMinCheck },
              intl,
            )}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default NumberQuestion;
