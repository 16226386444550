import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import Header from "shared/createHeader/createHeader";
import Steppers from "shared/steppers/steppers";
import "./addTeam.scss";
import { StepperFooter } from "../components/stepperFooter";
import { useHistory } from "react-router-dom";
import { TeamSharedFuntions } from "../sharedFunctions/teamSharedFunctions";
import CircularLoading from "components/circularProgress";
import SnackBar from "components/snackBar";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { transformWorkingHours } from "./components/addTeamUtils";
import { RenderCurrentStep } from "./components/renderCurrentStep";
import { useCreateTeam } from "../hooks/useCreateTeam";

const steps = ["teams_details", "teams_members" /*"accounts_and_monitors"*/];

export const AddTeam = () => {
  const intl = useIntl();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const {
    members,
    setMembers,
    selectedMembers,
    selectedMonitors,
    selectedChannels,
    handleSelectMember,
    handleSelectMonitor,
    handleSelectChannel,
    initilizeTeamDetails,
    isTeamMemberLoading,
    isTeamProductsLoading,
    products,
    teamError,
    setTeamError,
    setWorkingHours,
    workingHours,
    tabsErrors,
    setTabsErrors,
    setAutoReplyMessages,
    autoReplyMessages,
    formik,
  } = TeamSharedFuntions();
  const handleCloseSnackBar = () => {
    setTeamError({
      openSnackBar: false,
    });
  };

  const { mutate: handleCreateTeam, isPending: isPendingCreateTeam } =
    useCreateTeam();

  const createTeam = () => {
    const createTeamBody = {
      team_info: {
        name: formik?.values?.team_name,
        product_id: formik?.values?.selected_product,
        auto_reply_message: autoReplyMessages,
      },
      users: selectedMembers,
      working_hours: transformWorkingHours(workingHours.workingHours),
    };

    handleCreateTeam(
      { createTeamBody },
      {
        onSuccess: (res) => {
          const createdTeamId = res?.data?.id;
          history.push(
            `/settings/teams/view/${createdTeamId}?status=added_successfully`,
          );
        },
        onError: (error) => {
          // check if duplicate team name
          const isDuplicateTeamName =
            error?.errorMsg?.response?.data?.exception?.message ===
            "TEAM_NAME_ALREADY_EXIST";
          if (isDuplicateTeamName) {
            formik.setFieldError("team_name", "team_name_already_exist");
            setActiveStep(0);
            // go to the top of the page
            window.scrollTo(0, 0);
          } else {
            setTeamError({
              message: "try_again_error_message",
              severity: "error",
              title: "failed_error_message",
              openSnackBar: true,
            });
          }
        },
      },
    );
  };
  const handleChangeStep = (step) => {
    if (step < activeStep) {
      setActiveStep(step);
    } else if (step === steps?.length) {
      createTeam();
    } else if (completed[activeStep]) {
      // check if the current step is completed, this happen when click on next step from the stepper
      setActiveStep(step);
    }
  };
  const handleCancel = () => {
    // go to teams list
    history.push("/settings/teams");
  };

  useEffect(() => {
    if (activeStep === 0) {
      // check all required fields to be able to go the next step
      if (
        formik?.isValid &&
        autoReplyMessages?.length <= 255 &&
        !tabsErrors?.topicsPreError &&
        !tabsErrors?.workgingHoursPreError
      ) {
        setCompleted((prev) => ({ ...prev, 0: true }));
      } else {
        setCompleted({});
      }
    } else if (activeStep === 1) {
      if (selectedMembers?.length > 0) {
        setCompleted((prev) => ({ ...prev, 1: true }));
      } else {
        setCompleted({});
      }
    }
  }, [
    formik?.isValid,
    selectedMembers,
    autoReplyMessages,
    tabsErrors,
    activeStep,
  ]);

  useEffect(() => {
    initilizeTeamDetails();
  }, []);
  useEffect(() => {
    // we want to set the default product to the first engagment product
    if (!products?.length) return;
    const engagment = products.find(
      (product) => product?.name === "ENGAGEMENTS",
    );
    formik?.setFieldValue("selected_product", engagment?.id);
  }, [products]);
  if (isTeamMemberLoading || isTeamProductsLoading) return <CircularLoading />;

  return (
    <Container maxWidth="xl" className="add-team-container">
      <Header
        mainHeaderTitle="settings"
        subHeaderTitle="teams_list"
        subHeaderLink="/settings/teams"
        mainHeaderLink="/settings"
        cardHeaderHeading="create_new_team"
        isCreateScreen
      />
      <Box className="main-container">
        {steps?.length > 1 ? (
          <Steppers
            steps={steps}
            activeStep={activeStep}
            setActiveStep={handleChangeStep}
            completed={completed}
            setCompleted={setCompleted}
          />
        ) : null}
        <Box className="step-content">
          <Container>
            <RenderCurrentStep
              step={activeStep}
              formik={formik}
              isProductsDisabled={true}
              products={products || []}
              setWorkingHours={setWorkingHours}
              workingHours={workingHours}
              tabsErrors={tabsErrors}
              setTabsErrors={setTabsErrors}
              autoReplyMessages={autoReplyMessages}
              setAutoReplyMessages={setAutoReplyMessages}
              isMaxCharactersError={autoReplyMessages?.length > 255}
              members={members}
              handleSelectMember={handleSelectMember}
              selectedMembers={selectedMembers}
              setMembers={setMembers}
              selectedMonitors={selectedMonitors}
              handleSelectMonitor={handleSelectMonitor}
              handleSelectChannel={handleSelectChannel}
              selectedChannels={selectedChannels}
            />
          </Container>
        </Box>
        <StepperFooter
          activeStep={activeStep}
          disableNextStep={!completed[activeStep]}
          handleCancel={handleCancel}
          handleChangeStep={handleChangeStep}
          btnText={activeStep < steps.length - 1 ? "next_Step" : "create"}
          isLoading={isPendingCreateTeam}
        />
      </Box>
      <SnackBar
        open={teamError?.openSnackBar}
        severity={teamError?.severity}
        message={CheckValueLocale(teamError?.message, "", {}, intl)}
        title={CheckValueLocale(teamError?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </Container>
  );
};
