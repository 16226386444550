import { useQuery } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";

export const useGetAiAgentSwaggerFile = (data, enabled) => {
  return useQuery({
    queryKey: ["aiAgentSwaggerFile", data?.id],
    queryFn: () => AiAgentsServices.downloadResource(data?.id, data?.queryData),
    staleTime: 0,
    enabled,
  });
};
