import { Box, Grid } from "@mui/material";
import ProductAddonItem from "./components/productAddonItem";

const AddonsTab = (props) => {
  const { data, activeProducts, setSnackbar, setExpanded } = props;

  const productsData = [
    {
      title: "subscriptions_ai_agent_title",
      sub_title: "subscriptions_ai_agent_sub_title",
      productKey: "ai-agent",
      active: !!data?.ai_agent_package,
      demoKey: "request_ai_agent_demo",
      upgradeKey: "request_ai_agent_upgrade",
      isProduct: true,
    },
    {
      title: "subscriptions_lucidya_api_title",
      sub_title: "subscriptions_lucidya_api_sub_title",
      productKey: "public-api",
      active: activeProducts?.includes("public_api"),
      demoKey: "lucidya_api",
      upgradeKey: "request_public_api_upgrade",
      isProduct: true,
    },
    {
      title: "subscriptions_luci_title",
      sub_title: "subscriptions_luci_sub_title",
      productKey: "luci",
      active: activeProducts?.includes("luci"),
      demoKey: "request_luci_demo",
      upgradeKey: "request_luci_upgrade",
      isProduct: true,
    },
    {
      title: "subscriptions_nb_monitoring_title",
      sub_title: "subscriptions_nb_monitoring_sub_title",
      productKey: "nbMonitoring",
      demoKey: "news_&_blogs_monitoring",
      active: false,
    },
    {
      title: "subscriptions_branded_live_dashboard_title",
      sub_title: "subscriptions_branded_live_dashboard_sub_title",
      productKey: "brandedLiveDashboard",
      demoKey: "branded_live_dashboard",
      active: false,
    },
    {
      title: "subscriptions_professional_reports_title",
      sub_title: "subscriptions_professional_reports_sub_title",
      productKey: "professionalReports",
      demoKey: "professional_reports",
      active: false,
    },
    {
      title: "subscriptions_tv_radio_monitoring_title",
      sub_title: "subscriptions_tv_radio_monitoring_sub_title",
      productKey: "tvRadioMonitoring",
      demoKey: "tv_&_radio_monitoring",
      active: false,
    },
    {
      title: "subscriptions_social_media_management_title",
      sub_title: "subscriptions_social_media_management_sub_title",
      productKey: "socialMediaManagement",
      demoKey: "social_media_management",
      active: false,
    },
    {
      title: "subscriptions_social_media_migration_title",
      sub_title: "subscriptions_social_media_migration_sub_title",
      productKey: "socialMediaMigration",
      demoKey: "social_media_migration",
      active: false,
    },
  ];

  return (
    <Box id="products-tab-content">
      <Grid spacing={2} container>
        {[...(productsData || [])]?.map((item, index) => {
          return (
            <ProductAddonItem
              key={index}
              item={item}
              setSnackbar={setSnackbar}
              setExpanded={setExpanded}
              isAddonsTab
            />
          );
        })}
      </Grid>
    </Box>
  );
};

export default AddonsTab;
