import { IconButton, Modal, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import AIAgentTestModalLoading from "./aiAgentTestModalLoading";
import AIAgentChat from "./aiAgentChat";
import AIAgentTextInput from "./aiAgentTextInput";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { addChatMessage } from "utils/redux/features/AiAgentChat/AiAgentChatSlice";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";

const RunTestModal = ({
  open,
  handleClose,
  aiAgentDetails,
  socket,
  isAiAgentThinking,
  setIsAiAgentThinking,
}) => {
  const intl = useIntl();
  const { aiAgentSelected, selectedAiAgentPublishedVersion } =
    useContext(AiAgentsContext);
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");

  const handleReply = () => {
    setIsAiAgentThinking(true);
    const request = {
      company_id: localStorage.getItem("company_id"),
      ai_agent_id: aiAgentSelected,
      message,
      user_id: localStorage.getItem("user_id"),
    };
    setMessage("");
    dispatch(
      addChatMessage({
        text: message,
        isSent: true,
        agentId: aiAgentSelected,
        versionId: selectedAiAgentPublishedVersion,
      }),
    );
    socket?.emit("ai_agent_request", request);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="ai-agent-chat-container">
        <div className="ai-agent-chat-header">
          <div className="ai-agent-chat-header-stack">
            <Typography className="ai-agent-chat-header-title">
              {CheckValueLocale("ai-agent-modal-title", "", {}, intl)}
            </Typography>
            <Tooltip
              title={CheckValueLocale(
                "ai-agent-modal-tooltip-label",
                "",
                {},
                intl,
              )}
            >
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="ai-agent-chat-header-info-icon"
              />
            </Tooltip>
          </div>
          <IconButton onClick={handleClose}>
            <Tooltip
              title={CheckValueLocale("ai-agent-modal-close", "", {}, intl)}
            >
              <FontAwesomeIcon
                icon={faXmark}
                className="ai-agent-drawer-exit"
              />
            </Tooltip>
          </IconButton>
        </div>
        <div className="ai-agent-chat-relative-box">
          <AIAgentChat
            aiAgentDetails={aiAgentDetails}
            isAiAgentThinking={isAiAgentThinking}
          />
          <AIAgentTextInput
            message={message}
            setMessage={setMessage}
            handleReply={handleReply}
            isAiAgentThinking={isAiAgentThinking}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RunTestModal;
