import { useState } from "react";
import { useIntl } from "react-intl";
import { Menu, MenuItem, Box, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRepeatAlt, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import { useRetryHistoricalRequest } from "../../hooks/useRetryHistoricalRequest";
import { useStopHistoricalRequest } from "../../hooks/useStopHistoricalRequest";
import CustomizedTooltip from "components/customizedTooltip";
import SnackBar from "components/snackBar.js";
import PopupModal from "components/popupModal.js";

const OptionsDropdown = (props) => {
  const {
    requestId,
    requestData,
    processingIndicator,
    cancellableDatasources,
  } = props;

  const intl = useIntl();

  const [menuStats, setMenuStats] = useState({ anchorEl: null, open: false });
  const menuOpen = Boolean(menuStats?.anchorEl);

  const [snackBar, setSnackBar] = useState({});
  const [stopModalOpened, setStopModalOpened] = useState(false);

  const { mutate: retryHistoricalRequest, isPending: retryLoading } =
    useRetryHistoricalRequest();

  const handleRetryRequest = () => {
    retryHistoricalRequest(
      { requestId },
      {
        onSuccess: () =>
          setSnackBar({
            open: true,
            severity: "sucess",
            title: "retry_historical_request_success_title",
            message: "",
          }),
        onError: () =>
          setSnackBar({
            open: true,
            severity: "error",
            title: "retry_historical_request_failed_title",
            message: "try_again",
          }),
        onSettled: () => setMenuStats({ anchorEl: null, open: false }),
      },
    );
  };

  const { mutate: stopHistoricalRequest, isPending: stopLoading } =
    useStopHistoricalRequest();

  const handleStopHistoricalRequest = () => {
    stopHistoricalRequest(
      { requestId },
      {
        onSuccess: () =>
          setSnackBar({
            open: true,
            severity: "success",
            title: "request_stoped_successfully",
            message: "",
          }),
        onError: () =>
          setSnackBar({
            open: true,
            severity: "error",
            title: "something_went_wrong",
            message: "please_try_again_later",
          }),
        onSettled: () => {
          setMenuStats({ anchorEl: null, open: false });
          setStopModalOpened(false);
        },
      },
    );
  };

  return (
    <Box className="menu-list">
      <CustomizedTooltip
        id="menu-item-list-tooltip"
        title={
          <Box className="item-title-tooltip">
            {CheckValueLocale("items_ellipsis", "", {}, intl)}
          </Box>
        }
        arrow
        placement="bottom"
      >
        <Button
          variant="text"
          className={
            "menu-items menu-item-container " +
            (menuOpen ? "menu-item-active" : "")
          }
          aria-label="more"
          id="social-media-dropdown-long-button"
          aria-controls="long-menu"
          aria-expanded={menuOpen ? "true" : undefined}
          aria-haspopup="true"
          onClick={(e) =>
            setMenuStats({ anchorEl: e.currentTarget, open: true })
          }
          onClose={() => setMenuStats({ anchorEl: null, open: false })}
        >
          <FontAwesomeIcon className="ellipsis-icon" icon={faEllipsisH} />
        </Button>
      </CustomizedTooltip>
      <Menu
        id="demo-customized-menu"
        className="manage-menu historical-droplist"
        open={menuOpen}
        anchorEl={menuStats?.anchorEl}
        onClose={() => setMenuStats({ anchorEl: null, open: false })}
        MenuListProps={{ "aria-labelledby": "demo-customized-button" }}
        anchorOrigin={
          intl?.locale !== "ar" && { vertical: "bottom", horizontal: "right" }
        }
        transformOrigin={
          intl?.locale !== "ar" && { vertical: "top", horizontal: "right" }
        }
      >
        <MenuItem
          id="historical-request-dropdown-retry"
          className="drop-down-btn-historical-request"
          disabled={
            retryLoading ||
            requestData?.status !== "failed" ||
            (processingIndicator !== undefined ? processingIndicator : true)
          }
          onClick={handleRetryRequest}
          disableRipple
        >
          <FontAwesomeIcon icon={faRepeatAlt} />
          {CheckValueLocale("retry", "", {}, intl)}
        </MenuItem>

        {/* To check if the request is cancellable from datasources returned from BE   */}
        {cancellableDatasources
          ?.map((datasource) => datasource?.id)
          ?.includes(requestData?.data_source?.id) ? (
          <MenuItem
            id="historical-request-dropdown-stop"
            className="drop-down-btn-historical-request"
            disabled={requestData?.status !== "processing" || stopLoading}
            onClick={() => setStopModalOpened(true)}
          >
            <FontAwesomeIcon icon={faTimes} />
            {CheckValueLocale("stop_request", "", {}, intl)}
          </MenuItem>
        ) : null}
      </Menu>

      {stopModalOpened && (
        <PopupModal
          open={stopModalOpened}
          title={CheckValueLocale("stop_request", "", {}, intl)}
          body={
            <Box>
              <Box component={"span"} className="question-stop-request">
                {CheckValueLocale("َquestion_stop_request", "", {}, intl)}
              </Box>
              <Box className="estimated-collected">
                <Box className="estimated-collected-text">
                  {CheckValueLocale("estimated_Collected", "", {}, intl)}
                </Box>
                <Box className="estimated-collected-number">
                  {props?.reqPosts || 0}
                </Box>
              </Box>
            </Box>
          }
          leftBtn={CheckValueLocale("cancel", "", {}, intl)}
          close={() => setStopModalOpened(false)}
          rightBtn={CheckValueLocale("stop_request", "", {}, intl)}
          isRightBtnLoading={stopLoading}
          accept={handleStopHistoricalRequest}
          addClasses="actions-buttons-shared"
          warning
        />
      )}

      <SnackBar
        open={snackBar?.open}
        severity={snackBar?.severity}
        title={CheckValueLocale(snackBar?.title, "", {}, intl)}
        message={CheckValueLocale(snackBar?.message, "", {}, intl)}
      />
    </Box>
  );
};
export default OptionsDropdown;
