import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useProductOrAddonImage } from "pages/settings/hooks/useProductOrAddonImage";
import { useRequestDemoOrUpgrade } from "pages/settings/pages/subscriptionsPage/hooks/useRequestDemoOrUpgrade";
import { useIntl } from "react-intl";
import {
  CheckValueLocale,
  commasAfterDigit,
  handlelUserRoles,
} from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";

const OnePackagesQuota = (props) => {
  const intl = useIntl();

  const { item, setSnackbar } = props;

  const [requested, setRequested] = useState(false);

  const { mutate: requestUpgrade, isPending: requestLoading } =
    useRequestDemoOrUpgrade();

  const handleRquestUpgrade = (product) => {
    requestUpgrade(
      {
        queryData: {
          service_name:
            product === "SM"
              ? "request_social_listening_upgrade"
              : "request_channel_analytics_upgrade",
        },
      },
      {
        onSuccess: () => {
          setRequested(true);
          setSnackbar({
            open: true,
            severity: "success",
            title: "",
            message: "subscriptions_request_upgrade_succes_message",
          });
        },
        onError: () =>
          setSnackbar({
            open: true,
            severity: "error",
            title: "subscriptions_request_upgrade_failed_title",
            message: "subscriptions_request_upgrade_failed_message",
          }),
      },
    );
  };

  return (
    <Grid item xs={6} key={item?.id} id="packages-quota-item-wrapper">
      <Box className="packages-quota-item">
        <Box className="packages-quota-item-header-wrapper">
          <Box className="packages-quota-item-header-icon-title">
            <Box className="packages-quota-item-header-icon">
              <img
                src={useProductOrAddonImage(item?.name) || ""}
                alt={item?.name?.toLowerCase()}
              />
            </Box>
            <Typography>
              {CheckValueLocale(
                `settings_historical_request_${item?.name?.toLowerCase()}_title`,
                "",
                {},
                intl,
              )}
            </Typography>
          </Box>
          <Box component={"span"}>
            <LucButton
              id="historical-request-package-request-upgrade-btn"
              className={requested ? "requested" : ""}
              variant="flat"
              disabled={
                requested || !handlelUserRoles("GENERAL", "CREATE_REQUEST")
              }
              loading={requestLoading}
              onClick={() => handleRquestUpgrade(item?.name)}
            >
              {CheckValueLocale(
                requested
                  ? "subscriptions_requested_upgrade"
                  : "subscriptions_request_upgrade",
                "",
                {},
                intl,
              )}
            </LucButton>
          </Box>
        </Box>
        <Typography className="subscription-type">
          {CheckValueLocale(
            `historical_request_${item?.quota?.[Object.keys(item?.quota)?.[0]]?.type?.toLowerCase()}_usage`,
            "",
            {},
            intl,
          )}
        </Typography>
        <Box className="one-package-quota-wrapper">
          {Object?.keys(item?.quota)?.map((key) => (
            <Box className="one-package-quota-item" key={key}>
              <Box component="span" className="one-package-quota-item-title">
                {CheckValueLocale(`historical_request_${key}`, "", {}, intl)}
              </Box>
              <Box component="span" className="one-package-quota-item-usage">
                <Box component="span">
                  {commasAfterDigit(item?.quota?.[key]?.used || 0)}
                </Box>
                <Box component="span">
                  {CheckValueLocale("historical_request_used_of", "", {}, intl)}
                </Box>
                <Box component="span">
                  {commasAfterDigit(item?.quota?.[key]?.limit || 0)}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Grid>
  );
};

export default OnePackagesQuota;
