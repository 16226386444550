import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faEnvelope,
  faPhone,
} from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import Services from "services/api/Services";

const CustomerSuccessManager = (props) => {
  const { companyInfo, csmInfo } = props;
  const intl = useIntl();

  return (
    <Box>
      <Typography variant="h6" className="settings-section-title">
        {CheckValueLocale(
          "company_customer_success_manager_title",
          "",
          {},
          intl,
        )}
      </Typography>
      <Box className="company-page-section-box customer-success-manager">
        <Box className="manager-details">
          <Typography className="manager-name">
            {companyInfo?.customer_success_manager?.name}
          </Typography>
          <Box className="manager-email-container">
            <Typography className="manager-email">
              <FontAwesomeIcon icon={faEnvelope} />
              {companyInfo?.customer_success_manager?.email}
            </Typography>
            <Typography className="manager-phone">
              <FontAwesomeIcon icon={faPhone} />
              {companyInfo?.customer_success_manager?.phone_number}
            </Typography>
          </Box>
        </Box>
        {csmInfo?.session_url?.length && Services.showThirdCompany ? (
          <LucButton
            id="customer-success-manager-schedule-btn"
            variant="outline"
            type="secondary"
            size="small"
            startIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
            onClick={() => window?.open(csmInfo?.session_url, "_blank")}
          >
            {CheckValueLocale(
              "company_customer_success_manager_schedule",
              "",
              {},
              intl,
            )}
          </LucButton>
        ) : null}
      </Box>
    </Box>
  );
};

export default CustomerSuccessManager;
