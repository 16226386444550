import { hourNumberToString } from "pages/settings/pages/userManagementPage/subPages/shardComponents/tabs/workingHoursForDay";

export const typeFormatMonitor = (type) => {
  const monitor_type = {
    KEYWORD: "keyword",
    ACCOUNT_ANALYSIS: "ACCOUNT_ANALYSIS",
    MANAGED_PAGE: "MANAGED_ACCOUNT",
    MANAGED_ACCOUNT: "MANAGED_ACCOUNT",
  };
  return monitor_type[type];
};

export const generateUserInfo = (stepOneData) => {
  return {
    first_name: stepOneData?.first_name,
    last_name: stepOneData?.last_name,
    phone_number: stepOneData?.phone,
    email: stepOneData?.mail,
    job_title: stepOneData?.job_title,
  };
};

export const generateUserRoles = (stepTwoData) => {
  let productNames = {
    general_setting: "GENERAL",
    channel_analytics: "CXM",
    social_listening: "SM",
    surveys: "SURVEY",
    audience: "CDP",
    engagement: "ENGAGEMENTS",
    lucidya_api: "PUBLIC_API",
    ai_agent: "AI_AGENT",
  };
  let roleMapper = {
    manager: "product_admin",
    viwer: "viewer",
    moderator: "user",
    team_leader: "team_leader",
    agent_egagment: "agent",
    no_acc: "no_access",
  };
  let rolesArr = Object.keys(stepTwoData)?.map((item) => {
    return { [productNames[item]]: roleMapper[stepTwoData[item]] };
  });
  return rolesArr;
};

export const generateUserEngagementsPermissions = ({
  accTableSelectedData,
  infoTabsData,
  advancedTabsData,
  teamsData,
  accEngagId,
}) => {
  let engagement_featuresArr = [];

  Object.keys(accTableSelectedData?.engagement_features)?.map((item) => {
    if (accTableSelectedData?.engagement_features[item]?.length) {
      engagement_featuresArr.push({
        id: accEngagId[item],
        permissions: accTableSelectedData?.engagement_features[item],
      });
    }
  });

  let topics = [];
  infoTabsData?.topics?.forEach((topic) => {
    const newTopics = topic?.topics?.map((topic) => topic?.id);
    const monitor_id = topic?.id;
    const spreadedTopics = newTopics?.map((topicId) => {
      return {
        topic_id: topicId,
        monitor_id,
      };
    });
    topics.push(...spreadedTopics);
  });
  return {
    engagement_feature: [...engagement_featuresArr],
    teams: teamsData?.teamsSelected?.map((team) => team?.id),
    accounts_access: Object.keys(accTableSelectedData?.account_access)?.map(
      (item) => {
        return {
          account_id: +item.split("-")?.[1],
          data_source: item.split("-")?.[0],
          permissions: accTableSelectedData?.account_access[item],
        };
      },
    ),
    monitors_access: Object.keys(accTableSelectedData?.monitors_access)?.map(
      (item) => +item,
    ),
    channels_access: Object.keys(accTableSelectedData?.channels_access)?.map(
      (item) => +item,
    ),
    advanced_info: {
      languages: advancedTabsData?.languages
        ?.map((language) => ({
          language_id: language?.value?.id,
          language_proficiency: language?.subValue,
        }))
        ?.filter((language) => !!language?.language_id),
      skills: advancedTabsData?.skills
        ?.map((skill) => ({
          skill_id: skill?.value?.id,
          skill_level: skill?.subValue,
        }))
        ?.filter((skill) => !!skill?.skill_id),
      sentiment: infoTabsData?.sentiments,
      themes: infoTabsData?.themes,
      segments: infoTabsData?.segments,
      topics: topics,
      dialects: infoTabsData?.dialects,
      working_hours: Object.entries(advancedTabsData?.workingHours).reduce(
        (acc, [day, dayRanges]) => {
          const validRanges = [];
          dayRanges.forEach((range) => {
            if (range?.from && range?.to) {
              validRanges.push({
                from: hourNumberToString(range?.from),
                to: hourNumberToString(range?.to),
              });
            }
          });
          if (validRanges?.length) {
            acc[day?.toLowerCase()] = validRanges;
          }
          return acc;
        },
        {},
      ),
    },
  };
};

export const generateUserPayload = ({
  stepTwoData,
  stepOneData,
  isEngagement,
  accTableSelectedData,
  infoTabsData,
  advancedTabsData,
  teamsData,
  accEngagId,
}) => {
  return {
    user_info: generateUserInfo(stepOneData),
    roles: generateUserRoles(stepTwoData),
    engagements_permissions: isEngagement
      ? generateUserEngagementsPermissions({
          accTableSelectedData,
          infoTabsData,
          advancedTabsData,
          teamsData,
          accEngagId,
        })
      : {},
  };
};
