import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";

export const useForceRefreshResource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => AiAgentsServices.forceRefreshResource(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["aiAgentResources"],
      });
    },
  });
};
