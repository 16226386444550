import { Box, Grid } from "@mui/material";
import OnePackagesQuota from "./onePackagesQuota";

const PackagesQuota = (props) => {
  const { data, setSnackbar } = props;

  return (
    <Box id="packages-quota-wrapper">
      <Grid container spacing={2}>
        {[...(data || [])]?.map((item) => (
          <OnePackagesQuota
            key={item?.id}
            item={item}
            setSnackbar={setSnackbar}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default PackagesQuota;
