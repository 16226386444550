import { useQuery } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useCompanySubscriptions = () => {
  return useQuery({
    queryKey: ["companySubscriptions"],
    queryFn: () => SettingsController?.getCompanySubscriptions(),
    select: (data) => data?.data,
    retry: false,
  });
};
