import { Box } from "@mui/material";
import "./aiAgents.scss";
import AiAgentsHeader from "./component/aiAgentsHeader";
import AiAgentsSideBar from "./component/aiAgentsSideBar/aiAgentsSideBar";
import AiAgentsContent from "./component/aiAgentsContent/aiAgentsContent";
import { useAiAgentList } from "./aiAgentsHooks/useAiAgentList";
import { useEffect, useState } from "react";
import { AiAgentsContext } from "./aiAgentsContext";
import AiAgentsSnackBar from "./component/aiAgentsSnackBar";
import AiAgentHeaderAlerts from "./component/aiAgentHeaderAlerts/aiAgentHeaderAlerts";

const AiAgents = () => {
  const [aiAgentSelected, setAiAgentSelected] = useState(null);
  const [aiAgentSnackBar, setAiAgentSnackBar] = useState({
    success: null,
    title: "",
    message: "",
    severity: null,
  });
  const [selectedAiAgentPublishedVersion, setSelectedAiAgentPublishedVersion] =
    useState(null);
  const [aiAgentCurrentVersion, setAiAgentCurrentVersion] = useState(null);
  const [isAiAgentDown, setIsAiAgentDown] = useState(false);
  const [isDMLimitReached, setIsDMLimitReached] = useState(false);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [aiAgentDMReachDetails, setAiAgentDMReachDetails] = useState(null);
  const [aiAgentPublishError, setAiAgentPublishError] = useState({
    message: "",
    files: "",
  });
  const [scrollType, setScrollType] = useState(null);
  const [resetForm, setResetForm] = useState(0);
  const [publishedVersionResources, setPublishedVersionResources] = useState(
    [],
  );
  const { data: aiAgentList, isLoading: aiAgentListLoading } = useAiAgentList();

  const handleCloseAiAgentDownAlert = () => setIsAiAgentDown(false);

  const handleCloseDMLimitReached = () => setIsDMLimitReached(false);

  const handleCloseLimitReached = () => setIsLimitReached(false);

  const handleClosePublishError = () =>
    setAiAgentPublishError({
      message: "",
      files: "",
    });
  const alertTypes = [
    {
      alertType: "aiAgentDown",
      handleCloseHeaderAlert: handleCloseAiAgentDownAlert,
      active: isAiAgentDown,
    },
    {
      alertType: "dMLimitReached",
      handleCloseHeaderAlert: handleCloseDMLimitReached,
      active: isDMLimitReached,
      aiAgentDMReachDetails: aiAgentDMReachDetails,
    },
    {
      alertType: "limitReached",
      handleCloseHeaderAlert: handleCloseLimitReached,
      active: isLimitReached,
    },
    {
      alertType: "publishError",
      handleCloseHeaderAlert: handleClosePublishError,
      active: !!aiAgentPublishError?.message,
    },
  ];

  const scrollSideBarHandler = () => {
    const div = document.getElementById("ai-agents-side-bar-container");
    div.scrollTo({
      top: div.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (!aiAgentListLoading && aiAgentList?.length) {
      if (scrollType === "create" || scrollType === "dublicate") {
        scrollSideBarHandler();
        setScrollType(null);
      }
      aiAgentList?.map((aiAgent) => {
        if (aiAgent?.status === "down") setIsAiAgentDown(true);
        if (aiAgent?.quota_limit) setIsLimitReached(true);
        if (aiAgent?.x_rate_limit) {
          setIsDMLimitReached(true);
          setAiAgentDMReachDetails({
            x_reset_time: aiAgent?.x_reset_time,
          });
        }
      });
    }
  }, [aiAgentListLoading, aiAgentList]);

  const alertTypesLenght = alertTypes?.filter((alert) => alert?.active)?.length;

  return (
    <AiAgentsContext.Provider
      value={{
        aiAgentSnackBar,
        setAiAgentSnackBar,
        aiAgentSelected,
        setAiAgentSelected,
        selectedAiAgentPublishedVersion,
        setSelectedAiAgentPublishedVersion,
        aiAgentCurrentVersion,
        setAiAgentCurrentVersion,
        isAiAgentDown,
        isDMLimitReached,
        isLimitReached,
        setScrollType,
        resetForm,
        setResetForm,
        publishedVersionResources,
        setPublishedVersionResources,
        setAiAgentPublishError,
        aiAgentPublishError,
      }}
    >
      <AiAgentsHeader />
      {alertTypes?.map(({ active, ...rest }) =>
        active ? <AiAgentHeaderAlerts {...rest} /> : null,
      )}
      <Box
        className={`main-ai-agents-wrapper main-ai-agents-wrapper-reduced-height-${alertTypesLenght}`}
      >
        <AiAgentsSideBar
          aiAgentList={aiAgentList}
          aiAgentListLoading={aiAgentListLoading}
        />
        <AiAgentsContent />
      </Box>
      <AiAgentsSnackBar />
    </AiAgentsContext.Provider>
  );
};

export default AiAgents;
