import {
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./inputLabelFormik.scss";

const InputLabelFormik = ({ labelId, placeHolderId, errorMsg, ...props }) => {
  const intl = useIntl();
  return (
    <>
      <InputLabel className="input-user-settings-label">
        {CheckValueLocale(labelId, "", {}, intl)}
        {props?.required ? (
          <Typography component="span" className="required">
            *
          </Typography>
        ) : null}
      </InputLabel>
      <OutlinedInput
        className="input-user-settings"
        id={labelId}
        placeholder={CheckValueLocale(placeHolderId, "", {}, intl)}
        error={!!errorMsg}
        fullWidth
        {...props}
      />
      {!!errorMsg ? (
        <FormHelperText className="input-user-settings-form-error">
          {CheckValueLocale(errorMsg, "", {}, intl)}
        </FormHelperText>
      ) : null}
    </>
  );
};

export default InputLabelFormik;
