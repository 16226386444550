import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";

export const useDeleteResource = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => AiAgentsServices.deleteResource(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["aiAgentResources"] });
      queryClient.invalidateQueries({ queryKey: ["aiAgentDetails"] });
    },
  });
};
