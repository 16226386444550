import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import SnackBar from "components/snackBar";
import CircularLoading from "components/circularProgress";
import PublishSurveyModal from "./Header/publishSurveyModal";
import Header from "./Header/header";
import ConstraintsSection from "./ConstraintsSection/constraintsSection";
import PreviewPage from "./PreviewSurvey/pages/index";
import ControlSection from "./ControlSection/controlSection";
import EditSection from "./EditSection/editSection";
import SurveyFunctions from "./surveyFunctions";
import { useHistory } from "react-router-dom";

const SurveyBuilder = () => {
  const {
    id,
    isLoading,
    preview,
    setPreview,
    logoData,
    surveyData,
    setSurveyData,
    showPoweredBy,
    saveChangesFlag,
    handlePublishBtn,
    surveyLimitReach,
    handleSaveChanges,
    handleDeleteQuestion,
    setLogoData,
    setSQIndex,
    sqIndex,
    ssIndex,
    setSSIndex,
    setOperationPerformed,
    multiRes,
    handleMultiRes,
    setSaveChangesFlag,
    handleCardClick,
    selectedCountryCode,
    setSelectedCountryCode,
    operationPerformed,
    setOpenPublishSurveyModal,
    setIsLucidyaDomain,
    customDomain,
    customDomainStepLoading,
    setCustomDomainStep,
    customDomainStep,
    isSurveyEnabled,
    isSkeipped,
    setIsSkeipped,
    openPublishSurveyModal,
    surveySections,
    setSurveySections,
    filteredSections,
    handleNewSection,
    handleDuplicateSection,
    handleDeleteSection,
    emptySection,
    setEmptySection,
    handleSectionTitle,
    handleQuestionTitle,
    handleQuestionDesc,
    scrollToIdFunc,
    publishFlag,
    handleChangeProceed,
    proceedError,
    afterSecError,
    secTitleErr,
    editRef,
    snackBar,
    setSnackBar,
    saveLoading,
    handleSectionDir,
    controlTab,
    handleChangeControlTab,
    customDomainStatus,
    setDiscriptionError,
    discriptionError,
  } = SurveyFunctions();
  const history = useHistory();

  useEffect(() => {
    if (surveyData?.published == false) {
      history.push({
        pathname: `/survey/add-survey/survey-builder/${id}`,
      });
    } else if (surveyData?.published == true) {
      history.push({
        pathname: `/survey/edit-survey/survey-builder/${id}`,
      });
    }
  }, [surveyData?.published]);

  return (
    <>
      {isLoading ? (
        <CircularLoading />
      ) : (
        <>
          {preview ? (
            <PreviewPage
              surveyData={surveyData}
              filteredSections={filteredSections}
              setPreview={setPreview}
              logoData={logoData}
              showPoweredBy={showPoweredBy}
            />
          ) : (
            <>
              <Header
                surveyData={surveyData}
                setSurveyData={setSurveyData}
                saveChangesFlag={saveChangesFlag}
                publishFlag={publishFlag}
                handleSaveChanges={handleSaveChanges}
                handlePublishBtn={handlePublishBtn}
                id={id}
                preview={preview}
                setPreview={setPreview}
                surveyLimitReach={surveyLimitReach}
                saveLoading={saveLoading}
              />
              <Box className="survey-builder-container">
                <ControlSection
                  surveySections={surveySections}
                  setSurveySections={setSurveySections}
                  filteredSections={filteredSections}
                  handleNewSection={handleNewSection}
                  handleDuplicateSection={handleDuplicateSection}
                  handleDeleteSection={handleDeleteSection}
                  emptySection={emptySection}
                  proceedError={proceedError}
                  afterSecError={afterSecError}
                  secTitleErr={secTitleErr}
                  setEmptySection={setEmptySection}
                  handleDeleteQuestion={handleDeleteQuestion}
                  setSurveyData={setSurveyData}
                  setLogoData={setLogoData}
                  logoData={logoData}
                  handleSaveChanges={handleSaveChanges}
                  setSQIndex={setSQIndex}
                  sqIndex={sqIndex}
                  setSSIndex={setSSIndex}
                  ssIndex={ssIndex}
                  surveyData={surveyData}
                  setOperationPerformed={setOperationPerformed}
                  multiRes={multiRes}
                  handleMultiRes={handleMultiRes}
                  setSaveChangesFlag={setSaveChangesFlag}
                  scrollToIdFunc={scrollToIdFunc}
                  handleSectionDir={handleSectionDir}
                  controlTab={controlTab}
                  handleChangeControlTab={handleChangeControlTab}
                />
                <EditSection
                  surveyData={surveyData}
                  setSurveyData={setSurveyData}
                  surveySections={surveySections}
                  setSurveySections={setSurveySections}
                  filteredSections={filteredSections}
                  handleSectionTitle={handleSectionTitle}
                  handleQuestionTitle={handleQuestionTitle}
                  handleQuestionDesc={handleQuestionDesc}
                  setSQIndex={setSQIndex}
                  sqIndex={sqIndex}
                  setSSIndex={setSSIndex}
                  ssIndex={ssIndex}
                  setSaveChangesFlag={setSaveChangesFlag}
                  emptySection={emptySection}
                  proceedError={proceedError}
                  afterSecError={afterSecError}
                  secTitleErr={secTitleErr}
                  handleCardClick={handleCardClick}
                  editRef={editRef}
                  handleChangeProceed={handleChangeProceed}
                  multiRes={multiRes}
                  handleMultiRes={handleMultiRes}
                  handleSectionDir={handleSectionDir}
                  setDiscriptionError={setDiscriptionError}
                  discriptionError={discriptionError}
                />
                <ConstraintsSection
                  filteredSections={filteredSections}
                  surveySections={surveySections}
                  setSurveySections={setSurveySections}
                  sqIndex={sqIndex}
                  setSQIndex={setSQIndex}
                  ssIndex={ssIndex}
                  setSSIndex={setSSIndex}
                  selectedCountryCode={selectedCountryCode}
                  setSelectedCountryCode={setSelectedCountryCode}
                />

                {openPublishSurveyModal && (
                  <PublishSurveyModal
                    openPublishSurveyModal={openPublishSurveyModal}
                    setOpenPublishSurveyModal={setOpenPublishSurveyModal}
                    surveyData={surveyData}
                    setIsLucidyaDomain={setIsLucidyaDomain}
                    customDomain={customDomain}
                    handleSaveChanges={handleSaveChanges}
                    customDomainStepLoading={customDomainStepLoading}
                    setCustomDomainStep={setCustomDomainStep}
                    customDomainStep={customDomainStep}
                    isSurveyEnabled={isSurveyEnabled}
                    isSkeipped={isSkeipped}
                    setIsSkeipped={setIsSkeipped}
                    customDomainStatus={customDomainStatus}
                  />
                )}
              </Box>
            </>
          )}
        </>
      )}
      <SnackBar
        autoHideDuration={5000}
        message={snackBar?.message}
        title={snackBar?.title}
        open={snackBar?.open}
        severity={snackBar?.severity}
        handleClose={() => setSnackBar({ open: false, title: "", msg: "" })}
        alertStyle={{ maxWidth: "320px" }}
      />
    </>
  );
};

export default SurveyBuilder;
