import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
} from "@mui/material";
import { CheckValueLocale } from "utils/helpers/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import IpChip from "./ipChip";
import IpPopupModal from "./ipPopupModal";
import CircularLoading from "components/circularProgress";
import { LucidyaApisContext } from "../lucidyaApisContext";
import {
  initialValuesLucidyaApi,
  validationSchemaLucidyaApi,
} from "./validationApi";
import { lucidyaApisTypeLocale } from "../apisList/apisListBodyFunctions";
import LucButton from "shared/lucButton/lucButton";
import { useFormik } from "formik";
import _ from "lodash";
import { useGetLucidyaApisTypes } from "../../hooks/useGetLucidyaApisTypes";
import { useGetSingleLucidyaApis } from "../../hooks/useGetSingleLucidyaApis";
import { useCreateLucidyaApis } from "../../hooks/useCreateLucidyaApis";
import { useEditLucidyaApis } from "../../hooks/useEditLucidyaApis";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 304,
      width: 300,
    },
  },
};

const CreateForm = ({ setGenerateApi, setApikeyResponse }) => {
  const intl = useIntl();
  const {
    handleCreateApiDialog,
    setSuccess,
    setSnackBarHeading,
    apiDialogType,
    apiDialogItem,
    setPage,
    apisListData,
  } = useContext(LucidyaApisContext);
  const ipSubnetArrLength = 7;
  const [isLoading, setIsLoading] = useState(false);
  const [focused, setFocused] = useState(false);

  // call with create or edit
  const { data: lucidyaApisTypeNames, isLoading: isLoadingapiTypes } =
    useGetLucidyaApisTypes();

  // call this hook with edit
  const { data: singleLucidyaApis, isLoading: isLoadingSingle } =
    useGetSingleLucidyaApis(apiDialogItem?.id);

  const { mutate: handleCreateLucidyaApis } = useCreateLucidyaApis(); //to handle create APIS
  const { mutate: handleEditLucidyaApis } = useEditLucidyaApis(); //to handle edit APIS

  const formik = useFormik({
    initialValues: _?.cloneDeep(initialValuesLucidyaApi),
    validationSchema: validationSchemaLucidyaApi(),
  });

  const activeProducts = JSON.parse(window?.localStorage?.activeProducts);
  const lucidyaApisActive = activeProducts?.find(
    (product) => product?.name == "PUBLIC_API",
  );
  const checkTokenName = apisListData?.map((el) => el?.attributes?.token_name);

  // to add ip to the list "ip_subnet_arr"
  const handleIpSubnetAdding = () => {
    if (
      formik?.values?.ip_subnet &&
      (!formik?.errors?.ip_subnet ||
        formik?.errors?.ip_subnet === "ip_subnet_field_required")
    ) {
      formik?.setFieldValue("ip_subnet_arr", [
        ...formik?.values?.ip_subnet_arr,
        formik?.values?.ip_subnet,
      ]);
      formik?.setFieldValue("ip_subnet", "");
    }
  };

  // to remove error when adding the first ip
  useEffect(() => {
    formik?.validateForm();
  }, [formik?.values?.ip_subnet_arr]);

  const handleInputMouseDown = (event) => {
    event?.preventDefault();
  };

  const handleIpSubnetDelete = (index) => {
    formik?.setFieldValue("ip_subnet_arr", [
      ...formik?.values?.ip_subnet_arr?.filter((_, i) => i !== index),
    ]);
  };

  const createLucidyaApis = (queryData) => {
    handleCreateLucidyaApis(
      {
        queryData,
      },
      {
        onSuccess: (result) => {
          setSuccess(true);
          setSnackBarHeading("api_added_success");
          setApikeyResponse({
            tokenName: result?.data?.token_name,
            token: result?.data?.token,
          });
          setGenerateApi(true);
          setPage(1);
        },
        onError: (result) => {
          {
            if (
              result?.errorMsg?.response?.data?.error?.detail ==
              "TOKEN_LIMIT_REACHED"
            ) {
              setSnackBarHeading("no_more_than_keys_failed");
              setSuccess(false);
              handleCreateApiDialog();
            } else if (
              checkTokenName?.some(
                (name) => name === formik?.values?.token_name,
              )
            ) {
              setSuccess(false);
              setSnackBarHeading("this_key_name_has_already_used");
              handleCreateApiDialog();
            } else {
              setSuccess(false);
              setSnackBarHeading("api_added_error");
              handleCreateApiDialog();
            }
          }
        },
        onSettled: () => {
          setIsLoading(false);
        },
      },
    );
  };

  const editLucidyaApis = (queryData) => {
    handleEditLucidyaApis(
      {
        id: apiDialogItem?.id,
        queryData,
      },
      {
        onSuccess: () => {
          setSuccess(true);
          setSnackBarHeading("api_updated_success");
          handleCreateApiDialog();
          setPage(1);
        },
        onError: (result) => {
          if (
            result?.errorMsg?.response?.data?.error?.detail ==
            "TOKEN_LIMIT_REACHED"
          ) {
            setSnackBarHeading("no_more_than_keys_failed");
            setSuccess(false);
            handleCreateApiDialog();
          } else if (
            checkTokenName?.some((name) => name === formik?.values?.token_name)
          ) {
            setSuccess(false);
            setSnackBarHeading("this_key_name_has_already_used");
            handleCreateApiDialog();
          } else {
            setSuccess(false);
            setSnackBarHeading("api_added_error");
            handleCreateApiDialog();
          }
        },
        onSettled: () => {
          setIsLoading(false);
        },
      },
    );
  };

  const createLucidyaApisRequest = async () => {
    var queryData = {
      api_type: `[${formik?.values.api_type?.join(",")}]`,
      token_name: formik?.values?.token_name,
      ip_subnet: `[${formik?.values?.ip_subnet_arr?.map((ip) => `"${ip}"`)?.join(",")}]`,
      product_id: lucidyaApisActive?.id,
    };
    setIsLoading(true);
    if (apiDialogType === "editApiKey") {
      editLucidyaApis(queryData);
    } else {
      createLucidyaApis(queryData);
    }
  };

  useEffect(() => {
    // to handle the data response with formik as initial data to form
    if (!!singleLucidyaApis) {
      formik?.setValues({
        ...formik?.values,
        id: singleLucidyaApis?.id,
        token_name: singleLucidyaApis?.attributes?.token_name,
        ip_subnet_arr: singleLucidyaApis?.attributes?.ip_subnet.map((ip) =>
          ip.replaceAll('"', ""),
        ),
        api_type: lucidyaApisTypeNames?.reduce((apiTypeArr, type) => {
          if (singleLucidyaApis?.attributes?.api_type?.includes(type?.name))
            apiTypeArr?.push(type?.id);
          return apiTypeArr;
        }, []),
      });
    }
  }, [singleLucidyaApis, lucidyaApisTypeNames]);

  const handleValues = (values) => {
    const selected = lucidyaApisTypeNames?.filter((item) => {
      if (values?.includes(item?.id)) {
        return item;
      }
    });
    const typesLocales = selected?.map((item) =>
      CheckValueLocale(lucidyaApisTypeLocale(item?.name), "", {}, intl),
    );
    return typesLocales?.join(", ");
  };

  return (
    <>
      {isLoadingSingle &&
      apiDialogType === "editApiKey" &&
      isLoadingapiTypes ? (
        <Box className="circle-single-loading">
          <CircularLoading />
        </Box>
      ) : (
        <>
          <DialogContent className="create-api-content">
            <Box className="create-api-input-wrapper api-type-form-control actions-buttons-shared">
              <InputLabel
                id="api-type-label"
                className="create-api-input-label"
              >
                {CheckValueLocale("api_type", "", {}, intl)}
                <span className="span-require"> * </span>
              </InputLabel>
              <Select
                labelId="api-type-label"
                id="api-type"
                name="api_type"
                className="create-api-input"
                multiple
                value={formik?.values?.api_type || []}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                error={formik?.touched?.api_type && !!formik?.errors?.api_type}
                renderValue={(selected) => handleValues(selected)}
                MenuProps={MenuProps}
              >
                {lucidyaApisTypeNames?.map((val) => {
                  return (
                    <MenuItem
                      className="api-type-menuItem"
                      key={val?.id}
                      value={val?.id}
                      id={`api-header-type-menu-item-${
                        val?.name ?? "api-type-name"
                      }`}
                    >
                      <Checkbox
                        checked={formik?.values?.api_type?.includes(val?.id)}
                      />
                      <ListItemText
                        primary={CheckValueLocale(
                          lucidyaApisTypeLocale(val?.name),
                          "",
                          {},
                          intl,
                        )}
                      />
                    </MenuItem>
                  );
                })}
              </Select>
              {formik?.touched.api_type && formik?.errors?.api_type ? (
                <Box className="create-api-error">
                  {CheckValueLocale(formik?.errors?.api_type, "", {}, intl)}
                </Box>
              ) : null}
            </Box>
            <Box className="create-api-input-wrapper">
              <InputLabel
                id="api-name-label"
                className="create-api-input-label"
              >
                {CheckValueLocale("api_name", "", {}, intl)}
                <span className="span-require"> * </span>
              </InputLabel>
              <OutlinedInput
                labelId="api-name-label"
                id="api-name"
                name="token_name"
                variant="outlined"
                className="create-api-input"
                value={formik?.values?.token_name}
                error={
                  formik?.touched?.token_name && !!formik?.errors?.token_name
                }
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
              />
              {formik?.touched?.token_name && formik?.errors?.token_name ? (
                <Box className="create-api-error">
                  {CheckValueLocale(formik?.errors?.token_name, "", {}, intl)}
                </Box>
              ) : null}
            </Box>
            <Box className="create-api-input-wrapper">
              <InputLabel
                id="ip-subnet-label"
                className="create-api-input-label"
              >
                {CheckValueLocale("ip_subnet", "", {}, intl)}
                <Tooltip
                  title={CheckValueLocale(
                    "grant_network_access_only_to_specific_ip",
                    "",
                    {},
                    intl,
                  )}
                  placement={`${
                    window.localStorage?.lang === "en" ? "right" : "left"
                  }-start`}
                  arrow
                >
                  <InfoOutlined className="info-icon" />
                </Tooltip>
              </InputLabel>
              <OutlinedInput
                labelId="ip-subnet-label"
                id="ip-subnet"
                name="ip_subnet"
                variant="outlined"
                className="create-api-input"
                value={formik?.values?.ip_subnet}
                error={!!formik?.errors?.ip_subnet}
                onChange={formik?.handleChange}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                endAdornment={
                  focused && (
                    <InputAdornment onMouseDown={handleInputMouseDown}>
                      <Button
                        className="add-btn-plus"
                        onClick={handleIpSubnetAdding}
                        id="monitor-options-tw-pa-add-exclude-keyword-btn"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        {CheckValueLocale("add", "", {}, intl)}
                      </Button>
                    </InputAdornment>
                  )
                }
                onKeyDown={(event) =>
                  event.keyCode === 13 && handleIpSubnetAdding()
                }
              />
              {!!formik?.errors?.ip_subnet ? (
                <Box className="create-api-error">
                  {CheckValueLocale(formik?.errors?.ip_subnet, "", {}, intl)}
                </Box>
              ) : null}
              {formik?.values?.ip_subnet_arr?.length > 0 ? (
                <Box className="ip-chip-wrapper">
                  {formik?.values?.ip_subnet_arr?.map((label, index) =>
                    index < ipSubnetArrLength ? (
                      <IpChip
                        key={index}
                        onDelete={() => handleIpSubnetDelete(index)}
                        label={label}
                        index={index}
                      />
                    ) : null,
                  )}
                  <IpPopupModal
                    ipSubnetArr={formik?.values?.ip_subnet_arr}
                    ipSubnetArrLength={ipSubnetArrLength}
                    handleDelete={handleIpSubnetDelete}
                  />
                </Box>
              ) : null}
            </Box>
          </DialogContent>
          <DialogActions className="create-api-actions">
            <LucButton
              variant="flat"
              type="secondary"
              onClick={handleCreateApiDialog}
              id="create-api-cancel-btn"
              className="cancel-popup-btn"
            >
              {CheckValueLocale("cancel", "", {}, intl)}
            </LucButton>
            <LucButton
              onClick={createLucidyaApisRequest}
              disabled={isLoading || !formik?.isValid}
              id="create-api-generate-btn"
              loading={isLoading}
            >
              {CheckValueLocale(
                apiDialogType === "editApiKey"
                  ? "save_changes_api_key"
                  : "generate_api_key",
                "",
                {},
                intl,
              )}
            </LucButton>
          </DialogActions>
        </>
      )}
    </>
  );
};

export default CreateForm;
