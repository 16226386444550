import { useQuery } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";
import { handlelUserRoles } from "utils/helpers";

export const useGetCompanyBranding = () => {
  return useQuery({
    queryKey: ["companyBranding"],
    queryFn: () => SettingsController.getReportsBranding(),
    select: (data) => data?.data,
    enabled: handlelUserRoles("GENERAL", "VIEW_REPORT_BRANDING"),
    retry: false,
  });
};
