import { useMutation, useQueryClient } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useSaveSkill = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ newSkill }) =>
      CompaniesController.addCompanySkills(newSkill),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["companySkills"],
      });
    },
  });
};
