export const envConfig = {
  production: {
    baseRoute: "https://backend2-lb.lucidya.com/",
    nodeRoute: "https://ppt.lucidya.com:443/",
    switchRoute: "https://backend.lucidya.com/",
    switchUrl: "https://sm.lucidya.com/",
    googleTrackingId: "UA-219742565-1",
    googleTagManager: "GTM-NP9CT8R",
    wsbroker: "livedashboard3.lucidya.com",
    wsport: 443,
    socketUrl: "https://liveservice.lucidya.com/",
  },
  production_local: {
    baseRoute: "https://backend2-lb.lucidya.com/",
    nodeRoute: "https://ppt.lucidya.com:443/",
    switchRoute: "https://backend.lucidya.com/",
    switchUrl: "http://localhost:3080/",
    googleTrackingId: "UA-219742565-1",
    googleTagManager: "GTM-NP9CT8R",
    wsbroker: "livedashboard3.lucidya.com",
    wsport: 443,
    socketUrl: "https://liveservice.lucidya.com/",
  },
  staging_survey: {
    baseRoute: `https://stg-survey-backend.lucidya.com/`,
    switchRoute: `https://stg-survey-backend.lucidya.com/`,
    switchUrl: "http://localhost:3080/",
    googleTrackingId: "UA-216005184-1",
    googleTagManager: "GTM-NP9CT8R",
    wsbroker: "stg-streaming.lucidya.com",
    wsport: 443,
    socketUrl: "https://stg-socket.lucidya.com/",
  },
  testing: {
    baseRoute: "https://testing-backend2.lucidya.com/",
    switchRoute: "https://testing-backend.lucidya.com/",
    switchUrl: "https://sm-testing.lucidya.com/",
    googleTrackingId: "UA-216005184-1",
    googleTagManager: "GTM-NP9CT8R",
    wsbroker: "livedashboard3.lucidya.com",
    wsport: 443,
    socketUrl: "https://stg-socket.lucidya.com/",
  },
  testing_local: {
    baseRoute: "https://testing-backend2.lucidya.com/",
    switchRoute: "https://testing-backend.lucidya.com/",
    switchUrl: "http://localhost:3080/",
    googleTrackingId: "UA-216005184-1",
    googleTagManager: "GTM-NP9CT8R",
    wsbroker: "livedashboard3.lucidya.com",
    wsport: 443,
    socketUrl: "https://stg-socket.lucidya.com/",
  },
  staging_port: {
    baseRoute: `https://stg-backend-${process.env.WDS_SOCKET_PATH}.lucidya.com/`,
    nodeRoute: "https://stg-ppt.lucidya.com/",
    switchRoute: `https://stg-backend-${process.env.WDS_SOCKET_PATH}.lucidya.com/`,
    switchUrl: "http://stg-sm.lucidya.com/",
    googleTrackingId: "UA-216005184-1",
    googleTagManager: "GTM-NP9CT8R",
    wsbroker: "stg-streaming.lucidya.com",
    wsport: 443,
    socketUrl: "https://stg-socket.lucidya.com/",
  },
  staging_port_local: {
    baseRoute: `https://stg-backend-${process.env.WDS_SOCKET_PATH}.lucidya.com/`,
    nodeRoute: "https://stg-ppt.lucidya.com/",
    switchRoute: `https://stg-backend-${process.env.WDS_SOCKET_PATH}.lucidya.com/`,
    switchUrl: "http://localhost:3080/",
    googleTrackingId: "UA-216005184-1",
    googleTagManager: "GTM-NP9CT8R",
    wsbroker: "stg-streaming.lucidya.com",
    wsport: 443,
    socketUrl: "https://stg-socket.lucidya.com/",
  },
  staging: {
    baseRoute: `https://stg-backend2.lucidya.com/`,
    switchRoute: "https://stg-backend.lucidya.com/",
    nodeRoute: "https://stg-ppt.lucidya.com/",
    switchUrl: "http://stg-sm.lucidya.com/",
    googleTrackingId: "UA-216005184-1",
    googleTagManager: "GTM-NP9CT8R",
    wsbroker: "stg-streaming.lucidya.com",
    wsport: 443,
    socketUrl: "https://stg-socket.lucidya.com/",
  },
  staging_local: {
    baseRoute: `https://stg-backend2.lucidya.com/`,
    nodeRoute: "https://stg-ppt.lucidya.com/",
    switchRoute: "https://stg-backend.lucidya.com/",
    switchUrl: "http://localhost:3080/",
    googleTrackingId: "UA-216005184-1",
    googleTagManager: "GTM-NP9CT8R",
    wsbroker: "stg-streaming.lucidya.com",
    wsport: 443,
    socketUrl: "https://stg-socket.lucidya.com/",
  },
};
