import { useQuery } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useCompanyInfo = () => {
  return useQuery({
    queryKey: ["companyInfo"],
    queryFn: () => SettingsController.getCompanyInfo(),
    select: (data) => data?.data?.attributes || {},
    retry: false,
  });
};
