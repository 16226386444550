import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import ProductsTab from "./productsTab";
import AddonsTab from "./addonsTab";

const ProductsAddons = (props) => {
  const { data, activeProducts, setSnackbar, setExpanded } = props;

  const intl = useIntl();

  const [value, setValue] = useState("products");
  const handleChange = (e, newValue) => setValue(newValue);

  return (
    <Box id="products-addons-container">
      <TabContext value={value}>
        <Box className="products-addons-header">
          <TabList onChange={handleChange}>
            <Tab
              id="settings-subscriptions-products-tab-btn"
              className={`subscriptions-tab-btn ${value === "products" ? "active" : ""}`}
              label={
                <Box className="tab-button-title">
                  {CheckValueLocale("subscription_products", "", {}, intl)}
                </Box>
              }
              value="products"
            />
            <Tab
              id="settings-subscriptions-addons-tab-btn"
              className={`subscriptions-tab-btn ${value === "addons" ? "active" : ""}`}
              label={
                <Box className="tab-button-title">
                  {CheckValueLocale("subscription_addons", "", {}, intl)}
                </Box>
              }
              value="addons"
            />
          </TabList>
          <Typography>
            {CheckValueLocale(
              "subscription_360_view_of_customers",
              "",
              {},
              intl,
            )}
          </Typography>
        </Box>
        <TabPanel value="products">
          <ProductsTab
            data={data}
            activeProducts={activeProducts || []}
            setSnackbar={setSnackbar}
            setExpanded={setExpanded}
          />
        </TabPanel>
        <TabPanel value="addons">
          <AddonsTab
            data={data}
            activeProducts={activeProducts || []}
            setSnackbar={setSnackbar}
            setExpanded={setExpanded}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default ProductsAddons;
