import { Typography, Tooltip, Box } from "@mui/material";
import { useIntl } from "react-intl";
import { DoneAll, HelpOutline } from "@mui/icons-material";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckDouble,
  faEye,
  faReplyAll,
} from "@fortawesome/pro-regular-svg-icons";

const CardData = (props) => {
  const intl = useIntl();

  const { survey } = props;
  const sections = [
    {
      sectionTitle: "completion_rate",
      tooltipText: CheckValueLocale("completion_rate_tooltip", "", {}, intl),
      sectionIcon: <FontAwesomeIcon icon={faCheckDouble} />,
      sectionValue:
        survey?.completion_rate === -1 ? "-" : survey?.completion_rate + "%",
    },
    {
      sectionTitle: "num_of_response",
      tooltipText: CheckValueLocale("num_of_response_tooltip", "", {}, intl),
      sectionIcon: <FontAwesomeIcon icon={faReplyAll} />,
      sectionValue:
        survey?.number_of_responses === -1 ? "-" : survey?.number_of_responses,
    },
    {
      sectionTitle: "num_of_views",
      tooltipText: CheckValueLocale("num_of_views_tooltip", "", {}, intl),
      sectionIcon: <FontAwesomeIcon icon={faEye} />,
      sectionValue: survey?.views === -1 ? "-" : survey?.views,
    },
  ];
  return (
    <>
      <Box className="card-data-box">
        {sections.map((section, i) => (
          <Box key={i}>
            <Typography className="section-title" key={i}>
              {CheckValueLocale(section?.sectionTitle, "", {}, intl)}
              <Tooltip title={section?.tooltipText} placement="top" arrow>
                <HelpOutline className="help-tooltip" />
              </Tooltip>
            </Typography>

            <spaTypographyn component={"span"} className="section-icon">
              {section?.sectionIcon}
            </spaTypographyn>
            <Typography component={"span"} className="section-value">
              {section?.sectionValue}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};
export default CardData;
