import { Box, Typography } from "@mui/material";
import { SelectDropdown } from "./components/selectDropdown/selectDropdown";

export const MultiSelectTab = ({
  title,
  options,
  label,
  value,
  onChange,
  isLoading,
  emptyListMessage,
  nameKey,
  ...props
}) => {
  return (
    <Box className="selectable-tab-content-container">
      <Typography variant="body2" className="selectable-tab-content-title">
        {title}
      </Typography>
      <Box className="selectable-tab-content-row multi-select-row">
        <SelectDropdown
          label={label}
          options={options}
          value={value}
          onChange={onChange}
          isLoading={isLoading}
          emptyListMessage={emptyListMessage}
          nameKey={nameKey}
          multiple={true}
          {...props}
        />
      </Box>
    </Box>
  );
};
