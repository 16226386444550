import Services from "./Services";

var Settings = {};

// --------------------------------------------------------------------------------------------------
// ------------------------------------------ Company  ------------------------------------------
// --------------------------------------------------------------------------------------------------

// Get Company Info
Settings.getCompanyInfo = () => {
  return Services.getData("api/v3/company/info");
};

// Update Company Info
Settings.updateCompanyInfo = (queryData) => {
  return Services.putData("/api/v3/company/info", queryData);
};

// Add Custom Domain
Settings.addCustomDomain = (queryData) => {
  return Services.postData("api/v3/company/info/custom_domain", queryData);
};

// Verify Custom Domain
Settings.verifyCustomDomain = () => {
  return Services.putData("api/v3/company/info/custom_domain/verify");
};

// Delete Custom Domain
Settings.deleteCustomDomain = (queryData) => {
  return Services.deleteData("api/v3/company/info/custom_domain", queryData);
};

// --------------------------------------------------------------------------------------------------
// --------------------------------------- Subscriptions ---------------------------------------
// --------------------------------------------------------------------------------------------------

// Get Company Subscriptions
Settings.getCompanySubscriptions = () => {
  return Services.getData("api/v3/company/subscription");
};

// Request Demo Or Upgrade Package
Settings.requestDemoOrUpgrade = (queryData) => {
  return Services.putData("api/v3/company/requests/service", queryData);
};

Settings.getCompanyProducts = (queryData) => {
  return Services.getData("api/v3/company/products", queryData);
};

// --------------------------------------------------------------------------------------------------
// --------------------------------------- Historical Request Page ---------------------------------------
// --------------------------------------------------------------------------------------------------

// Get Company Packages Quota
Settings.getPackagesQuota = () => {
  return Services.getData("api/v3/historical_requests/company_quota");
};

// Get Historical Requests
Settings.getHistoricalRequests = (queryData) => {
  return Services.getData("api/v4/historical_requests", queryData);
};

// Get Historical Requests
Settings.getHistoricalRequestStatus = () => {
  return Services.getData("api/v3/historical_requests/status_indicator");
};

// Get Historical Requests Cancellable Datasources
Settings.getCancellableDatasources = () => {
  return Services.getData(
    "api/v4/historical_requests/cancellable_data_sources",
  );
};

// Get Historical Request Products
Settings.getHistoricalRequestProducts = () => {
  return Services.getData("api/v3/historical_requests/company_platforms");
};

// Get Historical Request Data Sources
Settings.getHistoricalRequestDatasoruces = (queryData) => {
  return Services.getData("api/v3/historical_requests/data_sources", queryData);
};

// Get Historical Request Monitors
Settings.getHistoricalRequestMonitors = (queryData) => {
  return Services.getData("api/v3/historical_requests/monitors", queryData);
};

// Get Historical Request Available Date For X-Platform
Settings.getAvailableDataXPlatform = (queryData) => {
  return Services.getData(
    "api/v4/historical_requests/current_twitter_counts",
    queryData,
  );
};

// Create Historical Request
Settings.createHistoricalRequest = (queryData) => {
  return Services.postData("api/v3/historical_requests", queryData);
};

// Retry Historical Request
Settings.retryHistoricalRequest = (requestId) => {
  return Services.getData(`api/v3/historical_requests/retry/${requestId}`);
};

// Stop Historical Request
Settings.stopHistoricalRequest = (requestId) => {
  return Services.getData(
    `api/v4/historical_requests/cancel_historical_request/${requestId}`,
  );
};

// --------------------------------------------------------------------------------------------------
// --------------------------------------- Reports Branding Page ---------------------------------------
// --------------------------------------------------------------------------------------------------
Settings.getReportsBranding = () => {
  return Services.getData("api/v3/company/report_branding");
};

Settings.editReportsBranding = (queryData) => {
  return Services.postData("api/v3/company/report_branding", queryData);
};

Settings.deleteReportsBranding = (id) => {
  return Services.deleteData(`api/v3/company/report_branding/${id}/reset`);
};

Settings.getBrandingDefaults = () => {
  return Services.getData("api/v3/company/report_branding/default");
};

Settings.getFonts = () => {
  return Services.getData("api/v3/company/font_types");
};

// --------------------------------------------------------------------------------------------------
// --------------------------------------- LucidyaApi Page ---------------------------------------
// --------------------------------------------------------------------------------------------------

// Get lucidya apis list
Settings.getLucidyaApisList = (queryData) => {
  return Services.getData("api/v3/public_apis/public_apis", queryData);
};

// Delete lucidya api
Settings.deleteLucidyaApisRequest = (id) => {
  return Services.deleteData(`api/v3/public_apis/public_apis/${id}`);
};

// InActivate lucidya api
Settings.inActivateLucidyaApisRequest = (id) => {
  return Services.postData(`api/v3/public_apis/deactivate/${id}`);
};

// Activate lucidya api
Settings.activateLucidyaApisRequest = (id) => {
  return Services.postData(`api/v3/public_apis/activate/${id}`);
};

// regenerate lucidya api
Settings.regenerateLucidyaApisRequest = (id) => {
  return Services.postData(`api/v3/public_apis/regenerate/${id}`);
};

// Get lucidya apis Types list
Settings.getLucidyaApisTypes = () => {
  return Services.getData("api/v3/public_apis/types");
};

// get single lucidya api
Settings.getSingleLucidyaApis = (queryData) => {
  return Services.getData(`api/v3/public_apis/informer`, queryData);
};

// create lucidya api
Settings.createLucidyaApisRequest = (queryData) => {
  return Services.postData("api/v3/public_apis/public_apis", queryData);
};

// edit lucidya api
Settings.editLucidyaApisRequest = (id, queryData) => {
  return Services.putData(`api/v3/public_apis/public_apis/${id}`, queryData);
};

// --------------------------------------------------------------------------------------------------
// --------------------------------------- 3rd Party Integrations ---------------------------------------
// --------------------------------------------------------------------------------------------------

// List of 3rd Party Integrations
Settings.thirdPartyList = () => {
  return Services.getData("api/v3/auth/third_party_integrations");
};

// Get 3rd Party Integrations Intercom Data
Settings.getIntercomData = () => {
  return Services.getData("api/v3/auth/third_party_integrations/intercom");
};

// Configure Intercom Integration
Settings.configureIntercom = (queryData) => {
  return Services.postData(
    "api/v3/auth/third_party_integrations/intercom",
    queryData,
  );
};

// Get 3rd Party Integrations Zendesk Data
Settings.getZendeskData = () => {
  return Services.getData("api/v3/auth/third_party_integrations/zendesk");
};

// Configure Zendesk Integration
Settings.configureZendesk = (queryData) => {
  return Services.postData(
    "api/v3/auth/third_party_integrations/zendesk",
    queryData,
  );
};

export default Settings;
