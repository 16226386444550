import React from "react";
import { Box } from "@mui/material";
import Header from "./editSectionHeader";
import SectionContainer from "./components/section/sectionContainer";
import "../surveyBuilder.scss";

function EditSection(props) {
  const {
    surveyData,
    setSurveyData,
    surveySections,
    setSurveySections,
    filteredSections,
    handleSectionTitle,
    handleQuestionTitle,
    handleQuestionDesc,
    sqIndex,
    ssIndex,
    setSaveChangesFlag,
    emptySection,
    handleCardClick,
    editRef,
    handleChangeProceed,
    proceedError,
    afterSecError,
    secTitleErr,
    multiRes,
    handleMultiRes,
    handleSectionDir,
    setDiscriptionError,
    discriptionError,
  } = props;

  return (
    <Box
      ref={editRef}
      id="editSectionScroll"
      className="edit-section-container"
    >
      <Box className="scroll-bar-style">
        <Header
          surveyData={surveyData}
          setSurveyData={setSurveyData}
          multiRes={multiRes}
          handleMultiRes={handleMultiRes}
          setDiscriptionError={setDiscriptionError}
          discriptionError={discriptionError}
        />
        {filteredSections?.map((section, index) => {
          return (
            <SectionContainer
              surveySections={surveySections}
              setSurveySections={setSurveySections}
              filteredSections={filteredSections}
              section={section}
              sectionIndex={index}
              hideSecHeader={surveySections?.length < 2 ? true : false}
              handleSectionTitle={handleSectionTitle}
              handleQuestionTitle={handleQuestionTitle}
              handleQuestionDesc={handleQuestionDesc}
              sqIndex={sqIndex}
              ssIndex={ssIndex}
              setSaveChangesFlag={setSaveChangesFlag}
              emptySection={emptySection}
              handleCardClick={handleCardClick}
              handleChangeProceed={handleChangeProceed}
              proceedError={proceedError}
              afterSecError={afterSecError}
              secTitleErr={secTitleErr}
              handleSectionDir={handleSectionDir}
              surveyData={surveyData}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default EditSection;
