import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./teamsSharedStyle.scss";
import LucButton from "shared/lucButton/lucButton";
export const ShowMoreButton = ({
  hasMore,
  show,
  onShowMore,
  onShowLess,
  loading,
}) => {
  const intl = useIntl();
  if (!show) return null;

  return (
    <Box className="team-show-more-footer">
      <LucButton
        variant={hasMore ? "flat" : "outline"}
        onClick={hasMore ? onShowMore : onShowLess}
        loading={loading}
      >
        {CheckValueLocale(
          hasMore ? "show_more_btn" : "show_less_btn",
          "",
          {},
          intl,
        )}
      </LucButton>
    </Box>
  );
};
