import { useMutation, useQueryClient } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useCreateLucidyaApis = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ queryData }) =>
      SettingsController.createLucidyaApisRequest(queryData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["companyLucidyaApisList"],
      });
    },
  });
};
