import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import "./stepsHeader.scss";
import { CheckValueLocale } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Divider from "@mui/material/Divider";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

const StepsHeader = ({ activeStep, steps }) => {
  const intl = useIntl();

  return (
    <Box className="main-steps-header">
      <Box className="main-sutep-comp">
        {steps?.map((step, i) => (
          <>
            {i > 0 ? (
              <Divider
                className={
                  steps.length == 2
                    ? "main-steps-line two-steps"
                    : "main-steps-line"
                }
              />
            ) : null}
            <Box className="main-steps-continer">
              <Box
                className={
                  i == activeStep
                    ? "main-step active-step"
                    : i < activeStep
                      ? "main-step done-step"
                      : "main-step"
                }
              >
                <Box className="main-step-icon">
                  {i < activeStep ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    <Box className="step-num">{i + 1}</Box>
                  )}
                </Box>

                <Box className="step-name">
                  {CheckValueLocale(step, "", {}, intl)}
                </Box>
              </Box>
            </Box>
          </>
        ))}
      </Box>
    </Box>
  );
};
export default StepsHeader;
