import { useEffect } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import "./MultiSelectDropdown.scss";
import LucButton from "shared/lucButton/lucButton";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { AutoCompleteDropdown } from "./autoCompleteDropdown/autoCompleteDropdown";
import { useGetAllTeams } from "pages/settings/pages/userManagementPage/hooks/useGetAllTeams";

function MultiSelectDropdown({ teamsData, setTeamsData }) {
  const intl = useIntl();

  const {
    data: dataTeams,
    isError: isErrorTeams,
    isPending: isLoading,
  } = useGetAllTeams(!teamsData?.teamsOptions?.length);

  useEffect(() => {
    if (dataTeams && !isErrorTeams) {
      setTeamsData((prevState) => ({
        ...prevState,
        teamsOptions: dataTeams?.data?.teams,
      }));
    }
  }, [dataTeams, isErrorTeams]);

  const handleChange = (value) => {
    setTeamsData((prevState) => ({
      ...prevState,
      teamsSelected: value,
    }));
  };
  return (
    <Box className="teams-multi-select-dropdown">
      <Typography className="teams-subtitle">
        {CheckValueLocale("teams_subtitle", "", {}, intl)}
      </Typography>
      {!isLoading && !teamsData?.teamsOptions?.length ? (
        <Box className="add-teams-empty-member-state">
          <Typography className="add-teams-empty-member-state-title">
            {CheckValueLocale("teams_subtitle_no_teams", "", {}, intl)}
          </Typography>
          <Link to="/settings/teams/add" className="add-user-link">
            <LucButton
              variant="outline"
              startIcon={<FontAwesomeIcon icon={faPlus} />}
            >
              {CheckValueLocale("new_team", "", {}, intl)}
            </LucButton>
          </Link>
        </Box>
      ) : (
        <Box className="teams-multi-select-dropdown-wrapper">
          <AutoCompleteDropdown
            label={CheckValueLocale("select_teams", "", {}, intl)}
            options={teamsData?.teamsOptions}
            value={teamsData?.teamsSelected || []}
            onChange={handleChange}
            multiple={true}
            isLoading={isLoading}
            loadingMessage={CheckValueLocale("dropdown_loading", "", {}, intl)}
            listboxClassName="user-tabs-select-dropdown-list"
            emptyListMessage={CheckValueLocale(
              "no_options_available",
              "",
              {},
              intl,
            )}
            renderTags={(selected = [], _getTagProps, isOpen) => {
              if (isOpen) return null;
              return (
                <Typography className="user-all-teams-content-select-msg auto-comp-empty">
                  {`${selected?.length} ${CheckValueLocale(
                    "tab_selected_options",
                    "",
                    {},
                    intl,
                  )}`}
                </Typography>
              );
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default MultiSelectDropdown;
