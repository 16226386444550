import { Box, Card } from "@mui/material";
import { useResetBranding } from "../hooks/useResetBranding"; // Commented untill input from Product Manager and BE
import LogoBranding from "./boxContant/logoBranding";
import FontBranding from "./boxContant/fontBranding";
import ColorsBranding from "./boxContant/colorsBranding";
import PreviewSection from "./boxContant/previewSection";

const BoxBranding = (props) => {
  const {
    formik,
    fontsList,
    resetModalOpened,
    setResetModalOpened,
    brandingId,
    setSnackBar,
  } = props;

  // Commented untill input from Product Manager and BE

  // const { mutate: resetDetualts, isPending: resetDetualtsLoading } =
  //   useResetBranding();

  // const handleResetAsDefault = () => {
  //   resetDetualts(
  //     { id: brandingId },
  //     {
  //       onSuccess: () =>
  //         setSnackBar({
  //           open: true,
  //           severity: "success",
  //           title: "",
  //           message: "reset_default_style_branding_successfully",
  //         }),
  //       onError: () =>
  //         setSnackBar({
  //           open: true,
  //           severity: "error",
  //           title: "something_went_wrong",
  //           message: "try_again",
  //         }),
  //       onSettled: () => setResetModalOpened(false),
  //     },
  //   );
  // };

  return (
    <Card className="branding-items-container">
      <Box className="container-contant">
        <Box className="action-branding-items">
          {/* Logo Image */}
          <LogoBranding formik={formik} />
          {/* Fonts Section */}
          <FontBranding formik={formik} fontsList={fontsList} />

          {/* Color section */}
          <ColorsBranding formik={formik} />
        </Box>

        {/* Preview Section */}
        <PreviewSection
          companyBranding={formik?.values}
          fontName={
            fontsList?.find((font) => font?.id == formik?.values?.font_type_id)
              ?.attributes?.font_name || ""
          }
        />
      </Box>

      {/* Commented untill input from Product Manager and BE */}

      {/* <Box className="container-footer">
        <LucButton
          id="branding-reset-as-default-btn"
          variant="outline"
          disabled={!brandingId}
          onClick={() => {}}
        >
          {CheckValueLocale("reset_as_default", "", {}, intl)}
        </LucButton>
      </Box> */}
      {/* <PopupModal
        title={CheckValueLocale("reset_as_default", "", {}, intl)}
        body={CheckValueLocale("reset_as_default_body_popup", "", {}, intl)}
        leftBtn={CheckValueLocale("cancel", "", {}, intl)}
        rightBtn={CheckValueLocale("reset_btn", "", {}, intl)}
        isRightBtnLoading={resetDetualtsLoading}
        open={resetModalOpened}
        close={() => setResetModalOpened(false)}
        addClasses="remove-logo-popup actions-buttons-shared"
        accept={handleResetAsDefault}
      /> */}
    </Card>
  );
};

export default BoxBranding;
