import { useQuery } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useXPlatformAvailableData = ({
  enableFetch,
  productId,
  dataSourceId,
  monitorId,
  startDate,
  endDate,
}) => {
  return useQuery({
    queryKey: [
      "xPlatformAvailableData",
      productId,
      dataSourceId,
      monitorId,
      startDate,
      endDate,
    ],
    queryFn: async () =>
      SettingsController.getAvailableDataXPlatform({
        product_id: productId,
        data_source_id: dataSourceId,
        monitor_model_id: monitorId,
        start_date: startDate,
        end_date: endDate,
      }),
    enabled: enableFetch,
    select: (data) => data?.data,
    retry: false,
  });
};
