import { useEffect, useState } from "react";
import { workgingHoursData } from "../../userManagementPage/subPages/shardComponents/tabs/workingHours";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGetTeamMembers } from "../hooks/useGetTeamMembers";
import { useGetProductOptions } from "../hooks/useGetProductOptions";
import { useGetTeamMembersForEdit } from "../hooks/useGetTeamMembersForEdit";
const teamFormInitialValues = {
  team_name: "",
  selected_product: null,
};

const teamFormSchema = Yup.object().shape({
  team_name: Yup?.string()
    ?.trim()
    ?.required("this_field_required")
    ?.max(50, "Name_must_be_under_50_characters"),
  selected_product: Yup?.number()?.required("this_field_required"),
});
// this shared functions for add team and edit team pages
export const TeamSharedFuntions = () => {
  const formik = useFormik({
    initialValues: teamFormInitialValues,
    validationSchema: teamFormSchema,
  });

  // members state
  const [members, setMembers] = useState([]); // {data?.users: [], pagination: {}}
  const [selectedMembers, setSelectedMembers] = useState([]); // [id1,id2]
  // accounts state
  const [socialAccounts, setSocialAccounts] = useState([]);
  const [selectedSocialAccounts, setSelectedSocialAccounts] = useState([]); // [{account_id: "x" , data_source_id: "x"}]
  // monitors state
  const [monitors, setMonitors] = useState([]);
  const [selectedMonitors, setSelectedMonitors] = useState([]); // [id1,id2]
  // channels state
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]); // [id1,id2]
  // loading state
  const [isTeamSocialAccountsLoading, setIsTeamSocialAccountsLoading] =
    useState(false);
  const [isTeamMonitorsLoading, setIsTeamMonitorsLoading] = useState(false);
  const [isTeamChannelsLoading, setIsTeamChannelsLoading] = useState(false);
  const [teamError, setTeamError] = useState({
    message: "",
    severity: "",
    title: "",
    openSnakbar: false,
    teamNameError: "",
  });

  const [workingHours, setWorkingHours] = useState({
    workingHours: workgingHoursData,
  });

  const [tabsErrors, setTabsErrors] = useState({
    workgingHoursPreError: false,
    workingHours: false,
    topicsPreError: false,
  });

  const [autoReplyMessages, setAutoReplyMessages] = useState("");

  const { mutate: handleTeamMembers, isPending: isTeamMemberLoading } =
    useGetTeamMembers();

  const {
    mutate: handleTeamMembersForEdit,
    isPending: isTeamMemberForEditLoading,
  } = useGetTeamMembersForEdit();

  const {
    data: products,
    isPending: isTeamProductsLoading,
    isError: isErrorProductOptions,
  } = useGetProductOptions();

  useEffect(() => {
    if (isErrorProductOptions) {
      setTeamError({
        message: "try_again_error_message",
        severity: "error",
        title: "failed_error_message",
        openSnakbar: true,
      });
    }
  }, [isErrorProductOptions]);

  // members functions
  const handleSelectMember = (selectdMemberId) => {
    if (selectedMembers?.includes(selectdMemberId)) {
      setSelectedMembers((prev) =>
        prev?.filter((member) => member !== selectdMemberId),
      );
    } else {
      setSelectedMembers((prev) => [...prev, selectdMemberId]);
    }
  };
  // accounts functions, selectdSocialAccount = {account_id: "x" , data_source_id: "x"}
  const handleSelectSocialAccount = (selectdSocialAccount) => {
    const account_id = selectdSocialAccount?.account_id;
    const findSelectdSocialAccount = selectedSocialAccounts?.find(
      (socialAccount) => socialAccount?.account_id === account_id,
    );
    if (findSelectdSocialAccount) {
      setSelectedSocialAccounts((prev) =>
        prev?.filter(
          (socialAccount) => socialAccount?.account_id !== account_id,
        ),
      );
    } else {
      setSelectedSocialAccounts((prev) => [...prev, selectdSocialAccount]);
    }
  };
  // monitors functions
  const handleSelectMonitor = (selectdMonitorId) => {
    if (selectedMonitors?.includes(selectdMonitorId)) {
      setSelectedMonitors((prev) =>
        prev?.filter((monitor) => monitor !== selectdMonitorId),
      );
    } else {
      setSelectedMonitors((prev) => [...prev, selectdMonitorId]);
    }
  };
  // channels functions
  const handleSelectChannel = (selectdChannelId) => {
    if (selectedChannels?.includes(selectdChannelId)) {
      setSelectedChannels((prev) =>
        prev?.filter((channel) => channel !== selectdChannelId),
      );
    } else {
      setSelectedChannels((prev) => [...prev, selectdChannelId]);
    }
  };

  //handle Team Members to create page from Hook (useGetTeamMembers)
  const initilizeTeamDetails = () => {
    handleTeamMembers(
      {},
      {
        onSuccess: (res) => {
          setMembers(res?.data?.users);
        },
        onError: () => {
          setTeamError({
            message: "try_again_error_message",
            severity: "error",
            title: "failed_error_message",
            openSnakbar: true,
          });
        },
      },
    );
  };

  //handle Team Members to edit page from Hook (useGetTeamMembers)
  const initializeDataForEditTeam = (id) => {
    handleTeamMembersForEdit(
      { id },
      {
        onSuccess: (res) => {
          setMembers(res?.data);
        },
        onError: () => {
          setTeamError({
            message: "try_again_error_message",
            severity: "error",
            title: "failed_error_message",
            openSnakbar: true,
          });
        },
      },
    );
  };

  return {
    formik,
    products,
    members,
    setMembers,
    selectedMembers,
    setSelectedMembers,
    socialAccounts,
    setSocialAccounts,
    selectedSocialAccounts,
    setSelectedSocialAccounts,
    monitors,
    setMonitors,
    selectedMonitors,
    setSelectedMonitors,
    channels,
    setChannels,
    selectedChannels,
    setSelectedChannels,
    handleSelectMember,
    handleSelectSocialAccount,
    handleSelectMonitor,
    handleSelectChannel,
    initilizeTeamDetails,
    isTeamChannelsLoading,
    isTeamMemberLoading: isTeamMemberForEditLoading || isTeamMemberLoading,
    isTeamMonitorsLoading,
    isTeamProductsLoading,
    isTeamSocialAccountsLoading,
    teamError,
    setTeamError,
    initializeDataForEditTeam,
    workingHours,
    setWorkingHours,
    tabsErrors,
    setTabsErrors,
    autoReplyMessages,
    setAutoReplyMessages,
  };
};
