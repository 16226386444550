import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import { facebookAuth } from "pages/channelAnalytics/manageChannels/manageChannels.hooks";
import { CheckValueLocale } from "utils/helpers";
import warningIcon from "images/warning.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import { useCompanyApps } from "pages/settings/pages/authenticationPage/hooks/useCompanyApps";

const HashtagsLimitReached = ({ hashtagsManagerLink }) => {
  const intl = useIntl();

  const { data: companyAppsResponse } = useCompanyApps();
  const companyApps = companyAppsResponse?.data?.data || [];

  return (
    <Box className="hashtags-limit-wrapper">
      <img src={warningIcon} className="limit-warning" />
      <Box component="p" className="limit-text-wrapper">
        <Box component="span" className="limit-text">
          {CheckValueLocale("hashtags_limit_text", "", {}, intl)}
          {hashtagsManagerLink ? (
            <>
              {CheckValueLocale("from", "", {}, intl)}{" "}
              <Link to="/settings/instagram_hashtags">
                {CheckValueLocale("ig_hashtags_manager", "", {}, intl)}
              </Link>
            </>
          ) : null}
        </Box>
      </Box>
      <LucButton
        type="primary"
        variant="outline"
        size="small"
        id="ig-business-account"
        onClick={() =>
          facebookAuth(
            companyApps,
            window?.location?.origin + "/settings/instagram_hashtags",
            "instagram",
            true,
            false,
          )
        }
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        className="limit-btn"
      >
        {CheckValueLocale("ig_business_account", "", {}, intl)}
      </LucButton>
    </Box>
  );
};
export default HashtagsLimitReached;
