import { Box } from "@mui/material";

import { CheckValueLocale } from "utils/helpers/index.js";
import { useIntl } from "react-intl";
import "./desginPDF.scss";
import HeaderPagePdf from "./components/headerPdf";

const setNewMonitor = (
  monitors,
  id,
  monitorName,
  monitorType,
  monitorDataSource,
  isDm,
) => {
  if (!monitors?.[id]?.dataSource?.includes(monitorDataSource)) {
    monitors[id] = {
      name: monitorName,
      type: monitorType,
      dataSource: [...(monitors?.[id]?.dataSource || []), monitorDataSource], // this is array
      is_dm: isDm,
    };
  }
};

const desginPDF = ({ startDate, endDate, dashboardWidgets, dashboardInfo }) => {
  const intl = useIntl();

  //to get monitors used in this dashboard
  const monitors = {};
  dashboardWidgets?.forEach((widget) => {
    const listIdsMonitors = Object?.keys(monitors);

    if (!listIdsMonitors?.includes(widget?.attributes?.monitor_id))
      setNewMonitor(
        monitors,
        widget?.attributes?.monitor_id,
        widget?.attributes?.monitor_name,
        widget?.attributes?.monitor_type?.name,
        widget?.attributes?.data_source,
        widget?.attributes?.is_dm,
      );

    widget?.attributes?.customized_widget?.attributes?.monitors?.forEach(
      (monitor) => {
        const subMonitor = monitor?.name + "-" + monitor?.id;
        if (!listIdsMonitors?.includes(subMonitor))
          setNewMonitor(
            monitors,
            monitor?.id,
            monitor?.name,
            monitor?.monitor_type_name,
            monitor?.data_source_name,
            monitor?.is_dm,
          );
      },
    );
  });

  return (
    <Box id="pdf-content-parent-custom-dashboard">
      <Box
        className="main-pdf-contant-dashboard"
        id="main-pdf-contant-dashboard"
      >
        <HeaderPagePdf />

        <Box className="main-title-pdf-dashboard">
          <Box className="title">
            "{dashboardInfo?.name}"
            {CheckValueLocale("dashboard_report", "", {}, intl)}
          </Box>
          <Box className="description">
            {CheckValueLocale("dashboard_report_description", "", {}, intl)}
          </Box>
        </Box>

        {/* monitors name section  */}
        <Box className="sub-section-pdf">
          <Box className="section-title-dashboard">
            {CheckValueLocale(
              dashboardInfo?.product === "cxm"
                ? "pdf_selected_accounts_dashboard"
                : "pdf_selected_monitors_dashboard",
              "",
              {},
              intl,
            )}
          </Box>
          <Box component={"ul"} className="details-section">
            {Object?.values(monitors)?.map((monitor, index) => {
              return (
                <Box
                  key={index}
                  component={"li"}
                  className="details-section-monitor"
                >
                  <Box component={"span"} className="monitor-name-dashboard">
                    {monitor?.name}
                  </Box>
                  <Box component={"span"} className="monitor-type-dashboard">
                    {CheckValueLocale(monitor?.type, "", {}, intl)}
                  </Box>
                  <Box component={"span"} className="monitor-data-source">
                    {CheckValueLocale(
                      monitor?.is_dm
                        ? `${monitor?.dataSource?.[0]?.toLowerCase()}_dm`
                        : monitor?.dataSource
                            ?.map((dataSource) =>
                              CheckValueLocale(dataSource, "", {}, intl),
                            )
                            ?.join(" , "),
                      "",
                      {},
                      intl,
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>

        {/* date section */}
        <Box className="sub-section-pdf">
          <Box className="section-title-dashboard">
            {CheckValueLocale("date_quick", "", {}, intl)}
          </Box>
          <Box className="details-section">
            <Box
              component={"span"}
              className="special-operator-between-monitors from-keyword"
            >
              {CheckValueLocale("from", "", {}, intl)}
            </Box>
            <Box component={"span"}>{startDate}</Box>
            <Box
              component={"span"}
              className="special-operator-between-monitors"
            >
              {CheckValueLocale("pdf_to", "", {}, intl)}
            </Box>
            <Box component={"span"}>{endDate}</Box>
          </Box>
        </Box>

        <Box className="fixed-border-bottom" />
      </Box>

      {/* to add widgets for each page in pdf */}

      <Box id="main-pdf-contant-dashboard-widgets-page">
        <HeaderPagePdf />

        <Box id="main-pdf-contant-dashboard-widgets" />

        <Box className="fixed-border-bottom" />
      </Box>
    </Box>
  );
};

export default desginPDF;
