import { Box, Typography } from "@mui/material";

const TeamCreatedAtCell = ({ createdAt }) => {
  return (
    <Box>
      <Typography as="span" className="teams-table-created-at">
        {new Date(createdAt)?.toLocaleDateString("en-GB").replaceAll("/", "-")}
      </Typography>
      <br />
      <Typography as="span" className="teams-table-created-at-time">
        {new Date(createdAt)?.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </Typography>
    </Box>
  );
};

export default TeamCreatedAtCell;
