import { useState } from "react";
import { useIntl } from "react-intl";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useProductOrAddonImage } from "../../../../hooks/useProductOrAddonImage";
import { useRequestDemoOrUpgrade } from "../../hooks/useRequestDemoOrUpgrade";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import LucButton from "shared/lucButton/lucButton";
import ProgressAndDetails from "../progressAndDetails";

const UsageAccordion = (props) => {
  const { item, expanded, handleExpand, setSnackbar } = props;

  const intl = useIntl();

  const [requested, setRequested] = useState(false);

  const { mutate: requestUpgrade, isPending: requestLoading } =
    useRequestDemoOrUpgrade();

  const handleRquestUpgrade = (upgrade_key) => {
    requestUpgrade(
      { queryData: { service_name: upgrade_key } },
      {
        onSuccess: () => {
          setRequested(true);
          setSnackbar({
            open: true,
            severity: "success",
            message: "subscriptions_request_upgrade_succes_message",
          });
        },
        onError: () =>
          setSnackbar({
            open: true,
            severity: "error",
            title: "subscriptions_request_upgrade_failed_title",
            message: "subscriptions_request_upgrade_failed_message",
          }),
      },
    );
  };

  return (
    <Accordion
      key={item?.key}
      id={`subscriptions-details-accordion-${item?.productKey}`}
      className="subscriptions-details-accordion"
      expanded={expanded === item?.productKey}
      onChange={() => handleExpand(item?.productKey)}
    >
      <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
        <Box className="subscriptions-details-accordion-summary">
          <Box className="subscriptions-details-accordion-title-wrapper">
            <Box component="span" className="logos-wrapper">
              {item?.logo?.map((logo) => (
                <img
                  key={logo}
                  src={useProductOrAddonImage(logo) || ""}
                  alt={logo || ""}
                />
              ))}
            </Box>
            <Box className="details-accordion-title-renewal-wrapper">
              <Typography className="details-accordion-title">
                {CheckValueLocale(item?.title, "", {}, intl)}
              </Typography>
              <Typography className="details-accordion-renewal">
                {CheckValueLocale("subscriptions_renewal_date", "", {}, intl)}:{" "}
                {item?.renewalDate}
              </Typography>
            </Box>
          </Box>
          <LucButton
            id="subscriptions-details-request-upgrade-btn"
            variant="outline"
            disabled={
              requested || !handlelUserRoles("GENERAL", "CREATE_REQUEST")
            }
            loading={requestLoading}
            onClick={(e) => {
              e.stopPropagation();
              !requested &&
                handlelUserRoles("GENERAL", "CREATE_REQUEST") &&
                handleRquestUpgrade(item?.upgradeKey, "upgrade");
            }}
          >
            {CheckValueLocale(
              requested
                ? "subscriptions_requested_upgrade"
                : "subscriptions_request_upgrade",
              "",
              {},
              intl,
            )}
          </LucButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails className="subscriptions-details-accordion-details">
        <Grid container spacing={4}>
          {item?.usage
            ?.filter((item) => !item?.hide)
            ?.map((usageItem) => (
              <Grid key={usageItem?.title} xs={6} item>
                <ProgressAndDetails
                  title={usageItem?.title || ""}
                  tooltip={usageItem?.tooltip || ""}
                  total={usageItem?.total || 0}
                  used={usageItem?.used || 0}
                />
              </Grid>
            ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default UsageAccordion;
