import * as Yup from "yup";

const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const userFormSchema = Yup.object().shape({
  first_name: Yup?.string()
    ?.trim()
    ?.required("this_field_required")
    ?.max(30, "Name_must_be_under_30_characters"),
  last_name: Yup?.string()
    ?.trim()
    ?.required("this_field_required")
    ?.max(30, "Name_must_be_under_30_characters"),
  mail: Yup?.string()
    ?.required("this_field_required")
    ?.matches(emailRegex, "please_enter_a_valid_email_address")
    ?.email("please_enter_a_valid_email_address"),
  phone: Yup?.string(),
  job_title: Yup?.string(),
});
