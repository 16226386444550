import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { TeamInformation } from "../../../components/teamInformation";
import WorkHourAndDay from "../../../components/workHourAndDay";

export const TeamsDetails = ({
  formik,
  isProductsDisabled,
  products,
  setWorkingHours,
  workingHours,
  tabsErrors,
  setTabsErrors,
  autoReplyMessages,
  setAutoReplyMessages,
  isMaxCharactersError,
}) => {
  const intl = useIntl();

  return (
    <Box className="add-team-section add-team-details">
      <Typography variant="h6" className="add-team-title">
        {CheckValueLocale("add_team_details", "", {}, intl)}
      </Typography>
      <TeamInformation
        formik={formik}
        isProductsDisabled={isProductsDisabled}
        options={products}
      />
      <Box className="team-members-section-block">
        <Box className="tabs-section-container">
          <WorkHourAndDay
            workingHours={workingHours}
            setWorkingHours={setWorkingHours}
            setTabsErrors={setTabsErrors}
            tabsErrors={tabsErrors}
            autoReplyMessages={autoReplyMessages}
            setAutoReplyMessages={setAutoReplyMessages}
            isMaxCharactersError={isMaxCharactersError}
          />
        </Box>
      </Box>
    </Box>
  );
};
