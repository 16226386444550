import { useQuery } from "@tanstack/react-query";
import NotificationsController from "services/controllers/notificationsController";

export const useNotificationsList = ({
  page,
  per_page,
  start_date,
  end_date,
  notification_type,
}) => {
  return useQuery({
    queryKey: [
      "notificationsList",
      page,
      start_date,
      end_date,
      notification_type,
    ],
    queryFn: () =>
      NotificationsController.getNotificationsList({
        page,
        per_page,
        start_date,
        end_date,
        notification_type,
      }),
    select: (data) => data?.data,
    retry: false,
  });
};
