import { useQuery } from "@tanstack/react-query";
import EngagementsController from "services/controllers/engagementsController";

export const useInboundRoutingsList = (assignmentInboundRouting) => {
  return useQuery({
    queryKey: ["inboundRoutingsList", assignmentInboundRouting],
    queryFn: () => EngagementsController.getInboundRoutings(),
    select: (data) => data?.data?.data,
    enabled: assignmentInboundRouting,
  });
};
