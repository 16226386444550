import { Box, Dialog } from "@mui/material";
import { useState } from "react";
import { SketchPicker } from "react-color";
import SectionBrandingHeader from "./sharedComponents/sectionBrandingHeader";
import { handlelUserRoles } from "utils/helpers";
import ColorBrandingSection from "./sharedComponents/ColorBrandingSection";

const ColorsBranding = ({ formik }) => {
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");

  const hexColorRegex = /^#[0-9A-Fa-f]{0,6}$/;
  const changeHandler = (e) => {
    const { name, value } = e?.target;
    if (handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")) {
      if (
        ["primary_color", "secondary_color", "tertiary_color"]?.includes(name)
      ) {
        if (hexColorRegex?.test(value)) formik.setFieldValue(name, value);
      } else formik.setFieldValue(name, value);
    }
  };

  const handleColorPicker = (keyColor) => {
    if (handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")) {
      if (!openColorPicker) {
        setOpenColorPicker(true);
        setSelectedColor(keyColor);
      } else {
        setOpenColorPicker(false);
        setSelectedColor("");
      }
    }
  };

  const handleColorChange = (color) => {
    if (handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")) {
      formik.setFieldValue(selectedColor, color?.hex);
    }
  };

  return (
    <Box className="branding-item">
      {openColorPicker ? (
        <Dialog
          open={openColorPicker}
          onClose={handleColorPicker}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <SketchPicker
            width="300px"
            color={formik?.values?.[selectedColor]}
            onChange={handleColorChange}
          />
        </Dialog>
      ) : null}
      <SectionBrandingHeader titleId={"branding_colors"} />

      <Box className="item-content colors-item">
        <ColorBrandingSection
          formik={formik}
          handleColorPicker={handleColorPicker}
          changeHandler={changeHandler}
          keyColor={"primary_color"}
        />
        <ColorBrandingSection
          formik={formik}
          handleColorPicker={handleColorPicker}
          changeHandler={changeHandler}
          keyColor={"secondary_color"}
        />

        <ColorBrandingSection
          formik={formik}
          handleColorPicker={handleColorPicker}
          changeHandler={changeHandler}
          keyColor={"tertiary_color"}
        />
      </Box>
    </Box>
  );
};

export default ColorsBranding;
