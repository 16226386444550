import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip } from "@mui/material";
import { Link } from "react-router-dom";

function BodyPopUpMonitorLink({ Monitors, getLinkMonitor }) {
  return (
    <>
      {Monitors?.map((monitor, index) => (
        <Link
          key={index}
          to={getLinkMonitor(monitor)}
          className="monitor-link-popup"
        >
          <Chip
            label={monitor?.name}
            onDelete={() => {}}
            deleteIcon={<FontAwesomeIcon icon={faExternalLink} />}
            color="primary"
            variant="outlined"
          />
        </Link>
      ))}
    </>
  );
}

export default BodyPopUpMonitorLink;
