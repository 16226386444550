import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";

export const usePublishAiAgent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id) => AiAgentsServices.publishAiAgent(id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["aiAgentDetails"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["aiAgentList"],
      });
    },
  });
};
