import { useQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useGetCompanyLanguages = () => {
  return useQuery({
    queryKey: ["companyLanguages"],
    queryFn: () => CompaniesController.getCompanyLanguages(),
    retry: false,
  });
};
