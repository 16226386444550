import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";

const opacityData = [40, 30, 20, 10];

const ColorBrandingSection = ({
  formik,
  handleColorPicker,
  changeHandler,
  keyColor,
}) => {
  const intl = useIntl();

  const shapeStyles = (color) => {
    return {
      bgcolor: `${color}`,
      width: 31,
      height: 28,
      borderRadius: 1,
      position: "relative",
      right: 9,
    };
  };

  // convert HEX code without opacity to HEX code with opacity
  const formatHexOpacity = (hexColor, opacity) => {
    if (hexColor?.startsWith("#")) hexColor = hexColor?.slice(1);
    if (hexColor?.length === 3)
      hexColor = hexColor
        ?.split("")
        ?.map((char) => char + char)
        ?.join("");

    if (hexColor?.toLowerCase() === "ffffff") {
      // Special case for white color
      if (opacity == 40) return `#FFFFFF`;
      else if (opacity == 30) return `#F6F6F6`;
      else if (opacity == 20) return `#F3F2F1`;
      else if (opacity == 10) return `#FCFCFC`;
    } else {
      const blendWithWhite = (color, opacity) =>
        Math?.round((1 - opacity) * 255 + opacity * color);

      const hexToRgb = (hex) => [
        parseInt(hex?.slice(0, 2), 16),
        parseInt(hex?.slice(2, 4), 16),
        parseInt(hex?.slice(4), 16),
      ];

      const rgbToHex = (rgb) =>
        `#${rgb
          ?.map((x) => x?.toString(16)?.padStart(2, "0"))
          ?.join("")
          ?.toUpperCase()}`;

      const rgb = hexToRgb(hexColor);
      const decimalOpacity = opacity / 100;
      const blendedRgb = rgb?.map((color) =>
        blendWithWhite(color, decimalOpacity),
      );

      return rgbToHex(blendedRgb);
    }
  };

  const handleSelectOpacity = (opacity) => {
    if (handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")) {
      formik.setFieldValue("opacity", opacity);
      formik.setFieldValue(
        "selected_tertiary_color",
        formatHexOpacity(formik?.values?.tertiary_color, opacity),
      );
    }
  };

  useEffect(() => {
    handleSelectOpacity(formik?.values?.opacity);
  }, [formik?.values?.tertiary_color]);

  return (
    <Box className="color-container">
      <Typography className="title">
        {CheckValueLocale(keyColor, "", {}, intl)}
      </Typography>
      <Box className="color-picker-container">
        <TextField
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton className="color-input" disableRipple>
                  <Box className="color-rectangle">
                    <Box
                      onClick={() => {
                        handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING") &&
                          handleColorPicker(keyColor);
                      }}
                      sx={shapeStyles(formik?.values?.[keyColor])}
                    />
                  </Box>
                </IconButton>
              </InputAdornment>
            ),
          }}
          name={keyColor}
          value={formik?.values?.[keyColor]}
          onChange={changeHandler}
        />
        {keyColor === "tertiary_color" ? (
          <Box className="color-opacity-boxs-select">
            {opacityData?.map((opacity, index) => {
              return (
                <Box
                  key={index}
                  className={`color-opacity-box ${formik?.values?.opacity == opacity ? "checked" : ""}`}
                  onClick={() => handleSelectOpacity(opacity)}
                >
                  <Box
                    style={{
                      background: `${formatHexOpacity(formik?.values?.tertiary_color, opacity)}`,
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default ColorBrandingSection;
