import { Box, Typography } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { AutoCompleteDropdown } from "../../addNewUser/components/multiSelectDropdown/autoCompleteDropdown/autoCompleteDropdown";
import { SelectDropdown } from "./components/selectDropdown/selectDropdown";

/**
 * SelectableTabContent component which is a part of AddNewUser component specially for tabs, which consists of two autocomplete components
 * it is used to select the main data and the sub data for a tab in the AddNewUser component
 *
 */
export const SelectableTabContent = ({
  tabName = "",
  title,
  mainOptions = [],
  mainLabel = "",
  mainNameKey,
  isMainDataMultiple = false,
  subOptions = [],
  subLabel = "",
  tabsData = {},
  setTabsData = () => {},
  isLoading = false,
  mainEmptyListMessage = "",
  ...props
}) => {
  const intl = useIntl();
  const tabData = tabsData?.[tabName] || [];
  const selectedIds = tabData?.map((row) => row?.value?.id);
  const filteredMainOptions = mainOptions?.filter(
    (option) => !selectedIds?.includes(option?.id),
  );
  const [lastRowValue, setLastRowValue] = useState({});
  const handleSelectDataChangeMain = (newValue, index) => {
    const newTabData = [...tabData];
    newTabData[index] = { value: newValue, subValue: "" };
    setTabsData({
      ...tabsData,
      [tabName]: newTabData,
    });
  };
  const handleSelectDataChangeSub = (event, index) => {
    const newValue = event.target.value;
    const newTabData = [...tabData];
    newTabData[index].subValue = newValue;
    setTabsData({
      ...tabsData,
      [tabName]: newTabData,
    });
  };
  const handleRemoveRow = (index) => {
    const rowData = tabData?.[index];
    // if only one row and has data, clear it
    if (tabData?.length === 1 && (rowData?.value?.id || rowData?.subValue)) {
      setTabsData({
        ...tabsData,
        [tabName]: [{}],
      });
      return;
    } else if (tabData?.length > 1) {
      // if more than one row and has data, remove it
      const newTabData = tabData?.filter((row, i) => i !== index);
      setTabsData({
        ...tabsData,
        [tabName]: newTabData,
      });
      return;
    }
  };
  const handleAddRow = () => {
    const newTabData = [...tabData, {}];
    setTabsData({
      ...tabsData,
      [tabName]: newTabData,
    });
  };
  useEffect(() => {
    if (tabData?.length) {
      setLastRowValue(tabData[tabData.length - 1]);
    }
  }, [tabsData]);
  return (
    <Box className="selectable-tab-content-container">
      <Typography variant="body2" className="selectable-tab-content-title">
        {title}
      </Typography>
      {tabData?.map((tab, index) => {
        const isSelectDisabled = !tab?.value?.id;
        return (
          <Box className="selectable-tab-content-row" key={index}>
            <AutoCompleteDropdown
              label={mainLabel}
              options={filteredMainOptions}
              value={tab?.value || null}
              onChange={(newValue) =>
                handleSelectDataChangeMain(newValue, index)
              }
              isLoading={isLoading}
              loadingMessage={CheckValueLocale(
                "dropdown_loading",
                "",
                {},
                intl,
              )}
              multiple={isMainDataMultiple}
              nameKey={mainNameKey}
              emptyListMessage={mainEmptyListMessage}
              {...props}
            />
            <SelectDropdown
              label={subLabel}
              options={subOptions}
              value={tab?.subValue || ""}
              onChange={(newValue) =>
                handleSelectDataChangeSub(newValue, index)
              }
              disabled={isSelectDisabled}
            />
            <LucButton
              variant="outline"
              type="red"
              minWidth={40}
              className="icon-tab-button"
              onClick={() => handleRemoveRow(index)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </LucButton>
          </Box>
        );
      })}
      <Box>
        <LucButton
          minWidth={40}
          className="icon-tab-button"
          disabled={!lastRowValue?.value?.id}
          onClick={handleAddRow}
        >
          <FontAwesomeIcon icon={faPlus} />
        </LucButton>
      </Box>
    </Box>
  );
};
