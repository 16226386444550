import { useQuery } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useGetLucidyaApisTypes = () => {
  return useQuery({
    queryKey: ["companyLucidyaApisTypes"],
    queryFn: () => SettingsController.getLucidyaApisTypes(),
    select: (data) =>
      data?.data?.map((item) => ({
        name: item?.name,
        id: item?.id,
      })),

    retry: false,
  });
};
