import MultiSelectDropdown from "../../addNewUser/components/multiSelectDropdown/MultiSelectDropdown";
import { AdvancedTabs, InboxTabs } from "../../shardComponents/tabs";
import { Box } from "@mui/material";

export const TeamsAndTabs = ({
  advancedTabsData,
  setAdvancedTabsData,
  infoTabsData,
  setInfoTabsData,
  tabsErrors,
  setTabsErrors,
  teamsData,
  setTeamsData,
}) => {
  return (
    <Box className="edit-user-main-section-box">
      <MultiSelectDropdown teamsData={teamsData} setTeamsData={setTeamsData} />
      <AdvancedTabs
        advancedTabsData={advancedTabsData}
        setAdvancedTabsData={setAdvancedTabsData}
        tabsErrors={tabsErrors}
        setTabsErrors={setTabsErrors}
      />
      <InboxTabs
        infoTabsData={infoTabsData}
        setInfoTabsData={setInfoTabsData}
        tabsErrors={tabsErrors}
        setTabsErrors={setTabsErrors}
      />
    </Box>
  );
};
