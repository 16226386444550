import { useMutation, useQueryClient } from "@tanstack/react-query";
import InstagramHashtagsController from "services/controllers/instagramHashtagsController";

export const useDeleteHashtag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ hashtagId }) =>
      InstagramHashtagsController.deletHashtag(hashtagId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["companyHashtags"],
      });
      queryClient.invalidateQueries({
        queryKey: ["headerHashtags"],
      });
    },
  });
};
