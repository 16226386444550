import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import UserForm from "../../shardComponents/userForm/UserForm";

const StepOne = ({ formik }) => {
  const intl = useIntl();

  return (
    <Box className="main-step-one">
      <Box className="main-step-tit">
        {CheckValueLocale("add_user_det", "", {}, intl)}
      </Box>
      <UserForm formik={formik} />
    </Box>
  );
};
export default StepOne;
