import { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Pagination, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { CheckValueLocale } from "utils/helpers";
import { generateHistoricalTableBody } from "./generateHistoricalTableBody";
import SharedTable from "shared/table/sharedTable";
import NoDataFoundProducts from "components/no-Data";
import NoDataLinks from "components/no-Data/noDataLinks";
import LucButton from "shared/lucButton/lucButton";
import CreateHistoricalRequest from "../createHistoricalRequest";

const HistoricalRequestTable = (props) => {
  const intl = useIntl();

  const [openCreateModal, setOpenCreateModal] = useState(false);

  const {
    isSortableLoading,
    historicalRequestsData,
    page,
    setPage,
    tableHeaders,
    setSnackbar,
    remainNewsBlogs,
    remainPosts,
    remainInteractions,
    processingIndicator,
    cancellableDatasources,
  } = props;

  return (
    <Box>
      <Box id="table-title-and-btn-wrapper">
        <Typography className="historical-request-table-title">
          {CheckValueLocale(
            "historical_request_table_title",
            "",
            { num: historicalRequestsData?.pagination?.count || 0 },
            intl,
          )}
        </Typography>
        <LucButton
          id="create-historical-request-btn"
          startIcon={<FontAwesomeIcon icon={faPlus} />}
          disabled={
            processingIndicator !== undefined ? processingIndicator : true
          }
          onClick={() => {
            if (processingIndicator !== undefined && !processingIndicator)
              setOpenCreateModal(true);
          }}
        >
          {CheckValueLocale(
            "historical_request_create_btn_title",
            "",
            {},
            intl,
          )}
        </LucButton>
      </Box>

      <SharedTable
        isSharedStyle
        loading={isSortableLoading}
        tableHeader={tableHeaders || []}
        tableBody={
          generateHistoricalTableBody(
            historicalRequestsData,
            processingIndicator,
            cancellableDatasources,
            intl,
          ) || []
        }
        emptyBody={
          <Box className="empty-item">
            <NoDataFoundProducts
              image="emptyHR"
              title="request_historical_data"
              description="request_historical_data_dec"
              linkOne="how_to_use"
              linkOneHref={NoDataLinks?.historical_request_link}
              orCase="or_visit_the"
              linkTwo="knowledge_base"
            />
          </Box>
        }
      />
      {historicalRequestsData?.pagination?.pages > 1 && !isSortableLoading ? (
        <Pagination
          onChange={(e, value) => setPage(value)}
          page={page}
          className="pagination-monitor-list settings-page-content-pagination"
          count={historicalRequestsData?.pagination?.pages}
          variant="outlined"
        />
      ) : null}

      {/* Create Historical Request Popup */}
      {openCreateModal ? (
        <CreateHistoricalRequest
          openCreateModal={openCreateModal}
          setOpenCreateModal={setOpenCreateModal}
          setPageSnackbar={setSnackbar}
          remainNewsBlogs={remainNewsBlogs}
          remainPosts={remainPosts}
          remainInteractions={remainInteractions}
        />
      ) : null}
    </Box>
  );
};

export default HistoricalRequestTable;
