import { useState } from "react";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { useRequestDemoOrUpgrade } from "../../subscriptionsPage/hooks/useRequestDemoOrUpgrade";
import LucButton from "shared/lucButton/lucButton";
import IntercomIntegrationPopup from "./intercomIntegrationPopup";
import ZendeskIntegrationPopup from "./zendeskIntegrationPopup";

const IntegrationItem = (props) => {
  const { item, setSnackbar } = props;

  const intl = useIntl();

  const [dialogOpened, setDialogOpened] = useState(false);
  const [requested, setRequested] = useState(false);

  const { mutate: requestDemo, isPending: requestLoading } =
    useRequestDemoOrUpgrade();

  const handleRequestDemo = () => {
    requestDemo(
      { queryData: { service_name: item?.demoKey } },
      {
        onSuccess: () => {
          setRequested(true);
          setSnackbar({
            open: true,
            severity: "success",
            title: "",
            message:
              item?.title === "Intercom"
                ? "third_party_requested_intercom"
                : "third_party_requested_zendesk",
          });
        },
        onError: () =>
          setSnackbar({
            open: true,
            severity: "error",
            title: "something_went_wrong",
            message: "try_again",
          }),
      },
    );
  };

  return (
    <Box className="third-party-integrations-item">
      <Box className="item-logo-wrapper">
        <img src={item?.icon} alt={item?.title} />
      </Box>
      <Typography variant="h5" className="integration-item-title">
        {item?.title}
      </Typography>
      <Typography className="integration-item-desc">
        {CheckValueLocale(item?.description, "", {}, intl)}
      </Typography>

      {item?.active ? (
        <LucButton
          id={`third-party-integrations-configure-${item?.title?.toLowerCase()}-btn`}
          variant="outline"
          disabled={!handlelUserRoles("GENERAL", "CREATE_INTEGRATION")}
          onClick={() =>
            handlelUserRoles("GENERAL", "CREATE_INTEGRATION") &&
            setDialogOpened(true)
          }
          fullWidth
        >
          {CheckValueLocale("configure", "", {}, intl)}
        </LucButton>
      ) : (
        <LucButton
          id={`third-party-integrations-request-${item?.title?.toLowerCase()}-btn`}
          className={`third-party-request-btn ${requested ? "requested" : ""}`}
          variant="outline"
          disabled={
            !handlelUserRoles("GENERAL", "CREATE_INTEGRATION") || requested
          }
          loading={requestLoading}
          onClick={
            !requested &&
            !requestLoading &&
            handlelUserRoles("GENERAL", "CREATE_INTEGRATION") &&
            handleRequestDemo
          }
          fullWidth
        >
          {CheckValueLocale(
            requested ? "third_party_requested" : "third_party_request",
            "",
            {},
            intl,
          )}
        </LucButton>
      )}

      {dialogOpened ? (
        item?.title?.toLowerCase() === "intercom" ? (
          <IntercomIntegrationPopup
            dialogOpened={dialogOpened}
            setDialogOpened={setDialogOpened}
            setSnackbar={setSnackbar}
          />
        ) : (
          <ZendeskIntegrationPopup
            dialogOpened={dialogOpened}
            setDialogOpened={setDialogOpened}
            setSnackbar={setSnackbar}
          />
        )
      ) : null}
    </Box>
  );
};

export default IntegrationItem;
