import React, { useEffect, useState } from "react";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import "../../publicAccount/twitterAccountAnalysis/customerCare/customerCare.scss";
import { faComments, faEye, faBolt } from "@fortawesome/pro-solid-svg-icons";
import { useIntl } from "react-intl";
import {
  faHeart,
  faRetweet,
  faGlobeAsia,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../publicAccount/twitterAccountAnalysis/customerCare/customerCare.scss";
import Reactions from "images/monitor_revamp/fb_react.svg";
import { useParams } from "react-router-dom";
import "./questionCard.scss";
import { numberFormatter, CheckValueLocale } from "utils/helpers/index";
import SentimentCategoriesThemes from "components/sentimentCategoriesThemes";
import { useEditSentiment } from "pages/channelAnalytics/InsightsPage/hooks/useEditSentiment";
import { useEditCategories } from "pages/channelAnalytics/InsightsPage/hooks/useEditCategories";
import { useEditThemes } from "pages/channelAnalytics/InsightsPage/hooks/useEditThemes";
import angryIcon from "images/reaction-icons/angry.svg";
import careIcon from "images/reaction-icons/care.svg";
import hahaIcon from "images/reaction-icons/haha.svg";
import likeIcon from "images/reaction-icons/like.svg";
import loveIcon from "images/reaction-icons/love.svg";
import newsIcon from "images/reaction-icons/newsIcon.svg";
import sadIcon from "images/reaction-icons/sad.svg";
import wowIcon from "images/reaction-icons/wow.svg";

const CardFooter = (props) => {
  const {
    question,
    showHeart,
    showRetweet,
    showFlash,
    showEye,
    showEarth,
    monitorDataSource,
    monitorType,
    getMoreInsights,
    insightsID,
    response,
    dates,
    datasourcePostsType,
    isAggregateDashboard,
    hideChangeSentiment,
    hideWhenRetweeted,
    activeTab,
    isLiveDashboardPublic,
    categoriesOptions,
    themesOptions,
    sentimentsOptions,
    isLiveDashboard,
    isQuickSearch,
  } = props;
  const intl = useIntl();
  const { monitorId, monitorType: monitorTypeName } = useParams();

  let reactionCount = 0;
  const reactionsValue =
    question?.reactions && typeof question?.reactions === "object"
      ? Object.values(question.reactions)
      : question?.reactions;

  if (reactionsValue) {
    if (typeof question?.reactions === "object") {
      for (let i = 0; i < reactionsValue.length; i++) {
        reactionCount += reactionsValue[i];
      }
    } else {
      reactionCount = question?.reactions;
    }
  }
  const [selectedSentiment, setSelectedSentiment] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedThemes, setSelectedThemes] = useState([]);
  // __________________________________________________________________________

  useEffect(() => {
    // Set selected sentiment with its ID
    if (sentimentsOptions?.[question?.sentiment] !== undefined)
      setSelectedSentiment(sentimentsOptions?.[question?.sentiment]);
    else setSelectedSentiment(null);
    // Set selected Categories and Themes
    if (question?.themes) setSelectedCategories(question?.themes);
    if (question?.sub_themes) setSelectedThemes(question?.sub_themes);
  }, [question, sentimentsOptions]);

  // __________________________________________________________________________

  const { mutate: editSentiment, isPending: isSentimentPending } =
    useEditSentiment();

  const handleEditSentiment = (sentimentId) => {
    const dataSource =
      isLiveDashboard || isAggregateDashboard
        ? datasourcePostsType === "NEWSBLOGS"
          ? "nb"
          : datasourcePostsType?.toLowerCase()
        : monitorDataSource === "NEWSBLOGS"
          ? "nb"
          : monitorDataSource?.toLowerCase();

    editSentiment(
      {
        apiVars: `social_listening/${dataSource}`,
        queryData: {
          id: question?.tweet_id || question?.id,
          monitor_id: monitorId || question?.tracker_id,
          data_source_id: question?.CA_channel_id,
          product_id: +window.localStorage.sm_id,
          old_label: selectedSentiment,
          new_label: sentimentId,
          created_at: question?.created_at,
        },
      },
      { onSuccess: () => setSelectedSentiment(sentimentId) },
    );
  };

  const localizedDate =
    window.localStorage.lang === "en"
      ? moment
          .unix(parseInt(question?.created_at))
          .utc()
          .format("D MMM, YYYY h:mm A")
      : moment
          .unix(question?.created_at)
          .utc()
          .format("D MMM, YYYY h:mm A")
          ?.split(" ")[0] +
        " " +
        CheckValueLocale(
          moment
            .unix(question?.created_at)
            .utc()
            .format("D MMM, YYYY h:mm A")
            ?.split(" ")[1],
          "",
          {},
          intl,
        ) +
        " " +
        moment
          .unix(question?.created_at)
          .utc()
          .format("D MMM, YYYY h:mm A")
          ?.split(" ")[2];
  const localizedTime =
    window.localStorage.lang === "en"
      ? ""
      : moment
          ?.unix(question?.created_at)
          ?.utc()
          ?.format("D MMM , YYYY h:mm A")
          ?.split(" ")[4] +
        " " +
        moment
          ?.unix(question?.created_at)
          ?.utc()
          ?.format("D MMM , YYYY h:mm A")
          ?.split(" ")[5];

  const reactionImages = {
    angry: angryIcon,
    care: careIcon,
    haha: hahaIcon,
    like: likeIcon,
    love: loveIcon,
    news: newsIcon,
    sad: sadIcon,
    wow: wowIcon,
  };

  const { mutate: editThemes, isPending: isThemesPending } = useEditThemes();
  const { mutate: editCategories, isPending: isCategoriesPending } =
    useEditCategories();

  const categorieThemesDataSource = isLiveDashboard
    ? datasourcePostsType?.toLowerCase()
    : isAggregateDashboard
      ? datasourcePostsType === "NEWSBLOGS"
        ? "nb"
        : datasourcePostsType?.toLowerCase()
      : monitorDataSource?.toLowerCase();

  const handleSelectCategory = (updatedCategories) => {
    editCategories(
      {
        apiVars:
          monitorDataSource == "NEWSBLOGS" ||
          (isAggregateDashboard && datasourcePostsType === "NEWSBLOGS") ||
          (isLiveDashboard && datasourcePostsType === "NEWSBLOGS")
            ? `social_listening/nb`
            : `channel_analytics/social_media/${categorieThemesDataSource}/public/interactions`,
        queryData: {
          id: question?.tweet_id || question?.id,
          comment_id: question?.tweet_id || question?.id,
          tracker_id: question?.tracker_id,
          product_id: +window.localStorage.sm_id,
          data_source_id: question?.CA_channel_id,
          old_label: selectedCategories || [],
          new_label: updatedCategories,
          created_at: question?.created_at,
        },
      },
      { onSuccess: () => setSelectedCategories(updatedCategories) },
    );
  };

  const handleSelectTheme = (updatedThemes) => {
    editThemes(
      {
        apiVars:
          monitorDataSource == "NEWSBLOGS" ||
          (isAggregateDashboard && datasourcePostsType === "NEWSBLOGS") ||
          (isLiveDashboard && datasourcePostsType === "NEWSBLOGS")
            ? `social_listening/nb`
            : `channel_analytics/social_media/${categorieThemesDataSource}/public/interactions`,
        queryData: {
          id: question?.tweet_id || question?.id,
          comment_id: question?.tweet_id || question?.id,
          tracker_id: question?.tracker_id,
          product_id: +window.localStorage.sm_id,
          data_source_id: question?.CA_channel_id,
          old_label: selectedThemes || [],
          new_label: updatedThemes,
          created_at: question?.created_at,
        },
      },
      { onSuccess: () => setSelectedThemes(updatedThemes) },
    );
  };

  return (
    <Box
      mt={
        (monitorDataSource === "INSTAGRAM" ||
          datasourcePostsType === "INSTAGRAM") &&
        3
      }
      className="card-footer"
    >
      <Box className="question-card-footer">
        <Box className="statistics-icons">
          {/* case to show when montiorType =keyword & reaction == 0 */}
          {question?.reactions == 0 &&
            (monitorDataSource === "FACEBOOK" ||
              datasourcePostsType === "FACEBOOK") &&
            monitorType === "keyword" && (
              <img
                src={Reactions}
                alt="reactions"
                className="reactions-style"
              />
            )}
          {question?.reactions && (
            <Box className="display-in-row">
              {Object.keys(question?.reactions).map((reaction) => {
                return (
                  <img
                    src={reactionImages[reaction]}
                    alt="reactions"
                    className="img-style"
                  />
                );
              })}
              {(reactionsValue.length === 0 ||
                typeof reactionsValue !== "object") && (
                <img
                  src={Reactions}
                  alt="reactions"
                  className="reactions-style"
                />
              )}
              {(typeof question?.reactions === "object" &&
                reactionsValue.length) ||
              (typeof question?.reactions === "number") > 0
                ? reactionCount.toLocaleString()
                : 0}
            </Box>
          )}
          {showRetweet && question.retweet_count !== undefined && (
            <Box className="post-footer-icon">
              <FontAwesomeIcon
                icon={faRetweet}
                id="monitors-question-card-retweet-fa-icon"
              />
              {question.retweet_count !== null
                ? parseInt(question?.retweet_count)?.toLocaleString()
                : 0}
            </Box>
          )}
          {showHeart && question.heart_count !== undefined && (
            <Box className="post-footer-icon">
              <FontAwesomeIcon
                icon={faHeart}
                id="monitors-question-card-heart-fa-icon"
              />
              {typeof question?.heart_count === "object" ||
              isNaN(question?.heart_count)
                ? 0
                : parseInt(question?.heart_count)?.toLocaleString()}
            </Box>
          )}
          <Box className="post-footer-icon">
            <FontAwesomeIcon
              icon={faComments}
              id="monitors-question-card-comments-fa-icon"
            />
            {question?.comments_count !== null
              ? parseInt(question?.comments_count)?.toLocaleString()
              : 0}
          </Box>

          {showEye && question?.alexa_pageviews && (
            <Box>
              <FontAwesomeIcon
                icon={faEye}
                id="monitors-question-card-eye-fa-icon"
              />
              {Math.round(
                parseInt(question?.alexa_pageviews / 1000),
              )?.toLocaleString()}
              {parseInt(question?.alexa_pageviews) !== 0 && "k"}
            </Box>
          )}
          {insightsID &&
            insightsID?.indexOf(question.tweet_id) !== -1 &&
            response?.length !== 0 && (
              <Box>
                <FontAwesomeIcon
                  icon={faEye}
                  id="monitors-question-card-tweets-eye-fa-icon"
                />
                {monitorDataSource === "FACEBOOK" ||
                datasourcePostsType === "FACEBOOK"
                  ? numberFormatter(
                      response[insightsID.indexOf(question.tweet_id)]?.reach[0]
                        ?.value,
                    )
                  : numberFormatter(
                      response[insightsID.indexOf(question.tweet_id)]?.reach,
                    )}
              </Box>
            )}

          {(question?.total_engagement || question?.reach) && (
            <Divider orientation="vertical" className="footer-divider" />
          )}
          {showFlash && (
            <Box>
              <FontAwesomeIcon
                icon={faBolt}
                id="monitors-question-card-bolt-fa-icon"
              />
              {parseInt(question?.total_engagement)?.toLocaleString()}
            </Box>
          )}
          {showEarth && question?.reach && (
            <Box>
              <FontAwesomeIcon
                icon={faGlobeAsia}
                id="monitors-question-card-global-asia-fa-icon"
              />

              {parseInt(question?.reach)?.toLocaleString()}
            </Box>
          )}
          {insightsID &&
            insightsID?.indexOf(question.tweet_id) !== -1 &&
            response?.length !== 0 && (
              <Box>
                <FontAwesomeIcon
                  icon={faGlobeAsia}
                  id="monitors-question-card-global-asia-fa-icon"
                />
                {monitorDataSource === "FACEBOOK" ||
                datasourcePostsType === "FACEBOOK"
                  ? parseInt(
                      response[insightsID.indexOf(question.tweet_id)]
                        ?.impressions[0]?.value,
                    )?.toLocaleString()
                  : parseInt(
                      response[insightsID.indexOf(question.tweet_id)]
                        ?.impressions,
                    )}
              </Box>
            )}
          {localizedDate && (
            <Divider orientation="vertical" className="footer-divider" />
          )}

          <Box className="question-footer-date">
            <Box className="display-in-row">
              <Box className="post-date ltr-direction">
                {localizedDate}
                {localizedTime ? (
                  <Box className="post-time ltr-direction">{localizedTime}</Box>
                ) : null}
              </Box>
            </Box>
          </Box>
          {!hideChangeSentiment && !hideWhenRetweeted && (
            <Divider
              orientation="vertical"
              sx={{ ml: 1 }}
              className="footer-divider"
            />
          )}
          {!hideChangeSentiment && !hideWhenRetweeted && (
            <SentimentCategoriesThemes
              sentimentData={{
                btnId: "question-card-sentiment-btn",
                options: sentimentsOptions,
                selected: selectedSentiment,
                handleChange: handleEditSentiment,
                loading: isSentimentPending,
                disabled: isLiveDashboardPublic || isQuickSearch,
              }}
            />
          )}
        </Box>
        {insightsID && insightsID?.indexOf(question.tweet_id) !== -1 && (
          <Box display="flex" component={"span"}>
            <Typography margin={0.5} variant={"caption"} color={"#757575"}>
              {CheckValueLocale("last_update", "", {}, intl)}
              {dates[insightsID.indexOf(question.tweet_id)]}
            </Typography>
            <Tooltip
              title={CheckValueLocale("update_insights", "", {}, intl)}
              placement="top"
              arrow
            >
              <Box component="span">
                <FontAwesomeIcon
                  icon={faSync}
                  className="more-insights"
                  onClick={() =>
                    getMoreInsights(
                      question,
                      response,
                      insightsID,
                      dates,
                      true,
                      undefined,
                      datasourcePostsType,
                    )
                  }
                  id="monitors-question-card-more-insights-fa-icon"
                />
              </Box>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Box className="question-theme">
        {(question?.themes && question?.themes?.length > 0) ||
        (monitorTypeName === "KEYWORD" && activeTab === "posts") ||
        activeTab === "comments_mentions" ||
        activeTab === "questions" ||
        activeTab === "engagements" ||
        activeTab === "customer_care" ? (
          <SentimentCategoriesThemes
            categoriesData={{
              btnId: "question-card-categories-btn",
              options: categoriesOptions,
              selected: selectedCategories,
              handleChange: handleSelectCategory,
              loading: isCategoriesPending,
              disabled: isLiveDashboardPublic || isQuickSearch,
            }}
            themesData={{
              btnId: "question-card-themes-btn",
              options: themesOptions,
              selected: selectedThemes,
              handleChange: handleSelectTheme,
              loading: isThemesPending,
              disabled: isLiveDashboardPublic || isQuickSearch,
            }}
            dividers={[1]}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default CardFooter;
