import { useQuery } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useHistoricalRequestMonitors = ({ productId, dataSourceId }) => {
  return useQuery({
    queryKey: ["historicalRequestMonitors", productId, dataSourceId],
    queryFn: async () =>
      SettingsController.getHistoricalRequestMonitors({
        product_id: productId,
        data_source_id: dataSourceId,
      }),
    enabled: !!productId && !!dataSourceId,
    select: (data) => data?.data?.monitors?.data || [],
    retry: false,
  });
};
