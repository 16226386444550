import { Box } from "@mui/material";

import { TeamMembers } from "../../components/teamMembers";
import { useShowMore } from "hooks/useShowMore";
import { ShowMoreButton } from "../../components/showMoreButton";
import { useGetTeamMembersForEdit } from "../../hooks/useGetTeamMembersForEdit";

export const EditTeamMembers = ({
  members,
  setMembers,
  teamId,
  handleSelectMember,
  selectedMembers,
}) => {
  const {
    isSettled,
    setIsSettled,
    setShowedData: setShowedMembers,
    showedData: showedMembers,
    showMore,
    setShowMore,
    onShowLess,
    nextPage,
    hasPagination,
  } = useShowMore({
    data: members?.data,
    pagination: members?.pagination,
  });

  const {
    mutate: handleTeamMembersForEdit,
    isPending: isTeamMemberForEditLoading,
  } = useGetTeamMembersForEdit();

  const onShowMore = () => {
    if (isSettled) {
      setShowedMembers(members?.data);
      setShowMore(false);
      return;
    }
    handleTeamMembersForEdit(
      {
        id: teamId,
        nextPage,
      },
      {
        onSuccess: (res) => {
          const newMembers = res?.data;
          const newMembersData = [...members?.data, ...newMembers?.data];
          const nextPage = newMembers?.pagination?.next;
          setMembers({
            data: newMembersData,
            pagination: newMembers?.pagination,
          });
          setShowedMembers(newMembersData);
          if (!nextPage) {
            setIsSettled(true);
            setShowMore(false);
          }
        },
      },
    );
  };
  return (
    <Box className="edit-team-details-section">
      <TeamMembers
        members={showedMembers}
        selectable={true}
        title="team_members"
        selectedMembers={selectedMembers}
        handleSelectMember={handleSelectMember}
        overallCount={members?.pagination?.count}
      />
      <ShowMoreButton
        show={hasPagination}
        loading={isTeamMemberForEditLoading}
        hasMore={showMore}
        onShowLess={onShowLess}
        onShowMore={onShowMore}
      />
    </Box>
  );
};
