import { useQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useGetUserActivityLogs = (
  start_date,
  end_date,
  page,
  user_ids,
  products,
  team_id,
) => {
  return useQuery({
    queryKey: [
      "userActivityLogs",
      start_date,
      end_date,
      user_ids,
      products,
      page,
      team_id,
    ],
    queryFn: () =>
      CompaniesController.getUserActivityLogs(
        start_date,
        end_date,
        user_ids,
        products,
        page,
        team_id,
      ),
    enabled: !!start_date && !!end_date && !!page,
    select: (data) => data?.data?.data,
    retry: false,
  });
};
