import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const TeamNameCell = ({ name, id }) => {
  return (
    <Box>
      <Link
        to={{
          pathname: `/settings/teams/view/${id}`,
          state: { id: name },
        }}
        className="teams-table-name"
      >
        {name}
      </Link>
    </Box>
  );
};
export default TeamNameCell;
