import { Grid, Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import CardHeader from "./cardHeader";
import CardData from "./cardData";

const SurveyCards = (props) => {
  const history = useHistory();

  const handleCardClick = (survey) => {
    if (survey?.status === "draft") {
      history?.push({
        pathname: `/survey/add-survey/survey-builder/${survey?.id}`,
      });
    } else {
      history?.push({
        pathname: `/survey/survey-insights/${survey?.id}`,
        state: survey,
      });
    }
  };

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {props?.surveys?.map((survey, i) => (
        <Grid item xs={6} key={i} className="survey-card-body">
          <Box className="survey-card">
            {/* Adding onClick on overlay to avoid going into e.stopPropagation for each button and popup functionallity inside CardHeader */}
            <Box
              className="survey-card-overlay"
              onClick={() => handleCardClick(survey)}
            />

            <CardHeader
              survey={survey}
              getSurveys={props?.getSurveys}
              surveyLimitReach={props?.surveyLimitReach}
            />
            <CardData survey={survey} />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
export default SurveyCards;
