import PopupModal from "components/popupModal";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import "./createSLAPopup.scss";
import BodyCreatePopup from "./bodyCreatePopup";
import { useEffect, useState } from "react";
import { useCreateSLA } from "../hooks/useCreateSLA";
import SnackBar from "components/snackBar";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import LucButton from "shared/lucButton/lucButton";

const defaultSLAValues = {
  first_response_time: {
    value: 0,
    unit: "minutes",
  },
  next_response_time: {
    value: 0,
    unit: "minutes",
  },
  time_to_complete: {
    value: 0,
    unit: "minutes",
  },
  total_unassigned_time: {
    value: 0,
    unit: "minutes",
  },
};

function CreateSLAPopup({
  openCreateSlaPopup,
  setOpenCreateSlaPopup,
  onCreateNewSLASuccess,
}) {
  const intl = useIntl();
  const [slaName, setSlaName] = useState("");
  const [slaValues, setSlaValues] = useState(defaultSLAValues);
  const [isValuesValid, setIsValuesValid] = useState(false);
  const [errorMsgName, setErrorMsgName] = useState("");
  const [createSLASnackbar, setCreateSLASanckbar] = useState({
    message: "",
    severity: "",
    title: "",
    openSnakbar: false,
  });

  const { mutate: createSLA, isPending: isLoading } = useCreateSLA();

  const confirmCreateSLA = () => {
    createSLA(
      { queryData: { name: slaName, ...slaValues } },
      {
        onSuccess: () => {
          setCreateSLASanckbar({
            message: "your_slas_created_successfully",
            severity: "success",
            openSnackBar: true,
          });
          resetValues();
          onCreateNewSLASuccess && onCreateNewSLASuccess();
        },
        onError: (error) => {
          const isNameDuplicate =
            error?.errorMsg?.response?.data?.exception?.message?.[0] ===
            "Name has already been taken";
          if (isNameDuplicate) {
            setErrorMsgName("routing_name_already_exist");
          } else {
            setCreateSLASanckbar({
              message: "try_again_error_message",
              severity: "error",
              title: "failed_error_message",
              openSnackBar: true,
            });
          }
        },
      },
    );
  };

  const handleCloseSnackBar = () => {
    setCreateSLASanckbar({
      openSnackBar: false,
    });
  };
  const resetValues = () => {
    setOpenCreateSlaPopup(null);
    setSlaName("");
    setSlaValues(defaultSLAValues);
    setErrorMsgName("");
  };
  const closeCreateSlaPopup = () => {
    if (isLoading) return;
    resetValues();
  };

  useEffect(() => {
    const isNameValid = slaName?.trim()?.length > 0;
    const isValuesValid = Object.values(slaValues)?.every(
      (value) => value?.value !== "" && value?.value >= 0,
    );
    setIsValuesValid(isNameValid && isValuesValid);
  }, [slaName, slaValues]);
  return (
    <>
      {openCreateSlaPopup && (
        <PopupModal
          open={openCreateSlaPopup || false}
          close={closeCreateSlaPopup}
          classeName="inbound-routing-popup-create-sla"
          addClasses="inbound-routing-popup-sla"
          modalStyle="inbound-routing-popup-form-body-sla"
          fixPadding={true}
          body={
            <Box className="inbound-routing-popup-content">
              <Box className="inbound-routing-popup-content-header">
                <Typography
                  variant="h4"
                  className="inbound-routing-popup-title"
                >
                  {CheckValueLocale("create_new_sla_title", "", {}, intl)}
                </Typography>
              </Box>
              <Box className="inbound-routing-popup-content-body">
                <BodyCreatePopup
                  slaName={slaName}
                  setSlaName={setSlaName}
                  slaValues={slaValues}
                  setSlaValues={setSlaValues}
                  errorMsgName={errorMsgName}
                  setErrorMsgName={setErrorMsgName}
                  disabled={isLoading}
                />
              </Box>
              <Box className="inbound-routing-popup-content-footer">
                <LucButton
                  variant="flat"
                  onClick={closeCreateSlaPopup}
                  type="secondary"
                  id="inbound-routing-popup-cancel-btn"
                >
                  {CheckValueLocale("cancel", "", {}, intl)}
                </LucButton>
                <LucButton
                  loading={isLoading}
                  disabled={!isValuesValid}
                  onClick={confirmCreateSLA}
                  id="inbound-routing-popup-create-btn"
                >
                  {CheckValueLocale("create", "", {}, intl)}
                </LucButton>
              </Box>
            </Box>
          }
        />
      )}
      <SnackBar
        open={createSLASnackbar?.openSnackBar}
        severity={createSLASnackbar?.severity}
        message={CheckValueLocale(createSLASnackbar?.message, "", {}, intl)}
        title={CheckValueLocale(createSLASnackbar?.title, "", {}, intl)}
        handleClose={handleCloseSnackBar}
      />
    </>
  );
}

export default CreateSLAPopup;
