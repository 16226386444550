import { createContext, useState } from "react";
import { useIntl } from "react-intl";

export const ExcelFileContext = createContext();

export const ExcelFileProvider = ({ children }) => {
  const intl = useIntl();
  const [fileExcelData, setFileExcelData] = useState([]);
  const [themesOptions, setThemesOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({
    id: intl?.locale === "en" ? "english" : "arabic",
    name: intl?.locale,
    disabled: false,
  });

  return (
    <ExcelFileContext.Provider
      value={{
        fileExcelData,
        setFileExcelData,
        selectedLanguage,
        setSelectedLanguage,
        themesOptions,
        setThemesOptions,
      }}
    >
      {children}
    </ExcelFileContext.Provider>
  );
};
