import { useMutation, useQueryClient } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useActiveUser = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId }) => CompaniesController.activeCompanyUser(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: id ? ["userViewInfo", id] : ["companyUsers"],
      });
    },
  });
};
