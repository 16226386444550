import { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import {
  CheckValueLocale,
  commasAfterDigit,
  handleUnixFormat,
} from "utils/helpers";
import faInstagram from "images/social-icons/ig-profile-icon.svg";
import PieChartWidget from "components/widgets/pieChartWidget";
import StackedBarWidget from "components/widgets/stackedBarWidget";
import LineChartWidget from "components/widgets/lineChartWidget";
import StackedLineWidget from "components/widgets/stackedLineWidget";
import BarChartWidget from "components/widgets/barChartWidget";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { faPercent, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import TableWidget from "components/widgets/tableWidget/tableWidget";
import QuestionCard from "pages/Monitors/Components/questionCard";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import ThemeSection from "pages/Monitors/Components/themeSection";
import PunchCardWidget from "components/widgets/punchCardWidget";
import publicInstagram from "images/social-icons/instagram-public-icon.svg";
import MediaInstagram from "components/media/media-insta/mediaInsta";
import DragHandleIcon from "../dragHandleIcon";

const Container = SortableContainer(({ children }) => {
  return (
    <Grid container spacing={1} className="live-dashboard-widget-container">
      {children}
    </Grid>
  );
});

const DragHandle = SortableHandle(() => <DragHandleIcon />);

const Element = SortableElement(
  ({
    widgetItem,
    showEditBtn,
    widgetSection,
    isLiveDashboardPublic,
    themesOptions,
  }) => {
    const intl = useIntl();
    if (widgetItem?.typeWidget === "pieChart") {
      return (
        <Grid
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          className="dashboard-widget-wrapper-column"
        >
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <PieChartWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              data={widgetItem?.data}
              subData={widgetItem?.subData}
              color={widgetItem?.color}
              areaStyle={widgetItem?.areaStyle}
              toolTipLabel={widgetItem?.toolTipLabel}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              showAI={widgetItem?.showAI}
              twitterIcon={widgetItem?.twitterIcon}
              pieChartPreLoaderDataSources={
                widgetItem?.pieChartPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              iconAfterTitle={widgetItem?.iconAfterTitle}
              inspectorName={widgetItem?.inspectorName}
              showReportIcon={widgetItem?.showReportIcon}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "lineChart") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <LineChartWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              totalLabel={widgetItem?.totalLabel}
              totalValue={widgetItem?.totalValue}
              data={widgetItem?.data}
              showGroupBy={widgetItem?.showGroupBy}
              toolTipLabel={widgetItem?.toolTipLabel}
              labelColor={widgetItem?.labelColor}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              bigImg={widgetItem?.bigImg}
              lineChartPreLoaderDataSources={
                widgetItem?.lineChartPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              iconAfterTitle={widgetItem?.iconAfterTitle}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              inspectorName={widgetItem?.inspectorName}
              haveData={widgetItem?.haveData}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "stackedLineChart") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <StackedLineWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              data={widgetItem?.data}
              showGroupBy={widgetItem?.showGroupBy}
              showScales={widgetItem?.showScales}
              areaStyle={widgetItem?.areaStyle}
              toolTipLabel={widgetItem?.toolTipLabel}
              showAI={widgetItem?.showAI}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              bigImg={widgetItem?.bigImg}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              inspectorName={widgetItem?.inspectorName}
              dataPiechart={widgetItem?.dataPiechart}
              hideLegend={widgetItem?.hideLegend}
              labelColors={widgetItem?.labelColors}
              isSwitchCase={widgetItem?.isSwitchCase}
              isSentimentAnalysisWidget={widgetItem?.isSentimentAnalysisWidget}
              isSentimentAnalysisShowCharts={
                widgetItem?.isSentimentAnalysisShowCharts
              }
              stackedLineWidgetPreLoaderDataSources={
                widgetItem?.stackedLineWidgetPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              iconAfterTitle={widgetItem?.iconAfterTitle}
              showGroupByClientAgent={widgetItem?.showGroupByClientAgent}
              isPostsReachImpression={widgetItem?.isPostsReachImpression}
              showReportIcon={widgetItem?.showReportIcon}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "stackedBarChart") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <StackedBarWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              bigImg={widgetItem?.bigImg}
              posts={widgetItem?.posts}
              colors={widgetItem?.colors}
              labels={widgetItem?.labels}
              showGroupBy={widgetItem?.showGroupBy}
              showScales={widgetItem?.showScales}
              showZoomPercentage={widgetItem?.showZoomPercentage}
              postsColumnPreLoaderDataSources={
                widgetItem?.postsColumnPreLoaderDataSources
              }
              hideLegend={widgetItem?.hideLegend}
              dataPiechart={widgetItem?.dataPiechart}
              showChartDetailsWidget={widgetItem?.showChartDetailsWidget}
              statsDetailsData={widgetItem?.statsDetailsData}
              isInteractionStatsWrapperStyle={
                widgetItem?.isInteractionStatsWrapperStyle
              }
              reachFunnel={widgetItem?.reachFunnel}
              iconAfterTitle={widgetItem?.iconAfterTitle}
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              showPostsInteractionStats={widgetItem?.showPostsInteractionStats}
              reachFunnelAccount={widgetItem?.reachFunnelAccount}
              showReportIcon={widgetItem?.showReportIcon}
              isGenderAgeSorted={widgetItem?.isGenderAgeSorted}
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "tableWidget") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <TableWidget
              unixDate={widgetItem?.unixDate}
              showProfile={widgetItem?.showProfile}
              productId={widgetItem?.productId}
              monitorId={widgetItem?.monitorId}
              circularPP={widgetItem?.circularPP}
              itemsList={widgetItem?.itemsList}
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              data={widgetItem?.data}
              leftColomnTitle={widgetItem?.leftColomnTitle}
              rightColomnTitle={widgetItem?.rightColomnTitle}
              tableChartPreLoaderDataSources={
                widgetItem?.tableChartPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              twitterIcon={widgetItem?.twitterIcon}
              tablePagination={widgetItem?.tablePagination}
              count={widgetItem?.count}
              iconAfterTitle={widgetItem?.iconAfterTitle}
              wordsList={widgetItem?.wordsList}
              keywords={widgetItem?.keywords}
              handleOpenQuickInspect={widgetItem?.handleOpenQuickInspect}
              inspectorName={widgetItem?.inspectorName}
              dynamicClass={widgetItem?.dynamicClass}
              hashtags={widgetItem?.hashtags}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              showAtIcon={widgetItem?.showAtIcon}
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "mediaInstagram") {
      return (
        <Grid item xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <MediaInstagram
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              data={widgetItem?.data}
              errorMessage={widgetItem?.errorMessage}
              iconAfterTitle={widgetItem?.iconAfterTitle}
              monitorInstagramMedia={widgetItem?.monitorInstagramMedia}
              mediaInstaPreLoaderDataSources={
                widgetItem?.mediaInstaPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              questions={widgetItem?.questions}
              insightsID={widgetItem?.insightsID}
              response={widgetItem?.response}
              dates={widgetItem?.dates}
              hideMoreInsights={
                widgetItem?.hideMoreInsights || isLiveDashboardPublic
              }
              showDownloadIcon={widgetItem?.showDownloadIcon}
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "questionCard") {
      return (
        <Grid item xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <Box component="p" className="question-card-title">
              <Box component="span" className="widget-title">
                {CheckValueLocale(widgetItem?.questionCardTitle, "", {}, intl)}
                {widgetSection ? (
                  <span className="widget-title-section">
                    ({CheckValueLocale(widgetSection, "", {}, intl)})
                  </span>
                ) : (
                  ""
                )}
              </Box>
              {widgetItem?.questionCardIcon}
            </Box>
            <QuestionCard
              unixDate={widgetItem?.unixDate}
              showProfile={widgetItem?.showProfile}
              productId={widgetItem?.productId}
              latestUnanswer={widgetItem?.latestUnanswer}
              icon={widgetItem?.icon}
              iconColor={widgetItem?.iconColor}
              showBanIcon={widgetItem?.showBanIcon}
              latestUnanswerPreloader={widgetItem?.latestUnanswerPreloader}
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              updateAnalysis={widgetItem?.updateAnalysis}
              noOfChar={widgetItem?.noOfChar}
              singleTweetAnalysis={widgetItem?.singleTweetAnalysis}
              hideWhenRetweeted={widgetItem?.hideWhenRetweeted}
              showRetweet={widgetItem?.showRetweet}
              showHeart={widgetItem?.showHeart}
              showScreenName={widgetItem?.showScreenName}
              showDefaultImg={widgetItem?.showDefaultImg}
              widget={widgetItem?.widget}
              unAnsweredQuestion={widgetItem?.unAnsweredQuestion}
              insightsID={widgetItem?.insightsID}
              response={widgetItem?.response}
              dates={widgetItem?.dates}
              instagramAccount={widgetItem?.instagramAccount}
              className={widgetItem?.className}
              hideChangeSentiment={widgetItem?.hideChangeSentiment}
              hideChartBar={widgetItem?.hideChartBar}
              isInstagramLiveDashBoard
              isLiveDashboardPublic={isLiveDashboardPublic}
              datasourcePostsType="INSTAGRAM"
              isLiveDashboard={true}
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "themeSection") {
      return (
        <Grid item xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <ThemeSection
              iconAfterTitle={widgetItem?.iconAfterTitle}
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              eventName={widgetItem?.eventName}
              resetAllFields={widgetItem?.resetAllFields}
              response={widgetItem?.response}
              themeTrendPreLoader={widgetItem?.themeTrendPreLoader}
              setThemeTrendPreLoader={widgetItem?.setThemeTrendPreLoader}
              themeAnalysisPreLoader={widgetItem?.themeAnalysisPreLoader}
              setThemeAnalysisPreLoader={widgetItem?.setThemeAnalysisPreLoader}
              liveDashboardWidget
              widgetSection={widgetSection}
              isLiveDashboardPublic={isLiveDashboardPublic}
              themesOptions={themesOptions}
              isLiveDashboard
            />
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "barChart") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <div className="chart-body">
              <BarChartWidget
                title={widgetItem?.title}
                titleToolTip={widgetItem?.titleToolTip}
                data={widgetItem?.data}
                showAI={widgetItem?.showAI}
                showDownloadIcon={widgetItem?.showDownloadIcon}
                showChartStatsWidget={widgetItem?.showChartStatsWidget}
                showZoomPercentage={widgetItem?.showZoomPercentage}
                customerCareChart={widgetItem?.customerCareChart}
                gridHeight={widgetItem?.gridHeight}
                respTimeDistributionTooltip={
                  widgetItem?.respTimeDistributionTooltip
                }
                statsData={widgetItem?.statsData}
                dataColor={widgetItem?.dataColor}
                toolTipLabel={widgetItem?.toolTipLabel}
                isTwitterAccount={widgetItem?.isTwitterAccount}
                iconAfterTitle={widgetItem?.iconAfterTitle}
                barChartPreLoaderDataSources={
                  widgetItem?.barChartPreLoaderDataSources
                }
                preLoaderTrackerDataSources={
                  widgetItem?.preLoaderTrackerDataSources
                }
                bigImg={widgetItem?.bigImg}
                showFollowersGrowthStats={widgetItem?.showFollowersGrowthStats}
                showGroupBy={widgetItem?.showGroupBy}
                showChartDetailsWidget={widgetItem?.showChartDetailsWidget}
                statsDetailsData={widgetItem?.statsDetailsData}
                liveDashboardWidget
                widgetSection={widgetSection}
              />
            </div>
          </Box>
        </Grid>
      );
    } else if (widgetItem?.typeWidget === "punchCard") {
      return (
        <Grid item lg={6} xs={12} className="dashboard-widget-wrapper-column">
          <Box className="live-dashboard-widget-wrapper">
            {showEditBtn ? <DragHandle /> : null}
            <PunchCardWidget
              title={widgetItem?.title}
              titleToolTip={widgetItem?.titleToolTip}
              clientActivity={widgetItem?.clientActivity}
              minValueClient={widgetItem?.minValueClient}
              maxValueClient={widgetItem?.maxValueClient}
              showDownloadIcon={widgetItem?.showDownloadIcon}
              bigImg={widgetItem?.bigImg}
              iconAfterTitle={widgetItem?.iconAfterTitle}
              authorActivityPreLoaderDataSources={
                widgetItem?.authorActivityPreLoaderDataSources
              }
              preLoaderTrackerDataSources={
                widgetItem?.preLoaderTrackerDataSources
              }
              liveDashboardWidget
              widgetSection={widgetSection}
            />
          </Box>
        </Grid>
      );
    } else {
      return "";
    }
  },
);

const DataSourceContent = ({
  resetAllFields,
  response,
  preLoaderTrackerDataSources,
  unixDate,
  singleTweetAnalysis,
  instagramWidgetTypes,
  setInstagramWidgetTypes,
  sortWidgets,
  getNameKeyWidget,
  showEditBtn,
  setTotalStatisticWidgets,
  setLoadingStatisticWidgets,
  themesOptions,
}) => {
  const intl = useIntl();
  const urlParams = useParams();
  const monitorId = urlParams.monitorId;
  const isLiveDashboardPublic = window?.location?.href?.includes(
    "/live-dashboard/public/",
  );
  const [insightsID, setInsightsID] = useState([]);
  const [insightsResponse, setInsightsResponse] = useState([]);
  const [dates, setDates] = useState([]);

  // engagement section

  const [commentsVolume, setCommentsVolume] = useState([]);
  const [totalCommentsVolumeCount, setTotalCommentsVolumeCount] = useState([]);
  // _____________________________________________
  const [commentsInteractions, setCommentsInteractions] = useState([]);
  const [commentsInteractionsPreLoader, setCommentsInteractionsPreLoader] =
    useState([]);
  const [commentsInteractionsLegend, setCommentsInteractionsLegend] = useState(
    [],
  );
  // _____________________________________________

  const [sentimentAnalysis, setSentimentAnalysis] = useState([]);
  const [sentimentAnalysisPreLoader, setSentimentAnalysisPreLoader] = useState(
    [],
  );

  //__________________

  const [negativeArray, setNegativeArray] = useState([]);
  const [positiveArray, setPositiveArray] = useState([]);
  const [neutralArray, setNeutralArray] = useState([]);
  // _____________________________________________

  const [topKeywords, setTopKeywords] = useState([]);
  const [topKeywordsPreLoader, setTopKeywordsPreLoader] = useState([]);
  // _____________________________________________
  const [topHashtags, setTopHashtags] = useState([]);
  const [topHashtagsPreLoader, setTopHashtagsPreLoader] = useState([]);

  const [commentsVolumePreLoader, setCommentsVolumePreLoader] = useState([]);
  // _____________________________________________
  const [associatedTopics, setAssociatedTopics] = useState([]);
  const [associatedTopicsPreLoader, setAssociatedTopicsPreLoader] = useState(
    [],
  );

  const [dialects, setDialects] = useState([]);
  const [subDialects, setSubDialects] = useState([]);
  const [dialectsPreLoader, setDialectsPreLoader] = useState([]);

  // ___________________________________________________________
  const [topCities, setTopCities] = useState([]);
  const [topCitiesPreLoader, setTopCitiesPreLoader] = useState([]);
  // ___________________________________________________________
  const [topCountries, setTopCountries] = useState([]);
  const [topCountriesPreLoader, setTopCountriesPreLoader] = useState([]);

  // ___________________________________________________________
  const [genderAgeDistribution, setGenderAgeDistribution] = useState([]);
  const [genderAgeDistributionPreLoader, setGenderAgeDistributionPreLoader] =
    useState([]);

  // ___________________________________________________________
  const [topAuthors, setTopAuthors] = useState([]);
  const [topAuthorsPreLoader, setTopAuthorsPreLoader] = useState([]);
  // ___________________________________________________________
  const [commentsMentions, setCommentsMentions] = useState([]);
  const [commentsMentionsPreloader, setCommentsMentionsPreloader] = useState(
    [],
  );
  const [themeTrendPreLoader, setThemeTrendPreLoader] = useState([]);
  const [themeAnalysisPreLoader, setThemeAnalysisPreLoader] = useState([]);

  const [genderPieChart, setGenderAnalysisPieChart] = useState([]);
  const [totalPage, setTotalPage] = useState(0);

  const [sentimentPieChart, setSentimentAnalysisPieChart] = useState([]);

  // ___________________________________________________________
  const [totalValuePostsInteractions, setTotalValuePostsInteractions] =
    useState(0);
  const [maxValuePostsInteractions, setMaxValuePostsInteractions] = useState(0);
  const [maxValueInteractionsDate, setMaxValueInteractionsDate] = useState(0);
  const [avgValuePostsInteractions, setAvgValuePostsInteractions] = useState(0);
  const [topVideosEngagement, setTopVideosEngagement] = useState([]);
  const [topVideosEngagementPreLoader, setTopVideosEngagementPreLoader] =
    useState([]);
  // _____________________________________________
  const [topImagesEngagement, setTopImagesEngagement] = useState([]);
  const [topImagesEngagementPreLoader, setTopImagesEngagementPreLoader] =
    useState([]);
  // _____________________________________________
  const [postsInteractionsEngagement, setPostsInteractionsEngagement] =
    useState([]);
  const [
    postsInteractionsEngagementPreLoader,
    setPostsInteractionsEngagementPreLoader,
  ] = useState([]);
  const [
    postsInteractionsLegendEngagement,
    setPostsInteractionsLegendEngagement,
  ] = useState([]);
  // _____________________________________________
  const [
    totalValuePostsInteractionsEngagement,
    setTotalValuePostsInteractionsEngagement,
  ] = useState(0);
  const [
    maxValuePostsInteractionsEngagement,
    setMaxValuePostsInteractionsEngagement,
  ] = useState(0);
  const [
    maxValueInteractionsDateEngagement,
    setMaxValueInteractionsDateEngagement,
  ] = useState(0);
  const [
    avgValuePostsInteractionsEngagement,
    setAvgValuePostsInteractionsEngagement,
  ] = useState(0);
  const [contentStylesEngagement, setContentStylesEngagement] = useState([]);
  const [
    contentStylesEngagementPreLoader,
    setContentStylesEngagementPreLoader,
  ] = useState([]);

  // account section
  const [postsVolume, setPostsVolume] = useState([]);
  const [totalPostsVolumeCount, setTotalPostsVolumeCount] = useState([]);
  // ___________________________________________________________

  const [topVideos, setTopVideos] = useState([]);
  const [topImages, setTopImages] = useState([]);
  const [topPosts, setTopPosts] = useState([]);
  const [topPostsCount, setTopPostsCount] = useState(0);
  // ___________________________________________________________

  const [authorActivity, setAuthorActivity] = useState([]);
  const [minValueAuthorActivity, setMinValueAuthorActivity] = useState(0);
  const [maxValueAuthorActivity, setMaxValueAuthorActivity] = useState(0);
  // ___________________________________________________________

  const [postsContentType, setPostsContentType] = useState([]);
  const [reachImpression, setReachImpression] = useState([]);
  const [reachImpressionLegend, setReachImpressionLegend] = useState([]);
  const [reach, setReach] = useState([]);
  const [impression, setImpression] = useState([]);

  // ___________________________________________________________

  const [postsContactDetails, setPostsContactDetails] = useState([]);
  const [postsContactDetailsLegend, setPostsContactDetailsLegend] = useState(
    [],
  );
  // ___________________________________________________________
  const [postsInteractions, setPostsInteractions] = useState([]);
  const [
    maxValuePostsInteractionsAccount,
    setMaxValuePostsInteractionsAccount,
  ] = useState(0);
  const [minValuePostsInteractions, setMinValuePostsInteractions] = useState(0);
  const [
    avgValuePostsInteractionsAccount,
    setAvgValuePostsInteractionsAccount,
  ] = useState(0);
  const [maxValueInteractionsDateAccount, setMaxValueInteractionsDateAccount] =
    useState(0);
  const [minValueInteractionsDate, setMinValueInteractionsDate] = useState(0);
  const [postInteractionsLegend, setPostInteractionsLegend] = useState([]);
  // ___________________________________________________________
  const [averageEngagementPerPost, setAverageEngagementPerPost] = useState([]);
  const [
    maxValueAverageEngagementPerPost,
    setMaxValueAverageEngagementPerPost,
  ] = useState(0);
  const [
    minValueAverageEngagementPerPost,
    setMinValueAverageEngagementPerPost,
  ] = useState(0);
  const [
    avgValueAverageEngagementPerPost,
    setAvgValueAverageEngagementPerPost,
  ] = useState(0);
  const [
    maxValueAverageEngagementPerPostDate,
    setMaxValueAverageEngagementPerPostDate,
  ] = useState(0);
  const [
    minValueAverageEngagementPerPostDate,
    setMinValueAverageEngagementPerPostDate,
  ] = useState(0);
  // ___________________________________________________________
  const [postContentPieChart, setPostContentPieChart] = useState([]);
  const [followersGrowth, setFollowersGrowth] = useState([]);
  const [totalFollowersFollowersGrowth, setTotalFollowersGrowth] = useState(0);
  const [maxChangeFollowersGrowth, setMaxChangeFollowersGrowth] = useState(0);
  const [avgChangeFollowersGrowth, setAvgChangeFollowersGrowth] = useState(0);
  // ___________________________________________________________

  // Values of DataSources totrack each widget preloader
  const [postsVolumePreLoaderDataSources, setPostsVolumePreLoaderDataSources] =
    useState([]);
  const [topVideosPreLoaderDataSources, setTopVideosPreLoaderDataSources] =
    useState([]);
  const [topImagesPreLoaderDataSources, setTopImagesPreLoaderDataSources] =
    useState([]);
  const [topTopPostsPreLoaderDataSources, setTopPostsPreLoaderDataSources] =
    useState([]);
  const [
    authorActivityPreLoaderDataSources,
    setAuthorActivityPreLoaderDataSources,
  ] = useState([]);
  const [
    postsInteractionsPreLoaderDataSources,
    setPostsInteractionsPreLoaderDataSources,
  ] = useState([]);

  const [
    averageEngagementPerPostPreLoaderDataSources,
    setAverageEngagementPerPostPreLoaderDataSources,
  ] = useState([]);
  const [
    postsContactDetailsPreLoaderDataSources,
    setPostsContactDetailsPreLoaderDataSources,
  ] = useState([]);
  const [
    postsContentTypePreLoaderDataSources,
    setPostsContentTypePreLoaderDataSources,
  ] = useState([]);
  const [
    followersGrowthPreLoaderDataSources,
    setFollowersGrowthPreLoaderDataSources,
  ] = useState([]);
  const [
    reachImpressionPreLoaderDataSources,
    setReachImpressionPreLoaderDataSources,
  ] = useState([]);
  const [topKeywordsAccount, setTopKeywordsAccount] = useState([]);
  const [topKeywordsAccountPreLoader, setTopKeywordsAccountPreLoader] =
    useState([]);
  const [topHashtagsAccount, setTopHashtagsAccount] = useState([]);
  const [topHashtagsAccountPreLoader, setTopHashtagsAccountPreLoader] =
    useState([]);

  // posts section
  const [topPostsPosts, setTopPostsPosts] = useState([]);
  const [
    topTopPostsPostsPreLoaderDataSources,
    setTopPostsPostsPreLoaderDataSources,
  ] = useState([]);

  // questions section
  const [topPostsQuestions, setTopPostsQuestions] = useState([]);
  const [
    topTopPostsQuestionsPreLoaderDataSources,
    setTopPostsQuestionsPreLoaderDataSources,
  ] = useState([]);

  // comments mentions section
  const [topPostsCommentsMentions, setTopPostsCommentsMentions] = useState([]);
  const [
    topTopPostsCommentsMentionsPreLoaderDataSources,
    setTopPostsCommentsMentionsPreLoaderDataSources,
  ] = useState([]);

  // customer care section
  const [latestUnanswer, setLatestUnanswer] = useState([]);
  const [customerCarePerformance, setCustomercarePerformance] = useState({});
  const [latestUnanswerPreloader, setLatestUnanswerPreloader] = useState([]);
  const [customerCarePreloader, setCustomercarePreloader] = useState([]);
  const [unAnsweredQuestion, setUnanswerQuestion] = useState(0);
  const [respRate, setRespRate] = useState(0);
  const [totalQuestion, setTotalQuestion] = useState(0);
  const [customerCareData, setcustomerCareData] = useState([]);

  const instagramIcon = (
    <span className="topics-twitter-icon">
      <img src={publicInstagram} className="icon-after-title instagram" />
    </span>
  );

  useEffect(() => {
    setInsightsResponse(
      localStorage.getItem("moreInsightsResp") !== null
        ? JSON.parse(localStorage.getItem("moreInsightsResp"))
        : [],
    );
    setInsightsID(
      localStorage.getItem("moreInsightsIds") !== null
        ? JSON.parse(localStorage.getItem("moreInsightsIds"))
        : [],
    );
    setDates(
      localStorage.getItem("moreInsightsDates") !== null
        ? JSON.parse(localStorage.getItem("moreInsightsDates"))
        : [],
    );
  }, []);

  let interactionsStats = [
    {
      name: "total_interactions",
      value: totalValuePostsInteractions,
    },
    {
      name: "max_interaction",
      value: maxValuePostsInteractions,
      date: maxValueInteractionsDate,
    },
    {
      name: "avg_interaction_per_day",
      value: avgValuePostsInteractions,
    },
  ];

  const instagramWidgetObj = {
    // engagement section
    Instagram__EngagementsPage__gender_distribution: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget(
        "Instagram__EngagementsPage__gender_distribution",
      ),
      titleToolTip: "gender_age_dist_ig_tooltip",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      posts: genderAgeDistribution,
      colors: ["#2AAAE2", "#FF4081"],
      showZoomPercentage: true,
      iconAfterTitle: instagramIcon,
      postsColumnPreLoaderDataSources: genderAgeDistributionPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showScales: !isLiveDashboardPublic,
      dataPiechart: genderPieChart,
      hideLegend: true,
      labels: ["male", "female"],
      showReportIcon: true,
      reachFunnel: true,
      isGenderAgeSorted: true,
    },
    Instagram__EngagementsPage__dialects_and_sub_dialects: {
      typeWidget: "pieChart",
      title: getNameKeyWidget(
        "Instagram__EngagementsPage__dialects_and_sub_dialects",
      ),
      titleToolTip: "dialects_subdialects_tooltip",
      data: dialects,
      subData: subDialects,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showAI: true,
      showDownloadIcon: !isLiveDashboardPublic,
      pieChartPreLoaderDataSources: dialectsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: instagramIcon,
    },
    Instagram__EngagementsPage__top_engagers: {
      typeWidget: "tableWidget",
      wordsList: true,
      title: getNameKeyWidget("Instagram__EngagementsPage__top_engagers"),
      titleToolTip: "top_authors_ig_tooltip",
      data: topAuthors,
      leftColomnTitle: "username",
      rightColomnTitle: "number_of_posts",
      tableChartPreLoaderDataSources: topAuthorsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showDownloadIcon: !isLiveDashboardPublic,
      tablePagination: true,
      showAtIcon: true,
      count: topAuthors.length,
      iconAfterTitle: instagramIcon,
    },
    Instagram__EngagementsPage__top_cities: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Instagram__EngagementsPage__top_cities"),
      titleToolTip: "top_cities_ig_tooltip",
      data: topCities,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: !isLiveDashboardPublic,
      pieChartPreLoaderDataSources: topCitiesPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: instagramIcon,
      showReportIcon: true,
    },
    Instagram__EngagementsPage__top_countries: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Instagram__EngagementsPage__top_countries"),
      titleToolTip: "top_countries_ig_tooltip",
      data: topCountries,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: !isLiveDashboardPublic,
      color: ["#009BDF", "#003D59", "#45B9E9", "#97D7F3"],
      pieChartPreLoaderDataSources: topCountriesPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: instagramIcon,
      showReportIcon: true,
    },
    Instagram__EngagementsPage__top_posts_comments: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Instagram__EngagementsPage__top_posts_comments",
      ),
      questionCardIcon: instagramIcon,
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      latestUnanswer: commentsMentions,
      unAnsweredQuestion: totalPage,
      icon: faInstagram,
      iconColor: "instagram_icon",
      showRetweet: true,
      showHeart: true,
      showDefaultImg: true,
      widget: "comments_mentions",
      latestUnanswerPreloader: commentsMentionsPreloader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      insightsID: insightsID,
      response: insightsResponse,
      dates: dates,
    },
    Instagram__EngagementsPage__volume_overtime: {
      typeWidget: "lineChart",
      title: getNameKeyWidget("Instagram__EngagementsPage__volume_overtime"),
      titleToolTip: "comments_volume_ig_tooltip",
      totalLabel: "posts",
      totalValue: totalCommentsVolumeCount,
      data: commentsVolume,
      showGroupBy: !isLiveDashboardPublic,
      toolTipLabel: "posts",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      lineChartPreLoaderDataSources: commentsVolumePreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: instagramIcon,
      labelColor: "#2AAAE2",
    },
    Instagram__EngagementsPage__sentiment_analysis: {
      typeWidget: "stackedLineChart",
      title: getNameKeyWidget("Instagram__EngagementsPage__sentiment_analysis"),
      titleToolTip: "comments_sentiment_analysis_tooltip",
      data: sentimentAnalysis,
      showGroupBy: !isLiveDashboardPublic,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showAI: false,
      hideLegend: true,
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      stackedLineWidgetPreLoaderDataSources: sentimentAnalysisPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      isSwitchCase: true,
      iconAfterTitle: instagramIcon,
      showGroupByClientAgent: true,
      showScales: !isLiveDashboardPublic,
      dataPiechart: sentimentPieChart,
      labelColors: ["#89BB2A", "#E50035", "#F9A700"],
      isSentimentAnalysisWidget: true,
      isSentimentAnalysisShowCharts: true,
    },
    Instagram__EngagementsPage__themes_and_sub_themes: {
      typeWidget: "themeSection",
      iconAfterTitle: instagramIcon,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      eventName: "Instagram__EngagementsPage__themes_and_sub_themes",
      resetAllFields: resetAllFields,
      response: response,
      themeTrendPreLoader: themeTrendPreLoader,
      setThemeTrendPreLoader: setThemeTrendPreLoader,
      themeAnalysisPreLoader: themeAnalysisPreLoader,
      setThemeAnalysisPreLoader: setThemeAnalysisPreLoader,
    },
    Instagram__EngagementsPage__comments_interactions: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget(
        "Instagram__EngagementsPage__comments_interactions",
      ),
      titleToolTip: "comments_interactions_ig_tooltip",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      posts: commentsInteractions,
      colors: ["#2AAAE2", "#80868C"],
      labels: ["likes", "comments"],
      showZoomPercentage: true,
      iconAfterTitle: instagramIcon,
      postsColumnPreLoaderDataSources: commentsInteractionsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showGroupByClientAgent: true,
      showScales: !isLiveDashboardPublic,
      hideLegend: true,
      dataPiechart: commentsInteractionsLegend,
      showChartDetailsWidget: true,
      statsDetailsData: interactionsStats,
      isInteractionStatsWrapperStyle: true,
    },
    Instagram__EngagementsPage__top_keywords: {
      typeWidget: "tableWidget",
      wordsList: true,
      title: getNameKeyWidget("Instagram__EngagementsPage__top_keywords"),
      titleToolTip: "comments_top_keywords_tooltip",
      data: topKeywords,
      leftColomnTitle: "keywords",
      rightColomnTitle: "number_of_posts",
      tableChartPreLoaderDataSources: topKeywordsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      keywords: true,
      tablePagination: true,
      count: topKeywords.length,
      showDownloadIcon: !isLiveDashboardPublic,
      iconAfterTitle: instagramIcon,
    },
    Instagram__EngagementsPage__top_hashtags: {
      typeWidget: "tableWidget",
      wordsList: true,
      title: getNameKeyWidget("Instagram__EngagementsPage__top_hashtags"),
      titleToolTip: "comments_top_hashtags_ig_tooltip",
      data: topHashtags,
      leftColomnTitle: "hashtags",
      rightColomnTitle: "number_of_posts",
      tableChartPreLoaderDataSources: topHashtagsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      hashtags: true,
      tablePagination: true,
      count: topHashtags.length,
      showDownloadIcon: !isLiveDashboardPublic,
      iconAfterTitle: instagramIcon,
    },
    Instagram__EngagementsPage__associated_topics: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Instagram__EngagementsPage__associated_topics"),
      titleToolTip: "comments_associated_topics_tooltip",
      data: associatedTopics,
      areaStyle: true,
      toolTipLabel: "toolTipLabel",
      showDownloadIcon: !isLiveDashboardPublic,
      showAI: true,
      pieChartPreLoaderDataSources: associatedTopicsPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: instagramIcon,
    },
    Instagram__EngagementsPage__top_videos: {
      typeWidget: "mediaInstagram",
      title: CheckValueLocale(
        getNameKeyWidget("Instagram__EngagementsPage__top_videos"),
        "",
        {},
        intl,
      ),
      titleToolTip: "posts_top_reels_tooltip",
      data: topVideosEngagement,
      errorMessage: CheckValueLocale("no_videos_found", "", {}, intl),
      iconAfterTitle: instagramIcon,
      hideMoreInsights: true,
      monitorInstagramMedia: true,
      mediaInstaPreLoaderDataSources: topVideosEngagementPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showDownloadIcon: !isLiveDashboardPublic,
    },
    Instagram__EngagementsPage__top_images: {
      typeWidget: "mediaInstagram",
      title: CheckValueLocale(
        getNameKeyWidget("Instagram__EngagementsPage__top_images"),
        "",
        {},
        intl,
      ),
      titleToolTip: "top_images_ig_tooltip",
      data: topImagesEngagement,
      errorMessage: CheckValueLocale("no_images_found", "", {}, intl),
      iconAfterTitle: instagramIcon,
      hideMoreInsights: true,
      monitorInstagramMedia: true,
      mediaInstaPreLoaderDataSources: topImagesEngagementPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showDownloadIcon: !isLiveDashboardPublic,
    },
    Instagram__EngagementsPage__posts_interactions: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Instagram__EngagementsPage__posts_interactions"),
      titleToolTip: "posts_interactions_ig_tooltip",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      posts: postsInteractionsEngagement,
      colors: ["#2AAAE2", "#80868C"],
      labels: ["comments", "likes"],
      showScales: !isLiveDashboardPublic,
      showZoomPercentage: true,
      iconAfterTitle: instagramIcon,
      postsColumnPreLoaderDataSources: postsInteractionsEngagementPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      hideLegend: true,
      dataPiechart: postsInteractionsLegendEngagement,
      showChartDetailsWidget: true,
      statsDetailsData: [
        {
          name: "total_interactions",
          value: totalValuePostsInteractionsEngagement,
        },
        {
          name: "max_interaction",
          value: maxValuePostsInteractionsEngagement,
          date: maxValueInteractionsDateEngagement,
        },
        {
          name: "avg_interaction_per_day",
          value: avgValuePostsInteractionsEngagement,
        },
      ],
      isInteractionStatsWrapperStyle: true,
    },
    Instagram__EngagementsPage__content_type: {
      typeWidget: "pieChart",
      title: getNameKeyWidget("Instagram__EngagementsPage__content_type"),
      titleToolTip: "posts_content_type_tw_tooltip",
      showDownloadIcon: false,
      data: contentStylesEngagement,
      pieChartPreLoaderDataSources: contentStylesEngagementPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: instagramIcon,
    },
    // account section
    Instagram__AccountPage__followers_growth: {
      typeWidget: "barChart",
      title: getNameKeyWidget("Instagram__AccountPage__followers_growth"),
      titleToolTip: "followers_growth_ig_tooltip",
      data: followersGrowth,
      showGroupBy: !isLiveDashboardPublic,
      dataColor: "#2AAAE2",
      toolTipLabel: "posts",
      showChartDetailsWidget: true,
      statsDetailsData: [
        {
          name: "total_followers",
          value: totalFollowersFollowersGrowth,
        },
        {
          name: "total_change_in_followers",
          value: maxChangeFollowersGrowth,
        },
        {
          name: "avg_change_per_day",
          value: avgChangeFollowersGrowth,
        },
      ],
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      showZoomPercentage: true,
      iconAfterTitle: instagramIcon,
      barChartPreLoaderDataSources: followersGrowthPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Instagram__AccountPage__page_contact_details_interactions: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget(
        "Instagram__AccountPage__page_contact_details_interactions",
      ),
      titleToolTip: "page_contact_details_interactions_ig_tooltip",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      posts: postsContactDetails,
      colors: ["#2AAAE2", "#80868C", "#334155", "#AFBCCE"],
      labels: [
        "website_clicks",
        "phone_clicks",
        "directions",
        "profile_visits",
      ],
      showScales: !isLiveDashboardPublic,
      showZoomPercentage: true,
      iconAfterTitle: instagramIcon,
      postsColumnPreLoaderDataSources: postsContactDetailsPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showReportIcon: true,
      hideLegend: true,
      dataPiechart: postsContactDetailsLegend,
    },
    Instagram__AccountPage__account_author_activity: {
      typeWidget: "punchCard",
      title: getNameKeyWidget(
        "Instagram__AccountPage__account_author_activity",
      ),
      titleToolTip: "account_authors_activity_ig_tooltip",
      clientActivity: authorActivity,
      minValueClient: minValueAuthorActivity,
      maxValueClient: maxValueAuthorActivity,
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      iconAfterTitle: instagramIcon,
      authorActivityPreLoaderDataSources: authorActivityPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Instagram__AccountPage__volume_overtime: {
      typeWidget: "lineChart",
      title: getNameKeyWidget("Instagram__AccountPage__volume_overtime"),
      titleToolTip: "posts_volume_ig_tooltip",
      totalLabel: "posts",
      totalValue: totalPostsVolumeCount,
      data: postsVolume,
      showGroupBy: !isLiveDashboardPublic,
      toolTipLabel: "posts",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      iconAfterTitle: instagramIcon,
      labelColor: "#2AAAE2",
      lineChartPreLoaderDataSources: postsVolumePreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Instagram__AccountPage__reach_funnel: {
      typeWidget: "stackedLineChart",
      title: getNameKeyWidget("Instagram__AccountPage__reach_funnel"),
      titleToolTip: "posts_reach_impressions_ig_tooltip",
      data: reachImpression,
      areaStyle: true,
      isSwitchCase: true,
      toolTipLabel: "toolTipLabel",
      colors: ["#2AAAE2", "#80868C"],
      showDownloadIcon: !isLiveDashboardPublic,
      stackedLineWidgetPreLoaderDataSources:
        reachImpressionPreLoaderDataSources,
      isPostsReachImpression: true,
      bigImg: !isLiveDashboardPublic,
      iconAfterTitle: instagramIcon,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      showGroupBy: !isLiveDashboardPublic,
      showScales: !isLiveDashboardPublic,
      showReportIcon: true,
      hideLegend: true,
      dataPiechart: reachImpressionLegend,
      labelColors: ["#2AAAE2", "#80868C"],
    },
    Instagram__AccountPage__posts_interactions: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Instagram__AccountPage__posts_interactions"),
      titleToolTip: "posts_interactions_ig_tooltip",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      posts: postsInteractions,
      showChartDetailsWidget: true,
      statsDetailsData: [
        {
          name: "max_interaction",
          value: maxValuePostsInteractionsAccount,
          date: maxValueInteractionsDateAccount,
        },
        {
          name: "min_interaction",
          value: minValuePostsInteractions,
          date: minValueInteractionsDate,
        },
        {
          name: "avg_interaction_per_day",
          value: avgValuePostsInteractionsAccount,
        },
      ],
      colors: ["#2AAAE2", "#80868C"],
      labels: ["comments", "likes"],
      showPostsInteractionStats: true,
      showScales: !isLiveDashboardPublic,
      showZoomPercentage: true,
      iconAfterTitle: instagramIcon,
      postsColumnPreLoaderDataSources: postsInteractionsPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      hideLegend: true,
      dataPiechart: postInteractionsLegend,
    },
    Instagram__AccountPage__average_engagement_per_post: {
      typeWidget: "barChart",
      title: getNameKeyWidget(
        "Instagram__AccountPage__average_engagement_per_post",
      ),
      titleToolTip: "average_engagements_per_post_ig_tooltip",
      data: averageEngagementPerPost,
      showGroupBy: !isLiveDashboardPublic,
      dataColor: "#2AAAE2",
      showChartDetailsWidget: true,
      statsDetailsData: [
        {
          name: "max_interactions_per_post",
          value: maxValueAverageEngagementPerPost,
          date: maxValueAverageEngagementPerPostDate,
        },
        {
          name: "min_interactions_per_post",
          value: minValueAverageEngagementPerPost,
          date: minValueAverageEngagementPerPostDate,
        },
        {
          name: "avg_interaction_per_post",
          value: avgValueAverageEngagementPerPost,
        },
      ],
      toolTipLabel: "posts",
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      iconAfterTitle: instagramIcon,
      showZoomPercentage: true,
      barChartPreLoaderDataSources:
        averageEngagementPerPostPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Instagram__AccountPage__content_type: {
      typeWidget: "stackedBarChart",
      title: getNameKeyWidget("Instagram__AccountPage__content_type"),
      titleToolTip: "posts_content_type_ig_tooltip",
      posts: postsContentType,
      colors: ["#2AAAE2", "#80868C", "#334155"],
      labels: ["video", "photo", "catalog"],
      hideLegend: true,
      dataPiechart: postContentPieChart,
      showDownloadIcon: !isLiveDashboardPublic,
      bigImg: !isLiveDashboardPublic,
      showScales: !isLiveDashboardPublic,
      showZoomPercentage: true,
      iconAfterTitle: instagramIcon,
      postsColumnPreLoaderDataSources: postsContentTypePreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
    },
    Instagram__AccountPage__top_images: {
      typeWidget: "mediaInstagram",
      title: CheckValueLocale(
        getNameKeyWidget("Instagram__AccountPage__top_images"),
        "",
        {},
        intl,
      ),
      titleToolTip: "posts_top_images_tooltip",
      data: topImages,
      errorMessage: CheckValueLocale("no_images_found", "", {}, intl),
      iconAfterTitle: instagramIcon,
      monitorInstagramMedia: true,
      mediaInstaPreLoaderDataSources: topImagesPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      questions: topImages,
      insightsID: insightsID,
      response: insightsResponse,
      dates: dates,
    },
    Instagram__AccountPage__top_videos: {
      typeWidget: "mediaInstagram",
      title: CheckValueLocale(
        getNameKeyWidget("Instagram__AccountPage__top_videos"),
        "",
        {},
        intl,
      ),
      titleToolTip: "posts_top_reels_tooltip",
      data: topVideos,
      errorMessage: CheckValueLocale("no_videos_found", "", {}, intl),
      iconAfterTitle: instagramIcon,
      monitorInstagramMedia: true,
      mediaInstaPreLoaderDataSources: topVideosPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      questions: topVideos,
      insightsID: insightsID,
      response: insightsResponse,
      dates: dates,
    },
    Instagram__AccountPage__top_posts_comments: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Instagram__AccountPage__top_posts_comments",
      ),
      questionCardIcon: instagramIcon,
      latestUnanswer: topPosts.slice(0, 6),
      latestUnanswerPreloader: topTopPostsPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: faInstagram,
      widget: "top_posts",
      iconColor: "instagram_icon",
      showHeart: true,
      showDefaultImg: true,
      instagramAccount: true,
      className: "no-data-found-question",
      insightsID: insightsID,
      response: insightsResponse,
      dates: dates,
      hideChangeSentiment: true,
    },
    Instagram__AccountPage__top_keywords: {
      typeWidget: "tableWidget",
      wordsList: true,
      title: getNameKeyWidget("Instagram__AccountPage__top_keywords"),
      titleToolTip: "posts_top_keywords_ig_tooltip",
      data: topKeywordsAccount,
      leftColomnTitle: "keywords",
      rightColomnTitle: "number_of_posts",
      tableChartPreLoaderDataSources: topKeywordsAccountPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      keywords: true,
      tablePagination: true,
      count: topKeywordsAccount.length,
      iconAfterTitle: instagramIcon,
    },
    Instagram__AccountPage__top_hashtags: {
      typeWidget: "tableWidget",
      wordsList: true,
      title: getNameKeyWidget("Instagram__AccountPage__top_hashtags"),
      titleToolTip: "posts_top_hashtags_ig_tooltip",
      data: topHashtagsAccount,
      leftColomnTitle: "hashtags",
      rightColomnTitle: "number_of_hashtags",
      tableChartPreLoaderDataSources: topHashtagsAccountPreLoader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      hashtags: true,
      tablePagination: true,
      count: topHashtagsAccount.length,
      iconAfterTitle: instagramIcon,
    },
    // posts section
    Instagram__PostsPage__top_posts_comments: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Instagram__PostsPage__top_posts_comments",
      ),
      questionCardIcon: instagramIcon,
      latestUnanswer: topPostsPosts,
      latestUnanswerPreloader: topTopPostsPostsPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: faInstagram,
      iconColor: "instagram_icon",
      showHeart: true,
      showDefaultImg: true,
      className: "no-data-found-question",
      // ShowPagination: true,
      // setPage: setPage,
      // page: page,
      insightsID: insightsID,
      response: insightsResponse,
      dates: dates,
      hideChangeSentiment: true,
    },
    // questions section
    Instagram__QuestionsPage__latest_unanswered_questions: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Instagram__QuestionsPage__latest_unanswered_questions",
      ),
      questionCardIcon: instagramIcon,
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      latestUnanswer: topPostsQuestions,
      latestUnanswerPreloader: topTopPostsQuestionsPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: faInstagram,
      iconColor: "instagram_icon",
      showHeart: true,
      showDefaultImg: true,
      hideChartBar: true,
      className: "no-data-found-question",
      widget: "comments_mentions",
      // ShowPagination: true,
      // setPage: setPage,
      // page: page,
    },
    // comments mentions section
    Instagram__CommentsMentionsPage__top_posts_comments: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Instagram__CommentsMentionsPage__top_posts_comments",
      ),
      questionCardIcon: instagramIcon,
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      latestUnanswer: topPostsCommentsMentions,
      latestUnanswerPreloader: topTopPostsCommentsMentionsPreLoaderDataSources,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: faInstagram,
      iconColor: "instagram_icon",
      showHeart: true,
      showDefaultImg: true,
      hideChartBar: true,
      className: "no-data-found-question",
      // ShowPagination: true,
      // setPage: setPage,
      // page: page,
      widget: "comments_mentions",
      showScreenName: true,
    },
    // customer care section
    Instagram__CustomerCarePage__customer_care_performance: {
      typeWidget: "barChart",
      title: getNameKeyWidget(
        "Instagram__CustomerCarePage__customer_care_performance",
      ),
      titleToolTip: "customer_performance_ig_tooltip",
      data: customerCareData,
      showAI: true,
      showDownloadIcon: !isLiveDashboardPublic,
      showChartStatsWidget: true,
      showZoomPercentage: true,
      customerCareChart: true,
      gridHeight: "70%",
      respTimeDistributionTooltip: "respone_time_distribution_tooltip",
      statsData: [
        {
          name: "total_ques",
          value:
            customerCarePerformance?.total_number_of_questions &&
            commasAfterDigit(
              customerCarePerformance?.total_number_of_questions,
            ),
          icon: faQuestion,
          titleToolTip: "number_of_ques_tooltip",
        },
        {
          name: "number_of_answered_questions",
          value: isNaN(respRate * 100)
            ? 0 + "%"
            : respRate.toFixed(2) * 100 + "%",
          icon: faPercent,
          titleToolTip: "response_rate_tooltip",
        },
        {
          name: "avg_response_time_in_seconds",
          value: customerCarePerformance?.avg_response_time_in_seconds,
          icon: faClock,
          type: "time",
          titleToolTip: "avg_response_time_tooltip",
        },
      ],
      dataColor: "#2AAAE2",
      toolTipLabel: "posts",
      isTwitterAccount: true,
      barChartPreLoaderDataSources: customerCarePreloader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      iconAfterTitle: (
        <img src={publicInstagram} className="instagram-icon-style" />
      ),
    },
    Instagram__CustomerCarePage__latest_unanswered_questions: {
      typeWidget: "questionCard",
      questionCardTitle: getNameKeyWidget(
        "Instagram__CustomerCarePage__latest_unanswered_questions",
      ),
      questionCardIcon: instagramIcon,
      unixDate: unixDate,
      // showProfile: true,
      productId: window.localStorage?.getItem("sm_id"),
      latestUnanswer: latestUnanswer,
      latestUnanswerPreloader: latestUnanswerPreloader,
      preLoaderTrackerDataSources: preLoaderTrackerDataSources,
      icon: <img src={publicInstagram} />,
      iconColor: "instagram_icon",
      showHeart: true,
      showDefaultImg: true,
      hideChartBar: true,
      className: "no-data-found-question",
      widget: "comments_mentions",
    },
  };

  const handleResponseFunc = async () => {
    // engagement section
    if (
      response.eventName === "Instagram__EngagementsPage__gender_distribution"
    ) {
      if (response.eventData.gender_distribution) {
        response.eventData.gender_distribution.map((gd) => {
          Object.keys(gd).map((val, index) => {
            setGenderAgeDistribution((oldArray) => [
              ...oldArray,
              {
                name: val,
                value: Object.values(gd)[index],
              },
            ]);
          });
        });
      }
      setGenderAgeDistributionPreLoader([response?.monitor_id]);
      setGenderAnalysisPieChart(response.eventData.gender_piechart);
    } else if (
      response.eventName ===
      "Instagram__EngagementsPage__dialects_and_sub_dialects"
    ) {
      setDialects(response.eventData.dialects);
      setSubDialects(response.eventData.sub_dialects);
      setDialectsPreLoader([response?.monitor_id]);
      // ___________________________________________________________
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_cities"
    ) {
      setTopCities(response.eventData.top_cities);
      setTopCitiesPreLoader([response?.monitor_id]);
      // ___________________________________________________________
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_countries"
    ) {
      setTopCountries(response.eventData.top_countries);
      setTopCountriesPreLoader([response?.monitor_id]);
      // ___________________________________________________________
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_engagers"
    ) {
      setTopAuthors(response.eventData.top_engagers);
      setTopAuthorsPreLoader([response?.monitor_id]);
      // ___________________________________________________________
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_posts_comments"
    ) {
      setCommentsMentions(response.eventData.top_posts);
      setTotalPage(response.eventData.top_posts_count);
      setCommentsMentionsPreloader([response?.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__volume_overtime"
    ) {
      setCommentsVolume(response.eventData.posts_over_time);
      setTotalCommentsVolumeCount(response.eventData.total_posts_count);
      setCommentsVolumePreLoader([response?.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__comments_interactions"
    ) {
      setCommentsInteractions(response?.eventData?.comments_interactions);
      setCommentsInteractionsLegend(
        response?.eventData?.comments_interactions_legends,
      );

      setTotalValuePostsInteractions(response?.eventData?.total_interactions);
      setMaxValuePostsInteractions(response?.eventData?.max_interactions);
      let date = handleUnixFormat(response?.eventData?.max_interaction_date);
      setMaxValueInteractionsDate(date);
      setAvgValuePostsInteractions(response?.eventData?.average_interactions);

      setCommentsInteractionsPreLoader([response?.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__sentiment_analysis"
    ) {
      Object.entries(response.eventData.sentiment_overtime).forEach(
        ([key, value]) => {
          switch (key) {
            case "negative":
              value.map((i) => {
                return negativeArray.push(i);
              });
              break;
            case "positive":
              value.map((i) => {
                return positiveArray.push(i);
              });
              break;
            case "neutral":
              value.map((i) => {
                return neutralArray.push(i);
              });
              break;
            default:
              break;
          }
        },
      );
      setSentimentAnalysisPieChart(response.eventData.sentiment_piechart);
      setSentimentAnalysis({
        negative: negativeArray,
        positive: positiveArray,
        neutral: neutralArray,
      });
      setSentimentAnalysisPreLoader([response?.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_keywords"
    ) {
      setTopKeywords(response.eventData.top_keywords);
      setTopKeywordsPreLoader([response?.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_hashtags"
    ) {
      setTopHashtags(response.eventData.top_hashtags);
      setTopHashtagsPreLoader([response?.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__associated_topics"
    ) {
      setAssociatedTopics(response.eventData.associated_topics);
      setAssociatedTopicsPreLoader([response?.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_videos"
    ) {
      response.eventData.top_videos !== null &&
        setTopVideosEngagement(response.eventData.top_videos);
      setTopVideosEngagementPreLoader([response?.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__top_images"
    ) {
      setTopImagesEngagement(response.eventData.top_images);
      setTopImagesEngagementPreLoader([response?.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__posts_interactions"
    ) {
      setPostsInteractionsEngagement(
        response?.eventData?.posts_interactions?.posts_interactions_over_time,
      );
      setPostsInteractionsLegendEngagement(
        response?.eventData?.posts_interactions?.posts_interactions_legends,
      );
      let totalInteractions = response?.eventData?.posts_interactions
        ?.total_interactions
        ? response?.eventData?.posts_interactions?.total_interactions
        : 0;

      setTotalValuePostsInteractionsEngagement(totalInteractions);
      setMaxValuePostsInteractionsEngagement(
        response?.eventData?.posts_interactions?.max_interactions,
      );
      let date = handleUnixFormat(
        response?.eventData?.posts_interactions?.max_interaction_date,
      );
      setMaxValueInteractionsDateEngagement(date);

      setAvgValuePostsInteractionsEngagement(
        response?.eventData?.posts_interactions?.average_interactions,
      );

      setPostsInteractionsEngagementPreLoader([response?.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__content_type"
    ) {
      setContentStylesEngagement(
        response.eventData.posts_content_types_legends,
      );
      setContentStylesEngagementPreLoader([response?.monitor_id]);
    } else if (
      response.eventName === "Instagram__EngagementsPage__dashboard_data"
    ) {
      setTotalStatisticWidgets((prev) => ({
        ...prev,
        instagramDataSource: {
          totalPosts: response?.eventData?.total_posts,
          totalAuthors: response?.eventData?.total_authors,
          totalInteractions: response?.eventData?.total_interactions,
        },
      }));
      setLoadingStatisticWidgets(false);
      // account section
    } else if (
      response.eventName === "Instagram__AccountPage__volume_overtime"
    ) {
      setPostsVolume(response.eventData.posts_over_time);
      setTotalPostsVolumeCount(response.eventData.total_posts_count);
      setPostsVolumePreLoaderDataSources([response?.monitor_id]);
    } else if (response.eventName === "Instagram__AccountPage__reach_funnel") {
      Object.entries(response.eventData.reach_funnel_overtime).forEach(
        ([key, value]) => {
          switch (key) {
            case "reach":
              value.map((i) => {
                return reach.push(i);
              });
              break;
            case "impression":
              value.map((i) => {
                return impression.push(i);
              });
              break;
            default:
              break;
          }
        },
      );
      setReachImpression({
        reach: reach,
        impression: impression,
      });
      setReachImpressionLegend(response.eventData.reach_funnel_legends);
      setReachImpressionPreLoaderDataSources([response?.monitor_id]);
    } else if (
      response.eventName === "Instagram__AccountPage__account_author_activity"
    ) {
      setMinValueAuthorActivity(response.eventData.min_value);
      setMaxValueAuthorActivity(response.eventData.max_value);
      setAuthorActivity(response.eventData.account_author_activity);
      setAuthorActivityPreLoaderDataSources([response?.monitor_id]);
    } else if (
      response.eventName === "Instagram__AccountPage__followers_growth"
    ) {
      setFollowersGrowth(response.eventData.followers_change_over_time);
      setTotalFollowersGrowth(response.eventData.total_followers);
      setMaxChangeFollowersGrowth(response.eventData.max_change_of_followers);
      setAvgChangeFollowersGrowth(response.eventData.avg_chage_of_day);
      setFollowersGrowthPreLoaderDataSources([response?.monitor_id]);
    } else if (
      response.eventName === "Instagram__AccountPage__posts_interactions"
    ) {
      setPostsInteractions(
        response.eventData.posts_interactions.posts_interactions_over_time,
      );
      setMaxValuePostsInteractionsAccount(
        response.eventData.posts_interactions.max_interactions,
      );
      setMinValuePostsInteractions(
        response.eventData.posts_interactions.min_interactions,
      );
      setAvgValuePostsInteractionsAccount(
        response.eventData.posts_interactions.average_interactions,
      );
      let date = handleUnixFormat(
        response.eventData.posts_interactions.max_interaction_date,
      );
      setMaxValueInteractionsDateAccount(date);
      date = handleUnixFormat(
        response.eventData.posts_interactions.min_interaction_date,
      );
      setMinValueInteractionsDate(date);
      setPostInteractionsLegend(
        response.eventData.posts_interactions.posts_interactions_legends,
      );
      setPostsInteractionsPreLoaderDataSources([response?.monitor_id]);
    } else if (
      response.eventName ===
      "Instagram__AccountPage__average_engagement_per_post"
    ) {
      setAverageEngagementPerPost(
        response.eventData.posts_interactions.posts_engagements_over_time,
      );
      setMaxValueAverageEngagementPerPost(
        response.eventData.posts_interactions.max_engagements,
      );
      setMinValueAverageEngagementPerPost(
        response.eventData.posts_interactions.min_engagements,
      );
      setAvgValueAverageEngagementPerPost(
        response.eventData.posts_interactions.average_interactions,
      );
      let date = handleUnixFormat(
        response.eventData.posts_interactions.max_engagement_date,
      );
      setMaxValueAverageEngagementPerPostDate(date);
      date = handleUnixFormat(
        response.eventData.posts_interactions.min_engagement_date,
      );
      setMinValueAverageEngagementPerPostDate(date);
      setAverageEngagementPerPostPreLoaderDataSources([response?.monitor_id]);
    } else if (
      response.eventName ===
      "Instagram__AccountPage__page_contact_details_interactions"
    ) {
      setPostsContactDetails(
        response.eventData.page_contact_details_interactions_overtime,
      );
      setPostsContactDetailsLegend(
        response.eventData.pie_chart_page_contact_details_interactions,
      );
      setPostsContactDetailsPreLoaderDataSources([response?.monitor_id]);
    } else if (response.eventName === "Instagram__AccountPage__content_type") {
      setPostsContentType(response.eventData.posts_content_type);
      setPostsContentTypePreLoaderDataSources([response?.monitor_id]);
      setPostContentPieChart(response.eventData.posts_content_types_legends);
    } else if (response.eventName === "Instagram__AccountPage__top_videos") {
      setTopVideos(response.eventData.top_videos);
      setTopVideosPreLoaderDataSources([response?.monitor_id]);
    } else if (response.eventName === "Instagram__AccountPage__top_images") {
      setTopImages(response.eventData.top_images);
      setTopImagesPreLoaderDataSources([response?.monitor_id]);
    } else if (
      response.eventName === "Instagram__AccountPage__top_posts_comments"
    ) {
      setTopPosts(response.eventData.top_posts);
      setTopPostsCount(response.eventData.top_posts_count);
      setTopPostsPreLoaderDataSources([response?.monitor_id]);
    } else if (response.eventName === "Instagram__AccountPage__top_keywords") {
      setTopKeywordsAccount(response.eventData.top_keywords);
      setTopKeywordsAccountPreLoader([response?.monitor_id]);
    } else if (response.eventName === "Instagram__AccountPage__top_hashtags") {
      setTopHashtagsAccount(response.eventData.top_hashtags);
      setTopHashtagsAccountPreLoader([response?.monitor_id]);
      // posts section
    } else if (
      response.eventName === "Instagram__PostsPage__top_posts_comments"
    ) {
      setTopPostsPosts(response.eventData.top_posts);
      setTopPostsPostsPreLoaderDataSources([response?.monitor_id]);
      // questions section
    } else if (
      response.eventName ===
      "Instagram__QuestionsPage__latest_unanswered_questions"
    ) {
      setTopPostsQuestions(response.eventData.latest_unanswered_questions);
      setTopPostsQuestionsPreLoaderDataSources([response?.monitor_id]);
      // comments mentions section
    } else if (
      response.eventName ===
      "Instagram__CommentsMentionsPage__top_posts_comments"
    ) {
      setTopPostsCommentsMentions(response.eventData.top_posts);
      setTopPostsCommentsMentionsPreLoaderDataSources([response?.monitor_id]);
      // customer care section
    } else if (
      response.eventName ===
      "Instagram__CustomerCarePage__customer_care_performance"
    ) {
      setCustomercarePerformance(response.eventData);
      setCustomercarePreloader([parseInt(response?.monitor_id)]);
      if (response?.eventData?.response_time_segment) {
        Object.keys(response.eventData?.response_time_segment).map(
          (val, index) => {
            setcustomerCareData((oldArray) => [
              ...oldArray,
              {
                name: val,
                value: Object.values(response.eventData?.response_time_segment)[
                  index
                ],
              },
            ]);
          },
        );
      }
      setRespRate(
        response.eventData.number_of_answered_questions /
          response.eventData.total_number_of_questions,
      );
      setTotalQuestion(response.eventData.total_number_of_questions);
    } else if (
      response.eventName ===
      "Instagram__CustomerCarePage__latest_unanswered_questions"
    ) {
      setLatestUnanswer(response.eventData.latest_unanswered_questions);
      setUnanswerQuestion(response.eventData.number_of_unanswered_questions);
      setLatestUnanswerPreloader([parseInt(response?.monitor_id)]);
    } else {
      return null;
    }
  };
  useEffect(() => {
    handleResponseFunc();
  }, [response]);

  // Refresh all values at Each request to default values
  useEffect(() => {
    // Avoid Initial State Calling
    if (resetAllFields !== 1) {
      // engagement section
      setDialects([]);
      setSubDialects([]);
      setDialectsPreLoader([]);
      setTopCities([]);
      setTopCitiesPreLoader([]);
      setTopCountries([]);
      setTopCountriesPreLoader([]);
      setTopAuthors([]);
      setTopAuthorsPreLoader([]);
      setGenderAgeDistribution([]);
      setGenderAgeDistributionPreLoader([]);
      setGenderAnalysisPieChart([]);
      setCommentsMentions([]);
      setTotalPage(0);
      setCommentsMentionsPreloader([]);
      setCommentsVolume([]);
      setTotalCommentsVolumeCount([]);
      setCommentsInteractions([]);
      setCommentsInteractionsPreLoader([]);
      setCommentsInteractionsLegend([]);
      setSentimentAnalysis([]);
      setSentimentAnalysisPreLoader([]);

      setTopKeywords([]);
      setTopKeywordsPreLoader([]);
      setTopHashtags([]);
      setTopHashtagsPreLoader([]);

      setCommentsVolumePreLoader([]);
      setAssociatedTopics([]);
      setAssociatedTopicsPreLoader([]);

      setNegativeArray([]);
      setPositiveArray([]);
      setNeutralArray([]);
      setSentimentAnalysisPieChart([]);

      setTotalValuePostsInteractions(0);
      setMaxValuePostsInteractions(0);
      setMaxValueInteractionsDate(0);
      setAvgValuePostsInteractions(0);
      setPostsInteractionsEngagement([]);
      setPostsInteractionsEngagementPreLoader([]);
      setPostsInteractionsLegendEngagement([]);
      setTopVideosEngagement([]);
      setTopVideosEngagementPreLoader([]);
      setTopImagesEngagement([]);
      setTopImagesEngagementPreLoader([]);
      setContentStylesEngagement([]);
      setContentStylesEngagementPreLoader([]);

      // account section
      setPostsVolume([]);
      setTotalPostsVolumeCount([]);
      setTopImages([]);
      setTopVideos([]);
      setTopPosts([]);
      setTopPostsCount(0);
      setPostContentPieChart([]);
      setMinValueAuthorActivity(0);
      setMaxValueAuthorActivity(0);
      setPostsInteractions([]);
      setMaxValuePostsInteractionsAccount(0);
      setMinValuePostsInteractions(0);
      setAvgValuePostsInteractionsAccount(0);
      setMaxValueInteractionsDateAccount(0);
      setMinValueInteractionsDate(0);
      setPostsInteractions([]);
      setPostInteractionsLegend([]);
      setReachImpression([]);
      setReachImpressionLegend([]);
      setReach([]);
      setImpression([]);
      setAverageEngagementPerPost([]);
      setMaxValueAverageEngagementPerPost(0);
      setMinValueAverageEngagementPerPost(0);
      setAvgValueAverageEngagementPerPost(0);
      setMaxValueAverageEngagementPerPostDate(0);
      setMinValueAverageEngagementPerPostDate(0);
      setPostsContentType([]);
      setFollowersGrowth([]);
      setTotalFollowersGrowth(0);
      setMaxChangeFollowersGrowth(0);
      setAvgChangeFollowersGrowth(0);
      setPostsContactDetails([]);
      setPostsContactDetailsLegend([]);
      setPostsVolumePreLoaderDataSources([]);
      setTopVideosPreLoaderDataSources([]);
      setTopImagesPreLoaderDataSources([]);
      setTopPostsPreLoaderDataSources([]);
      setAuthorActivityPreLoaderDataSources([]);
      setPostsInteractionsPreLoaderDataSources([]);
      setAverageEngagementPerPostPreLoaderDataSources([]);
      setPostsContentTypePreLoaderDataSources([]);
      setFollowersGrowthPreLoaderDataSources([]);
      setPostsContactDetailsPreLoaderDataSources([]);
      setReachImpressionPreLoaderDataSources([]);
      setTopKeywordsAccount([]);
      setTopKeywordsAccountPreLoader([]);
      setTopHashtagsAccount([]);
      setTopHashtagsAccountPreLoader([]);

      // posts section
      setTopPostsPosts([]);
      setTopPostsPostsPreLoaderDataSources([]);

      // questions section
      setTopPostsQuestions([]);
      setTopPostsQuestionsPreLoaderDataSources([]);

      // comments mentions section
      setTopPostsCommentsMentions([]);
      setTopPostsCommentsMentionsPreLoaderDataSources([]);

      // customer care section
      setLatestUnanswer([]);
      setCustomercarePreloader({});
      setLatestUnanswerPreloader([]);
      setCustomercarePreloader([]);
      setUnanswerQuestion(0);
      setcustomerCareData([]);
    }
  }, [resetAllFields]);

  //this function is used for open the quick-inspect-model
  // const handleOpenQuickInspect = (value, name, sentimentValue) => {
  //   switch (true) {
  //     case name === "comments_overtime":
  //       setDataValue(
  //         props.engagementsKeyword ? "posts_mentions" : "comments_mentions",
  //       );
  //       setInspectorValue(props.engagementsKeyword ? "posts" : "comments");
  //       setColor("#2AAAE2");
  //       setDate(value.split("-").reverse().join("/"));
  //       break;
  //     case name === "sentiment_overtime":
  //       setDataValue(sentimentValue);
  //       setInspectorValue(
  //         CheckValueLocale(sentimentValue, "", {}, intl) +
  //           " " +
  //           CheckValueLocale("volume", "", {}, intl),
  //       );
  //       sentimentValue === "positive"
  //         ? setColor("#BAD532")
  //         : sentimentValue === "negative"
  //           ? setColor("#ED1846")
  //           : setColor("#F5B819");
  //       setDate(value.split("-").reverse().join("/"));

  //       break;
  //     //incase of new design for quick inspect from sentiment legend
  //     case name === "sentiment_legend":
  //       setDataValue(
  //         sentimentValue + " " + CheckValueLocale(value, "", {}, intl),
  //       );
  //       setInspectorValue(CheckValueLocale(value + "_volume", "", {}, intl));
  //       value === "positive"
  //         ? setColor("#8CC63F")
  //         : value === "negative"
  //           ? setColor("#ED1846")
  //           : setColor("#F5B819");

  //       setDate(
  //         CheckValueLocale("all_time_sentiment", "", {}, intl) +
  //           dateFormRefactor(reduxFilterStartDateUX) +
  //           " - " +
  //           dateFormRefactor(reduxFilterEndDateUX),
  //       );
  //       break;
  //     case name === "top_hashtags" || name === "top_keywords":
  //       setDataValue(value);
  //       setInspectorValue(value);
  //       setColor("#2AAAE2");
  //       setDate(
  //         dateFormRefactor(reduxFilterStartDateUX) +
  //           "-" +
  //           dateFormRefactor(reduxFilterEndDateUX),
  //       );
  //       break;
  //     default:
  //       setDataValue(sentimentValue + "% " + value);
  //       setInspectorValue(value);
  //       setColor("#2AAAE2");
  //       setDate(
  //         dateFormRefactor(reduxFilterStartDateUX) +
  //           "-" +
  //           dateFormRefactor(reduxFilterEndDateUX),
  //       );
  //   }
  //   setQuickResponse([]);
  //   setInspectorName(name);
  //   setOpen(true);

  //   let valueToSend;
  //   if (dataToSend[name] !== undefined && dataToSend[name].length !== 0) {
  //     valueToSend = dataToSend[name].filter(
  //       (data) =>
  //         CheckValueLocale(data.name, "", {}, intl).toUpperCase() ===
  //         value.toUpperCase(),
  //     );
  //   }
  //   value =
  //     valueToSend && valueToSend.length !== 0 ? valueToSend[0].name : value;
  //   name === "sentiment_overtime"
  //     ? getQuickInspectData(
  //         reduxFilterStartDateUX,
  //         reduxFilterEndDateUX,
  //         name,
  //         value,
  //         sentimentValue,
  //       )
  //     : getQuickInspectData(
  //         reduxFilterStartDateUX,
  //         reduxFilterEndDateUX,
  //         name,
  //         value,
  //       );
  // };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    sortWidgets(
      oldIndex,
      newIndex,
      instagramWidgetTypes,
      setInstagramWidgetTypes,
    );
  };
  return (
    <>
      <Container
        axis={"xy"}
        onSortEnd={onSortEnd}
        useDragHandle
        useWindowAsScrollContainer
      >
        {instagramWidgetTypes
          ?.filter((item) => instagramWidgetObj[item?.name])
          ?.map((item, index) => (
            <Element
              key={index}
              index={index}
              widgetItem={instagramWidgetObj[item?.name]}
              showEditBtn={showEditBtn}
              widgetSection={item?.section}
              isLiveDashboardPublic={isLiveDashboardPublic}
              themesOptions={themesOptions}
            />
          ))}
      </Container>
    </>
  );
};
export default DataSourceContent;
