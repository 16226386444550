import { Box } from "@mui/material";
import PopupModal from "components/popupModal";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import { useDeleteResource } from "pages/engagements/components/aiAgents/aiAgentsHooks/useDeleteResource";
import { useContext } from "react";
import { AiAgentsContext } from "pages/engagements/components/aiAgents/aiAgentsContext";

const DeleteKnowledgeBasesSource = ({
  open,
  setOpenPopupDelete,
  resourceId,
  handleClose,
}) => {
  const intl = useIntl();
  const { setAiAgentSnackBar } = useContext(AiAgentsContext);

  const { mutate: deleteResource, isPending } = useDeleteResource();

  const handleDelete = () => {
    deleteResource(resourceId, {
      onSuccess: () => {
        setAiAgentSnackBar({
          success: true,
          message: CheckValueLocale("ai_agent_source_deleted", "", {}, intl),
        });
        setOpenPopupDelete(false);
      },
      onError: () => {
        setAiAgentSnackBar({
          success: false,
          message: CheckValueLocale("try_again_error_message", "", {}, intl),
        });
        setOpenPopupDelete(false);
      },
      onSettled: () => {
        handleClose();
      },
    });
  };

  const modalBody = (
    <Box>
      <Box component="p" className="ai-agent-modal-hint">
        {CheckValueLocale("delete_source_description", "", {}, intl)}
        <Box component="span">
          {CheckValueLocale("delete_ai_agent_not_reversed", "", {}, intl)}
        </Box>
      </Box>
    </Box>
  );

  return (
    <PopupModal
      title={CheckValueLocale("delete_source", "", {}, intl)}
      body={modalBody}
      leftBtn={CheckValueLocale("cancel", "", {}, intl)}
      rightBtn={CheckValueLocale("confirm_delete", "", {}, intl)}
      disabled={isPending}
      isRightBtnLoading={isPending}
      open={open || false}
      close={() => {
        handleClose();
        setOpenPopupDelete(false);
      }}
      accept={handleDelete}
      classeName={"ai-agent-popupModal"}
      dialogContentId="delete-knowledge-base-source"
      warning
    />
  );
};
export default DeleteKnowledgeBasesSource;
