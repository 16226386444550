import { Box, FormControl, MenuItem, Select } from "@mui/material";
import SectionBrandingHeader from "./sharedComponents/sectionBrandingHeader";
import { handlelUserRoles } from "utils/helpers";

const FontBranding = ({ formik, fontsList }) => {
  return (
    <Box className="branding-item">
      <SectionBrandingHeader titleId={"branding_fonts"} />
      <Box className="item-content">
        <FormControl fullWidth>
          <Select
            className="fonts-select"
            name="font_type_id"
            value={formik?.values?.font_type_id}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            disabled={!handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING")}
            size="small"
            MenuProps={{ PaperProps: { style: { maxHeight: 220 } } }}
            renderValue={(selected) => {
              const selectedFont = fontsList?.find(
                (font) => font?.id == selected,
              )?.attributes?.font_name;
              return (
                <Box
                  className="fonts-rendered-value"
                  sx={{ fontFamily: `${selectedFont} !important` }}
                >
                  {selectedFont}
                  <Box
                    component={"span"}
                    style={{ fontFamily: "inherit !important" }}
                  >
                    العربية
                  </Box>
                </Box>
              );
            }}
          >
            {fontsList?.map((font, index) => (
              <MenuItem
                key={index}
                id="branding-font-menu-item"
                style={{ fontFamily: `${font?.attributes?.font_name}` }}
                value={+font?.id}
              >
                {font?.attributes?.font_name}
                <Box component={"span"}>العربية</Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default FontBranding;
