// "handleNameHeader" --> to return header name for first column
const handleNameHeader = (widgetName) => {
  switch (widgetName) {
    case "top_images":
      return "image";
    case "top_videos":
      return "video";
    default:
      return widgetName;
  }
};

/////////////////// media widget (default) ////////////////////////////////////
// return ===> [[nameHeader] , [url] , ............]
export const handleMediaWidgetToExcel = (
  widgetData,
  widgetName,
  xlsLanguage,
) => {
  try {
    const nameHeader = handleNameHeader(widgetName);
    const dataListExcelSheet = [[xlsLanguage?.[nameHeader] || nameHeader]];

    widgetData?.media_data?.forEach((item) => {
      dataListExcelSheet?.push([item?.permalink || item?.post_url || item?.id]);
    });

    return dataListExcelSheet;
  } catch {}
};
