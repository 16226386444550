import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "@mui/material";
import { checkKeyboardLanguage, CheckValueLocale } from "utils/helpers";
import TextDirectionSwitcher from "./textDirectionSwitcher";
import "../surveyBuilder.scss";

const EditSectionHeader = (props) => {
  const {
    surveyData,
    setSurveyData,
    multiRes,
    handleMultiRes,
    setDiscriptionError,
    discriptionError,
  } = props;
  const inputRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    if (
      surveyData?.title === "Untitled Survey" ||
      surveyData?.title === "نموذج بدون عنوان"
    ) {
      inputRef?.current?.focus();
      inputRef?.current?.select();
    }
  }, []);

  const handleSurveyTitle = (e) =>
    setSurveyData({ ...surveyData, title: e.target.value });
  const handleSurveyDescription = (e) => {
    const value = e.target.value;
    if (value?.length > 256) {
      setDiscriptionError(true);
    } else {
      setDiscriptionError(false);
    }
    setSurveyData({ ...surveyData, subtitle: e.target.value });
  };
  let surveyDirection = surveyData?.text_direction;
  return (
    <Box
      className={`header-style header-style-${
        surveyDirection ? surveyDirection : intl?.locale == "en" ? "ltr" : "rtl"
      }`}
    >
      <Divider className="divider-style" />
      <Box mx={4} mt={4}>
        <TextField
          inputRef={inputRef}
          id="outlined-basic"
          variant="standard"
          className="title-header-field"
          value={surveyData?.title}
          onChange={handleSurveyTitle}
          style={{
            direction: checkKeyboardLanguage(surveyData?.title) ? "rtl" : "ltr",
          }}
          name="title"
          placeholder={CheckValueLocale("Untitled Survey", "", {}, intl)}
          disableUnderline={true}
          multiline
          fullWidth
        />
      </Box>
      <Box mx={4} mt={2}>
        <TextField
          variant="standard"
          className="subtitle-header-field"
          value={surveyData?.subtitle}
          style={{
            direction: checkKeyboardLanguage(surveyData?.subtitle)
              ? "rtl"
              : "ltr",
          }}
          name="subtitle"
          onChange={handleSurveyDescription}
          placeholder={CheckValueLocale(
            "Survey Description (Optional)",
            "",
            {},
            intl,
          )}
          disableUnderline={true}
          error={discriptionError}
          helperText={
            discriptionError
              ? CheckValueLocale(
                  "description_survey-title-error-limit",
                  "",
                  {},
                  intl,
                )
              : ""
          }
          multiline
          fullWidth
        />
      </Box>
      <Divider className="divider-after-title" />
      <Box className="multiple-res-lang-container">
        <Box className="multiple-responses-container">
          <FormGroup>
            <FormControlLabel
              className="multiple-responses"
              control={<Switch checked={multiRes} onChange={handleMultiRes} />}
              label={CheckValueLocale(
                "multiple_responses_per_device",
                "",
                {},
                intl,
              )}
            />
          </FormGroup>
        </Box>
        <TextDirectionSwitcher
          value={surveyData?.text_direction}
          handleChangeDir={(value) => {
            setSurveyData({ ...surveyData, text_direction: value });
          }}
        />
      </Box>
    </Box>
  );
};

export default EditSectionHeader;
