import { NavLink } from "react-router-dom";
import { useIntl } from "react-intl";
import { Box, Divider, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckValueLocale } from "utils/helpers";
import "./style.scss";

const SettingsSidebar = (props) => {
  const { settingsNavItems } = props;

  const intl = useIntl();

  return (
    <Box id="settings-sidebar">
      <Box className="settings-sidebar-content">
        <Typography variant="h6" className="settings-sidebar-title">
          {CheckValueLocale("settings", "", {}, intl)}
        </Typography>
        {settingsNavItems
          ?.filter((group) => group?.items?.some((item) => item?.show))
          ?.map((group, index) => (
            <Box key={group?.name} className="menu-items-group">
              <Box className="group-title">
                {CheckValueLocale(group?.name, "", {}, intl)}
              </Box>
              <Box className="group-items-wrapper">
                {group?.items
                  ?.filter((item) => item?.show)
                  ?.map((item) => (
                    <NavLink key={item?.name} to={item?.path}>
                      <Box className="menu-item-icon-wrapper">
                        <FontAwesomeIcon
                          className="menu-item-icon"
                          icon={item?.icon}
                        />
                      </Box>
                      {CheckValueLocale(item?.name, "", {}, intl)}
                    </NavLink>
                  ))}
              </Box>

              {/* To hide divider for the last group */}
              {settingsNavItems?.filter((group) =>
                group?.items?.some((item) => item?.show),
              )?.length !==
              index + 1 ? (
                <Divider id="group-divider" />
              ) : null}
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default SettingsSidebar;
