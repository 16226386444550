import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import UsageAccordion from "./usageAccordion";
import "./style.scss";

const SubscriptionsDetails = (props) => {
  const { usageData, expanded, handleExpand, setSnackbar } = props;
  const intl = useIntl();

  return (
    <Box id="subscriptions-details-container">
      <Typography variant="h6" className="settings-section-title">
        {CheckValueLocale("subscriptions_details", "", {}, intl)}
      </Typography>
      <Box id="subscriptions-details-accodrions-wrapper">
        {[...(usageData || [])]?.map((item) => (
          <UsageAccordion
            key={item?.productKey}
            item={item}
            expanded={expanded}
            handleExpand={handleExpand}
            setSnackbar={setSnackbar}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SubscriptionsDetails;
