import moment from "moment";

// with sentiment_analysis widget i will add "native_value" to excel sheet
const handleNameForValueKeyByWidgetName = (widgetName) => {
  switch (widgetName) {
    case "sentiment_analysis":
      return "native_value";
    default:
      return "value";
  }
};

/////////////////// StackedLine (aggregate && defualt ) ////////////////////////////////////
// widgetData ===> {data:[ {LabelName1 : [{name:unix , value : number} , ... ], ... } , ... ] , pieData:[ {name:"LabelName1" , value: number} , ...] }
// return ===> (aggregate- defualt) ---->  [[date , "label1"  , "label2" ,...] , [ "5-5-2025" , 30 , 10 ,... ] , ............]
export const handleDataStackedLineChartsToExcel = (
  widgetData,
  widgetName,
  xlsLanguage,
  customizedType,
) => {
  try {
    const dataListExcelSheet = [[xlsLanguage?.date]];
    const finalDataArray = []; //to push all data in the value array for each row
    const nameKeyValue = handleNameForValueKeyByWidgetName(widgetName);

    Object?.entries(widgetData?.data)?.forEach(([key, value], index) => {
      //set name of header for anther columns
      if (customizedType === "aggregate") {
        dataListExcelSheet?.[0]?.push(
          `${xlsLanguage?.[key] || key} - ${xlsLanguage?.aggregate}`,
        );
      } else {
        dataListExcelSheet?.[0]?.push(xlsLanguage?.[key] || key);
      }

      value?.forEach((item, indexRow) => {
        if (index === 0) {
          //to insert new row with first column (date)
          finalDataArray?.push([
            moment.unix(item?.name).utc().format("YYYY-MM-DD"),
            item?.[nameKeyValue],
          ]);
        } else {
          //to push the value in the same row (date)
          finalDataArray?.[indexRow]?.push(item?.[nameKeyValue]);
        }
      });
    });

    //to push each row after append all data for all monitors
    finalDataArray?.forEach((row) => {
      dataListExcelSheet?.push(row);
    });
    return dataListExcelSheet;
  } catch {}
};

/////////////////// StackedLine (comparison) ////////////////////////////////////
// widgetData?.dataExcel ===> [{ data:[ {LabelName1 : [{name:unix , value : number} , ... ], ... } , ... ] ,
//                              pieData:[ {name:"LabelName1" , value: number} , ...] ,
//                              monitorName : "m1" }  , .......m2 ]
// return ===>  [[date , "label1-m1"  , "label2-m1" ,...] , [ "5-5-2025" , 30 , 10 ,... ] , ............]
export const handleStackedLineComparisonToExcel = (
  widgetData,
  widgetName,
  xlsLanguage,
) => {
  try {
    const { dataExcel } = widgetData;
    const dataListExcelSheet = [[xlsLanguage?.date]];
    const finalDataArray = []; //to push all data in the value array for each row
    const nameKeyValue = handleNameForValueKeyByWidgetName(widgetName);

    dataExcel?.forEach((monitor, indexMonitor) => {
      Object?.entries(monitor?.data)?.forEach(([key, value], index) => {
        // to set all labels with monitor name in the header
        dataListExcelSheet?.[0]?.push(
          `${xlsLanguage?.[key] || key} - ${monitor?.monitorName}`,
        );

        value?.forEach((item, indexRow) => {
          if (indexMonitor === 0 && index === 0) {
            //set value for (first label and first monitor)
            finalDataArray?.push([
              moment.unix(item?.name).utc().format("YYYY-MM-DD"),
              item?.[nameKeyValue],
            ]);
          } else {
            //to push the value in the same row (date)
            finalDataArray?.[indexRow]?.push(item?.[nameKeyValue]);
          }
        });
      });
    });

    //to push each row after append all data for all monitors
    finalDataArray?.forEach((row) => {
      dataListExcelSheet?.push(row);
    });
    return dataListExcelSheet;
  } catch {}
};
