import {
  changeFromObjectToArrayOfObject,
  handleSentimentResponse,
} from "utils/helpers";
import {
  handleCustomerCareStatsData,
  handleFollowersGrowthStatsData,
  getPieDataReachFunnel,
} from "../statsDataHandling";

export const twitterRespHandling = (
  response,
  handleWidgetResponse,
  dataSource,
) => {
  const eventNameObj = {
    Twitter__AccountPage__followers_growth: {
      value: (response) => ({
        data: response?.followers_change_over_time,
        total_followers: response?.total_followers?.[0],
        max_change_of_followers: response?.max_change_of_followers,
        avg_chage_of_day: response?.avg_chage_of_day,
        stats_data: handleFollowersGrowthStatsData(response),
      }),
    },
    Twitter__AccountPage__top_images: {
      value: (response) => ({
        media_data: response?.top_images,
      }),
    },
    Twitter__AccountPage__top_videos: {
      value: (response) => ({
        media_data: response?.top_videos,
      }),
    },
    Twitter__AccountPage__reach_funnel: {
      value: (response) => ({
        data: response?.reach_funnel,
        pie_data: getPieDataReachFunnel(response?.reach_funnel),
      }),
    },
    Twitter__AccountPage__volume_overtime: {
      value: (response) => ({
        data: response?.posts_over_time,
        total_count: response?.total_posts_count,
      }),
    },
    Twitter__EngagementsPage__volume_overtime: {
      value: (response) => ({
        data: response?.posts_over_time,
        total_count: response?.total_posts_count,
      }),
    },
    Twitter__AccountPage__posts_interactions: {
      value: (response) => ({
        data: response?.posts_interactions?.posts_interactions_over_time,
        pie_data: response?.posts_interactions?.posts_interactions_legends,
        max_interactions: response?.posts_interactions?.max_interactions,
        min_interactions: response?.posts_interactions?.min_interactions,
        max_interaction_date:
          response?.posts_interactions?.max_interaction_date,
        min_interaction_date:
          response?.posts_interactions?.min_interaction_date,
        average_interactions:
          response?.posts_interactions?.average_interactions,
      }),
    },
    Twitter__AccountPage__content_type: {
      value: (response) => ({
        data: response?.posts_content_type,
        pie_data: response?.posts_content_types_legends,
      }),
    },
    Twitter__EngagementsPage__sentiment_analysis: {
      value: (response) => ({
        data: handleSentimentResponse(response?.sentiment_overtime),
        pie_data: response?.sentiment_piechart,
      }),
    },
    Twitter__EngagementsPage__associated_topics: {
      value: (response) => ({
        pie_data: response?.associated_topics,
      }),
    },
    Twitter__EngagementsPage__comments_interactions: {
      value: (response) => ({
        data: response?.comments_interactions,
        pie_data: response?.comments_interactions_legends,
        max_interactions: response?.max_interactions,
        min_interactions: response?.min_interactions,
        max_interaction_date: response?.max_interaction_date,
        min_interaction_date: response?.min_interaction_date,
        total_interactions: response?.total_interactions,
        average_interactions: response?.average_interactions,
      }),
    },
    Twitter__EngagementsPage__top_keywords: {
      value: (response) => ({
        pie_data: response?.top_keywords,
      }),
    },
    Twitter__EngagementsPage__top_languages: {
      value: (response) => ({
        pie_data: response?.top_languages,
      }),
    },
    Twitter__AccountPage__account_author_activity: {
      value: (response) => ({
        activity_data: response?.account_author_activity,
        max_value: response?.max_value,
        min_value: response?.min_value,
      }),
    },
    Twitter__EngagementsPage__dialects_and_sub_dialects: {
      value: (response) => ({
        pie_data: response?.dialects,
        pie_sub_data: response?.sub_dialects,
      }),
    },
    Twitter__EngagementsPage__top_countries: {
      value: (response) => ({
        pie_data: response?.top_countries,
      }),
    },
    Twitter__EngagementsPage__top_videos: {
      value: (response) => ({
        media_data: response?.top_videos,
      }),
    },
    Twitter__EngagementsPage__top_images: {
      value: (response) => ({
        media_data: response?.top_images,
      }),
    },
    Twitter__EngagementsPage__top_hashtags: {
      value: (response) => ({
        pie_data: response?.top_hashtags,
      }),
    },
    Twitter__EngagementsPage__content_style: {
      value: (response) => ({
        pie_data: response,
      }),
    },
    Twitter__EngagementsPage__top_cities: {
      value: (response) => ({
        pie_data: response?.top_cities,
      }),
    },
    Twitter__EngagementsPage__gender_distribution: {
      value: (response) => ({
        pie_data: response?.top_genders,
      }),
    },
    Twitter__EngagementsPage__account_types: {
      value: (response) => ({
        pie_data: response?.account_types,
      }),
    },
    Twitter__CustomerCarePage__customer_care_performance: {
      value: (response) => ({
        data: changeFromObjectToArrayOfObject(response?.response_time_segment),
        total_number_of_questions: response?.total_number_of_questions,
        avg_response_time_in_seconds: response?.avg_response_time_in_seconds,
        number_of_answered_questions: response?.number_of_answered_questions,
        stats_data: handleCustomerCareStatsData(response),
      }),
    },
    Twitter__EngagementsPage__top_verified_engagers: {
      value: (response) => ({
        pie_data: response?.top_verified_engagers,
      }),
    },
    Twitter__EngagementsPage__top_influencers: {
      value: (response) => ({
        pie_data: response?.top_influencers,
      }),
    },
    Twitter__EngagementsPage__top_engagers: {
      value: (response) => ({
        pie_data: response?.top_engagers,
      }),
    },
    Twitter__EngagementsPage__reach_funnel: {
      value: (response) => ({
        data: response?.reach_funnel,
        pie_data: getPieDataReachFunnel(response?.reach_funnel),
      }),
    },
  };

  const eventNameKey = eventNameObj?.[response?.eventName];

  if (eventNameKey) {
    handleWidgetResponse(
      response?.metric_id,
      response?.monitor_id,
      dataSource,
      eventNameKey?.value(response?.eventData),
      response?.customized_metric_id,
    );
  }
};
