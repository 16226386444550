import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import "./productsPermissions.scss";
import Permissions from "./permissionDummy";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { faUserCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PermissionsTable = ({
  stepTwoData,
  setStepTwoData,
  permName,
  engagRemaining,
  isView,
  isEngagAcc,
}) => {
  const intl = useIntl();

  return (
    <Box className="perm-table-container">
      <Box className="perm-table-row">
        {Object.keys(Permissions[permName][0])?.map((name, i) => {
          return (
            <Box
              key={i}
              className={
                i
                  ? "perm-table-cell perm-header"
                  : "perm-table-cell first perm-header"
              }
            >
              {i ? (
                isView ? (
                  <Box className="perm-view">
                    {stepTwoData[permName] === name ? (
                      <FontAwesomeIcon icon={faUserCheck} />
                    ) : null}
                    {CheckValueLocale(name, "", {}, intl)}
                  </Box>
                ) : (
                  <FormControlLabel
                    value={name}
                    control={
                      <Radio
                        disabled={
                          isEngagAcc
                            ? false
                            : (name == "team_leader" ||
                                name == "agent_egagment") &&
                              !engagRemaining
                        }
                        checked={stepTwoData[permName] === name}
                        onClick={() =>
                          setStepTwoData({ ...stepTwoData, [permName]: name })
                        }
                      />
                    }
                    label={CheckValueLocale(name, "", {}, intl)}
                  />
                )
              ) : (
                CheckValueLocale(name, "", {}, intl)
              )}
            </Box>
          );
        })}
      </Box>
      {Permissions[permName]?.map((row, x) => {
        return (
          <Box
            className={
              x == Permissions[permName]?.length - 1
                ? "perm-table-row last"
                : "perm-table-row"
            }
          >
            {Object.values(row)?.map((cell, i) => {
              return (
                <Box
                  className={i ? "perm-table-cell" : "perm-table-cell first"}
                >
                  {i ? (
                    <Box
                      className={cell ? "cell-acc correct" : "cell-acc worng"}
                    >
                      {cell ? <CheckIcon /> : <ClearIcon />}
                      {CheckValueLocale(
                        cell ? "access" : "no_acc",
                        "",
                        {},
                        intl,
                      )}
                    </Box>
                  ) : (
                    CheckValueLocale(cell, "", {}, intl)
                  )}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};
export default PermissionsTable;
