import { useQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useUsersAccounts = (
  sortColumn,
  sortDirection,
  pageNum,
  searchText,
  createdBy,
  dataSource,
) => {
  return useQuery({
    queryKey: [
      "usersAccounts",
      sortColumn,
      sortDirection,
      pageNum,
      searchText,
      createdBy,
      dataSource,
    ],
    queryFn: () =>
      CompaniesController.getUsersAuthorizations(
        sortColumn,
        sortDirection,
        pageNum,
        searchText,
        createdBy,
        dataSource,
      ),
    select: (data) => data?.data,
    enabled: !!pageNum,
  });
};
