import { useMutation, useQueryClient } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useDeleteUser = (notEnabled) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId }) => CompaniesController.deleteCompanyUser(userId),
    onSuccess: () => {
      if (!notEnabled) {
        queryClient.invalidateQueries({
          queryKey: ["companyUsers"],
        });
      }
    },
  });
};
