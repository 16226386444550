import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { CheckValueLocale, convertXPlateformToTwitter } from "utils/helpers";
// showHeart,showRetweet,showBanIcon,showScreenName,showDefaultImg,
// hideChartBar,showVector,showFlash,showEye,showEarth

// if any component require to show the following icons/screenName/defaultImage we have to pass a props for that

import CardBody from "./cardBody";
import CardFooter from "./cardFooter";
import CardHeader from "./cardHeader";
import "../../publicAccount/twitterAccountAnalysis/customerCare/customerCare.scss";
import { Box } from "@mui/system";
import FetchingData from "components/fetchingData/fetchingData";
import _ from "lodash";
import { isEmptyArray } from "utils/helpers";
import NoDataFound from "components/no-Data/noDataFound";
import IconFunctions from "../headerIconFunctions";
import {
  getDatasourceIconName,
  handlelUserRoles,
  retrieveActiveProductsArray,
} from "utils/helpers/index.js";
import { faBan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PostQuestionPopup from "./components/postQuestionPopup";
import InsightsController from "services/controllers/insightsController";
import MonitorsController from "services/controllers/monitorsController.js";
import xPlatform from "images/social-icons/x-platform-white-icon.svg";
import { useThemesOptions } from "components/sentimentCategoriesThemes/hooks/useThemesOptions";
import { useCategoriesOptions } from "components/sentimentCategoriesThemes/hooks/useCategoriesOptions";
import { useSentimentsOptions } from "components/sentimentCategoriesThemes/hooks/useSentimentsOptions";

const QuestionCard = (props) => {
  const intl = useIntl();
  const {
    snackBarData,
    openSnackBar,
    handleCloseSnack,
    postSpamTweets,
    handleSpamPost,
    handleUndoSpam,
    getMoreInsights,
  } = IconFunctions();

  const {
    latestUnanswer,
    latestUnanswerPreloader,
    preLoaderTrackerDataSources,
    icon,
    iconColor,
    showHeart,
    showRetweet,
    showBanIcon,
    showScreenName,
    showDefaultImg,
    quickInspectClass,
    hideChartBar,
    widget,
    hideVector,
    showFlash,
    showEye,
    showEarth,
    newsBlogImg,
    updateAnalysis,
    singleTweetAnalysis,
    insightsID,
    response,
    dates,
    datasourcePostsType,
    isAggregateDashboard,
    isPostsManagedWidget,
    isCustomerCareAggreagte,
    isCommentsMentionsAggregate,
    hideChangeSentiment,
    hideWhenRetweeted,
    activePostsTab,
    isQuickSearch,
    isAlert,
    showProfile,
    unixDate,
    productId,
    isTwitterLiveDashBoard,
    isInstagramLiveDashBoard,
    isLiveDashboardPublic,
  } = props;

  const exploreShowFlash = (datasourceName) => {
    return datasourceName == "TALKWALKER" ? true : false;
  };
  const urlParams = useParams();
  const monitorType = urlParams.monitorType;
  const monitorDataSource = convertXPlateformToTwitter(
    urlParams.monitorDataSource,
  );
  const activeTab = urlParams.activeTab;
  const [allawedTrackers, setAllawedTrackers] = useState([]);
  const [replyModalData, setReplyModalData] = useState([]);
  const [isReplyDeleted, setIsReplyDeleted] = useState([]);
  const [activeReplyEdit, setActiveReplyEdit] = useState([]);
  const [isThemesPolicy, setIsThemesPolicy] = useState(false);
  const [openPopup, setOpenPopup] = useState({
    open: true,
    item: {},
    done: false,
  });

  const getThemesPolicy = () => {
    InsightsController.getApplyPolicy().then((result) => {
      if (result?.data?.length > 0) {
        result?.data?.map((item) => {
          if (item?.policy_type == "themes") {
            return setIsThemesPolicy(false);
          }
        });
      } else {
        setIsThemesPolicy(true);
      }
    });
  };

  useEffect(() => {
    !window?.location?.href?.includes("/live-dashboard/public/") &&
      getThemesPolicy();
    if (
      monitorDataSource !== "NEWSBLOGS" &&
      handlelUserRoles("ENGAGEMENT_ACCESS", "SL_ENGAGEMENT") &&
      retrieveActiveProductsArray()?.includes("ENGAGEMENTS") &&
      (isAggregateDashboard === undefined || !isAggregateDashboard) &&
      !isAlert
    ) {
      MonitorsController.getAllawedMonitors(window?.localStorage?.sm_id).then(
        (AllawedData) => {
          if (AllawedData?.data?.data?.monitors_list?.length) {
            setAllawedTrackers([...AllawedData?.data?.data?.monitors_list]);
          }
        },
      );
    }
  }, []);

  // Card Body Text [ if text is deleted]
  const deleteReplyMsg = () => {
    return (
      <Box className="delete-msg-wrapper">
        <Box className="delete-msg-comments">
          <FontAwesomeIcon icon={faBan} />
          {CheckValueLocale("comment_was_deleted", "", {}, intl)}
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    let replyData = [];
    latestUnanswer?.map((question) => {
      replyData.push({
        selectedValue: "",
        replyTime: "",
      });
      setIsReplyDeleted([...isReplyDeleted, false]);
      setActiveReplyEdit([...activeReplyEdit, false]);
    });
    setReplyModalData(replyData);
  }, [latestUnanswer]);

  const { data: themesOptions } = useThemesOptions({ locale: intl?.locale });
  const { data: categoriesOptions } = useCategoriesOptions({
    locale: intl?.locale,
  });
  const { data: sentimentsOptions } = useSentimentsOptions({
    locale: intl?.locale,
  });

  return (
    <>
      {_.isEqual(
        preLoaderTrackerDataSources?.sort(),
        latestUnanswerPreloader?.sort(),
      ) !== true ? (
        <FetchingData />
      ) : isEmptyArray(latestUnanswer) ? (
        <Box
          className={
            props.className !== undefined
              ? `${props.className} fetching-data-style`
              : "fetching-data-style"
          }
        >
          <NoDataFound />
        </Box>
      ) : (
        <div
          className={
            quickInspectClass === undefined
              ? `topposts-wrapper ${
                  monitorDataSource === "FACEBOOK" && "topposts-wrapper-fb"
                }`
              : quickInspectClass
          }
        >
          {latestUnanswer?.map((question, index) => {
            const isTwitter =
              monitorDataSource === "TWITTER" ||
              monitorDataSource === "TWITTER_PUBLIC" ||
              question?.datasourceTypePosts === "TWITTER" ||
              question?.datasourceTypePosts === "TWITTER_PUBLIC" ||
              isTwitterLiveDashBoard;
            return (
              <Box
                className={`post-item question-card-items ${
                  showBanIcon && "post-height-overlay"
                }`}
                key={index}
              >
                <CardHeader
                  productId={productId}
                  unixDate={unixDate}
                  showProfile={showProfile}
                  question={question}
                  icon={
                    isAggregateDashboard && !props?.isPostsAggregate
                      ? isTwitter
                        ? xPlatform
                        : getDatasourceIconName(question?.datasourceTypePosts)
                      : icon
                  }
                  iconColor={
                    isAggregateDashboard && !props?.isPostsAggregate
                      ? `${question?.datasourceTypePosts?.toLowerCase()}_icon`
                      : iconColor
                  }
                  showBanIcon={
                    isAggregateDashboard &&
                    question?.datasourceTypePosts === "TWITTER"
                      ? true
                      : showBanIcon
                  }
                  showScreenName={showScreenName}
                  showDefaultImg={showDefaultImg}
                  hideChartBar={
                    isAggregateDashboard &&
                    !isPostsManagedWidget &&
                    question?.datasourceTypePosts !== "TWITTER" &&
                    ((props?.isCustomerCareAggreagte &&
                      question?.datasourceTypePosts === "INSTAGRAM") ||
                      (question?.datasourceTypePosts !== "INSTAGRAM" &&
                        (monitorType !== "MANAGED_ACCOUNT" ||
                          monitorType !== "MANAGED_PAGE")) ||
                      (question?.datasourceTypePosts === "INSTAGRAM" &&
                        isCommentsMentionsAggregate))
                      ? true
                      : hideChartBar
                  }
                  hideVector={hideVector}
                  newsBlogImg={newsBlogImg}
                  monitorDataSource={monitorDataSource}
                  updateAnalysis={updateAnalysis}
                  monitorType={monitorType}
                  snackBarData={snackBarData}
                  openSnackBar={openSnackBar}
                  handleCloseSnack={handleCloseSnack}
                  postSpamTweets={postSpamTweets}
                  handleSpamPost={handleSpamPost}
                  handleUndoSpam={handleUndoSpam}
                  getMoreInsights={getMoreInsights}
                  insightsID={insightsID}
                  response={response}
                  dates={dates}
                  noOfChar={props.noOfChar}
                  singleTweetAnalysis={singleTweetAnalysis}
                  datasourcePostsType={
                    datasourcePostsType
                      ? datasourcePostsType
                      : question?.datasourceTypePosts
                  }
                  index={index}
                  replyModalData={replyModalData}
                  isAggregateDashboard={isAggregateDashboard}
                  isAlert={isAlert}
                  setIsReplyDeleted={setIsReplyDeleted}
                  isReplyDeleted={isReplyDeleted}
                  setActiveReplyEdit={setActiveReplyEdit}
                  activeReplyEdit={activeReplyEdit}
                  activeTab={activeTab}
                  allawedTrackers={allawedTrackers}
                  isTwitterLiveDashBoard={isTwitterLiveDashBoard}
                  isInstagramLiveDashBoard={isInstagramLiveDashBoard}
                  isLiveDashboardPublic={!!isLiveDashboardPublic}
                />
                {isReplyDeleted[index] && monitorDataSource !== "NEWSBLOGS" ? (
                  deleteReplyMsg()
                ) : (
                  <>
                    <CardBody
                      question={question}
                      monitorDataSource={monitorDataSource}
                      showHeart={showHeart}
                      showRetweet={showRetweet}
                      widget={widget}
                      datasourcePostsType={
                        datasourcePostsType
                          ? datasourcePostsType
                          : question?.datasourceTypePosts
                      }
                      activePostsTab={activePostsTab}
                      monitorType={monitorType}
                      activeTab={activeTab}
                      isAggregateDashboard
                      isReplyDeleted={isReplyDeleted}
                      activeReplyEdit={activeReplyEdit}
                      index={index}
                      setActiveReplyEdit={setActiveReplyEdit}
                    />

                    {!activeReplyEdit[index] ? (
                      <CardFooter
                        question={question}
                        showHeart={showHeart}
                        showRetweet={showRetweet}
                        monitorDataSource={monitorDataSource}
                        showFlash={
                          isQuickSearch
                            ? exploreShowFlash(question?.datasourceTypePosts)
                            : showFlash
                        }
                        showEye={showEye}
                        showEarth={showEarth}
                        monitorType={monitorType}
                        getMoreInsights={getMoreInsights}
                        insightsID={insightsID}
                        response={response}
                        dates={dates}
                        datasourcePostsType={
                          datasourcePostsType
                            ? datasourcePostsType
                            : question?.datasourceTypePosts
                        }
                        isAggregateDashboard={isAggregateDashboard}
                        hideChangeSentiment={
                          hideChangeSentiment ? hideChangeSentiment : false
                        }
                        hideWhenRetweeted={
                          hideWhenRetweeted &&
                          question?.retweeted_status !== null
                        }
                        activeTab={activeTab}
                        isLiveDashboardPublic={!!isLiveDashboardPublic}
                        categoriesOptions={categoriesOptions}
                        themesOptions={themesOptions}
                        sentimentsOptions={sentimentsOptions}
                        isLiveDashboard={props?.isLiveDashboard}
                        isQuickSearch={isQuickSearch}
                      />
                    ) : null}
                  </>
                )}
              </Box>
            );
          })}
        </div>
      )}
      {(activeTab === "comments_mentions" ||
        activeTab === "questions" ||
        activeTab === "engagements" ||
        activeTab === "customer_care") &&
      isThemesPolicy ? (
        <PostQuestionPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setIsThemesPolicy={setIsThemesPolicy}
        />
      ) : null}
    </>
  );
};
export default QuestionCard;
