import { Box } from "@mui/material";
import Services from "services/api/Services";
import { CheckValueLocale } from "utils/helpers/index.js";
import { useIntl } from "react-intl";

const HeaderPagePdf = () => {
  const intl = useIntl();
  return (
    <Box className="main-pdf-header-text">
      <Box
        component={"img"}
        src={Services?.projectLogoPNG}
        alt="projectLogoSVG"
      />
      {Services?.showPoweredBy ? (
        <Box component={"p"} className="pdf-header-text-dashboard">
          {CheckValueLocale("pdf_header_text_dashboard", "", {}, intl)}

          <Box component={"b"}>
            {intl?.locale === "en"
              ? Services?.project_name_en
              : Services?.project_name_ar}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default HeaderPagePdf;
