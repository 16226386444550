import { Skeleton, Stack } from "@mui/material";

export const FirstBodyCellLoading = () => {
  return (
    <Stack direction="row" justifyContent="flex-start" spacing={2}>
      <Skeleton animation="wave" variant="circular" width={40} height={40} />
      <Stack direction="column" mb={4}>
        <Skeleton
          animation="wave"
          height={10}
          width="50px"
          style={{ marginBottom: 6 }}
        />
        <Skeleton
          animation="wave"
          height={10}
          width="100px"
          style={{ marginBottom: 6 }}
        />
      </Stack>
    </Stack>
  );
};
