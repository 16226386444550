import { useMutation, useQueryClient } from "@tanstack/react-query";
import SettingsController from "services/controllers/settingsController";

export const useAddCustomDomain = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (custom_domain) =>
      SettingsController?.addCustomDomain(custom_domain),
    onSuccess: () =>
      queryClient?.invalidateQueries({ queryKey: ["companyInfo"] }),
    select: (data) => data?.data,
    retry: false,
  });
};
