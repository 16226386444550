import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useIntl } from "react-intl";
import { CheckValueLocale } from "utils/helpers";
import { usePackagesQuota } from "./hooks/usePackagesQuota";
import { useHistoricalRequestStatus } from "./hooks/useHistoricalRequestStatus";
import { useCancellableDatasources } from "./hooks/useCancellableDatasources";
import { useHistoricalRequests } from "./hooks/useHistoricalRequests";
import { sortedColumnTypes, useSortableTable } from "hooks/useSortableTable";
import { generateHistoricalTableHeader } from "./components/historicalRequestTable/generateHistoricalTableHeader";
import SnackBar from "components/snackBar";
import CircularLoading from "components/circularProgress";
import PageHeader from "components/pageHeader";
import PackagesQuota from "./components/packagesQuota";
import HistoricalRequestTable from "./components/historicalRequestTable";
import "./style.scss";

const HistoricalRequestPage = () => {
  const intl = useIntl();

  const [page, setPage] = useState(1);
  const [snackbar, setSnackbar] = useState({});

  const [remainNewsBlogs, setRemainNewsBlogs] = useState(0);
  const [remainPosts, setRemainPosts] = useState(0);
  const [remainInteractions, setRemainInteractions] = useState(0);

  const { data: packagesQuota, isPending: quotaLoading } = usePackagesQuota({
    enableFetch: true,
  });

  const { data: processingIndicator, isLoading: statusLoading } =
    useHistoricalRequestStatus({
      enableFetch: true,
    });

  const {
    data: cancellableDatasources,
    isPending: cancellableDatasourcesLoading,
  } = useCancellableDatasources();

  const onSortChangeCallback = () => setPage(1);

  const {
    columns: tableHeaders,
    sortColumn,
    sortDirection,
  } = useSortableTable({
    initialColumns: generateHistoricalTableHeader(sortedColumnTypes, intl),
    onSortChangeCallback,
  });

  const {
    data: historicalRequestsData,
    isPending: isHistoricalRequestsLoading,
  } = useHistoricalRequests({ page, sortColumn, sortDirection });

  useEffect(() => {
    if (packagesQuota) {
      for (let i = 0; i < packagesQuota?.length; i++) {
        if (packagesQuota?.[i]?.name === "SM") {
          setRemainNewsBlogs(
            packagesQuota?.[i]?.quota?.news_blogs?.limit -
              packagesQuota?.[i]?.quota?.news_blogs?.used,
          );
          setRemainPosts(
            packagesQuota?.[i]?.quota?.posts_limit?.limit -
              packagesQuota?.[i]?.quota?.posts_limit?.used,
          );
        }
        if (packagesQuota?.[i]?.name === "CXM") {
          setRemainInteractions(
            packagesQuota?.[i]?.quota?.interactions?.limit -
              packagesQuota?.[i]?.quota?.interactions?.used,
          );
        }
      }
    }
  }, [packagesQuota]);

  return (
    <Box id="historical-request-page-container">
      {quotaLoading || cancellableDatasourcesLoading || statusLoading ? (
        <CircularLoading />
      ) : (
        <Box id="historical-request-page">
          <PageHeader
            title="historical_request_page_title"
            titleId="secondary-pages-header"
          />

          <PackagesQuota data={packagesQuota} setSnackbar={setSnackbar} />

          <HistoricalRequestTable
            isSortableLoading={isHistoricalRequestsLoading}
            historicalRequestsData={historicalRequestsData}
            page={page}
            setPage={setPage}
            tableHeaders={tableHeaders}
            setSnackbar={setSnackbar}
            remainNewsBlogs={remainNewsBlogs}
            remainPosts={remainPosts}
            remainInteractions={remainInteractions}
            processingIndicator={processingIndicator}
            cancellableDatasources={cancellableDatasources}
          />
        </Box>
      )}

      <SnackBar
        open={snackbar?.open}
        handleClose={() => setSnackbar({})}
        severity={snackbar?.severity}
        title={CheckValueLocale(snackbar?.title, "", {}, intl)}
        message={CheckValueLocale(snackbar?.message, "", {}, intl)}
        alertStyle={{ maxWidth: "320px" }}
      />
    </Box>
  );
};

export default HistoricalRequestPage;
