import { Box } from "@mui/material";
import "./aiAgentsContent.scss";
import ContentEmpty from "./contentEmpty";
import AiAgentsForm from "./aiAgentsForm";
import { useAiAgentDetails } from "../../aiAgentsHooks/useAiAgentDetails";
import CircularLoading from "components/circularProgress";
import { useContext, useEffect } from "react";
import { AiAgentsContext } from "../../aiAgentsContext";
import { useAiAgentPublishedVersionDetails } from "../../aiAgentsHooks/useAiAgentPublishedVersionDetails";

const AiAgentsContent = () => {
  const {
    aiAgentSelected,
    selectedAiAgentPublishedVersion,
    setAiAgentPublishError,
  } = useContext(AiAgentsContext);
  const {
    data: publishedAiAgentDetails,
    isLoading: isPublishedVersionDetailsLoading,
  } = useAiAgentPublishedVersionDetails(selectedAiAgentPublishedVersion);

  const { data: aiAgentListResponse, isLoading: isAiAgentDetailsLoading } =
    useAiAgentDetails(aiAgentSelected);
  const aiAgentDetails = selectedAiAgentPublishedVersion
    ? {
        ...publishedAiAgentDetails?.data?.data,
        user: publishedAiAgentDetails?.data?.user,
        ai_agent_version_id: publishedAiAgentDetails?.data?.id,
        ai_agent_created_at: publishedAiAgentDetails?.created_at,
      }
    : aiAgentListResponse?.data;

  useEffect(() => {
    if (
      (aiAgentDetails?.publish_error === "publish_file_error" &&
        aiAgentDetails?.publish_error_files?.length > 0) ||
      aiAgentDetails?.publish_error !== "publish_file_error"
    ) {
      setAiAgentPublishError({
        message: aiAgentDetails?.publish_error,
        files: aiAgentDetails?.publish_error_files?.join(", ") || "",
      });
    } else {
      setAiAgentPublishError({
        message: "",
        files: "",
      });
    }
  }, [
    aiAgentDetails?.publish_error,
    JSON.stringify(aiAgentDetails?.publish_error_files),
  ]);

  return (
    <Box className="ai-agents-content-container">
      {!aiAgentSelected ? (
        <ContentEmpty />
      ) : isAiAgentDetailsLoading || isPublishedVersionDetailsLoading ? (
        <CircularLoading />
      ) : !aiAgentDetails ? (
        <ContentEmpty />
      ) : (
        <AiAgentsForm aiAgentDetails={aiAgentDetails} />
      )}
    </Box>
  );
};

export default AiAgentsContent;
