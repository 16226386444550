import { useQuery } from "@tanstack/react-query";
import CompaniesController from "services/controllers/companiesController";

export const useGetAccount = (userEditID, limit, pageNum, enabled) => {
  return useQuery({
    queryKey: ["accessAccount", { pageNum, enabled }],
    queryFn: () => {
      if (userEditID) {
        return CompaniesController.getAccountsEdit(pageNum, limit, userEditID);
      } else return CompaniesController.getAccounts(pageNum, limit);
    },
    enabled: !!pageNum && enabled,
    retry: false,
  });
};
