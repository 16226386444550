import { useIntl } from "react-intl";
import { Box, TextField, Typography } from "@mui/material";
import { CheckValueLocale, commasAfterDigit } from "utils/helpers";

const HistoricalRequestStepTwo = (props) => {
  const { formik, remainQuota, xPlatformId, xPlatformActualCount } = props;

  const intl = useIntl();

  return (
    <Box className="stepTwo">
      <Box className="shared-dialog-body">
        <Typography id="post-limit-msg-content">
          {CheckValueLocale("post_limit_msg", "", {}, intl)}
        </Typography>
      </Box>
      <Box className="remaining-posts">
        <span>{CheckValueLocale("remaining_posts", "", {}, intl)}</span>{" "}
        <b>{commasAfterDigit(remainQuota)}</b>
      </Box>
      <TextField
        className="create-historical-select create-historical-input"
        placeholder={CheckValueLocale("EX:30", "", {}, intl)}
        name="count"
        value={formik?.values?.["count"]}
        onChange={(e) => {
          formik?.setFieldTouched("count", true);
          formik?.setFieldValue("count", e.target.value);
        }}
        onBlur={formik?.handleBlur}
        type="number"
        min="0"
        variant="outlined"
        size="small"
        error={formik?.errors?.["count"] !== undefined}
        helperText={
          formik?.touched?.["count"] && Boolean(formik?.errors?.["count"]) ? (
            <Typography variant="h6" id="historical-count-validation-msg">
              {CheckValueLocale(formik?.errors?.["count"], "", {}, intl)}
            </Typography>
          ) : null
        }
      />

      {formik?.values?.["data_source_id"] === xPlatformId ? (
        <Box className="estimated-data-twitter">
          <Box>{CheckValueLocale("estimated_twitter", "", {}, intl)}</Box>
          <Box component="span" fontSize={16}>
            {xPlatformActualCount}
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default HistoricalRequestStepTwo;
