import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AiAgentsServices } from "services/api/aiAgents";

export const useEditPublishedVersion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ queryData }) =>
      AiAgentsServices.editPublishedVersion(queryData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["aiAgentList"] });
      queryClient.invalidateQueries({ queryKey: ["aiAgentResources"] });
    },
  });
};
