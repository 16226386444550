import { Box, Modal, Slider } from "@mui/material";
import { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import "./editImageModal.scss";
import { useIntl } from "react-intl";
import { CheckValueLocale, handlelUserRoles } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import LucButton from "shared/lucButton/lucButton";
import DropZoneArea from "../dropZone";
import getCroppedImg from "./cropUtils";

const EditImageModal = (props) => {
  const { formik, open, handleClose } = props;

  const intl = useIntl();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedArea, setCroppedArea] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState(null);
  const [saveImageLoading, setSaveImageLoading] = useState(false);

  useEffect(() => {
    if (open) setImage(formik?.values?.logo);
  }, [open, formik?.values?.logo]);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSubmit = async () => {
    setSaveImageLoading(true);
    const newImg = await getCroppedImg(
      image,
      croppedArea,
      formik?.values?.logo_details,
    ); // Await the result to get the File object
    if (newImg) formik?.setFieldValue("logo", newImg); // Set Logo with cropped image
    await setSaveImageLoading(false);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="edit-image-branding-modal">
        <Box className="header-edit-image-branding-modal">
          <Box className="title">
            {CheckValueLocale("edit_Your_Logo_title", "", {}, intl)}
          </Box>
          <LucButton
            id="remove-logo-button"
            type="secondary"
            variant="flat"
            size="small"
            minWidth={32}
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faX} />
          </LucButton>
        </Box>
        <Box className="container-image-cropper">
          {image ? (
            <>
              <Box className="cropper">
                <Cropper
                  image={
                    image instanceof File ? URL.createObjectURL(image) : image
                  }
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </Box>

              <Box className="slider">
                <Slider
                  min={1}
                  max={3}
                  step={0.1}
                  value={zoom}
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </Box>
            </>
          ) : (
            <DropZoneArea formik={formik} setImage={setImage} editModal />
          )}
        </Box>

        <Box className="edit-modal-container-buttons">
          <LucButton
            id="settings-branding-upload-logo-remove-btn"
            variant="outline"
            disabled={
              !handlelUserRoles("GENERAL", "EDIT_REPORT_BRANDING") || !image
            }
            onClick={() => setImage(null)}
          >
            {CheckValueLocale("remove", "", {}, intl)}
          </LucButton>
          <Box className="edit-modal-submit">
            <LucButton
              id="settings-branding-upload-logo-cancel-btn"
              variant="flat"
              type="secondary"
              onClick={handleClose}
              className="cancel"
            >
              {CheckValueLocale("cancel", "", {}, intl)}
            </LucButton>

            <LucButton
              id="settings-branding-upload-logo-save-btn"
              onClick={onSubmit}
              loading={saveImageLoading}
              disabled={!image}
            >
              {CheckValueLocale("save", "", {}, intl)}
            </LucButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditImageModal;
