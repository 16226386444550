import APIIcon from "images/product-icons/api-icon.svg";
import EngagementIcon from "images/product-icons/engagement-icon.svg";
import AiAgentsIcon from "images/ai-agents/ai-agents-icon.svg";
import DCPIcon from "images/product-icons/cdp-icon.svg";
import OCIcon from "images/product-icons/oc.icon.svg";
import SettingIcon from "images/product-icons/settings-icon.svg";
import SLIcon from "images/product-icons/sl-icon.svg";
import Surveys from "images/product-icons/surveys-icon.svg";
import { Box } from "@mui/material";
import { CheckValueLocale } from "utils/helpers";
import { useIntl } from "react-intl";
import CustomizedTooltip, {
  customizedStyledTooltip,
} from "components/customizedTooltip";

const PermissionBodyCell = ({ companyActiveProduct, user, index }) => {
  const intl = useIntl();
  const iconMapper = {
    ENGAGEMENTS: EngagementIcon,
    SM: SLIcon,
    CDP: DCPIcon,
    CXM: OCIcon,
    PUBLIC_API: APIIcon,
    GENERAL: SettingIcon,
    SURVEY: Surveys,
    AI_AGENT: AiAgentsIcon,
  };

  let roleMapper = {
    product_admin: "manager",
    viewer: "viwer",
    user: "moderator",
    team_leader_egag: "team_leader",
    agent_egag: "agent_egagment",
    no_access_egag: "no_acc",
  };
  let activeProducts = user?.attributes?.permissions
    ? user?.attributes?.permissions?.filter(
        (val) =>
          companyActiveProduct?.includes(val?.product) ||
          val?.product === "GENERAL" ||
          (val?.product === "PUBLIC_API" &&
            companyActiveProduct?.includes("AI_API")),
      )
    : [];
  return (
    <Box className="main-permissions-products" key={index}>
      {activeProducts?.map((product, i) => {
        const roleName = CheckValueLocale(
          roleMapper[
            product?.product == "ENGAGEMENTS" || product?.product == "AI_AGENT"
              ? `${product?.role}_egag`
              : product?.role
          ],
          "",
          {},
          intl,
        );
        const productName = CheckValueLocale(
          product?.product?.toLowerCase() === "general"
            ? "general_setting"
            : product?.product,
          "",
          {},
          intl,
        );
        return (
          <CustomizedTooltip
            key={i}
            title={
              roleName +
              CheckValueLocale("permissions_products_in", "", {}, intl) +
              productName
            }
            arrow
            placement="top"
            styles={customizedStyledTooltip}
          >
            <Box className="permissions-product" key={i}>
              <img src={iconMapper[product?.product]} alt="product" />
              <Box className="permissions-product-role">
                {`( 
              ${roleName}
              )`}
              </Box>
            </Box>
          </CustomizedTooltip>
        );
      })}
    </Box>
  );
};

export default PermissionBodyCell;
