let baseRoute;
switch (process.env.REACT_APP_ENV) {
  case "production":
  case "production_local":
    baseRoute = "wss://backend2-lb.lucidya.com/";
    break;
  case "falcon":
    baseRoute = "wss://backend2.falcon-site.com/";
    break;
  case "staging_port":
  case "staging_port_local":
    //STG WITH CERTAIN PORT
    baseRoute = `wss://stg-backend-${process.env.WDS_SOCKET_PATH}.lucidya.com/`;
    break;
  default: // "staging" or "staging_local"
    baseRoute = "wss://stg-backend2.lucidya.com/";
}

const useHandleSocket = (queryURL, setResponse) => {
  const cable = new WebSocket(`${baseRoute}${queryURL}`);

  const subscribeToChannel = (subscribeMessageIdentifier) => {
    const subscribeMessage = {
      command: "subscribe",
      identifier: JSON.stringify({ ...(subscribeMessageIdentifier || {}) }),
      //Example: on the object to send to backEnd to start the subscribe.
      // subscribeMessageIdentifier = {
      //   channel: "LiveDashboardsChannel",
      //   filter_date: dateRange,
      // }
    };

    if (cable?.readyState === WebSocket.OPEN) {
      // to start the subscribe
      cable?.send(JSON.stringify(subscribeMessage));
    } else if (window.location.host !== "cxm.lucidya.com") {
      console.warn("WebSocket not open:", cable?.readyState);
    }
  };
  const unsubscribeToChannel = (unsubscribeMessageIdentifier) => {
    const unsubscribeMessage = {
      command: "unsubscribe",
      identifier: JSON.stringify({ ...(unsubscribeMessageIdentifier || {}) }),
      //Example: on the object to send to backEnd to end the subscribe. The same as the subscribe object was sent before.
      // unsubscribeMessageIdentifier= {
      //   channel: "LiveDashboardsChannel",
      //   filter_date: dateRange,
      // }
    };
    if (cable?.readyState === WebSocket.OPEN) {
      // to end the subscribe
      cable.send(JSON.stringify(unsubscribeMessage));
    } else if (window.location.host !== "cxm.lucidya.com") {
      console.log("WebSocket not open");
    }
  };
  cable.onmessage = function (event) {
    const response = JSON.parse(event?.data);
    if (response?.type !== "ping") {
      if (
        response?.type === "welcome" &&
        window.location.host !== "cxm.lucidya.com"
      ) {
        console.log("--welcome-- can start any subscription");
      } else if (
        response?.type === "confirm_subscription" &&
        window.location.host !== "cxm.lucidya.com"
      ) {
        console.log("new Subscription");
      }
      if (window.location.host !== "cxm.lucidya.com") {
        console.log(response);
      }
      setResponse(response);
    }
  };

  cable.onclose = function () {
    if (window.location.host !== "cxm.lucidya.com") {
      console.log("WebSocket connection closed");
    }
  };

  // to control subscribe and unsubscribe from any component used useHandleSocket
  return { subscribeToChannel, unsubscribeToChannel, cable };
};
export default useHandleSocket;
